import React from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Col, Row } from 'react-bootstrap';
import { ComboboxInput, FormInput, FormTextArea } from '../../Lib/Core/Work365FormInputs';
import { Utils } from '../../Services/Utils'
import { Global } from '../../Helpers/Global';
import Work365FlexiGrid, { IGridState } from '../../Lib/Core/Grid/Work365FlexiGrid';
import { CompositeFilterDescriptor, SortDescriptor, groupBy, orderBy, filterBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import Work365RadioButton from '../../Lib/Core/RadioButton/Work365RadioButton';
import Work365TreeView, { ITreeViewDataItem } from '../../Lib/Core/TreeView/Work365TreeView';
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs';
import Work365Switch from '../../Lib/Core/Work365Switch';
import Work365Checkbox from '../../Lib/Core/Checkbox/Work365Checkbox';
import { Splitter } from '@progress/kendo-react-layout';
import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import Work365Chart, { IChartSeriesItemProps } from '../../Lib/Core/Work365Chart';
import Work365InputLink from '../../Lib/Core/Work365InputLink';
import Work365DropdownList from '../../Lib/Core/Work365DropDownList';
import { Work365Column } from '../../Lib/Models/Work365Column';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

export interface ITenantProps {
    tenant: any | undefined;
}

export interface IOption {
    name: string;
    value: string;
}

export const pasreRevenueConfig: any = (data: any, strategyOptions: IOption[] | undefined) => {
    var obj = undefined as any;
    if (data.usageSummaryRevenueConfig)
        obj = JSON.parse(data.usageSummaryRevenueConfig);

    if (obj != undefined) {
        const strategy: any = (strategyOptions ?? data.strategyParams)?.find(
            (b: any) => b.value === obj.Strategy
        );

        data.strategyOption = strategy;
    } else {
        data.strategyOption = undefined;
    }

    data.commission = obj?.StrategyParams?.Commission
    data.amount = obj?.StrategyParams?.MaxAmount ?? obj?.StrategyParams?.MinAmount

    return data;
}

export interface IGeneralTabProps extends ITenantProps {
    licensePlanOptions?: IOption[] | undefined,
    customerOptions?: IOption[] | undefined,
    billingContractSubscriptionIdsOptions?: IOption[] | undefined,
    licensePlanSubscriptionIdsOptions?: IOption[] | undefined,
    coreSolutionVersion: string,
    portalSolutionVersion: string,
    revenueConfig: any
}

export interface IScheduledJobProps extends ITenantProps {
    tableWidth?: number | undefined;
    tableHeight?: number | undefined;
}

export interface IProviderBillingProps extends ITenantProps {
    tableWidth?: number | string | undefined;
    tableHeight?: number | string | undefined;
    showTitle?: boolean
}

export interface IRegistrationsProps extends ITenantProps {
}

interface IAddOnsTreeviewProps extends ITreeViewDataItem {
    fromLicense?: boolean;
    tenantFeatureSubscriptionId?: string | null;
    featureId?: number;
}

export const GeneralTab: React.FC<IGeneralTabProps> = (props: IGeneralTabProps) => {
    const [bcFieldState, setBCFieldState] = React.useState(false);
    const [lpFieldState, setLPFieldState] = React.useState(false);
    const [revenueConFig, setRevenueConfig] = React.useState(props.revenueConfig)
    const [environment, setEnvironment] = React.useState(props.tenant);

    var userString = localStorage.getItem('user');
    var disableControls = true;

    if (userString != undefined) {
        var user: any = JSON.parse(userString);
        if (user.isAdministrator || user.isManager) {
            disableControls = false;
        }
    }

    //License Plan Options
    const licensePlans = props.licensePlanOptions?.map<IOption>((x: any) => {
        return {
            name: x.planName,
            value: x.planId
        }
    })?.sort((a: any, b: any) => {
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    if (props.tenant.licensingPlanId != undefined) {
        const licensePlan: any = licensePlans?.find(
            (b: any) => b.value === props.tenant.licensingPlanId
        );

        props.tenant.licensingPlanOption = licensePlan
    } else {
        props.tenant.licensingPlanOption = undefined;
    }

    // Customer Options
    const customers = props.customerOptions?.map<IOption>((x: any) => {
        return {
            name: x.name,
            value: x.customerId
        }
    });

    if (props.tenant.customerId != undefined) {
        const customer: any = customers?.find(
            (b: any) => b.value === props.tenant.customerId
        );

        props.tenant.customerOption = customer;
    } else {
        props.tenant.customerOption = undefined;
    }

    // Billing Contract Subscription Ids Options
    const billingContractSubscriptionIds = props.billingContractSubscriptionIdsOptions?.map<IOption>((x: any) => {
        return {
            name: x.id,
            value: x.id
        }
    });

    if (props.tenant.billingContractSubscriptionId != undefined) {
        const billingContractSubscriptionId: any = billingContractSubscriptionIds?.find(
            (b: any) => b.value === props.tenant.billingContractSubscriptionId
        );
        if (!billingContractSubscriptionId) {
            billingContractSubscriptionIds?.push({ name: props.tenant.billingContractSubscriptionId, value: props.tenant.billingContractSubscriptionId })
            props.tenant.billingContractSubscriptionIdsOption = { name: props.tenant.billingContractSubscriptionId, value: props.tenant.billingContractSubscriptionId };
        } else {
            props.tenant.billingContractSubscriptionIdsOption = billingContractSubscriptionId;
        }
    } else {
        props.tenant.billingContractSubscriptionIdsOption = undefined;
    }

    // License Plan Subscription Ids Options
    const licensePlanSubscriptionIds = props.licensePlanSubscriptionIdsOptions?.map<IOption>((x: any) => {
        return {
            name: x.id ?? "",
            value: x.id ?? "",
        }
    });

    if (props.tenant.licensePlanSubscriptionId != undefined) {
        const licensePlanSubscriptionId: any = licensePlanSubscriptionIds?.find(
            (b: any) => b.value === props.tenant.licensePlanSubscriptionId
        );

        if (!licensePlanSubscriptionId) {
            licensePlanSubscriptionIds?.push({ name: props.tenant.licensePlanSubscriptionId, value: props.tenant.licensePlanSubscriptionId })
            props.tenant.licensePlanSubscriptionIdsOption = { name: props.tenant.licensePlanSubscriptionId, value: props.tenant.licensePlanSubscriptionId };
        } else {
            props.tenant.licensePlanSubscriptionIdsOption = licensePlanSubscriptionId;
        }
    } else {
        props.tenant.licensePlanSubscriptionIdsOption = undefined;
    }

    //Created date
    if (props.tenant.createdOnUtc != undefined) {
        props.tenant.created = Utils.ToDisplayDateFormat(props.tenant.createdOnUtc, true, 'fr-CA', 'h')
    } else {
        props.tenant.created = undefined
    }

    if (props.tenant.deactivatedOnUtc != undefined) {
        props.tenant.deactivated = Utils.ToDisplayDateFormat(props.tenant.deactivatedOnUtc, true, 'fr-CA', 'h')
    } else {
        props.tenant.deactivated = undefined
    }

    props.tenant.coreSolutionVersion = props.coreSolutionVersion;
    props.tenant.portalSolutionVersion = props.portalSolutionVersion;

    const handleLicensePlanChange = (e: any) => {
        props.tenant.licensingPlanId = e.value.value;
    }

    const handleCustomerChange = (e: any) => {
        props.tenant.customerId = e.value.value;
    }

    const handleBillingContractSubscriptionIdChange = (e: any) => {
        props.tenant.billingContractSubscriptionId = e.value;
    }

    const handleLicensePlanSubscriptionIdChange = (e: any) => {
        props.tenant.licensePlanSubscriptionId = e.value;
    }

    const handleSaveTenant = () => {
        updateTenant(props.tenant);
        handleSave();
    }

    function updateTenant(tenant: any) {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        var data =
        {
            'licensingPlanId': tenant.licensingPlanId,
            'customerId': tenant.customerId,
            'billingContractSubscriptionId': tenant.billingContractSubscriptionId,
            'licensePlanSubscriptionId': tenant.licensePlanSubscriptionId
        }

        apiClient.ExecuteRequest(`tenant/update?tenantId=${tenant.guid}&data=${JSON.stringify(data)}`, 'PUT').then((response: any) => {
            setBCFieldState(false);
            setLPFieldState(false);
        });
    }

    function updateTenantSettings(id: string, prop: string, value: any) {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        const data = {
            [prop]: prop == 'disabled' ? !value : value
        };

        apiClient.ExecuteRequest(`tenant/update?tenantId=${id}&data=${JSON.stringify(data)}`, 'PUT').then(async (response: any) => {

        });
    }

    const onBCIconClick = (e: any) => {
        setBCFieldState(true)
    }

    const onLPIconClick = (e: any) => {
        setLPFieldState(true)
    }

    const onBCBlur = (e: any) => {
        setBCFieldState(false)
    }

    const onLPBlur = (e: any) => {
        setLPFieldState(false)
    }

    const handleSubscriptionChange = (e: any) => {
        setRevenueConfig({
            ...revenueConFig,
            subscriptionId: e.target.value
        });
    }

    const handleStrategyOnChange = (e: any) => {
        setRevenueConfig({
            ...revenueConFig,
            strategyOption: e
        });
    }

    const handleCommissionChange = (e: any) => {
        setRevenueConfig({
            ...revenueConFig,
            commission: e.target.value
        });
    }

    const handleAmountChange = (e: any) => {
        setRevenueConfig({
            ...revenueConFig,
            amount: e.target.value
        });
    }

    const updateRevenueConfig = (tenantGuid: string, subscriptionId: string, revenueStrategy: number, commission: number, amount: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${tenantGuid}/tenantconfiguration/azurerevenueconfig/insertorupdate?subscriptionId=${subscriptionId}&revenueStrategy=${revenueStrategy}&commission=${commission}&amount=${amount}`, 'PUT').then((response: any) => {
            var data = response.data;
            data = pasreRevenueConfig(data);
            setRevenueConfig({ ...revenueConFig, data });
        });
    }

    const handleSave = () => {
        updateRevenueConfig(
            props.tenant.guid,
            revenueConFig.subscriptionId,
            revenueConFig.strategyOption.value,
            revenueConFig.commission,
            revenueConFig.amount
        );
    }

    return (
        <div style={{ width: 'calc(75vw)' }}>
            <Work365Card>
                <Form
                    initialValues={props.tenant}
                    key={JSON.stringify(props.tenant)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <table>
                                <tr>
                                    <td style={{ width: "250pxpx" }}>
                                        <h4>{'General Information'}</h4>
                                    </td>
                                    <td style={{ width: "120px" }}>
                                        <Work365Switch
                                            style={{ display: 'inline' }}
                                            title='Active'
                                            key='environment-active'
                                            value={!(environment.disabled ?? true)}
                                            onChange={(e) => {
                                                props.tenant.disable = !e.value;
                                                updateTenantSettings(environment.guid, 'disable', !e.value);
                                                setEnvironment({ ...environment, disable: !e.value });
                                            }}
                                            disabled={disableControls}
                                        />
                                    </td>
                                    <td style={{ width: "150px" }}>
                                        <Work365Switch
                                            style={{ display: 'inline' }}
                                            title='Production'
                                            key='environment-prod'
                                            value={environment.isProduction ?? false}
                                            onChange={(e) => {
                                                props.tenant.isProduction = e.value;
                                                updateTenantSettings(environment.guid, 'isProduction', e.value);
                                                setEnvironment({ ...environment, isProduction: e.value });
                                            }}
                                            disabled={disableControls}
                                        />
                                    </td>
                                    <td style={{ width: "165px" }}>
                                        <Work365Switch
                                            style={{ display: 'inline' }}
                                            title='Portal Eligible'
                                            key='environment-portal-eligable'
                                            value={environment.isEligibleForPortal ?? false}
                                            onChange={(e) => {
                                                props.tenant.isEligibleForPortal = e.value;
                                                updateTenantSettings(environment.guid, 'isEligibleForPortal', e.value);
                                                setEnvironment({ ...environment, isEligibleForPortal: e.value });
                                            }}
                                            disabled={disableControls}
                                        />
                                    </td>
                                    <td style={{ width: "180px" }}>
                                        <Work365Switch
                                            style={{ display: 'inline' }}
                                            title='Enforce Entitlement'
                                            key='environment-enforce-entitlement'
                                            value={environment.enforceEntitlement ?? false}
                                            onChange={(e) => {
                                                props.tenant.enforceEntitlement = e.value;
                                                updateTenantSettings(environment.guid, 'enforceEntitlement', e.value);
                                                setEnvironment({ ...environment, enforceEntitlement: e.value });
                                            }}
                                            disabled={disableControls}
                                        />
                                    </td>
                                </tr>
                            </table>
                            <Row>
                                <Col>
                                    <Field
                                        name={'licensingPlanOption'}
                                        component={ComboboxInput}
                                        onChange={handleLicensePlanChange}
                                        data={licensePlans}
                                        label={'License Plan'}
                                        disabled={disableControls}
                                    />

                                </Col>
                                <Col>
                                    <Field
                                        name={'customerOption'}
                                        component={ComboboxInput}
                                        onChange={handleCustomerChange}
                                        data={customers}
                                        label={'Customer'}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'created'}
                                        component={FormInput}
                                        label={'Created On (UTC)'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />

                                </Col>
                                <Col>
                                    <Field
                                        name={'deactivated'}
                                        component={FormInput}
                                        label={'Last Deactivated On (UTC)'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        (!bcFieldState ?
                                            <div style={{ marginTop: "5px" }}>
                                                <Label>
                                                    {'Billing Contract Subscription ID'}
                                                </Label>
                                                <Work365InputLink
                                                    value={props.tenant.billingContractSubscriptionId}
                                                    link={props.tenant.billingContractSubscriptionId ? `${props.tenant.crmSubscriptionRecord}${props.tenant.crmBillingContractSubscriptionId ?? props.tenant.billingContractSubscriptionId}` : '#'}
                                                    onIconClick={onBCIconClick} />
                                            </div> :
                                            <Field
                                                name={'billingContractSubscriptionId'}
                                                component={FormInput}
                                                label={'Billing Contract Subscription ID'}
                                                onChange={handleBillingContractSubscriptionIdChange}
                                                onBlur={onBCBlur}
                                                disabled={disableControls}
                                            />)
                                    }
                                </Col>
                                <Col>
                                    <Field
                                        name={'username'}
                                        component={FormInput}
                                        label={'Service Account'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        (!lpFieldState ?
                                            <div style={{ marginTop: "5px" }}>
                                                <Label>
                                                    {'License Plan Subscription Id'}
                                                </Label>
                                                <Work365InputLink
                                                    value={props.tenant.licensePlanSubscriptionId}
                                                    link={props.tenant.licensePlanSubscriptionId ? `${props.tenant.crmSubscriptionRecord}${props.tenant.crmLicensePlanSubscriptionId ?? props.tenant.licensePlanSubscriptionId}` : '#'}
                                                    onIconClick={onLPIconClick} />
                                            </div> :
                                            <Field
                                                name={'licensePlanSubscriptionId'}
                                                component={FormInput}
                                                label={'License Plan Subscription Id'}
                                                onChange={handleLicensePlanSubscriptionIdChange}
                                                onBlur={onLPBlur}
                                                disabled={disableControls}
                                            />)
                                    }
                                </Col>
                                <Col>
                                    <Field
                                        name={'appVersion'}
                                        component={FormInput}
                                        label={'App Version'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Field
                                        name={'uniqueName'}
                                        component={FormInput}
                                        label={'Unique Name'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />

                                </Col>
                                <Col lg={3}>
                                    <Field
                                        name={'coreSolutionVersion'}
                                        component={FormInput}
                                        label={'Core Solution Version'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Field
                                        name={'portalSolutionVersion'}
                                        component={FormInput}
                                        label={'Portal Solution Version'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'typeName'}
                                        component={FormInput}
                                        label={'Type'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />

                                </Col>
                                <Col>
                                    <Field
                                        name={'timeZone'}
                                        component={FormInput}
                                        label={'Time Zone'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'globalIdentifier'}
                                        component={FormInput}
                                        label={'Global Identifier'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />

                                </Col>
                            </Row>
                            <div style={{ lineHeight: '10px' }}>
                                <br />
                            </div>
                            {
                                revenueConFig ? <>
                                    <Row>
                                        <h4>{'Revenue Configuration'}</h4>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <div>
                                                    <label>{'Subscription Id'}</label>
                                                    <Input
                                                        defaultValue={revenueConFig.subscriptionId}
                                                        onBlur={handleSubscriptionChange}
                                                        type='text'
                                                        disabled={disableControls}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <label>{'Revenue Startegy'}</label>
                                                    <Work365DropdownList
                                                        defaultValue={revenueConFig.strategyOption}
                                                        data={revenueConFig.strategyParams}
                                                        onChange={handleStrategyOnChange}
                                                        textField="name"
                                                        dataItemKey="value"
                                                        disabled={disableControls}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <div>
                                                    <label>{'Commission'}</label>
                                                    <Input
                                                        defaultValue={revenueConFig.commission}
                                                        onBlur={handleCommissionChange}
                                                        type='number'
                                                        disabled={disableControls}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <label>{'Amount'}</label>
                                                    <Input
                                                        defaultValue={revenueConFig.amount}
                                                        onBlur={handleAmountChange}
                                                        type='number'
                                                        disabled={disableControls}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </> : <></>
                            }
                            <div style={{ lineHeight: '10px' }}>
                                <br />
                            </div>
                            <Row>
                                <h4>{'Tenant Report'}</h4>
                            </Row>

                            <Row>
                                <Col>
                                    <Field
                                        name={'fL7D'}
                                        component={FormInput}
                                        label={'Failed Last 7 Days'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />

                                </Col>
                                <Col>
                                    <Field
                                        name={'fL30D'}
                                        component={FormInput}
                                        label={'Failed Last 30 Days'}
                                        readOnly={true}
                                        disabled={disableControls}
                                    />
                                </Col>
                            </Row>

                            <div style={{ lineHeight: '10px' }}>
                                <br />
                            </div>

                            <Row>
                                <div style={{ textAlign: 'right' }}>
                                    <Button themeColor='primary' style={{ width: '100px' }} onClick={handleSaveTenant} disabled={disableControls}>Save</Button>
                                </div>
                            </Row>

                        </FormElement>)
                    } />
            </Work365Card >
        </div >
    );
}

const initialFilter = {
    logic: "and", filters: []
} as CompositeFilterDescriptor;

export const ScheduleJobsTab: React.FC<IScheduledJobProps> = (props: IScheduledJobProps) => {
    const [state, setState] = React.useState(
        {
            scheduledJobs: [] as any[],
            scheduledJobLogs: [] as any[],
            showEditJob: false,
            showRunJobParameter: false,
            showConfirmDialog: false,
            showLogsDialog: false,
            selectedJob: {} as any,
            confirmDialogMessage: ''
        }
    );
    const [gridState, setGridState] = React.useState<IGridState>({
        groupable: false,
        resizable: true,
        filterable: false,
        sortable: true,
        scrollable: "none",
        pageSize: 30,
        rowHeight: 40,
    });

    const [cronValue, setCronValue] = React.useState("* * * * *");
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState([
        {
            field: "scheduleJobDefinitionId",
            dir: "asc",
        } as SortDescriptor,
    ]);
    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant?.guid ?? null}/scheduledjob/byJobDefinition/get`, 'GET').then((response: any) => {
            var scheduledJobs = response.data;
            setState({ ...state, scheduledJobs: scheduledJobs });
        });
    }, []);

    const ActionCustomCell = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];

        var userString = localStorage.getItem('user');
        let disabled = false;

        if (userString != undefined) {
            var user: any = JSON.parse(userString);
            disabled = !user.isJobController
        }

        return (
            <td
                style={{
                    color: 'white'
                }}
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                <Button icon='edit' size={'small'} fillMode={'link'} themeColor={'primary'} title='Edit' onClick={() => handleEditJobClick(props.dataItem)} disabled={disabled}/>
                {props.dataItem['isActive'] ?
                    <Button icon='checkmark-circle' size={'small'} fillMode={'link'} themeColor={'primary'} title='Set Inactive' onClick={() => handleSetInactiveClick(props.dataItem)} disabled={disabled} /> :
                    <Button icon='x-circle' size={'small'} fillMode={'link'} themeColor={'error'} title='Set Active' onClick={() => handleSetActiveClick(props.dataItem)} disabled={disabled}/>
                }
                <Button icon='file' size={'small'} fillMode={'link'} themeColor={'primary'} title='Logs' onClick={() => handleShowLogs(props.dataItem)} disabled={disabled ?? !props.dataItem.isActive} />
                <Button icon='play' size={'small'} fillMode={'link'} themeColor={'primary'} title='Run Now' onClick={() => handleRunJobClick(props.dataItem)} disabled={disabled ?? !props.dataItem.isActive} />
            </td>
        )
    }

    const handleEditJobClick = (dataItem: any) => {
        setState({ ...state, showEditJob: true, selectedJob: dataItem });
        setCronValue(dataItem.cron);
    }

    const handleRunJobClick = (dataItem: any) => {
        setState({ ...state, showRunJobParameter: true, selectedJob: dataItem });
    }

    const handleSetInactiveClick = (dataItem: any) => {

        setState({
            ...state,
            confirmDialogMessage: `${dataItem.name} will be set inactive. Are you sure?`,
            showConfirmDialog: true,
            selectedJob: dataItem
        });
    }

    const handleSetActiveClick = (dataItem: any) => {
        setState({
            ...state,
            confirmDialogMessage: `${dataItem.name} will be set active. Are you sure?`,
            showConfirmDialog: true,
            selectedJob: dataItem
        });
    }

    const handleTimezoneChange = (e: any) => {
        state.selectedJob.isLocalTimeZone = e.value == '1';
        setState({
            ...state,
            selectedJob: state.selectedJob
        })
    }

    const handleParameterChange = (e: any) => {
        state.selectedJob.parameters = e.target.value;
        setState({
            ...state,
            selectedJob: state.selectedJob
        })
    }

    const handleNotesChange = (e: any) => {
        state.selectedJob.notes = e.target.value
        setState({
            ...state,
            selectedJob: state.selectedJob
        })
    }

    const handleRunJobSave = (e: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/scheduledjob/runjob/${state.selectedJob.jobId}/${state.selectedJob.parameter}`, 'POST').then((response: any) => {
            setState({ ...state, showRunJobParameter: false });
        });
    }

    const handleConfirmSetActiveJob = (e: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        if (state.selectedJob.isActive) {
            apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/scheduledjob/delete/${state.selectedJob.scheduledJobDefinitionId}`, 'DELETE').then((response: any) => {
                var newSchedules = state.scheduledJobs.map((s: any) => {
                    if (s?.jobId === state.selectedJob.jobId) {
                        s['isActive'] = false;
                    }
                    return s
                })
                setState({ ...state, showConfirmDialog: false, scheduledJobs: newSchedules });
            });
        } else {
            apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/scheduledjob/activate/${state.selectedJob.jobId}`, 'POST').then((response: any) => {
                var newSchedules = state.scheduledJobs.map((s: any) => {
                    if (s?.jobId === state.selectedJob.jobId) {
                        s.isActive = true;
                    }
                    return s
                })
                setState({ ...state, showConfirmDialog: false, scheduledJobs: newSchedules });
            });
        }
    }

    const handleShowLogs = (dataItem: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/scheduledjob/joblogs/${dataItem.scheduledJobDefinitionId}`, 'GET').then((response: any) => {
            var history = response.data.data;
            setState({ ...state, showLogsDialog: true, scheduledJobLogs: history, selectedJob: dataItem })
        });
    }

    const handleScheduledJobSave = (e: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        var jobId = state.selectedJob.jobId;
        var jobDefinitionId = state.selectedJob.scheduledJobDefinitionId;
        var cron = state.selectedJob.cron;
        var parameters = state.selectedJob.parameters;
        var notes = state.selectedJob.notes;
        var isLocalTimeZone = state.selectedJob.isLocalTimeZone;
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/scheduledjob/${jobId}/save?scheduledJobDefinitionId=${encodeURI(jobDefinitionId)}&cron=${encodeURI(cron)}&parameters=${encodeURI(parameters)}&notes=${encodeURI(notes)}&isLocalTimezone=${encodeURI(isLocalTimeZone)}`, 'POST').then((response: any) => {
            if (response.status === 200) {
                var scheduledJobs = state.scheduledJobs.map((job: any) => {
                    if (job.jobId == state.selectedJob.jobId) {
                        job = state.selectedJob;
                    }
                    return job;
                })
                setState({ ...state, scheduledJobs: scheduledJobs, showEditJob: false });
            }
        });
    }

    const onFilterChange = (e: any) => {
        setFilter(e.filter);
        setGridState({ ...gridState, sort: e.sort });

        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const onSortChange = (e: any) => {
        setSort(e.sort);
        setGridState({ ...gridState, sort: e.sort });
        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    return (<>
        <div style={{ maxHeight: 'calc(100vh - 14em)', overflowY: 'auto', paddingTop: '5px' }}>
            {state.showEditJob &&
                <Work365Dialog
                    width={650}
                    onClose={() => setState({ ...state, showEditJob: false })}
                    title={state.selectedJob?.name ?? ''}
                    children={
                        <>
                            <Form
                                initialValues={state.selectedJob}
                                key={JSON.stringify(state.selectedJob)}
                                render={(formRenderProps) => {
                                    return (
                                        <>
                                            <Row>
                                                <Field
                                                    name={'description'}
                                                    component={FormTextArea}
                                                    label={'Descripion'}
                                                    readOnly={true} />
                                            </Row>
                                            <Row>
                                                <div style={{ padding: '10px' }}>
                                                    <Label>{'Parameters'}</Label>
                                                    <Input
                                                        defaultValue={state.selectedJob.parameters}
                                                        onBlur={handleParameterChange}
                                                    />
                                                    <label>What is this? <a target='_blank' href='https://dev.azure.com/Work365/Work365/_wiki/wikis/Work365.wiki/151/Scheduled-Job-Run-Parameters'>Help</a></label>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div style={{ padding: '10px' }}>
                                                    <Label>{'Notes'}</Label>
                                                    <Input
                                                        defaultValue={state.selectedJob.notes}
                                                        onBlur={handleNotesChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Cron className='pt-4' value={cronValue} setValue={setCronValue} />
                                            </Row>
                                            <Work365RadioButton
                                                name='timezone'
                                                value='1'
                                                checked={state.selectedJob.isLocalTimeZone ?? false}
                                                label={'Local Timezone'}
                                                onChange={handleTimezoneChange}
                                            />
                                            <div style={{ lineHeight: '5px' }}>
                                                <br />
                                            </div>
                                            <Work365RadioButton
                                                name='timezone'
                                                value='0'
                                                checked={!state.selectedJob.isLocalTimeZone ?? false}
                                                label={'Tenant Timezone'}
                                                onChange={handleTimezoneChange}
                                            />
                                            <div style={{ lineHeight: '5px' }}>
                                                <br />
                                            </div>
                                            <Row>
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button themeColor='primary' onClick={handleScheduledJobSave}>Save</Button> &nbsp;
                                                    <Button themeColor='error' onClick={() => setState({ ...state, showEditJob: false })}>Cancel</Button>
                                                </div>
                                            </Row>
                                        </>
                                    );
                                }}
                            />
                        </>
                    }
                />
            }

            {state.showRunJobParameter &&
                <Work365Dialog
                    width={650}
                    onClose={() => setState({ ...state, showRunJobParameter: false })}
                    title={state.selectedJob?.name ?? ''}
                    children={<>
                        <Form
                            initialValues={state.selectedJob}
                            key={JSON.stringify(state.selectedJob)}
                            render={(formRenderProps) => {
                                return (<>
                                    <Row>
                                        <Field
                                            name={'parameters'}
                                            component={FormInput}
                                            label={'Parameters'} />
                                        <label>What is this? <a target='_blank' href='https://dev.azure.com/Work365/Work365/_wiki/wikis/Work365.wiki/151/Scheduled-Job-Run-Parameters'>Help</a></label>
                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button themeColor='primary' onClick={handleRunJobSave}>Run Now</Button>;
                                        </div>
                                    </Row>
                                </>);
                            }}
                        />
                    </>}
                />
            }

            {state.showLogsDialog &&
                <Work365Dialog
                    onClose={() => setState({ ...state, showLogsDialog: false })}
                    title={state.selectedJob?.name ?? ''}
                    children={<>
                        <Form
                            initialValues={state.selectedJob}
                            key={JSON.stringify(state.selectedJob)}
                            render={(formRenderProps) => {
                                return (<>
                                    <Work365FlexiGrid
                                        style={
                                            {
                                                height: 83,
                                                width: 75
                                            }
                                        }
                                        columns={[
                                            {
                                                field: 'startTimeUtc',
                                                title: 'Start Time',
                                                width: 250,
                                            },
                                            {
                                                field: 'endTimeUtc',
                                                title: 'End Time',
                                                width: 250,
                                                filterable: false,
                                            },
                                            {
                                                field: 'status',
                                                title: 'Status',
                                                width: 250,
                                                filterable: false,
                                            },
                                            {
                                                field: 'message',
                                                title: 'Message',
                                                width: 620,
                                                filterable: false,
                                            }
                                        ]}
                                        data={orderBy(state.scheduledJobLogs, [{
                                            field: "scheduleJobDefinitionId",
                                            dir: "asc",
                                        }])}
                                    />
                                </>);
                            }}
                        />
                    </>}
                />
            }

            {state.showConfirmDialog &&
                <Work365Dialog
                    width={400}
                    onClose={() => setState({ ...state, showConfirmDialog: false })}
                    children={<>
                        <Form
                            initialValues={state.selectedJob}
                            key={JSON.stringify(state.selectedJob)}
                            render={(formRenderProps) => {
                                return (<>
                                    <Row>
                                        <p>{state.confirmDialogMessage}</p>

                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button themeColor='primary' onClick={handleConfirmSetActiveJob}>Confirm</Button> &nbsp;
                                            <Button themeColor='error' onClick={() => setState({ ...state, showConfirmDialog: false })}>Cancel</Button>
                                        </div>
                                    </Row>
                                </>);
                            }}
                        />
                    </>}
                />
            }
            {
                state.scheduledJobs ?

                    <Work365Card>
                        <Work365FlexiGrid
                            columns={[
                                {
                                    field: 'name',
                                    title: 'Name',
                                    width: 300,
                                    filterable: true,
                                    filter: 'text'
                                },
                                {
                                    field: 'schedule',
                                    title: 'Schedule',
                                    width: 220,
                                    filterable: true,
                                    filter: 'text'
                                },
                                {
                                    field: 'timezone',
                                    title: 'Timezone',
                                    width: 200,
                                    filterable: true,
                                    filter: 'text'
                                },
                                {
                                    field: 'lastJobState',
                                    title: 'Last Job State',
                                    width: 150,
                                    filterable: true,
                                    filter: 'text'
                                },
                                {
                                    field: 'lastExecutionString',
                                    title: 'Last Execution',
                                    width: 150,
                                    filterable: true,
                                    filter: 'text'
                                },
                                {
                                    field: 'nextExecutionString',
                                    title: 'Next Execution',
                                    width: 150,
                                    filterable: true,
                                    filter: 'text'
                                },

                                {
                                    field: '',
                                    title: 'Action',
                                    width: 200,
                                    filterable: false,
                                    cell: ActionCustomCell,
                                },
                            ] as Work365Column[]}
                            data={filterBy(orderBy(state.scheduledJobs, sort), filter)}
                            style={
                                {
                                    height: '50%',
                                    width: '1400px'
                                }
                            }
                            gridState={gridState}
                            sortChange={onSortChange}
                            onFilterChange={onFilterChange}
                        />

                    </Work365Card >

                    : <><p style={{ padding: '10px' }}>No Scheduled Jobs Found</p></>
            }
        </div>
    </>);
}

export const ProviderBillingTab: React.FC<ITenantProps> = (props: ITenantProps) => {
    return (
        <Work365Card>
            <div style={{ position: 'relative' }}>
                <div style={{ maxHeight: 'calc(100vh - 14em)', overflowY: 'auto', paddingTop: '5px' }}>
                    <ProviderBillingTable tenant={props.tenant} tableHeight={680} tableWidth={1370} />
                </div>
            </div>
        </Work365Card>
    )
}

export const EntitlementTab: React.FC<ITenantProps> = (props: ITenantProps) => {
    const [state, setState] = React.useState({
        tenant: props.tenant,
        modelAddHeader: '',
        selectedLimitBasedEntitlementId: 0,
        paneState: [
            { size: "30%", resizable: true },
            { size: "70%", resizable: true }
        ],
        data: {
            entitlements: [],
            entitlementPacks: [],
            entitlementPlans: [],
            tenantEntitlements: [],
            limitBasedEntitlements: [],
            switchBasedEntitlements: []
        }
    });
    const [showAddForm, setShowForm] = React.useState(false);

    const [bcCount, setBCCount] = React.useState(0);
    const [apCount, setAPCount] = React.useState(0);

    var userString = localStorage.getItem('user');
    var disableControls = true;

    if (userString != undefined) {
        var user: any = JSON.parse(userString);
        if (user.isAdministrator || user.isManager) {
            disableControls = false;
        }
    }

    React.useEffect(() => {
        const fetchData = async () => {
            var appSettings = Global.getAppSettings();
            var authService = Global.getAuthService(appSettings);
            var apiClient = Global.getApiService(appSettings, authService);

            let ents = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/${null}/get`, 'GET');
            let packs = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/pack/${null}/get`, 'GET');
            let plans = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/plan/get`, 'GET');
            let tenantEnts = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/override/get`, 'GET');
            let bcCountRes = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/BillingContract/count`, 'GET');
            let apCountRes = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/Provider/automatic/count`, 'GET');

            var entitlements = ents.data;
            var entitlementPacks = packs.data;
            var entitlementPlans = plans.data;
            var tenantEntitlements = tenantEnts.data;
            var limitBasedEntitlements = entitlements.filter((x: any) => x.type == 'LimitBased');
            var switchBasedEntitlements = entitlements.filter((x: any) => x.type == 'Switch');
            var bcCount = bcCountRes.data;
            var apCount = apCountRes.data;
            setBCCount(bcCount);
            setAPCount(apCount);
            setState({
                ...state,
                data: {
                    entitlements: entitlements,
                    entitlementPacks: entitlementPacks,
                    entitlementPlans: entitlementPlans,
                    tenantEntitlements: tenantEntitlements,
                    limitBasedEntitlements: limitBasedEntitlements,
                    switchBasedEntitlements: switchBasedEntitlements
                }
            });
        }

        fetchData();
    }, []);

    const LimitCustomCell = (props: any) => {
        let value = 0;
        if (props.dataItem.entitlementId == 1) {
            value = bcCount;
        } else {
            value = apCount
        }
        return (
            <td>
                {value}
            </td>
        )
    }

    const PlusCustomCell = (props: any) => {
        return (
            <td style={{ textAlign: 'center' }}>
                +
            </td>
        )
    }

    const EqualsCustomCell = (props: any) => {
        return (
            <td style={{ textAlign: 'center' }}>
                =
            </td>
        )
    }

    const EntitlementFromPlanCustomCell = (props: any) => {
        var entitlementPlan = state.data.entitlementPlans.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'] && x.planId == state.tenant.licensingPlanId)[0] as any;
        return (
            <td>
                <Input value={entitlementPlan?.limit ?? 0} readOnly={true} />
            </td>
        )
    }

    const AdditionalAllowanceCustomCell = (props: any) => {
        var tenantOverride = state.data.tenantEntitlements.filter((x: any) => x.entitlementId == props.dataItem.entitlementId);
        var value = tenantOverride.reduce((acc, x: any) => {
            var pack = state.data.entitlementPacks.find((e: any) => e.entitlementPackId == x.entitlementPackId) as any;
            var packQTY = pack ? pack.quantity : 1
            return acc + Number(x.quantity * packQTY)
        }, 0)
        return (
            <td>
                <Input value={value} readOnly={true} />
            </td>
        )
    }

    const TotalAllowanceCustomCell = (props: any) => {
        var entitlementPlan = state.data.entitlementPlans.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'] && x.planId == state.tenant.licensingPlanId)[0] as any;
        var tenantOverride = state.data.tenantEntitlements.filter((x: any) => x.entitlementId == props.dataItem.entitlementId);
        var value = tenantOverride.reduce((acc, x: any) => {
            var pack = state.data.entitlementPacks.find((e: any) => e.entitlementPackId == x.entitlementPackId) as any;
            var packQTY = pack ? pack.quantity : 1
            return acc + Number(x.quantity * packQTY)
        }, 0) + (entitlementPlan?.limit ?? 0);
        return (
            <td>
                <Input value={value} readOnly={true} />
            </td>
        )
    }

    const AddEntitlementCustomCell = (props: any) => {
        return (
            <td>
                <Button icon='edit' size={'small'} fillMode={'link'} themeColor={'primary'} title='Add Entitlement' disabled={disableControls}
                    onClick={() => {
                        setState({
                            ...state,
                            modelAddHeader: props.dataItem.entitlementName,
                            selectedLimitBasedEntitlementId: props.dataItem.entitlementId,
                        })
                        setShowForm(true)
                    }} />
            </td>
        )
    }

    const EffectiveDateCustomCell = (props: any) => {
        return (
            <td>
                <Input
                    type='date'
                    defaultValue={Utils.ToDisplayDateFormat(props.dataItem.effectiveDate, false, "fr-CA")}
                    onChange={(e) => {
                        var result = state.data.tenantEntitlements.map((x: any) => x.tenantEntitlementOverrideId == props.dataItem.tenantEntitlementOverrideId ?
                            { ...x, effectiveDate: e.target.value } : x) as never[]
                        setState({
                            ...state,
                            data: {
                                ...state.data,
                                tenantEntitlements: result
                            }
                        })
                    }}
                    disabled={disableControls}
                />
            </td>
        )
    }

    const QuantityCustomCell = (props: any) => {
        return (
            <td>
                <Input
                    type='number'
                    defaultValue={props.dataItem.quantity}
                    onBlur={(e) => {
                        var result = state.data.tenantEntitlements.map((x: any) => x.tenantEntitlementOverrideId == props.dataItem.tenantEntitlementOverrideId ?
                            { ...x, quantity: e.target.value } : x) as never[]
                        setState({
                            ...state,
                            data: {
                                ...state.data,
                                tenantEntitlements: result
                            }
                        })
                    }}
                    disabled={disableControls}
                />
            </td>
        )
    }

    const ReasonCustomCell = (props: any) => {
        return (
            <td>
                <Input
                    type='text'
                    defaultValue={props.dataItem.reason}
                    onBlur={(e) => {
                        var result = state.data.tenantEntitlements.map((x: any) => x.tenantEntitlementOverrideId == props.dataItem.tenantEntitlementOverrideId ?
                            { ...x, reason: e.target.value } : x) as never[]
                        setState({
                            ...state,
                            data: {
                                ...state.data,
                                tenantEntitlements: result
                            }
                        })
                    }}
                    disabled={disableControls}
                />
            </td>
        )
    }

    const EntitlementPackCustomCell = (props: any) => {
        var values = state.data.entitlementPacks.filter((x: any) => x.entitlementId == props.dataItem.entitlementId).map((x: any) => {
            return {
                value: x.entitlementPackId,
                name: x.entitlementPackName
            }
        })

        var defaultValue = values.filter((x: any) => x.value == props.dataItem.entitlementPackId);
        var value = defaultValue.length > 0 ? defaultValue[0] : undefined;
        return (
            <td>
                <Work365DropdownList
                    data={values}
                    dataItemKey="value"
                    textField="name"
                    defaultValue={value}
                    title={value?.name}
                    onChange={(e: any) => {
                        var result = state.data.tenantEntitlements.map((x: any) => x.tenantEntitlementOverrideId == props.dataItem.tenantEntitlementOverrideId ?
                            { ...x, entitlementPackId: e.value } : x) as never[]
                        setState({
                            ...state,
                            data: {
                                ...state.data,
                                tenantEntitlements: result
                            }
                        })
                    }}
                    disabled={disableControls}
                />
            </td>
        )
    }

    const DateCustomCell = (props: any) => {
        var field = props.field || '';
        var value = props.dataItem[field];
        return (
            <td>
                {Utils.ToDisplayDateFormat(value, false, 'fr-CA')}
            </td>
        )
    }

    const StateFromPlanCustomCell = (props: any) => {
        var entitlementPlan = state.data.entitlementPlans.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'] && x.planId == state.tenant.licensingPlanId)[0] as any;
        return (
            <td style={{ textAlign: 'center' }}>
                <Work365Switch value={entitlementPlan?.limit > 0} disabled={disableControls} />
            </td>
        )
    }

    const EffectiveStateCustomCell = (props: any) => {
        var tenantOverride = state.data.tenantEntitlements?.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'])[0] as any;
        var entitlementPlan = state.data.entitlementPlans.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'] && x.planId == state.tenant.licensingPlanId)[0] as any;
        var value = tenantOverride ? tenantOverride.limit === 1 : entitlementPlan?.limit > 0;

        return (<td style={{ textAlign: 'center' }}>
            <Work365Switch value={value} disabled={disableControls && tenantOverride == undefined} onChange={(e) => {
                var limit = e.value ? 1 : 0;
                updateEntitlementOverride(tenantOverride.tenantEntitlementOverrideId, limit, limit, '', Utils.ToDisplayDateFormat(new Date().toLocaleString(), false, "fr-CA"))
            }} />
        </td>)
    }

    const CheckEffectiveStateCustomCell = (props: any) => {
        var tenantOverride = state.data.tenantEntitlements?.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'])[0] as any;
        var entitlementPlan = state.data.entitlementPlans.filter((x: any) => x.entitlementId === props.dataItem['entitlementId'] && x.planId == state.tenant.licensingPlanId)[0] as any;
        var limit = entitlementPlan?.limit > 0 ? 1 : 0;
        return (<td style={{ textAlign: 'right' }}>
            <Work365Checkbox id={''} isDisabled={disableControls} onChange={(e: any) => {
                if (e.target.value) {
                    saveEntitlementOverride(props.dataItem.entitlementId, limit, 1, '', Utils.ToDisplayDateFormat(new Date().toLocaleString(), false, "fr-CA"));
                } else {
                    deleteEntitlementOverride(tenantOverride.tenantEntitlementOverrideId);
                }
            }} defaultChecked={tenantOverride !== undefined} />
        </td>)
    }

    const ActionCustomCell = (props: any) => {
        return (
            <td>
                <Button icon='floppy' size={'small'} fillMode={'link'} themeColor={'primary'} title='Save'
                    onClick={() => {
                        var limit = (state.data.entitlementPacks.filter((x: any) => x.entitlementPackId == props.dataItem.entitlementPackId)[0] as any)?.quantity ?? 0;
                        updateEntitlementOverride(
                            props.dataItem.tenantEntitlementOverrideId,
                            limit,
                            props.dataItem.quantity,
                            props.dataItem.reason,
                            Utils.ToDisplayDateFormat(props.dataItem.effectiveDate, false, 'fr-CA'),
                            props.dataItem.entitlementPackId
                        );
                    }} 
                    disabled={disableControls} />
                <Button icon='trash' size={'small'} fillMode={'link'} themeColor={'primary'} title='Delete'
                    onClick={() => {
                        deleteEntitlementOverride(props.dataItem.tenantEntitlementOverrideId);
                    }} 
                    disabled={disableControls} />
            </td>
        )
    }

    const saveEntitlementOverride = (entitlementId: number, limit: number, quantity: number, reason: string, effectiveDate?: string, entitlementPackId?: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/override/save?entitlementId=${entitlementId}&limit=${limit}&quantity=${quantity}&reason=${reason}&effectiveDate=${effectiveDate}`, 'POST')
            .then((ents: any) => {
                state.data.tenantEntitlements.push(ents.data as never)
                setState({
                    ...state, data: {
                        ...state.data, tenantEntitlements: state.data.tenantEntitlements
                    }
                })
            });
    }

    const deleteEntitlementOverride = (tenantEntitlementOverrideId: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/${tenantEntitlementOverrideId}/override/delete`, 'DELETE').then((ents: any) => {
            state.data.tenantEntitlements = state.data.tenantEntitlements.filter((e: any) => e.tenantEntitlementOverrideId !== tenantEntitlementOverrideId)
            setState({
                ...state, data: {
                    ...state.data, tenantEntitlements: state.data.tenantEntitlements
                }
            })
        });
    }

    const updateEntitlementOverride = (tenantEntitlementOverrideId: number, limit: number, quantity: number, reason: string, effectiveDate?: string, entitlementPackId?: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/entitlement/${tenantEntitlementOverrideId}/override/update?limit=${limit}&quantity=${quantity}&reason=${reason}&effectiveDate=${effectiveDate}&entitlementPackId=${entitlementPackId}`, 'PUT').then((ents: any) => {
            var tenantOverride = state.data.tenantEntitlements?.filter((x: any) => x.tenantEntitlementOverrideId === tenantEntitlementOverrideId)[0] as any;
            tenantOverride.limit = limit;
            tenantOverride.quantity = quantity;
            tenantOverride.effectiveDate = effectiveDate;
            state.data.tenantEntitlements = state.data.tenantEntitlements.filter((e: any) => e.tenantEntitlementOverrideId !== tenantEntitlementOverrideId)
            state.data.tenantEntitlements.push(tenantOverride as never)
            setState({
                ...state, data: {
                    ...state.data, tenantEntitlements: state.data.tenantEntitlements
                }
            })
        });
    }

    const onPaneStateChange = (e: any) => {
        setState({ ...state, paneState: e.newState });
    }

    const saveMultipleEntitlementOverride = async () => {
        var tenantOverrides = state.data.tenantEntitlements.filter((x: any) => x.entitlementId == state.selectedLimitBasedEntitlementId)
        tenantOverrides.forEach((t: any) => {
            updateEntitlementOverride(t.tenantEntitlementOverrideId, t.limit, Number(t.quantity ?? 0), t.reason ?? "", t.effectiveDate, t.entitlementPackId);
        })
    }

    return <>
        <div style={{ width: 'calc(75vw)', height: 'calc(80vh)' }}>
            {showAddForm ?
                <Work365Dialog
                    onClose={() => setShowForm(false)}
                    title={state.modelAddHeader ?? '[No Header]'}
                    children={
                        <>
                            <Work365FlexiGrid
                                style={
                                    {
                                        height: '250px',
                                        width: '1500px'
                                    }
                                }
                                columns={[
                                    {
                                        field: 'entitlementSubscriptionId',
                                        title: 'Subscription Id',
                                        width: 150,
                                    },
                                    {
                                        field: 'effectiveDate',
                                        title: 'Effective Date',
                                        width: 150,
                                        cell: EffectiveDateCustomCell
                                    },
                                    {
                                        field: 'entitlementPackId',
                                        title: 'Entitlement Pack',
                                        width: 200,
                                        cell: EntitlementPackCustomCell
                                    },
                                    {
                                        field: 'quantity',
                                        title: 'Quantity',
                                        width: 100,
                                        cell: QuantityCustomCell
                                    },
                                    {
                                        field: 'reason',
                                        title: 'Reason',
                                        width: 150,
                                        cell: ReasonCustomCell
                                    },
                                    {
                                        field: 'createdOn',
                                        title: 'Created On',
                                        width: 150,
                                        cell: DateCustomCell
                                    },
                                    {
                                        field: 'createdBy',
                                        title: 'Created By',
                                        width: 150,
                                    },
                                    {
                                        field: 'modifiedOn',
                                        title: 'Modified On',
                                        width: 150,
                                        cell: DateCustomCell
                                    },
                                    {
                                        field: 'modifiedBy',
                                        title: 'Modified By',
                                        width: 150,
                                    }, {
                                        field: '',
                                        title: 'Actions',
                                        width: 100,
                                        cell: ActionCustomCell
                                    }
                                ]}
                                data={orderBy(state.data.tenantEntitlements.filter((x: any) => x.entitlementId == state.selectedLimitBasedEntitlementId), [
                                    {
                                        field: "effectiveDate",
                                        dir: "desc",
                                    },
                                    {
                                        field: "quantity",
                                        dir: "asc",
                                    },
                                ] as SortDescriptor[])}
                            />
                            <br />
                            <div style={{ textAlign: 'right' }}>
                                <Button themeColor='primary' onClick={() => {
                                    saveEntitlementOverride(state.selectedLimitBasedEntitlementId, 0, 1, '', Utils.ToDisplayDateFormat(new Date().toLocaleDateString(), false, 'fr-CA'))
                                }}>Add</Button> &nbsp;
                                <Button themeColor='primary' onClick={async () => {
                                    setShowForm(false);
                                    await Promise.all([saveMultipleEntitlementOverride()]);
                                }
                                }>Save All and Close</Button>
                            </div>
                        </>
                    }
                /> :
                <></>
            }

            <Work365Card>
                <Splitter
                    style={{ height: 'calc(75vh)' }}
                    orientation={"vertical"}
                    panes={state.paneState}
                    onChange={onPaneStateChange}
                >
                    <Work365FlexiGrid
                        style={
                            {
                                height: '175px',
                                width: '1330px'
                            }
                        }
                        columns={[
                            {
                                field: 'entitlementName',
                                title: 'Limit based entitlements',
                                width: 400,
                            },
                            {
                                field: 'entitlementName',
                                title: 'Usage',
                                width: 180,
                                cell: LimitCustomCell
                            },
                            {
                                field: '',
                                title: 'Entitlement from plan',
                                width: 200,
                                cell: EntitlementFromPlanCustomCell
                            },
                            {
                                title: '',
                                width: 50,
                                cell: PlusCustomCell
                            },
                            {
                                field: '',
                                title: 'Additional Allowance',
                                width: 180,
                                cell: AdditionalAllowanceCustomCell
                            },
                            {
                                title: '',
                                width: 50,
                                cell: EqualsCustomCell
                            },
                            {
                                field: '',
                                title: 'Total Allowance',
                                width: 150,
                                cell: TotalAllowanceCustomCell
                            },
                            {
                                field: '',
                                title: '',
                                width: 50,
                                cell: AddEntitlementCustomCell
                            }

                        ]}
                        data={state.data.limitBasedEntitlements} />

                    <Work365FlexiGrid
                        style={
                            {
                                height: '400px',
                                width: '1330px'
                            }
                        }
                        columns={[
                            {
                                field: 'entitlementName',
                                title: 'Switch based entitlements',
                                width: 800,
                            },
                            {
                                field: '',
                                title: 'State from plan',
                                width: 200,
                                cell: StateFromPlanCustomCell
                            },
                            {
                                field: '',
                                title: '',
                                width: 50,
                                cell: CheckEffectiveStateCustomCell
                            },
                            {
                                field: '',
                                title: 'Effective state',
                                width: 150,
                                cell: EffectiveStateCustomCell
                            }
                        ]}
                        data={state.data.switchBasedEntitlements} />
                    <></>
                </Splitter>

            </Work365Card>
        </div>
    </>
}

export const LicenseTab: React.FC<ITenantProps> = (props: ITenantProps) => {
    const [state, setState] = React.useState(
        {
            showErrorMessage: false,
            errorMessage: '',
            data: {
                iotapLicense: '',
                tenantLicense: '',
                licenseCount: 0,
            } as any
        }
    );

    var userString = localStorage.getItem('user');
    var disableControls = true;

    if (userString != undefined) {
        var user: any = JSON.parse(userString);
        if (user.isAdministrator || user.isManager) {
            disableControls = false;
        }
    }

    React.useEffect(() => {
        getLicenses();
    }, [])

    const handlePushToTenantClick = () => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/pushtotenant?licenseXml=${encodeURIComponent(state.data.tenantLicense)}`, 'POST').then((response: any) => {
            var res = response.data;
            if (res.toLowerCase() === 'ok') {
                alert('Environment License XML Updated Successfully.');
            } else {
                alert(res.error);
            }
        });
    }

    const getLicenses = () => {
        const fetchData = async () => {
            var appSettings = Global.getAppSettings();
            var authService = Global.getAuthService(appSettings);
            var apiClient = Global.getApiService(appSettings, authService);

            let tenantLicenseResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/get`, 'GET');
            let iotapLicenseResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/iotap/get`, 'GET')
            let licenseCountResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/count/get`, 'GET');

            var tenantLicense = tenantLicenseResponse.data;
            var iotapLicense = iotapLicenseResponse.data;
            var licenseCount = licenseCountResponse.data;
            setState({
                ...state, data: {
                    ...state.data,
                    tenantLicense: tenantLicense,
                    iotapLicense: iotapLicense,
                    licenseCount: licenseCount
                }
            });
        }

        fetchData();
    }

    const refreshTenantLicense = () => {
        const fetchData = async () => {
            var appSettings = Global.getAppSettings();
            var authService = Global.getAuthService(appSettings);
            var apiClient = Global.getApiService(appSettings, authService);

            let tenantLicenseResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/get`, 'GET');
            let licenseCountResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/count/get`, 'GET');

            var tenantLicense = tenantLicenseResponse.data;
            var licenseCount = licenseCountResponse.data;
            setState({
                ...state, data: {
                    ...state.data,
                    tenantLicense: tenantLicense,
                    licenseCount: licenseCount
                }
            });
        };

        fetchData();
    }

    const refreshIotapLicense = () => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/license/iotap/get`, 'GET').then(async (iotapLicenseResponse: any) => {
            var iotapLicense = iotapLicenseResponse.data;
            setState({
                ...state, data: {
                    ...state.data,
                    iotapLicense: iotapLicense
                }
            });
        });
    }

    const handleTenantLicenseChange = (e: any) => {
        setTimeout(() => {
            setState({
                ...state,
                data: {
                    ...state.data,
                    tenantLicense: e.target.value
                }
            })

        }, 500)
    }

    const getIOTAPLicenseLink = (tenantLicense: string) => {

        if (!tenantLicense) {
            return undefined;
        }
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(tenantLicense, "text/xml");
        if (isParseError(xmlDoc)) {
            return undefined;
        }

        let licenseKey = xmlDoc.getElementsByTagName("licenseid")[0].childNodes[0].nodeValue;
        return `${props.tenant.crmLicense}${licenseKey}`;
    }

    const isParseError = (parsedDocument: any): boolean => {
        // parser and parsererrorNS could be cached on startup for efficiency
        var parser = new DOMParser(),
            errorneousParse = parser.parseFromString('<', 'application/xml'),
            parsererrorNS = errorneousParse.getElementsByTagName("parsererror")[0].namespaceURI;

        if (parsererrorNS === 'http://www.w3.org/1999/xhtml') {
            // In PhantomJS the parseerror element doesn't seem to have a special namespace, so we are just guessing here :(
            return parsedDocument.getElementsByTagName("parsererror").length > 0;
        }

        return parsedDocument.getElementsByTagNameNS(parsererrorNS, 'parsererror').length > 0;
    };

    return (<>
        {state.showErrorMessage ? <>
            <Work365Dialog
                onClose={() => setState({ ...state, showErrorMessage: false })}
                title={'Something went wrong'}
                children={
                    <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                        <p>{state.errorMessage}</p>
                    </div>
                }
            />
        </> : <></>}
        <div style={{ width: 'calc(75vw)' }}>
            <Work365Card>
                <Form
                    initialValues={state.data}
                    key={JSON.stringify(state.data)}
                    render={function (props: FormRenderProps) {
                        return (
                            <FormElement>
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: "5px" }}>
                                            <div style={{ display: 'inline-flex' }}>
                                                <Label>{'Tenant License'} </Label>
                                                &nbsp;&nbsp;
                                                <Label style={{ border: '1px solid #808080', borderRadius: '4px', paddingInline: '5px' }} className='bg-primary text-light'> Count: {state.data.licenseCount}</Label>
                                                <Button style={{ height: '18px' }} icon='refresh' size={'small'} fillMode={'link'} themeColor={'primary'} title='Refresh' onClick={refreshTenantLicense} />
                                            </div>
                                            <TextArea
                                                value={state.data.tenantLicense}
                                                rows={20}
                                                onChange={handleTenantLicenseChange}
                                                readOnly={false}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ marginTop: "5px" }}>
                                            <div style={{ width: '100%', display: 'inline-flex' }}>
                                                <span style={{ width: '95%', display: 'inline-flex' }}>
                                                    <Label>{'IOTAP License'}</Label>
                                                    <Button style={{ height: '18px' }} icon='refresh' size={'small'} fillMode={'link'} themeColor={'primary'} title='Refresh' onClick={refreshIotapLicense} />
                                                </span>
                                                <span style={{ textAlign: 'right' }}>
                                                    <a href={`${getIOTAPLicenseLink(state.data.tenantLicense)}`} target='_blank'>Open</a>
                                                </span>
                                            </div>


                                            <TextArea
                                                value={state.data.iotapLicense}
                                                rows={20}
                                                readOnly={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ lineHeight: '10px' }}>
                                    <br />
                                </div>
                                <Row>
                                    <div style={{ textAlign: 'right' }}>
                                        <Button disabled={disableControls} themeColor='primary' onClick={handlePushToTenantClick}>Push To Tenant</Button>
                                    </div>
                                </Row>
                            </FormElement>
                        )
                    }}
                />
            </Work365Card>
        </div>
    </>)
}

export const AddOnsTab: React.FC<ITenantProps> = (props: ITenantProps) => {
    var userString = localStorage.getItem('user');
    var disableControls = true;

    if (userString != undefined) {
        var user: any = JSON.parse(userString);
        if (user.isAdministrator || user.isManager) {
            disableControls = false;
        }
    }

    const toTreeViewDataItem = (groups: any[], features: any[], planFeatures: any[], tenantFeatures: any[]): ITreeViewDataItem[] => {
        if (!groups.map((g: any) => g.featureGroupId).includes(null)) {
            groups.push({
                featureGroupName: 'Unspecified',
                featureGroupId: null,
            });
        }
        var treeViewItem = groups.map((g: any) => ({
            text: g.featureGroupName,
            expanded: true,
            groupId: g.featureGroupId,
            checked: features.filter((f: any) => f.featureGroupId == g.featureGroupId).length === 0 ? false : !features.filter((f: any) => f.featureGroupId == g.featureGroupId).map((x: any) => ({
                text: x.featureName,
                expanded: true,
                checked: (planFeatures.map((tf: any) => tf.featureId).includes(x.featureId) || tenantFeatures.map((tf: any) => tf.featureId).includes(x.featureId)),
            } as IAddOnsTreeviewProps)).map((f: any) => f.checked).includes(false),
            items: features.filter((f: any) => f.featureGroupId == g.featureGroupId).map((x: any) => ({
                text: x.featureName,
                expanded: true,
                checked: (planFeatures.map((tf: any) => tf.featureId).includes(x.featureId) || tenantFeatures.map((tf: any) => tf.featureId).includes(x.featureId)),
                tenantFeatureSubscriptionId: tenantFeatures.filter((tf: any) => tf.featureId === x.featureId)[0]?.featureSubscriptionId ?? '',
                fromLicense: planFeatures.map((tf: any) => tf.featureId).includes(x.featureId),
                featureId: x.featureId
            } as IAddOnsTreeviewProps))
        } as IAddOnsTreeviewProps));

        return treeViewItem;
    }
    const [state, setState] = React.useState({
        tenantFeatures: [],
        planFeatures: [],
        features: [],
        featureGroups: []
    })

    const [treeViewData, setTreeViewData] = React.useState([] as IAddOnsTreeviewProps[])

    const onExpandChange = (event: any) => {
        event.item.expanded = !event.item.expanded;
        setTreeViewData([...treeViewData]);
    };

    const onCheckChange = (event: any) => {
        event.item.checked = !event.item.checked;
        var isMultiple = false;
        var indexes = event.itemHierarchicalIndex.split('_');
        if (treeViewData[indexes[0]].items?.length === 0) {
            treeViewData[indexes[0]].checked = false;
            return;
        }
        if (indexes.length === 1) {
            if (event.item.checked) {
                treeViewData[indexes[0]].items?.forEach(i => {
                    if (!i.fromLicense) {
                        i.checked = true;
                        isMultiple = true;
                        addFeature(props.tenant.guid, i.featureId, event)
                    }
                });
            } else {
                treeViewData[indexes[0]].items?.forEach(i => {
                    if (!i.fromLicense) {
                        i.checked = false;
                        isMultiple = true;
                        removeFeature(props.tenant.guid, i.featureId)
                    }
                });
            }
        } else {
            treeViewData[indexes[0]].checked = !treeViewData[indexes[0]].items?.map((i: any) => i.checked).includes(false);
        }

        setTreeViewData([...treeViewData]);
        if (!isMultiple) {
            if (event.item.checked) {
                addFeature(props.tenant.guid, event.item.featureId, event)
            } else {
                removeFeature(props.tenant.guid, event.item.featureId)
            }
        }
        isMultiple = false;
    }

    const addFeature = (tenantGuid: string, featureId: number, treeData: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${tenantGuid}/licenseplan/tenantfeature/${featureId}`, 'POST').then(async (response: any) => {
            treeData.item.tenantFeatureSubscriptionId = response.data;
            setTreeViewData([...treeViewData]);
        })
    }

    const removeFeature = (tenantGuid: string, featureId: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${tenantGuid}/licenseplan/tenantfeature/${featureId}`, 'DELETE').then(async (response: any) => {

        })
    }

    const renderTreeViewItem = (e: any) => {
        return (
            <div style={{ display: 'flex' }}>
                <span style={{ display: 'flex' }}>
                    <input type="checkbox" disabled={e.item.fromLicense} defaultChecked={e.item.checked} onChange={() => onCheckChange(e)} />
                    &nbsp;&nbsp;
                    <span>{e.item.text} </span>
                    &nbsp;&nbsp;
                    <span style={{ textAlign: 'right' }}><i style={{ color: '#b9bcbd' }}>{(e.item.fromLicense ? '(assigned via plan)' : e.item.tenantFeatureSubscriptionId ?? '')}</i></span>
                </span>
            </div >
        );
    };

    React.useEffect(() => {
        const fetchData = async () => {
            var appSettings = Global.getAppSettings();
            var authService = Global.getAuthService(appSettings);
            var apiClient = Global.getApiService(appSettings, authService);

            let tenantFeaturesResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/licenseplan/tenantfeatures/get`, 'GET');
            let planFeaturesResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/licenseplan/${props.tenant.licensingPlanId}/planfeatures/get`, 'GET');
            let featuresResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/licenseplan/features/get`, 'GET');
            let featureGroupsResponse = await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/licenseplan/featuregroups/get`, 'GET');

            var tenantFeatures = tenantFeaturesResponse.data;
            var planFeatures = planFeaturesResponse.data;
            var features = featuresResponse.data;
            var featureGroups = featureGroupsResponse.data;
            setState({
                ...state,
                tenantFeatures: tenantFeatures,
                planFeatures: planFeatures,
                features: features,
                featureGroups: featureGroups
            });
            var tData = toTreeViewDataItem(featureGroups, features, planFeatures, tenantFeatures)
            setTreeViewData(tData);
        };

        fetchData();
    }, [])

    const addOnsDetails = (i: any) => {
        return <>
            <Work365TreeView
                data={i.dataItem.items}
                expandIcon={true}
                onExpandChange={onExpandChange}
                onCheckChange={onCheckChange}
                item={renderTreeViewItem}
            />
        </>
    }

    const SelectAllCustomCell = (e: any) => {
        var dataItem = e.dataItem;
        var isChecked = dataItem.items.filter((x: any) => !x.checked).length === 0;
        return (
            <td style={{ textAlign: 'center' }}>
                <Checkbox size={'small'} defaultChecked={isChecked} disabled={disableControls} onChange={(evt: any) => {
                    if (disableControls) {
                        return;
                    }

                    if (evt.value) {
                        dataItem.items?.forEach((i: any) => {
                            if (!i.fromLicense) {
                                i.checked = true;
                                var item = {
                                    item: i
                                }
                                addFeature(props.tenant.guid, i.featureId, item)
                            }
                        });
                    } else {
                        dataItem.items?.forEach((i: any) => {
                            if (!i.fromLicense) {
                                i.checked = false;
                                removeFeature(props.tenant.guid, i.featureId)
                            }
                        });
                    }
                    setTreeViewData([...treeViewData]);
                }} />
            </td>
        )
    }

    const expandChange = (event: GridExpandChangeEvent) => {
        let newData = treeViewData.map((item: any) => {
            if (item.groupId === event.dataItem.groupId) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setTreeViewData([...newData]);
    };
    return (
        <div style={{ width: 'calc(75vw)' }}>
            <Work365Card>
                <Label><strong>Add Ons</strong></Label>
                <Work365FlexiGrid
                    style={
                        {
                            height: '710px',
                            width: '1370px'
                        }
                    }
                    columns={[
                        {
                            field: "text",
                            title: "Feature Groups",
                            width: 1150,
                        },
                        {
                            field: "",
                            title: "",
                            width: 100,
                            cell: SelectAllCustomCell
                        },
                    ]}
                    data={treeViewData}
                    dataStateChange={undefined}
                    expandChange={expandChange}
                    detailComponent={addOnsDetails}
                />
            </Work365Card>
        </div>
    )
}

export const RegistrationTab: React.FC<IRegistrationsProps> = (props: IRegistrationsProps) => {
    const [state, setState] = React.useState({
        data: {},
        consent: {},
    })

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/tenantconfiguration/registration/get`, 'GET').then(async (reg: any) => {
            await apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/tenantconfiguration/consent/get`, 'GET').then(async (consent: any) => {
                var data = reg.data.registration;
                var consent = consent.data.consent ?? {};

                if (consent.firstConsentOnUtc != undefined) {
                    consent.firstConsentDate = Utils.ToDisplayDateFormat(consent.firstConsentOnUtc, true, 'fr-CA', 'h')
                } else {
                    consent.firstConsentDate = undefined
                }

                if (consent.lastConsentOnUtc != undefined) {
                    consent.lastConsentDate = Utils.ToDisplayDateFormat(consent.lastConsentOnUtc, true, 'fr-CA', 'h')
                } else {
                    consent.lastConsentDate = undefined
                }

                if (consent.firstConsentBy != undefined) {
                    consent.domain = Utils.GetDomainFromEmail(consent.firstConsentBy)
                } else {
                    props.tenant.domain = undefined
                }

                setState({ ...state, data: data, consent: consent });

            })
        });
    }, [])

    return (
        <div style={{ width: 'calc(75vw)' }}>
            <Work365Card>
                <Label><strong>Last Consent</strong></Label>
                <Form
                    initialValues={state.consent}
                    key={JSON.stringify(state.consent)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Row>
                                <Col>
                                    <Field
                                        name={'azureAdTenantId'}
                                        component={FormInput}
                                        label={'AAD ID'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'domain'}
                                        component={FormInput}
                                        label={'Email Domain'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'firstConsentBy'}
                                        component={FormInput}
                                        label={'First Consent By'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'firstConsentDate'}
                                        component={FormInput}
                                        label={'First Consent On'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'lastConsentBy'}
                                        component={FormInput}
                                        label={'Last Consent By'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'lastConsentDate'}
                                        component={FormInput}
                                        label={'Last Consent On'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>

                        </FormElement>
                    )}
                />
            </Work365Card>
            <Work365Card style={{ marginTop: '10px' }}>
                <Label><strong>Registration</strong></Label>
                <Form
                    initialValues={state.data}
                    key={JSON.stringify(state.data)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Row>
                                <Col>
                                    <Field
                                        name={'companyName'}
                                        component={FormInput}
                                        label={'Company Name'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'website'}
                                        component={FormInput}
                                        label={'Website'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'firstName'}
                                        component={FormInput}
                                        label={'First Name'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'lastName'}
                                        component={FormInput}
                                        label={'Last Name'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'email'}
                                        component={FormInput}
                                        label={'Email'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'jobTitle'}
                                        component={FormInput}
                                        label={'Job Title'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        name={'mobilePhone'}
                                        component={FormInput}
                                        label={'Mobile Number'}
                                        readOnly={true}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        name={'businessPhone'}
                                        component={FormInput}
                                        label={'Business Phone'}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                        </FormElement>
                    )}
                />
            </Work365Card>
        </div>
    )
}

export const ContactsTab: React.FC<ITenantProps> = (props: ITenantProps) => {
    const _export = React.useRef<ExcelExport | null>(null);
    const [state, setState] = React.useState({
        data: []
    })
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState([
        {
            field: "customer",
            dir: "asc",
        } as SortDescriptor,
    ]);
    const [gridState, setGridState] = React.useState<IGridState>({
        groupable: false,
        resizable: true,
        filterable: false,
        sortable: true,
        scrollable: "none",
        pageSize: 30,
        rowHeight: 40,
        //total:0,
    });
    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props.tenant.guid}/contacts`, 'GET').then((response: any) => {
            var data = response.data;
            setState({ ...state, data: data, });
        });
    }, [])

    const StatusCustomCell = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td
                style={{
                    color: 'white'
                }}
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >

                {value === null ? "" : value ?
                    <a className='badge badge-success'>
                        Active
                    </a>
                    : <a className='badge badge-danger'>
                        In Active
                    </a>
                }
            </td>
        )
    }

    const onFilterChange = (e: any) => {
        setFilter(e.filter);
        setGridState({ ...gridState, sort: e.sort });

        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const onSortChange = (e: any) => {
        setSort(e.sort);
        setGridState({ ...gridState, sort: e.sort });
        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const contactsColumn = [
        {
            field: 'customer',
            title: 'Customer Name',
            width: 200,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'tenantUrl',
            title: 'Tenant Url',
            width: 280,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'firstName',
            title: 'First Name',
            width: 180,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'lastName',
            title: 'Last Name',
            width: 180,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'email',
            title: 'Email',
            width: 200,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'work365Roles',
            title: 'Role',
            width: 200,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'isActive',
            title: 'Active',
            width: 100,
            filterable: false,
            cell: StatusCustomCell
        },
    ] as Work365Column[];

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterBy(orderBy(state.data, sort), filter), contactsColumn)
        }
    }

    return (<>
        <Work365Card>
            <div style={{ position: 'relative' }}>
                <div style={{ maxHeight: 'calc(100vh - 14em)', overflowY: 'auto', paddingTop: '5px' }}>
                    <ExcelExport ref={_export} fileName={`Work365Contacts_${new Date().toLocaleString().replace(/[/\\:*?"<>|]/g, "_")}`} />
                    <Work365FlexiGrid
                        style={
                            {
                                height: `680px`,
                                width: `1400px`
                            }
                        }
                        columns={contactsColumn}
                        data={filterBy(orderBy(state.data, sort), filter)}
                        gridState={gridState}
                        onFilterChange={onFilterChange}
                        sortChange={onSortChange}
                        toolbar={
                            <button
                                title='Export to Excel'
                                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'
                                onClick={excelExport}
                            >
                                Export to Excel
                            </button>
                        } />
                </div>
            </div>
        </Work365Card>
    </>)
}

export const ProviderBillingTable: React.FC<IProviderBillingProps> = (props: IProviderBillingProps) => {
    const _export = React.useRef<ExcelExport | null>(null);
    const [state, setState] = React.useState({
        data: {} as any,
    })

    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState([
        {
            field: "invoiceDate",
            dir: "desc",
        } as SortDescriptor,
    ]);
    const [gridState, setGridState] = React.useState<IGridState>({
        groupable: false,
        resizable: true,
        filterable: false,
        sortable: true,
        scrollable: "none",
        pageSize: 30,
        rowHeight: 40,
        //total:0,
    });

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props?.tenant?.guid}/providerbilling`, 'GET').then((r: any) => {
            var data = {
                providerBilling: r.data
            };
            setState({ ...state, data: data });
        });
    }, []);

    const DateFieldWithTime = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td>
                {Utils.ToDisplayDateFormat(value, true, 'fr-CA', 'h') ?? ''}
            </td>
        )
    }
    const DateField = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td>
                {Utils.ToDisplayDateFormat(value, false, 'fr-CA') ?? ''}
            </td>
        )
    }

    const CenterField = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td style={{ textAlign: 'center' }}>
                {(value ?? '')}
            </td>
        )
    }

    const ConsumableField = (props: any) => {
        const consumableVlaue = (props.dataItem.licenseAmount ?? 0) + (props.dataItem.usageAmount ?? 0);
        return (
            <td style={{ textAlign: 'right' }}>
                {(Utils.ToCurrency(consumableVlaue) ?? '')}
            </td>
        )
    }

    const RightCurrencyField = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td style={{ textAlign: 'right' }}>
                {(Utils.ToCurrency(value) ?? '')}
            </td>
        )
    }

    const CenterHeaderCell = (props: any) => {
        return (
            <th style={{ textAlign: 'center', textDecoration: 'none' }}>
                {props.title}
            </th>
        )
    }

    const onFilterChange = (e: any) => {
        setFilter(e.filter);
        setGridState({ ...gridState, sort: e.sort });

        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const onSortChange = (e: any) => {
        setSort(e.sort);
        setGridState({ ...gridState, sort: e.sort });
        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const providerBillingColumns = [
        {
            field: 'invoiceDate',
            title: 'Invoice Date',
            width: 200,
            cell: DateField,
        },
        {
            field: 'provider',
            title: 'Provider',
            width: 200,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'name',
            title: 'Name',
            width: 200,
            filterable: true,
            filter: 'text'
        },
        {
            field: 'currencyCode',
            title: 'Currency',
            width: 100,
            cell: CenterField,
            filterable: true,
            filter: 'text'
        },
        // {
        //     field: 'consumable',
        //     title: 'Consumable',
        //     width: 150,
        //     filterable: false,
        //     cell: ConsumableField,
        // },
        {
            field: 'licenseAmount',
            title: 'License Amount',
            width: 130,
            cell: RightCurrencyField,
            filterable: true,
            filter: 'numeric'
        },
        {
            field: 'usageCount',
            title: 'Usage Count',
            width: 120,
            cell: CenterField,
            filterable: true,
            filter: 'numeric'
        },
        {
            field: 'usageAmount',
            title: 'Usage Amount',
            width: 120,
            cell: RightCurrencyField,
            filterable: true,
            filter: 'numeric'
        },
        {
            field: 'nonRecurringCount',
            title: 'Non-Recurring Count',
            width: 200,
            filterable: true,
            filter: 'numeric',
            cell: CenterField,
        },
        {
            field: 'nonRecurringAmount',
            title: 'Non-Recurring Amount',
            width: 180,
            filterable: true,
            filter: 'numeric',
            cell: RightCurrencyField,
        },
        {
            field: 'revenue',
            title: 'Revenue',
            width: 150,
            filterable: true,
            filter: 'numeric',
            cell: RightCurrencyField,
        },
        {
            field: 'processedOn',
            title: 'Processed On',
            width: 250,
            filterable: false,
            cell: DateFieldWithTime,
        },
    ] as Work365Column[];

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterBy(orderBy(state.data.providerBilling ?? [], sort), filter)?.map((x: any) => {
                x.invoiceDate = Utils.ToDisplayDateFormat(x.invoiceDate, false, 'fr-CA');
                x.processedOn = Utils.ToDisplayDateFormat(x.processedOn, true, 'fr-CA', 'h')
                // x.licenseAmount = Utils.ToCurrency(x.licenseAmount);
                // x.revenue = Utils.ToCurrency(x.revenue);
                // x.nonRecurringAmount = Utils.ToCurrency(x.nonRecurringAmount);
                // x.usageAmount = Utils.ToCurrency(x.usageAmount);
                return x;
            }), providerBillingColumns)
        }
    }

    return (<>
        <ExcelExport ref={_export} fileName={`Work365ProviderBillings_${new Date().toLocaleString().replace(/[/\\:*?"<>|]/g, "_")}`} />
        <Work365FlexiGrid
            style={
                {
                    height: `${props.tableHeight ?? '755'}px`,
                    width: `${props.tableWidth ?? '1400'}px`
                }
            }
            columns={providerBillingColumns}
            gridState={gridState}
            onFilterChange={onFilterChange}
            sortChange={onSortChange}
            data={filterBy(orderBy(state.data.providerBilling ?? [], sort), filter)}
            toolbar={
                <button
                    title='Export to Excel'
                    className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'
                    onClick={excelExport}
                >
                    Export to Excel
                </button>
            } />
    </>

    )
}

export const ProviderBillingReport: React.FC<IProviderBillingProps> = (props: IProviderBillingProps) => {
    const [state, setState] = React.useState({
        providerBillingReport: [] as any[],
    })

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${props?.tenant?.guid}/Report/providerbilling/get`, 'GET').then((report: any) => {
            var providerBillingReport = report.data;
            setState({ ...state, providerBillingReport: providerBillingReport });
        });
    }, []);

    const customLabelformat = (e: any) => {
        return Utils.nFormatter(e.value, 0)
    }

    const toChartSeriesItems = (data: any[]): IChartSeriesItemProps[] => {
        return groupBy(data ?? [], [{
            field: 'invoiceType'
        }]).map((item: any) =>
        ({
            data: item.items,
            name: item.value,
            field: 'totalAmountUSD',
            categoryField: 'monthYear',
            type: 'area'
        })) as IChartSeriesItemProps[];
    }
    return (<>
        {state.providerBillingReport?.length > 0 ?
            <div className="k-card" style={{ height: props.tableHeight ?? '250px', width: props.tableWidth ?? '100%' }}>
                <Work365Chart
                    title={props.showTitle ? 'Provider Billing' : ''}
                    legendOrientation='horizontal'
                    legendPosition='top'
                    pannable={true}
                    zoomable={{
                        mousewheel: {
                            lock: "y"
                        },
                        selection: {
                            lock: "y"
                        }
                    }}
                    categoryItemAxis={{
                        max: 4
                    }}
                    categoryValueAxis={{
                        labels: {
                            visible: true,
                            content: customLabelformat
                        }
                    }}
                    seriesItems={toChartSeriesItems(state.providerBillingReport)}
                />
            </div>
            : <>
                <div className="k-card" style={{ height: props.tableHeight ?? 'calc(33vh)', width: props.tableWidth ?? '100%', textAlign: 'center' }}>No data available</div>
            </>}
    </>)
}

export const EnvironmentSettings: React.FC<ITenantProps> = (props: ITenantProps) => {
    const [environment, setEnvironment] = React.useState(props.tenant);

    function updateTenant(id: string, prop: string, value: any) {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        const data = {
            [prop]: prop == 'disabled' ? !value : value
        };

        apiClient.ExecuteRequest(`tenant/update?tenantId=${id}&data=${JSON.stringify(data)}`, 'PUT').then(async (response: any) => {

        });
    }
    return (<>
        <Work365Card>
            <div style={{ padding: '10px' }}>
                <h4>{'Environment Settings'}</h4>
                <div style={{ lineHeight: '10px' }}>
                    <br />
                </div>
                <Work365Switch
                    style={{ display: 'inline' }}
                    title='Active'
                    key='environment-active'
                    value={!(environment.disabled ?? true)}
                    onChange={(e) => {
                        props.tenant.disable = !e.value;
                        updateTenant(environment.guid, 'disable', !e.value);
                        setEnvironment({ ...environment, disable: !e.value });
                    }}
                />
                <div style={{ lineHeight: '10px' }}>
                    <br />
                </div>
                <Work365Switch
                    style={{ display: 'inline' }}
                    title='Production'
                    key='environment-prod'
                    value={environment.isProduction ?? false}
                    onChange={(e) => {
                        props.tenant.isProduction = e.value;
                        updateTenant(environment.guid, 'isProduction', e.value);
                        setEnvironment({ ...environment, isProduction: e.value });
                    }}
                />
                <div style={{ lineHeight: '10px' }}>
                    <br />
                </div>
                <Work365Switch
                    style={{ display: 'inline' }}
                    title='Portal Eligible'
                    key='environment-portal-eligable'
                    value={environment.isEligibleForPortal ?? false}
                    onChange={(e) => {
                        props.tenant.isEligibleForPortal = e.value;
                        updateTenant(environment.guid, 'isEligibleForPortal', e.value);
                        setEnvironment({ ...environment, isEligibleForPortal: e.value });
                    }}
                />
                <div style={{ lineHeight: '10px' }}>
                    <br />
                </div>
                <Work365Switch
                    style={{ display: 'inline' }}
                    title='Enforce Entitlement'
                    key='environment-enforce-entitlement'
                    value={environment.enforceEntitlement ?? false}
                    onChange={(e) => {
                        props.tenant.enforceEntitlement = e.value;
                        updateTenant(environment.guid, 'enforceEntitlement', e.value);
                        setEnvironment({ ...environment, enforceEntitlement: e.value });
                    }}
                />
                <div style={{ lineHeight: '10px' }}>
                    <br />
                </div>
            </div>
        </Work365Card>
    </>)
}