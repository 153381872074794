import { Component } from "react";
import { Chart, ChartSeries, ChartSeriesItem, ChartTitle, ChartCategoryAxisItem, ChartCategoryAxis, ChartCategoryAxisTitle, ChartTooltip, TooltipContext, SharedTooltipContext } from '@progress/kendo-react-charts'
import React from "react";

interface Work365HorizontalBarChartProps {
    tooltipRender?: ((props: TooltipContext | SharedTooltipContext) => React.ReactNode) | undefined;
    title?: string | undefined;
    categories?: any[] | undefined;
    axisTitle?: string | undefined;
    data? : any[] | undefined;
    height?: number | undefined;
    width?: number | undefined;
}

export default class Work365HorizontalBarChart extends Component<Work365HorizontalBarChartProps>{
    render(){
        return (
            <Chart style={{height: this.props.height, width: this.props.width}}>
                <ChartTooltip render={this.props.tooltipRender} />
                <ChartTitle text={this.props.title} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={this.props.categories}>
                        <ChartCategoryAxisTitle text={this.props.axisTitle} />
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                    <ChartSeriesItem type='bar' data={this.props.data} />
                </ChartSeries>
            </Chart>
        )
    }
}