import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Work365ArcGauge from "../../Lib/Core/Work365ArcGauge";
import Work365Textbox from "../../Lib/Core/TextBox/Work365Textbox";
import Work365Switch from "../../Lib/Core/Work365Switch";
import Work365Label from "../../Lib/Core/Work365Label";
import Work365Card from "../../Lib/Core/Work365Card";
import Work365StackedBarChart from "../../Lib/Core/Work365StackedBarChart";
import Work365TabStrip from "../../Lib/Core/Work365TabStrip";
import Work365TabStripTab from "../../Lib/Core/Work365TabStripTab";
import SetupInstall from "../../Views/Tenant/SetupInstall";
import Work365Title from "../../Lib/Core/Work365Title";
import NumberStatComponent from "./NumberStatComponent";
import { Tenant } from "../../Lib/Models/Tenant";
import Work365Grid from "../../Lib/Core/Work365Grid";
import { ScheduledJob } from "../../Lib/Models/ScheduledJob";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { Global } from "../../Helpers/Global";
import { Invoice } from "../../Lib/Models/Invoice";
import { ProviderBillingReport, ProviderBillingTable, ScheduleJobsTab } from "../../Views/NewTenant/TenantDetails";
import OperationLogs from "../../Views/NewTenant/OperationLogs";
import Work365ConfirmationDialog from "../../Lib/Core/Work365ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { Work365Dialog } from "../../Lib/Core/Work365Dialog";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";

export interface TenantsInfoProps {
  selectedTenant: Tenant | undefined;
  billingContractCount: number;
  subscriptionsCount: number;
  licenseChangeLogsCount: number;
  accountingSystemsCount: number;
  providersCount: number;
  scheduledJobs: ScheduledJob[] | undefined;
  invoices: Invoice[] | undefined;
  companyId?: string | null;
  changeSelectedTenant: (tenant: any) => void;
}

const TenantsInfo: React.FC<TenantsInfoProps> = ({
  selectedTenant,
  billingContractCount,
  subscriptionsCount,
  licenseChangeLogsCount,
  accountingSystemsCount,
  providersCount,
  scheduledJobs,
  invoices,
  companyId,
  changeSelectedTenant
}) => {

  const [forceUpdate, setForceUpdate] = useState(false);
  const [showUpdateTenantStatusDialog, setShowUpdateTenantStatusDialog] = useState(false);
  const [showUpdateTenantTypeDialog, setShowUpdateTenantTypeDialog] = useState(false);
  const [showUpdateTenantUrlDialog, setShowUpdateTenantUrlDialog] = useState(false);

  const [newTenantUrl, setNewTenantUrl] = useState(selectedTenant?.endPointUrl);

  if (billingContractCount == undefined) {
    billingContractCount = 0;
  }

  if (subscriptionsCount == undefined) {
    subscriptionsCount = 0;
  }

  if (licenseChangeLogsCount == undefined) {
    licenseChangeLogsCount = 0;
  }

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  let countByMonth: any[] = [];

  if (invoices != undefined) {
    const initialCounts = invoices
      .filter(model => new Date(model.createdOn).getFullYear() === 2022)
      .reduce((counts: { [key: string]: number }, model) => {
        const month = new Date(model.createdOn).toISOString().slice(0, 7); // 'YYYY-MM'
        counts[month] = (counts[month] || 0) + 1;
        return counts;
      }, {});

    countByMonth = Object.entries(initialCounts).map(([category, value]) => ({ category, value }));
  }


  const renderTooltip = (context: any) => {
    const { category, series, value } = context.point || context;
    return (
      <div>
        {category} : {value} Invoice's generated
      </div>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {selectedTenant?.endPointUrl !== '' ? renderForExistingTenant() : renderForNewTenant()}
    </div>
  );

  function renderForNewTenant() {
    return (
      <Work365TabStrip>
        <Work365TabStripTab title="Initial Setup">
          <SetupInstall tenantGuid={selectedTenant?.guid} companyId={companyId} />
        </Work365TabStripTab>
      </Work365TabStrip>
    )
  }

  function onTenantTypeChange(event: any) {
    setShowUpdateTenantTypeDialog(true);
  }

  function onTenantStatusChange(event: any) {
    setShowUpdateTenantStatusDialog(true);
  }

  function changeTenantType(isProduction: boolean) {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${selectedTenant?.guid}/updatetype?isProduction=${isProduction}`, 'POST')
      .then((response: any) => {
        if (selectedTenant != undefined) {
          selectedTenant.isProduction = isProduction;
          setForceUpdate(!forceUpdate);
        }
      });
  }

  function changeTenantStatus(isActive: boolean) {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${selectedTenant?.guid}/updatestatus?isActive=${isActive}`, 'POST')
      .then((response: any) => {
        if (selectedTenant != undefined) {
          selectedTenant.disabled = !isActive;
          setForceUpdate(!forceUpdate);
        }
      });
  }

  function showChangeTenantEndPointUrlDialog() {
    setShowUpdateTenantUrlDialog(true);
  }

  function hideChangeTenantEndPointUrlDialog() {
    setShowUpdateTenantUrlDialog(false);
  }

  function renderForExistingTenant() {
    return (
      <>
        <Work365TabStrip>
          <Work365TabStripTab title="General">
            <div style={{ padding: '0.5REM' }}>
              <Row className="mt-2">
                <Col md={9}>
                  <Work365Card>
                    <Row>
                      <Col>
                        <Work365Title title="End Point Url" />
                      </Col>
                      <Col>
                        <div className='text-right'>
                          <FontAwesomeIcon icon={faWrench} onClick={showChangeTenantEndPointUrlDialog} style={{ cursor: 'pointer' }} />
                        </div>
                      </Col>
                    </Row>
                    <Work365Textbox style={{ marginTop: '4px' }} value={selectedTenant?.endPointUrl} disabled />
                  </Work365Card>
                </Col>
                <Col md={3} style={{ marginTop: '42px' }}>
                  <Row>
                    <Col>
                      <Work365Switch style={{ width: '100px' }} title='Production' onChange={onTenantTypeChange} value={selectedTenant?.isProduction} />
                    </Col>
                    <Col>
                      <Work365Switch style={{ width: '100px' }} title='Enabled' onChange={onTenantStatusChange} value={!selectedTenant?.disabled} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={12}>
                  <Row>
                    <Col md={4}>
                      <Work365Card>
                        <Work365ArcGauge value={billingContractCount} total={150} title="Billing Contracts" />
                      </Work365Card>
                    </Col>
                    <Col md={4} style={{ padding: '0px' }}>
                      <Work365Card>
                        <Work365ArcGauge value={providersCount} total={10} title="Automatic Providers" />
                      </Work365Card>
                    </Col>
                    <Col md={4}>
                      <Work365Card>
                        <Work365ArcGauge value={accountingSystemsCount} total={10} title="Accounting Systems" />
                      </Work365Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <NumberStatComponent title="Subscriptions" amount={subscriptionsCount.toString()} />
                    </Col>
                    <Col md={4} style={{ padding: '0px' }}>
                      <NumberStatComponent title="Billing Contracts" amount={billingContractCount.toString()} />
                    </Col>
                    <Col md={4}>
                      <NumberStatComponent title="Items Provisioned" amount={licenseChangeLogsCount.toString()} />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Work365Card>
                        <Work365Label>Invoices Generate each month</Work365Label>
                        <Work365StackedBarChart uninvoicedUsageSummaries={countByMonth} tooltipRender={renderTooltip} />
                      </Work365Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Work365TabStripTab>
          <Work365TabStripTab title="Setup">
            <SetupInstall tenantGuid={selectedTenant?.guid} companyId={companyId} />
          </Work365TabStripTab>
          <Work365TabStripTab title="Scheduled Jobs">
            <div style={{ padding: '0.5REM' }}>
              <Row className="mt-2">
                <ScheduleJobsTab tenant={selectedTenant} tableWidth={85} tableHeight={150} />
              </Row>
            </div>
          </Work365TabStripTab>
          <Work365TabStripTab title="Logs">
            <OperationLogs tenantId={selectedTenant?.guid} />
          </Work365TabStripTab>
          <Work365TabStripTab title="Provider Billing">
            <div style={{ padding: '0.5REM' }}>
              <Row className="mt-2">
                <ProviderBillingReport tenant={selectedTenant} tableHeight={'280px'} />
              </Row>
              <Row className="mt-2">
                <ProviderBillingTable tenant={selectedTenant} tableWidth={85} showTitle={false} />
              </Row>
            </div>
          </Work365TabStripTab>
        </Work365TabStrip>
        {showUpdateTenantStatusDialog && (
          <Work365ConfirmationDialog
            headerText={selectedTenant?.disabled ? 'Are you sure you want to enable the tenant?' : 'Are you sure you want to disable the tenant?'}
            onOKClick=
            {
              () => {

                if (selectedTenant != undefined) {
                  if (selectedTenant?.disabled == undefined) {
                    selectedTenant.disabled = true;
                  }

                  changeTenantStatus(selectedTenant.disabled);
                }

                setShowUpdateTenantStatusDialog(false);
              }
            }
            onCancelClick=
            {
              () => {
                setShowUpdateTenantStatusDialog(false);
              }
            } />
        )}

        {showUpdateTenantTypeDialog && (
          <Work365ConfirmationDialog
            headerText={selectedTenant?.isProduction ? 'Are you sure you want to update the tenant type to Sandbox?' : 'Are you sure you want to update the tenant type to Production?'}
            onOKClick={
              () => {
                if (selectedTenant != undefined) {
                  if (selectedTenant?.isProduction == undefined) {
                    selectedTenant.isProduction = false;
                  }

                  changeTenantType(!selectedTenant.isProduction);
                }

                setShowUpdateTenantTypeDialog(false);
              }
            }

            onCancelClick={
              () => {
                setShowUpdateTenantTypeDialog(false);
              }
            } />
        )}

        {showUpdateTenantUrlDialog && (
          <Work365Dialog onClose={hideChangeTenantEndPointUrlDialog} title="Update Tenant Url">
            <p>Please specify a new Tenant URL and than click submit.</p>

            <Work365Label>End Point Url</Work365Label>
            <Work365Textbox onChange={(event: any) => {
              setNewTenantUrl(event.value);
            }} />
            <Row style={{ marginTop: '5px' }}>
              <Col>
                <Work365PrimaryButton style={{ width: '100%' }} title='Cancel' onClick={hideChangeTenantEndPointUrlDialog} />
              </Col>
              <Col>
                <Work365PrimaryButton style={{ width: '100%' }} title="Submit" onClick={() => {
                  Global.getApiService(appSettings, authService)
                    .ExecuteRequest(`tenant/${selectedTenant?.guid}/updateurl?url=${newTenantUrl}`, 'POST')
                    .then((response: any) => {
                      if (selectedTenant != undefined) {
                        selectedTenant.endPointUrl = newTenantUrl;

                        changeSelectedTenant(selectedTenant);
                        hideChangeTenantEndPointUrlDialog();
                      }
                    });
                }} />
              </Col>
            </Row>
          </Work365Dialog>
        )}
      </>
    )
  }
};

export default TenantsInfo;

