import React from 'react';
import UsageCharts from '../../Components/Tenant/UsageSummaries/UsageCharts';
import { Global } from '../../Helpers/Global';
import OverviewPanel from '../../Components/Tenant/Home/OverviewPanel';
import Work365PagingGrid from '../../Lib/Core/Work365PagingGrid';

const TestPage: React.FC = () => {
    React.useEffect(() => {

        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        apiClient.ExecuteRequest('/UsageSummary', 'GET').then((response: any) => {
            console.log('TestPage.tsx', { response });
        });

        return () => {
            console.log("Unmounted")
        };
    }, []);

    return (<div>
    </div>)

}

export default TestPage;