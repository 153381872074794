import { Component } from "react";
import Work365LoggedInUser from "../../Lib/Core/LoggedInUser/Work365LoggedInUser";
import { Work365DropDownButton } from "../../Lib/Core/DropDownButton/Work365DropDownButton";
import { Global } from "../../Helpers/Global";

export default class Work365NavBar extends Component {
    render() {
        const exclamationItems = [{
            Name: 'You have 4 pending actions',
            Action: () => {
                console.log('You have 4 pending actions');
            }
        },
        {
            Name: 'Email Verified',
            Action: () => {
                console.log('Email Verified');
            }
        },
        {
            Name: 'Microsoft Partner Program Details',
            Action: () => {
                console.log('Microsoft Partner Program Details');
            }
        },
        {
            Name: 'Select Your Plan',
            Action: () => {
                console.log('Select Your Plan');
            }
        },
        {
            Name: 'Payment Information',
            Action: () => {
                console.log('Payment Information');
            }
        }];

        const questionItems = [{
            Name: 'Documentation',
            Action: () => {
                console.log('Documentation');
            }
        },
        {
            Name: 'Knowlege Base',
            Action: () => {
                console.log('Knowlege Base');
            }
        },
        {
            Name: 'Community',
            Action: () => {
                console.log('Community');
            }
        }];

        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        return (
            <div style={{backgroundColor: 'white', height: '45px', position: 'fixed', top: '0', left: '240', width: 'calc(100% - 240px)'}}>
                <div className='text-right'>
                    <Work365LoggedInUser name={authService.account?.name == null ? '' : authService.account?.name} />
                </div>
            </div>
        )
    }
}