import React, { useEffect } from 'react';
import { Global } from '../Helpers/Global';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
  FieldWrapper,
} from '@progress/kendo-react-form';
import { Container, Row } from 'react-bootstrap';
import {
  FormInput,
  EmailValidator,
  FormMaskedTextBox,
  phoneValidator,
  RequiredValidator,
} from '../Lib/Core/Work365FormInputs';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import Work365CompleteProfileForm from '../Forms/Work365CompleteProfileForm';
import { User } from '../Lib/Models/User';

const ProfileComplete: React.FC = () => {
  let { i18n, t } = useTranslation(['CompleteProfile']);

  const navigate = useNavigate();
  const [user, setUser] = React.useState<User>();

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  useEffect(() => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest('user/me', 'GET')
      .then((response: any) => {
        setUser({
          FirstName: response.data.givenName,
          LastName: response.data.surname,
          MiddleInitials: undefined,
          Email: response.data.userPrincipalName,
          JobTitle: response.data.jobTitle,
          Phone: response.data.mobilePhone,
        });
      });
  }, []);

  const handleSubmit = (dataItem: any) => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest('user/createuser', 'POST', dataItem)
      .then((response: any) => {
        navigate('/ManageEnvironments');
      });
  };

  return (
    <div style={{ marginTop: '35px' }}>
      <div className='text-center'>{t('welcomeMessage')}</div>
      <Work365CompleteProfileForm user={user} onSubmit={handleSubmit} />
    </div>
  );
};
export default ProfileComplete;
