import { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Work365ButtonProps } from '../../Models/Work365ButtonProps';

export default class Work365SecondaryButton extends Component<Work365ButtonProps> {
  render() {
    var isDisabled = false;

    if (this.props.isDisabled) {
      isDisabled = true;
    }

    return (
      <Button
        fillMode='outline'
        type={'submit'}
        disabled={isDisabled}
        icon={this.props.icon}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        {this.props.title}
      </Button>
    );
  }
}
