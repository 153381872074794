import React from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365StackLayout from '../../Lib/Core/Layout/Work365StackLayout';
import { Form } from '@progress/kendo-react-form';
import Work365StackListLayout, { IWork365StockListItem, IWork365StockListEvent } from '../../Lib/Core/Layout/Work365StackListLayout';
import { Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import Work365FlexiGrid from '../../Lib/Core/Grid/Work365FlexiGrid';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Row } from 'react-bootstrap';
import { Global } from '../../Helpers/Global';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';

const EntitlementPacks: React.FC = () => {
    const [entitlements, setEntitlements] = React.useState([]);
    const [selectedEntitlementId, setSelectedEntitlementId] = React.useState(0);
    const [packs, setPacks] = React.useState([]);
    const [selectedPack, setSelectedPack] = React.useState({} as any);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [confirmMessage, setConfirmConfirmMessage] = React.useState('');
    const [addEditForm, setaddEditForm] = React.useState(false);

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/entitlement/LimitBased/get`, 'GET').then(async (ents: any) => {
            var entitlements = ents.data;
            await apiClient.ExecuteRequest(`tenant/${null}/entitlement/pack/${entitlements[0].entitlementId}/get`, 'GET').then(async (packs: any) => {

                var entitlementPacks = packs.data;

                setEntitlements(entitlements);
                setPacks(entitlementPacks);
            });
        });
        return () => {
            console.log("Unmounted")
        };
    }, [])

    const entilementOnClick = (e: IWork365StockListEvent) => {
        setSelectedEntitlementId(e.selected.id);
        getEntitlementsPacks(e.selected.id);
    }

    const deleteEntitlementPack = (entitlementPackId: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/entitlement/pack/${entitlementPackId}/delete`, 'DELETE').then((packs: any) => {
            getEntitlementsPacks(selectedEntitlementId);
        });
    }

    const saveEntitlementPack = (pack: any) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/entitlement/pack/${pack.entitlementPackId}/update?entitlementPackName=${encodeURI(pack.entitlementPackName)}&entitlementPackInternalName=${encodeURI(pack.entitlementPackInternalName)}&quantity=${encodeURI(pack.quantity)}&entitlementId=${encodeURI(pack.entitlementId)}`, 'PUT').then((packs: any) => {

            getEntitlementsPacks(selectedEntitlementId);
        });
    }
    const getEntitlementsPacks = (entitlementId: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/entitlement/pack/${entitlementId}/get`, 'GET').then((packs: any) => {
            var entitlementPacks = packs.data;
            setPacks(entitlementPacks);
        });
    }

    const ActionCustomCell = (props: any) => {
        return (
            <td>
                <Button icon='pencil' size={'small'} fillMode={'link'} themeColor={'primary'} title='Edit'
                    onClick={() => {
                        setSelectedPack(props.dataItem);
                        setaddEditForm(true);
                    }} />
                <Button icon='trash' size={'small'} fillMode={'link'} themeColor={'primary'} title='Delete'
                    onClick={() => {
                        setSelectedPack(props.dataItem);
                        setConfirmConfirmMessage('Are you sure you want to delete this entitlement pack?');
                        setConfirmDelete(true);
                    }} />
            </td>
        )
    }

    const handleEntitlementPackNameChange = (e: any) => {
        var val = e.target.value
        setSelectedPack({ ...selectedPack, entitlementPackName: val })
    }
    const handleEntitlementPackInternalNameChange = (e: any) => {
        var val = e.target.value
        setSelectedPack({ ...selectedPack, entitlementPackInternalName: val })
    }
    const handleEntitlementPackQuantityChange = (e: any) => {
        var val = e.target.value
        setSelectedPack({ ...selectedPack, quantity: val })
    }

    return <>
        {
            confirmDelete ?
                <Work365Dialog
                    width={400}
                    onClose={() => setConfirmDelete(false)}
                    children={<>
                        <Form
                            render={(formRenderProps) => {
                                return (<>
                                    <Row>
                                        <p>{confirmMessage}</p>

                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button themeColor='primary' onClick={() => {
                                                setConfirmDelete(false);
                                                deleteEntitlementPack(selectedPack.entitlementPackId)
                                            }}
                                            >Confirm</Button> &nbsp;
                                            <Button themeColor='error' onClick={() => setConfirmDelete(false)}>Cancel</Button>
                                        </div>
                                    </Row>
                                </>);
                            }}
                        />
                    </>}
                />
                : <></>
        }

        {
            addEditForm ?
                <Work365Dialog
                    width={400}
                    onClose={() => setaddEditForm(false)}
                    children={<>
                        <Form
                            initialValues={selectedPack}
                            key={JSON.stringify(selectedPack)}
                            render={(formRenderProps) => {
                                return (<>
                                    <Row>
                                        <div style={{ padding: '10px' }}>
                                            <Label>{'Entitlement Pack Name'}</Label>
                                            <Input
                                                defaultValue={selectedPack.entitlementPackName}
                                                onBlur={handleEntitlementPackNameChange}
                                            />
                                        </div>
                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ padding: '10px' }}>
                                            <Label>{'Entitlement Pack Internal Name'}</Label>
                                            <Input
                                                defaultValue={selectedPack.entitlementPackInternalName}
                                                onBlur={handleEntitlementPackInternalNameChange}
                                            />
                                        </div>
                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ padding: '10px' }}>
                                            <Label>{'Quantity'}</Label>
                                            <Input
                                                defaultValue={selectedPack.quantity}
                                                onBlur={handleEntitlementPackQuantityChange}
                                            />
                                        </div>
                                    </Row>
                                    <div style={{ lineHeight: '5px' }}>
                                        <br />
                                    </div>
                                    <Row>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button themeColor='primary' onClick={() => {
                                                setaddEditForm(false);
                                                saveEntitlementPack(selectedPack);
                                            }}
                                            >Confirm</Button> &nbsp;
                                            <Button themeColor='error' onClick={() => setaddEditForm(false)}>Cancel</Button>
                                        </div>
                                    </Row>
                                </>);
                            }}
                        />
                    </>}
                />
                : <></>
        }

        {entitlements?.length > 0 ?
            <Work365StackLayout
                orientation='horizontal'
                gap={5} >
                <Col lg={6}>
                    <Work365Card>
                        <Work365StackListLayout
                            height='100px'
                            data={entitlements.map((p: any) => {
                                return {
                                    id: p.entitlementId,
                                    name: p.entitlementName,
                                    selected: false
                                } as IWork365StockListItem;
                            })}
                            editable={false}
                            onClick={entilementOnClick}
                        />
                    </Work365Card>
                </Col>
                <Col lg={6}>
                    <Button themeColor='primary' size={'small'} style={{ marginBottom: '5px' }} onClick={() =>{
                        setSelectedPack({
                            ...selectedPack,
                            entitlementPackId: 0,
                            entitlementPackName: '',
                            entitlementId: selectedEntitlementId,
                            entitlementPackInternalName: '',
                            quantity: 0
                        });
                        setaddEditForm(true);
                    }}
                    >Add Entitlement Pack</Button>
                <Work365Card>
                    <Work365FlexiGrid
                        style={
                            {
                                height: '700px',
                                width: '750px',
                            }
                        }
                        data={packs}
                        columns={[
                            {
                                field: 'entitlementPackName',
                                title: 'Entitlement Pack',
                                width: 200,
                            },
                            {
                                field: 'entitlementPackInternalName',
                                title: 'Internal Name',
                                width: 200,
                            },
                            {
                                field: 'quantity',
                                title: 'Quantity',
                                width: 200,
                            },
                            {
                                title: 'Action',
                                width: 100,
                                cell: ActionCustomCell
                            },
                        ]}
                    />
                </Work365Card>
            </Col>
            </Work365StackLayout > : <></>
}
    </>
}

export default EntitlementPacks;