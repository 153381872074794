import React from 'react';
import Work365EmptyNavbar from '../Components/Common/Work365EmptyNavbar';
import Work365LoadingIndicator from '../Lib/Common/Work365LoadingIndicator';
import Work365Notifications from '../Lib/Core/Notifications/Work365Notification';

const HiddenLayout: React.FC<any> = ({children}) => {
    return (
        <>
            <Work365LoadingIndicator />
            <Work365Notifications />
            <Work365EmptyNavbar />
            {children}
        </>
    )
}

export default HiddenLayout;