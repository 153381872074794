import { useEffect, useState } from "react";
import { Global } from "../../Helpers/Global";
import LogScreen from "../../Lib/Common/LogScreen";

interface OperationLogProps {
    operation: any;
    tenantId: any;
}

const OperationLog: React.FC<OperationLogProps> = ({operation, tenantId}) => {

    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);

    const [intervalId, setIntervalId] = useState<any>(undefined);

    const [logs, setLogs] = useState<string[]>([]);
    let logCount = 0;

    function getOperationLogsInterval() {
        getOperationLogs(false, false);
    }

    function getOperationLogs(showLoadingScreen: boolean | undefined, clearLogs: boolean | undefined) {
        let params = {};

        if (logCount != 0) {
            params = {
                $skip: logCount,
            }
        }

        if (showLoadingScreen === undefined) {
            showLoadingScreen = false;
        }

        if (clearLogs === undefined) {
            clearLogs = false;
        }

        Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/${operation.operationId}/log/get`, 'GET', params, showLoadingScreen)
        .then((response: any) => {
            let messages: string[] = response.data.map((data: any) => data.message);
            if (logCount == 0 && response.data.length > 0) {
                logCount = response.data.length;
            }

            if (clearLogs) {
                setLogs(messages);
                return;
            }

            // TODO : make it so it doesn't retrieve the whole page
            setLogs([...logs, ...messages]);
            logCount = logs.length + messages.length;
        });
    }

    useEffect(() => {
        return () => {
            if (intervalId !== undefined) {
                clearInterval(intervalId);
                setIntervalId(undefined);
            }
        }
    }, []);

    useEffect(() => {
        if (intervalId !== undefined) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        }

        getOperationLogs(true, true);
    }, [operation]);

    return (
        <div>
            <LogScreen logs={logs} />
        </div>
    )
}

export default OperationLog;