import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Col, Row } from 'react-bootstrap';
import {
  FormInput,
  ComboboxInput,
  FormNumericTextBox,
  MultiSelectInput,
} from '../Lib/Core/Work365FormInputs';
import Work365Card from '../Lib/Core/Work365Card';
import { BillingContractTemplate } from '../Lib/Models/BillingContractTemplate';
import { Currency } from '../Lib/Models/Currency';
import { Global } from '../Helpers/Global';
import Work365Label from '../Lib/Core/Work365Label';

export type CreateTenantFormProps = {
  onSubmit: ( values: { [name: string]: any }, event?: SyntheticEvent<any, Event> | undefined) => void;
  timeZones?: any[] | undefined;
};

const CreateTenantForm: React.FC<CreateTenantFormProps> = ({ onSubmit, timeZones }) => {

  let environmentTypes = [{
    name: 'Production',
    value: 'production'
  }, {
    name: 'Sandbox',
    value: 'sandbox'
  }]

  return (
    <Work365Card>
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <Row>
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={'k-messagebox k-messagebox-error'}>
                    {formRenderProps.errors.VALIDATION_SUMMARY}
                  </div>
                )}
            </Row>
            
            <Row>
                <Work365Label>Dynamics Environment Settings</Work365Label>
                <Col>
                    <Field name={'organizationName'} component={FormInput} label={'Organization Name'} />
                </Col>
                <Col>
                    <Field name={'uniqueName'} component={FormInput} label={'Unique Name'} />
                </Col>
                <Col>
                    <Field name={'endPointUrl'} component={FormInput} label={'End Point Url'} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name={'timeZone'} component={ComboboxInput} label={'Time Zone'} data={timeZones} />
                </Col>
                <Col>
                    <Field name={'environmentType'} component={ComboboxInput} label={'Environment Type'} data={environmentTypes} />
                </Col>
            </Row>

            <div className='k-form-buttons text-right'>
              <button
                type={'submit'}
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'>
                Save
              </button>
            </div>
          </FormElement>
        )}
      />
    </Work365Card>
  );
};

export default CreateTenantForm;
