import { Splitter, SplitterOnChangeEvent } from '@progress/kendo-react-layout';
import React from 'react';
import Work365PieChart from '../../Lib/Core/ChartJS/Work365PieChart';
import Work365BarChart from '../../Lib/Core/ChartJS/Work365BarChart';
import { Global } from '../../Helpers/Global';
import Work365FlexiGrid, { IGridState } from '../../Lib/Core/Grid/Work365FlexiGrid';
import { Work365Column } from '../../Lib/Models/Work365Column';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, orderBy, filterBy } from '@progress/kendo-data-query';
import { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';
import { Button } from '@progress/kendo-react-buttons';
import { Row } from 'react-bootstrap';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Utils } from '../../Services/Utils';
import { randomInt } from 'crypto';
import Work365Switch from '../../Lib/Core/Work365Switch';

const initialFilter = {
    logic: "and", filters: [
        {
            field: 'disabled',
            operator: 'eq',
            value: false
        }
    ] as FilterDescriptor[]
} as CompositeFilterDescriptor;

const EnvironmentReport: React.FC = () => {
    const _export = React.useRef<ExcelExport | null>(null);
    const [environments, setenvironments] = React.useState([] as any);
    const [heartBeat, setHeartBeat] = React.useState([] as any);
    const [showError, setShowError] = React.useState({ value: false, message: '' });
    const [filter, setFilter] = React.useState(initialFilter)
    const [includeInactive, setIncludeInactive] = React.useState(false)
    const [includeNonProduction, setIncludeNonProduction] = React.useState(true)
    const [selectedVersion, setSelectedVersion] = React.useState(null)
    const [sort, setSort] = React.useState([
        {
            field: "endPointUrl",
            dir: "asc",
        } as SortDescriptor,
    ]);
    const [gridState, setGridState] = React.useState<IGridState>({
        groupable: false,
        resizable: true,
        filterable: false,
        sortable: true,
        scrollable: "virtual",
        total: 0,
        skip: 0,
        take: 30
    });

    const [panes, setPanes] = React.useState<Array<any>>([
        { size: "55%", min: "20px", collapsible: true },
        {},
        { size: "45%", min: "20px", collapsible: true },
    ]);
    const [nestedPanes, setNestedPanes] = React.useState<Array<any>>([
        { size: "30%" },
        {},
        { size: "0%", resizable: false },
    ]);
    const onChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };
    const onNestedChange = (event: SplitterOnChangeEvent) => {
        setNestedPanes(event.newState);
    };

    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);
    var apiClient = Global.getApiService(appSettings, authService);
    let navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            let environments = await apiClient.ExecuteRequest(`tenant/withcustomerdetails/all/get`, 'GET');
            let heartBeat = await apiClient.ExecuteRequest(`tenant/${undefined}/report/heartbeat/get`, 'GET')
            setenvironments(() => environments.data);
            setHeartBeat(() => heartBeat.data);
            setGridState({ ...gridState, total: environments.data.length })
        }
        fetchData();
    }, []);

    const LinkCustomCell = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        const isDisabled = props.dataItem['disabled'];
        return (
            <td
                style={{ color: isDisabled ? 'red' : 'blue' }}
            >
                <p style={{ cursor: 'pointer' }} onClick={() => {
                    CanConnectToDataVerse(props.dataItem['guid'], () => {
                        navigate(`/Environment/${props.dataItem['guid']}`)
                    })
                }}>
                    {value}
                </p>
            </td>
        )
    }
    const StatusCustomCell = (props: any) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td
                style={{
                    color: 'white'
                }}
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >

                {value === null ? "" : value ?
                    <a className='badge badge-success'>
                        Production
                    </a>
                    : <a className='badge badge-danger'>
                        Non-Production
                    </a>
                }
            </td>
        )
    }

    const environmentColumns = [
        {
            field: "endPointUrl",
            title: "Endpoint URL",
            filterable: true,
            filter: "text",
            cell: LinkCustomCell
        },
        {
            field: 'isProduction',
            title: 'State',
            filterable: false,
            cell: StatusCustomCell
        },
        {
            field: "appVersion",
            title: "App Version",
            filterable: true,
            filter: "text"
        },
        {
            field: "customerName",
            title: "Customer Name",
            filterable: true,
            filter: "text"
        },
        {
            field: "fL7D",
            title: "FL7D",
            filterable: true,
            filter: "text"
        },
        {
            field: "fL7D",
            title: "FL30D",
            filterable: true,
            filter: "text"
        },
    ] as Work365Column[];

    const versionOptions = {
        onClick: (e: any, element: any) => {
            if (element.length > 0) {
                var ind = element[0].index;
                const labels = environments?.map((x: any) => x.appVersion).filter((x: any, i: number, s: any) => s.indexOf(x) === i).sort();

                const filterIndex = filter.filters.findIndex((x: any) => x.field === 'appVersion' && x.operator === 'eq') as any;
                if (filterIndex !== -1) {
                    if (selectedVersion !== labels[ind]) {
                        filter.filters[filterIndex] = { field: 'appVersion', operator: 'eq', value: labels[ind] };
                        setSelectedVersion(labels[ind])
                    } else {
                        filter.filters = filter.filters.filter((x: any) => x.field !== 'appVersion')
                        setSelectedVersion(null);
                    }
                } else {
                    filter.filters.push({ field: 'appVersion', operator: 'eq', value: labels[ind] });
                    setSelectedVersion(labels[ind])
                }
                setFilter(filter);
                setGridState({ ...gridState, filter: filter });
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Environment Version',
            },
        },
        responsive: true,
        maintainAspectRation: false,
        aspectRation: 1,
    };
    const getRandomColor = (index: number) => {
        var colorNum = index * 10000;
        var color = "hsl(" + colorNum + ", 100%, 75%)";
        return {
            color: color,
            border: "hsl(" + colorNum + ", 100%, 100%)"
        };
    }

    const environmentVersionData = (env: any) => {
        const labels = env?.map((x: any) => x.appVersion).filter((x: any, i: number, s: any) => s.indexOf(x) === i).sort();
        const colors = labels.map((x: any) => getRandomColor(labels.indexOf(x)));

        const versions = {
            labels: labels,
            datasets: [
                {
                    label: 'Total Count',
                    data: labels.map((x: any) => {
                        var count = env?.filter((e: any) => e.appVersion === x).length;
                        return count;
                    }),
                    backgroundColor: colors.map((c: any) => c.color),
                    borderColor: colors.map((c: any) => c.border),
                    borderWidth: 1,
                },
            ],
        };

        return versions;
    }

    const heartBeatOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Environment Heartbeat',
            },
        },
        responsive: true,
        maintainAspectRation: false,
        aspectRation: 1,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                stepSize: 100
            },
        },
    };

    const heartBeatData = (hb: any) => {
        const labels = hb?.map((x: any) => Utils.ToDisplayDateFormat(x.date, false, 'fr-CA'))
        var heartBeatData = {
            labels,
            datasets: [
                {
                    label: 'Succeeded',
                    data: hb.map((x: any) => x.success),
                    backgroundColor: 'green',
                    stack: 'Stack 0',
                },
                {
                    label: 'Failed',
                    data: hb.map((x: any) => x.failed * -1),
                    backgroundColor: 'red',
                    stack: 'Stack 0',
                }
            ],
        };
        return heartBeatData
    }

    const CanConnectToDataVerse = (tenantId: string, callback: Function) => {
        apiClient.ExecuteRequest(`tenant/${tenantId}/canConnectToDataverse`, 'GET').then((canConnect: any) => {
            if (!canConnect.data) {
                setShowError({ value: true, message: 'Unable to connect to dataverse' })
            } else {
                callback();
            }
        });
    }

    const onPageChange = (e: GridPageChangeEvent) => {
        setGridState({ ...gridState, skip: e.page.skip });
    }

    const onFilterChange = (e: any) => {
        setFilter(e.filter);
        setGridState({ ...gridState, filter: e.filter });

        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const onSortChange = (e: any) => {
        setSort(e.sort);
        setGridState({ ...gridState, sort: e.sort });
        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterBy(orderBy(environments, sort), filter)?.map((x: any) => {
                x.isProduction = x.isProduction ?? false ? 'PRODUCTION' : 'NON-PRODUCTION';
                return x;
            }), environmentColumns)
        }
    }

    return (
        <div>
            {
                showError.value ?
                    <Work365Dialog
                        width={300}
                        onClose={() => setShowError({ value: false, message: '' })}
                        title={'Error'}
                        children={<>
                            <p>
                                {showError.message}
                            </p>
                            <Row>
                                <div style={{ textAlign: 'right' }}>
                                    <Button themeColor='error' onClick={() =>
                                        setShowError({ value: false, message: '' })
                                    }>Ok</Button>
                                </div>
                            </Row>
                        </>} /> : <></>
            }
            <Splitter
                style={{ height: '655px' }}
                panes={nestedPanes}
                orientation={"horizontal"}
                onChange={onNestedChange}
            >
                <Splitter
                    panes={panes}
                    onChange={onChange}
                    orientation={'vertical'}
                >
                    <div className="pane-content" style={{ padding: '10px', textAlign: 'center' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Work365PieChart
                                key={nestedPanes[0].size}
                                style={{ maxHeight: '330px', maxWidth: '330px' }}
                                options={versionOptions} data={environmentVersionData(environments)}
                            />
                        </div>
                    </div>
                    <div className="pane-content" style={{ padding: '10px' }}>
                        <Work365BarChart
                            style={{ minHeight: '100px' }}
                            key={nestedPanes[0].size}
                            options={heartBeatOptions} data={heartBeatData(heartBeat)}
                        />
                    </div>
                </Splitter>
                <div className="pane-content" style={{ padding: '10px', textAlign: 'center' }}>
                    <ExcelExport ref={_export} fileName={`Work365EnvironmentReport_${new Date().toLocaleString().replace(/[/\\:*?"<>|]/g, "_")}`} />
                    <Work365FlexiGrid
                        style={
                            {
                                height: '630px'
                            }
                        }
                        gridState={gridState}
                        columns={environmentColumns}
                        data={filterBy(orderBy(environments, sort), filter).slice(gridState.skip, (gridState.skip ?? 0) + (gridState.take ?? 0))}
                        onFilterChange={onFilterChange}
                        sortChange={onSortChange}
                        onPageChange={onPageChange}
                        toolbar={
                            <div>
                                <button
                                    title='Export to Excel'
                                    className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'
                                    onClick={excelExport}
                                >
                                    Export to Excel
                                </button>
                                <div style={{ width: '20px' }}></div>
                                <Work365Switch
                                    style={{ display: 'inline' }}
                                    title='Include Non-Production'
                                    key='environment-include-non-prod'
                                    value={includeNonProduction}
                                    onChange={(e) => {
                                        if (e.value) {
                                            filter.filters = filter.filters.filter((x: any) => x.field !== 'isProduction')
                                        } else {
                                            const filterIndex = filter.filters.findIndex((x: any) => x.field === 'isProduction' && x.operator === 'eq') as any;
                                            if (filterIndex !== -1) {
                                                filter.filters[filterIndex] = { field: 'isProduction', operator: 'eq', value: !e.value };
                                            } else {
                                                filter.filters.push({ field: 'isProduction', operator: 'eq', value: !e.value });
                                            }
                                        }

                                        setFilter({ ...filter })
                                        setIncludeNonProduction(!includeNonProduction);
                                        setGridState({ ...gridState, filter: filter });

                                        let gridContainer = document.querySelector('.k-grid-content');
                                        if (gridContainer) {
                                            gridContainer.scrollTop = 0;
                                        }
                                    }}
                                />
                                <div style={{ width: '20px' }}></div>
                                <Work365Switch
                                    style={{ display: 'inline' }}
                                    title='Include Inactive'
                                    key='environment-include-inactive'
                                    value={includeInactive}
                                    onChange={(e) => {
                                        if (e.value) {
                                            filter.filters = filter.filters.filter((x: any) => x.field !== 'disabled')
                                        } else {
                                            const filterIndex = filter.filters.findIndex((x: any) => x.field === 'disabled' && x.operator === 'eq') as any;
                                            if (filterIndex !== -1) {
                                                filter.filters[filterIndex] = { field: 'disabled', operator: 'eq', value: e.value };
                                            } else {
                                                filter.filters.push({ field: 'disabled', operator: 'eq', value: e.value });
                                            }
                                        }

                                        setFilter({ ...filter })
                                        setIncludeInactive(!includeInactive);
                                        setGridState({ ...gridState, filter: filter });


                                        let gridContainer = document.querySelector('.k-grid-content');
                                        if (gridContainer) {
                                            gridContainer.scrollTop = 0;
                                        }
                                    }}
                                />
                            </div>

                        }
                    />
                </div>
                <div className="pane-content">
                </div>
            </Splitter>
            {/* <style type="text/css">{`
                .chart-container {
                    display: flex;
                    width: 100%;
                    height:500px;
                }
            `}
            </style> */}
        </div>
    )
}

export default EnvironmentReport;