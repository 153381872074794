import { useEffect, useRef } from 'react';

interface LogScreenProps {
    logs: string[];
}

const LogScreen: React.FC<LogScreenProps> = ({
    logs
}) => {
    const endOfLogsRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
      endOfLogsRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [logs]);

    return (
        <div className="log-container" style={{marginLeft: '8px'}}>
            <p>
            {logs.map((log: any) => {
                return <><br />{log}</>
            })}
            </p>
        </div>
    )
}

export default LogScreen;