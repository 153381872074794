import React from 'react';
import { useTranslation } from 'react-i18next';
import TemplateGrid from '../../Components/Templates/TemplatesGrid';
import { Global } from '../../Helpers/Global';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';
import { Work365LargeDialog } from '../../Lib/Core/Work365LargeDialog';

const EmailTemplates: React.FC = () => {
    let { i18n, t } = useTranslation(['billingContractTemplates']);
    const [visible, setVisible] = React.useState(false);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    const handleUpdate = (dataItem: any) => {
        return Global.getApiService(appSettings, authService).ExecuteRequest('/BillingContractTemplates/SaveBillingTemplate', 'POST', dataItem);
    };

    const loadSaveModel = (id: any) => {
        return Global.getApiService(appSettings, authService).ExecuteRequest('/BillingContractTemplates/GetBillingTemplate', 'get', id);
    };

    return (
        <div>
            <TemplateGrid 
                excelFileName={'BillingContractTemplates.xlsx'}
                baseUrl={'/BillingContractTemplates/GetBillingTemplates'}
                method={'GET'}
                saveItem={handleUpdate}
                loadSaveModel={loadSaveModel}
                columns={[
                {
                    field: 'name',
                    title: t('name')
                }
            ]}/>
        </div>
    )
}

export default EmailTemplates;