import React, { ReactNode, useEffect } from 'react';
import '../ViewStyles/Common.Styles.css';
import Work365KendoCard, {
  Work365KendoCardProps,
} from '../../Lib/Core/Work365KendoCard';
import Work365BackgroundTile from '../../Lib/Core/Work365BackgroundTile';
import { Global } from '../../Helpers/Global';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import DrawerContainer, { IDrawerItem } from '../../Lib/Core/Drawer/DrawerContainer';
import CustomerSync from '../NewTenant/CustomerSync';
import CreateProvider from '../NewTenant/CreateProvider';
import ImportCRMSolution from './ImportCRMSolution';
import CreateBillingContractOperation from '../NewTenant/CreateBillingContractOperation';
import SyncProductsOperation from '../NewTenant/SyncProductsOperation';
import SyncSubscriptionsOperation from '../NewTenant/SyncSubscriptionsOperation';
import ArchiveItems from '../NewTenant/ArchiveItems';

export interface tenantProps { 
  tenantGuid? : string;
  companyId? : string | null;
} 

interface IState {
  tenant: any,
  licencePlanOptions: unknown[] | undefined,
  customerOptions: unknown[] | undefined,
  items: IDrawerItem[];
  children: JSX.Element | undefined;
}

const SetupInstall: React.FC<tenantProps> = ({
  tenantGuid,
  companyId
}) => {

  console.log(tenantGuid);

  const [operationTabs, setOperationTabs] = React.useState<IDrawerItem[]>([]);
  const [selectedOperation, setSelectedOperation] = React.useState<ReactNode>(<></>);

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  let operationRoutes = [{
    operationId: '1',
    route: 'importcrmsolution',
    component: <ImportCRMSolution isInstalled={false} tenantId={tenantGuid} />
  },
  {
    operationId: '2',
    route: 'createprovider',
    component: <CreateProvider tenantId={tenantGuid}/>
  },
  {
    operationId: '3',
    route: 'customersync',
    component: <CustomerSync tenantId={tenantGuid}/>
  },
  {
    operationId: '4',
    route: 'createbillingcontracts',
    component: <CreateBillingContractOperation tenantId={tenantGuid} companyId={companyId} />
  },
  {
    operationId: '5',
    route: 'syncproducts',
    component: <SyncProductsOperation tenantId={tenantGuid} />
  },
  {
    operationId: '6',
    route: 'syncsubscriptions',
    component: <SyncSubscriptionsOperation tenantId={tenantGuid} />
  },
  {
    operationId: '7',
    route: 'archiveitems',
    component: <ArchiveItems tenantId={tenantGuid} />
  }];

  useEffect(() => {
    Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantGuid}/operation/list`, 'GET').then((response: any) => {
      let operations = response.data;
      if (operations == undefined){
        return;
      }

      let operationCards = operations.map((operation: any) => {
        let operationRoute: any = operationRoutes.filter((route: any) => {
          return route.operationId === operation.id;
        })[0];

        return {
          text: operation.title,
          pathName: operationRoute.route,
          selected: false,
          content: operationRoute.component
        }
      });

      setSelectedOperation(operationCards[0].content);
      setOperationTabs(operationCards);
    });
  }, []);

  const handleSelect = (ev: { itemTarget: { props: { text: string; content: JSX.Element | undefined }; }; }) => {
    setOperationTabs(operationTabs.map(item => {
      if (item.text === ev.itemTarget.props.text) {
          item.selected = true;
      } else {
          item.selected = false;
      }
      return item;
    }));

    setSelectedOperation(ev.itemTarget.props.content);
  };

  return (
    <div style={{ height:'100%', overflowY: 'auto' }}>
      <DrawerContainer
        items={operationTabs}
        onSelect={handleSelect}>
          <div style={{paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px'}}>
            {selectedOperation}
          </div>
      </DrawerContainer>
    </div>
  )
};
export default SetupInstall;
