import React, { ReactNode } from "react";

export interface Work365TitleProps {
  title: string | undefined;
}
const Work365Title: React.FC<Work365TitleProps> = ({
    title,
}) => {
  return (
    <p style={{fontSize: 18, marginBottom: '0px'}}><b>{title}</b></p>
  );
};

export default Work365Title;
