import { time } from "console";

export class Utils {
    static ToDisplayDateFormat = (dateStr: string, includeTime?: boolean, dateformat:string="pt-BR", timeformat:'h'|'H' = 'H' ): string | undefined => {
        if (dateStr) {
            var date = new Date(dateStr);
            if (isNaN(date.getTime())) {
                return undefined;
            } else {
                return `${date.toLocaleDateString(dateformat) + (includeTime ? ' ' + date.toLocaleTimeString('en-Us', { hour12: (timeformat== 'h' ? true : false), hour: '2-digit', minute:'2-digit', second:'2-digit' }) : '')}`;
            }
        } else {
            return undefined;
        }
    }

    static ToCurrency = (num: any): string | undefined => {
        if (num) {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num);
        }
        else {
            return "0.00";
        }
    }

    static GetDomainFromEmail = (email?: string) => {
        let emailDomain = null;
        let pos = email?.search("@");
        if (pos) {
            if (pos > 0) {
                emailDomain = email?.slice(pos + 1);
            }
        }
        return emailDomain;
    };

    static ToAdminHubUrl(version: any, tenantUrl: string): string {
        var ver = this.VersionToDecimal(version);
        if (ver < 4.0) {
            ver = '4.0';
        } else {
            ver = Number(ver).toFixed(1);
        }
        var link = `https://app.work365apps.com/${ver}/AdminHub/Admin?data=${tenantUrl}`;
        return link;
    }

    static VersionToDecimal(version: any): any {
        version = version ?? "0.0";
        var verArray = version.split('.');
        var ver = "0";
        if (verArray.length >= 2) {
            ver = `${verArray[0]}.${verArray[1]}`;
        }

        return ver;
    }

    static PadLeft = (padString: any, length: number, char: any = '0') => {
        let result = String(padString);
        for (let i = result.length; i < length; i++) {
            result = char + result
        }
        return result;
    }
    static nFormatter(num:number, digits:number) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
      }
}

