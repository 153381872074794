import { Component } from "react";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, SharedTooltipContext, TooltipContext } from '@progress/kendo-react-charts'
import React from "react";

interface Work365StackedBarChartProps {
    tooltipRender?: ((props: TooltipContext | SharedTooltipContext) => React.ReactNode) | undefined;
    title?: string | undefined;
    invoicedUsageSummaries?: any[] | undefined;
    uninvoicedUsageSummaries?: any[] | undefined;
    height?: number | undefined;
    width?: number | undefined;
}

export default class Work365StackedBarChart extends Component<Work365StackedBarChartProps>{
    render(){
        
        return (
            <Chart style={{height: '100%', width: '100%'}}>
                <ChartTooltip render={this.props.tooltipRender} />
                <ChartSeries>
                    <ChartSeriesItem
                        type="column"
                        categoryField="category"
                        stack={true}
                        data={this.props.invoicedUsageSummaries}/>   
                     <ChartSeriesItem
                        type="column"
                        categoryField="category"
                        stack={true}
                        data={this.props.uninvoicedUsageSummaries}
                        color='rgb(217, 98, 39)'/>                                      
                </ChartSeries>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem labels={{ format: 'd', rotation: 'auto' }} />
                </ChartCategoryAxis>
            </Chart>
        )
    }
}