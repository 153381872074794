import { RadioButton } from '@progress/kendo-react-inputs';
import React from 'react';

export interface IRadioButtonProps {
    name: string | undefined;
    value: string | undefined;
    checked: boolean | undefined;
    label: string | undefined;
    onChange?: (value: any) => void;
}

export const Work365RadioButton: React.FC<IRadioButtonProps> = (p: IRadioButtonProps) => {
    return (
        <RadioButton
            name={p.name}
            value={p.value}
            checked={p.checked}
            label={p.label}
            onChange={p.onChange}
        />
    )
}

export default Work365RadioButton;