import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import Work365Title from '../../Lib/Core/Work365Title';
import Work365Label from '../../Lib/Core/Work365Label';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import BillingContractMatchGrid from './BillingContractMatchGrid';


type CreateBillingContractOperationProps = {
    tenantId: string | undefined;
    companyId: string | null | undefined;
};

const CreateBillingContractOperation: React.FC<CreateBillingContractOperationProps> = ({ 
  tenantId,
  companyId
}) => {

    const operationType = 4;

    let opId: any = undefined;

    const [operationId, setOperationId] = useState();
    const [inProgress, setInProgress] = useState(false); 
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    const [defaultBillingContractTemplate, setDefaultBillingContractTemplate] = useState<any>();
    let intervalNumber: any = undefined;
  
    const [selectedBillingContracts, setSelectedBillingContracts] = React.useState<any[]>([]);
    const [billingContractTemplates, setBillingContractTemplates] = React.useState<any[]>([]);
    
    function saveAdditionalParameters(selectedBillingContractsTemp: any[]) {
      let body = {
        selectedBillingContracts: selectedBillingContractsTemp,
        companyId: companyId
      };

      Global.getApiService(appSettings, authService)
          .ExecuteRequest(`tenant/${tenantId}/operation/${operationId}/saveadditionalparameters/${JSON.stringify(body)}`, 'POST')
          .then((response: any) => {

          });
    }

    const checkProgress = () => {

      if (operationId != undefined){
        opId = operationId;
      }

      Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/get/${opId}`, 'GET', undefined, false)
        .then((response: any) => {
          if (response.data != undefined) {
            setProgress(response.data.progressPercent);
  
            if (response.data.operationStatus != 'P'){
              clearInterval(intervalNumber);
              setCompleted(true);
  
              intervalNumber = undefined;
            }
          }
        });
    };
  
    function modifyDefaultTemplate(event: any) {
      setDefaultBillingContractTemplate(event.value);
    }

    function handleNewOperation() {
      setTimeout(function () {
        getOperation();
      }, 5000);
    }
  
    useEffect(() => {
      getOperation();
  
      Global.getApiService(appSettings, authService)
        .ExecuteRequest(`company/${companyId}/billingcontracttemplate/get`, 'GET')
        .then((response) => {
          setBillingContractTemplates(response.data.items.filter((obj: any) => obj.name !== undefined && obj.id !== undefined));
        });

      return () => {
        if (intervalNumber != undefined) {
          clearInterval(intervalNumber);
          intervalNumber = undefined;
        }
      };
    }, []); 
  
    function getOperation() {
      let operationContext = {
        operationType: operationType
      };
  
      Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
      .then((response: any) => {
        if (response?.data?.additionalParameters){
          var additionalParametersJson = JSON.parse(response.data.additionalParameters);
          setSelectedBillingContracts(additionalParametersJson.selectedBillingContracts);
        }
  
        if (response.data == undefined){
          Global.getApiService(appSettings, authService)
          .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
          .then((response: any) => {
            opId = response.data.operationId
            setOperationId(response.data.operationId);
          });
        }
        else {
          opId = response.data.operationId
          setOperationId(response.data.operationId);
  
          if (response.data.operationStatus == 'P'){
            setInProgress(true);
            setProgress(response.data.progressPercent);
  
            if (intervalNumber != undefined) {
              clearInterval(intervalNumber);
              intervalNumber = undefined;
            }
  
            intervalNumber = setInterval(checkProgress, 5000);
          }
        }
      });
    }

    function handleCheckboxClicked(object: any, event: CheckboxChangeEvent) {
      if (event.value){
        selectedBillingContracts.push({
          id: object.id,
          billingContractTemplateId: defaultBillingContractTemplate?.value,
          billingContractTemplateName: defaultBillingContractTemplate?.name
        });

        setSelectedBillingContracts(selectedBillingContracts);
        saveAdditionalParameters(selectedBillingContracts);
      }
      else{
        var objectsToRemove = selectedBillingContracts.find((item) => item.id !== object.id);
        setSelectedBillingContracts(objectsToRemove);
        saveAdditionalParameters(objectsToRemove);
      }
    }

    const handleTextboxValueChange = (id: any, event: any) => {
      modifyOrAddSelectedBillingContract(id, event.value.value);
    }

    const modifyOrAddSelectedBillingContract = (id: any, newBillingContractTemplate: any) => {
      // Check if the item exists
      const existingItem = selectedBillingContracts.find(item => item.id === id);
      const billingContractTemplate = billingContractTemplates.find(item => item.id === newBillingContractTemplate);

      let newProperties = {
        billingContractTemplateId: billingContractTemplate.id,
        billingContractTemplateName: billingContractTemplate.name
      }

      let newData = [];
      
      if (existingItem) {

        if (existingItem.billingContractTemplateId === billingContractTemplate.id)
        {
          return;
        }

        newData = selectedBillingContracts.map(obj =>
          obj.id === id ? { ...obj, billingContractTemplateId: billingContractTemplate.id, billingContractTemplateName: billingContractTemplate.name } : obj
        );
      } else {
        newData = [...selectedBillingContracts, { 
          id: id, 
          billingContractTemplateId: billingContractTemplate.id,
          billingContractTemplateName: billingContractTemplate.name
        }];
      }
    
      // Update the state
      setSelectedBillingContracts(newData);
      saveAdditionalParameters(newData);
    };
    
    async function selectAllContracts(contracts: any) {
      debugger;
      var newSelectedBillingContracts = contracts.map((contract: any) => {

        return {
          id: contract.id,
          billingContractTemplateId: defaultBillingContractTemplate.value,
          billingContractTemplateName: defaultBillingContractTemplate.name  
        }
      });

      let newSelectedContracts = [...selectedBillingContracts, ...newSelectedBillingContracts];
      setSelectedBillingContracts(newSelectedContracts);
      saveAdditionalParameters(newSelectedContracts);
    }

    return (
        <div style={{marginTop: '8px'}}>
            {inProgress && (
                <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
            )}

            <Work365Title title='Create Billing Contracts' />
            <br />
            <Work365Label>Select accounts you want to create Billing Contracts for.</Work365Label>
            <BillingContractMatchGrid
              tenantId={tenantId}
              selectedBillingContracts={selectedBillingContracts}
              handleCheckboxClicked={handleCheckboxClicked}
              billingContractTemplates={billingContractTemplates}
              onTextboxChange={handleTextboxValueChange}
              modifyDefaultTemplate={modifyDefaultTemplate} 
              selectAllContracts={selectAllContracts} />
            
            <Work365SubmitPanel
                operationId={operationId} 
                tenantId={tenantId}
                handleNewOperation={handleNewOperation} />
        </div>
    );
}

export default CreateBillingContractOperation;