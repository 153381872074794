import React from 'react';
import { Drawer, DrawerContent, DrawerSelectEvent, DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';
import Work365Logo from '../../Assets/images/logo.png';
import Work365NavBar from '../../Components/Common/NavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Work365DrawerProps{
    items: DrawerItemProps[] | undefined;
    customItem: React.ComponentType<DrawerItemProps> | undefined;
    children?: JSX.Element | JSX.Element[];
}

const Work365Drawer = (props: Work365DrawerProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [expanded, setExpanded] = React.useState(true);
  
    const handleClick = () => { setExpanded(!expanded) };
  
    const onSelect = (e: any) => {
        navigate(e.itemTarget.props.route);
    };

    return (
      <div>
        <div className="custom-toolbar p-0">
            <div className='flex-container'>
                <div className={`w365MainBar ${expanded ? 'normal' : 'hidden'}`}>
                    <div className='row'>
                        <div className='col-md-2'>
                            <button className={`navbar-toggler `} type="button" onClick={handleClick} >
                                <span className="mdi mdi-sort-variant"></span>
                            </button>
                        </div>
                        <div className='col-md-10'>
                            {expanded && <a className="w365sidebarLogo" href="/"><img src={Work365Logo} alt="logo" /></a> }
                        </div>
                    </div>
                </div>
                <div className={`navbarWidth ${expanded ? 'normal' : 'hidden'}`}>
                    <Work365NavBar />
                </div>
            </div>
        </div>
        <Drawer
          style={{
            backgroundColor: '#f2f4f4'
          }}
          expanded={expanded}
          position={'start'}
          mode={'push'}
          mini={true}
          items={props.items}
          onSelect={onSelect}
          item={props.customItem}
            >
          <DrawerContent>
            {props.children}
          </DrawerContent>
        </Drawer>
      </div >
    );
  }
  
  export default Work365Drawer;