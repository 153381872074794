import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Col, Row } from 'react-bootstrap';
import {
  FormInput,
  ComboboxInput,
  FormNumericTextBox,
  MultiSelectInput,
} from '../Lib/Core/Work365FormInputs';
import Work365Card from '../Lib/Core/Work365Card';
import { BillingContractTemplate } from '../Lib/Models/BillingContractTemplate';
import { Currency } from '../Lib/Models/Currency';
import { Global } from '../Helpers/Global';

export type Work365PriceListFormProps = {
  onSubmit: ( values: { [name: string]: any }, event?: SyntheticEvent<any, Event> | undefined) => void;
  tenantId: string | undefined;
};

const Work365PriceListForm: React.FC<Work365PriceListFormProps> = ({ onSubmit, tenantId }) => {
  const [currencies, setCurrencies] = useState([]);
  const [markets, setMarkets] = useState([]);

  let { i18n, t } = useTranslation(['BillingContractTemplateForm']);

  const segments = [
    {
        name: 'Commercial',
        value: 'COMMERCIAL'
    },
    {
        name: 'Education',
        value: 'EDUCATION'
    },
    {
        name: 'Government',
        value: 'GOVERNMENT'
    },
    {
        name: 'Non Profit',
        value: 'NONPROFIT'
    }]

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  useEffect(() => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/currency/get`, 'GET')
      .then((response: any) => {
        setCurrencies(
          response.data.map((currency: any) => {
            return {
              name: currency.name,
              value: currency.id,
            };
          })
        );
      });

      Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/country/get`, 'GET').then((response: any) => {
        setMarkets(
            response.data.map((market: any) => {
                return {
                    name: market.text,
                    value: market.value
                }
            }));
      });
  }, []);

  return (
    <Work365Card>
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <Row>
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={'k-messagebox k-messagebox-error'}>
                    {formRenderProps.errors.VALIDATION_SUMMARY}
                  </div>
                )}
            </Row>
            
            <Row>
                <Col>
                    <Field name={'name'} component={FormInput} label={'Name'} />
                </Col>
                <Col>
                    <Field
                        name={'currencyOption'}
                        component={ComboboxInput}
                        data={currencies}
                        label={t('currencyIso')}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '6px'}}>
                <Col>
                    <Field name={'segmentOption'} component={ComboboxInput} data={segments} label={'Segment'} />
                </Col>
            </Row>
            <Row style={{marginTop: '6px'}}>
                <Col>
                    <Field name='marketOption' component={MultiSelectInput} data={markets} label={'Markets'} />
                </Col>
            </Row>

            <div className='k-form-buttons text-right'>
              <button
                type={'submit'}
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'>
                {t('save')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Work365Card>
  );
};

export default Work365PriceListForm;
