import { Tooltip } from "@progress/kendo-react-tooltip";
import { ReactNode } from "react";
import { Card, Container } from "react-bootstrap";

interface Work365HoverTooltip{
    children: ReactNode;
}

export const Work365HoverTooltip: React.FC<Work365HoverTooltip> = ({children}) => {
    return (
        <Tooltip openDelay={100} anchorElement="target" parentTitle={true}>
            <div>
                {children}
            </div>
        </Tooltip>
    );
}