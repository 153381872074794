import React from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365TabStrip from '../../Lib/Core/Work365TabStrip';
import Work365TabStripTab from '../../Lib/Core/Work365TabStripTab';
import { CardHeader } from '@progress/kendo-react-layout';
import Plans from './Plan';
import EntitlementPacks from './EntitlementPacks';

const LicensingHome: React.FC = () => {
    return <>
        <Work365Card>
            <CardHeader>Licensing</CardHeader>
            <Work365TabStrip>
                <Work365TabStripTab title={'Plans'}>
                    <Plans/>
                </Work365TabStripTab>
                <Work365TabStripTab title={'Features'}>
                    <p>Features</p>
                </Work365TabStripTab>
                <Work365TabStripTab title={'Entitlement Packs'}>
                    <EntitlementPacks/>
                </Work365TabStripTab>
            </Work365TabStrip>
        </Work365Card>
    </>
}
export default LicensingHome;