import React from "react";
import { Global } from "../../Helpers/Global";
import { GridState } from "../../Lib/Models/GridState";
import { Col, Row } from "react-bootstrap";
import { Work365DropdownItem } from "../../Lib/Models/Work365DropdownItem";
import Work365AutocompleteTextbox from "../../Lib/Core/TextBox/Work365AutocompleteTextbox";
import Work365Textbox from "../../Lib/Core/TextBox/Work365Textbox";
import Work365Card from "../../Lib/Core/Work365Card";
import Work365PagingGrid from "../../Lib/Core/Work365PagingGrid";
import { GridColumn } from "@progress/kendo-react-grid";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import Work365DropdownBox from "../../Lib/Core/Work365DropdownBox";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";

export type BillingContractMatchGridProps = {
    tenantId: string | undefined;
    selectedBillingContracts: any[];
    handleCheckboxClicked: (object: any, event: CheckboxChangeEvent) => void;
    billingContractTemplates: any[];
    onTextboxChange: ((entityId: any, event: any) => void);
    modifyDefaultTemplate: (event: any) => void;
    selectAllContracts: (objects: any[]) => void;
}

const BillingContractMatchGrid: React.FC<BillingContractMatchGridProps> = ({
    tenantId,
    selectedBillingContracts,
    handleCheckboxClicked,
    billingContractTemplates,
    onTextboxChange,
    modifyDefaultTemplate,
    selectAllContracts
}) => {
    const [visible, setVisible] = React.useState(false);
    const [update, setUpdate] = React.useState(0);

    const PAGE_SIZE = 50;

    const locales = [{
        language: 'en-US',
        locale: 'en'
    }, {
        language: 'de',
        locale: 'de'
    },
    {
        language: 'ja',
        locale: 'ja'
    },
    {
        language: 'es-ES',
        locale: 'es'
    }
    ];

    //default to english
    var selectedlang = locales[0];

    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);

    locales.forEach(function (arrayItem) {
        if (arrayItem.locale == localStorage.getItem('i18nextLng')) {
            selectedlang = arrayItem;
        }
    });

    const [state, setState] = React.useState<GridState>(
        { 
            data: null, 
            dataState: null, 
            isLoading: false, 
            currentLocale: selectedlang, 
            lastUsedDataState: null,
            skip: 0,
            take: PAGE_SIZE,
            morePagesAvailable: false
        }
    );

    function handleData(data: any[]) {
        return data;
    }

    const fetchData = (event: string) => {
        let params = {
            $skip: state.skip,
            $top: state.take
        };

        if (event.toLowerCase() === 'increase') {
            params.$skip += state.take;
        }
        else if (event.toLowerCase() === 'decrease') {
            params.$skip -= state.take;
        }

        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/account/getallwithoutbillingcontract`, 'GET', undefined, undefined, params)
            .then((response: any) => {
                if (event.toLowerCase() === 'increase') {
                    setState({
                        data: response.data.items,
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip + PAGE_SIZE,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    });
                }
                else if (event.toLowerCase() === 'decrease') {
                    setState({
                        data: response.data.items,
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip - PAGE_SIZE,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    })
                }
                else{
                    setState({
                            data: response.data.items,
                            dataState: undefined,
                            currentLocale: state.currentLocale,
                            isLoading: false,
                            lastUsedDataState: undefined,
                            skip: state.skip,
                            take: state.take,
                            morePagesAvailable: response.data.morePagesAvailable
                        }
                    );
                }
            });
    }

    React.useEffect(() => {
        fetchData('');
    }, [selectedBillingContracts, billingContractTemplates]);

    const handleEditButtonClicked = (id: any) => {

    };

    const onNextPage = () => {
        fetchData('INCREASE');
    }

    const onPreviousPage = () => {
        fetchData('DECREASE');
    }

    const onCheckboxClicked = (object: any, event: CheckboxChangeEvent) => {
        handleCheckboxClicked(object, event);
        setUpdate(update + 1);
    }

    function isSelected(object: any)
    {
        var billingContract = selectedBillingContracts?.filter(x => x?.id === object?.id);

        if (billingContract != undefined && billingContract.length > 0)
        {
            return true;
        }

        return false;
    }

    let autocompleteData : any[]  = [];

    if (billingContractTemplates != undefined){
        autocompleteData = billingContractTemplates
            ?.map((template: any) => {
                return {
                    name: template.name,
                    value: template.id
                }
            })
            ?.filter(obj => obj.name !== null && obj.value !== null);
    }

    const filterTextbox = ({ dataItem }: { dataItem: any }) => {
        let selectedItem = selectedBillingContracts?.find(x => x.id === dataItem.id);
        let selectedBillingContractTemplate = autocompleteData?.find(x => x?.value == selectedItem?.billingContractTemplateId);

        if (selectedItem !== undefined) {
            return (
                <td>
                    <Work365DropdownBox 
                        data={autocompleteData} 
                        onChange={(event: any) => onTextboxChange(dataItem.id, event)}
                        defaultValue={selectedBillingContractTemplate} />
                </td>
            )
        }

        return (
            <td>
                <Work365Textbox value={dataItem.crmName} disabled/>
            </td>
        )
    };

    return (
        <Row>
            <Col className={'text-right'}>
                <Work365PrimaryButton title='Select All Contracts' style={{width: '185px' }} onClick={() => selectAllContracts(state.data)}/>
            </Col>
            <Col className={'text-right'}>
                <Work365DropdownBox 
                    data={autocompleteData}
                    onChange={modifyDefaultTemplate} />
            </Col>
            <Col md={12} style={{marginTop: '5px'}}>
                <div>
                    <Work365Card>
                        <Work365PagingGrid
                            filterable={false}
                            showCheckbox={true}
                            excelref={undefined}
                            onNextPage={onNextPage}
                            onPreviousPage={onPreviousPage}
                            state={state}
                            showEditButton={false}
                            onEditButtonClick={handleEditButtonClicked}
                            onCheckClicked={onCheckboxClicked}
                            getCheckboxValue={isSelected}
                            columns={[
                                {
                                    field: 'name',
                                    title: 'Name'
                                }
                            ]}>
                            <GridColumn cell={filterTextbox} title='Billing Contract Template' />
                        </Work365PagingGrid>
                    </Work365Card>
                </div>
            </Col>
        </Row>
    )
}

export default BillingContractMatchGrid;