import React from 'react';
import { useTranslation } from 'react-i18next';
import TemplateGrid from '../../Components/Templates/TemplatesGrid';
import { Global } from '../../Helpers/Global';
import { BillingContractTemplate } from '../../Lib/Models/BillingContractTemplate';

const BillingContractTemplates: React.FC = () => {
    let { i18n, t } = useTranslation(['BillingContractTemplates']);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    let companyId = localStorage.getItem('companyId');

    const handleUpdate = (dataItem: any) => {
        var updateBillingContractTemplate: BillingContractTemplate = {
            id: dataItem?.id,
            company: dataItem?.company,
            companyId: dataItem?.companyId,
            name: dataItem?.name,
            contractTerm: dataItem?.contractTerm,
            startDateMonth: dataItem?.startDateMonth,
            startDateDay: dataItem?.startDateDay,
            defaultPriceList: dataItem?.defaultPriceList,
            taxService: dataItem?.taxService,
            taxRateGroup: dataItem?.taxRateGroup,
            emailTemplate: dataItem?.emailTemplate,
            discountPercent: dataItem?.discountPercent,
            adminCharges: dataItem?.adminCharges,
            invoiceTemplate: dataItem?.invoiceTemplate,
            indexationRate: dataItem?.indexationRate,
            autosendInvoice: dataItem?.autosendInvoice,

            currencyIso: dataItem?.currencyOption?.value,
            processRefunds: dataItem?.processRefundsOption?.value,
            autoRenew: dataItem?.autoRenewOption?.value,
            autosyncInvoice: dataItem?.autosyncInvoiceOption?.value,
            autochargeInvoice: dataItem?.autochargeInvoiceOption?.value,
            prorateUnit: dataItem?.prorateUnitOption?.value,
            type: dataItem?.billingTypeOption?.value,
            paymentTerms: dataItem?.paymentTermOption?.value,
            productType: dataItem?.productTypeOption?.value,
            billingPolicy: dataItem?.billingPolicyOption?.value,
            indexationType: dataItem?.indexationTypeOption?.value,
            billingFrequency: dataItem?.billingFrequencyOption?.value
        };

        if (updateBillingContractTemplate.indexationRate != null) {
            updateBillingContractTemplate.indexationRate = updateBillingContractTemplate.indexationRate * 100;
        };

        if (updateBillingContractTemplate.adminCharges != null) {
            updateBillingContractTemplate.adminCharges = updateBillingContractTemplate.adminCharges * 100;
        }

        if (updateBillingContractTemplate.discountPercent != null) {
            updateBillingContractTemplate.adminCharges = updateBillingContractTemplate.discountPercent * 100;
        }

        return Global.getApiService(appSettings, authService).ExecuteRequest(`company/${companyId}/billingcontracttemplate/createorupdate`, 'POST', updateBillingContractTemplate);
    };

    const loadSaveModel = (id: any) => {
        return Global.getApiService(appSettings, authService).ExecuteRequest(`company/${companyId}/billingcontracttemplate/get/${id}`, 'GET');
    };

    return (
        <div>
            <TemplateGrid 
                excelFileName={'BillingContractTemplates.xlsx'}
                baseUrl={`company/${companyId}/billingcontracttemplate/get`}
                method={'GET'}
                saveItem={handleUpdate}
                loadSaveModel={loadSaveModel}
                columns={[
                {
                    field: 'name',
                    title: t('name')
                }
            ]}/>
        </div>
    )
}

export default BillingContractTemplates;