import AppSettingsService from '../Services/AppSettingsService';
import AuthService from '../Services/AuthService';
import ApiService from '../Services/ApiService';

export class Global {
  static appSettings: AppSettingsService | undefined;
  static authService: AuthService | undefined;
  static apiService: ApiService | undefined;
  static setNotificationMessage: (
    flag: 'success' | 'error' | 'warning' | 'info' | 'none',
    message: string
  ) => void;
  static showNotificationMessage: (
    flag: 'success' | 'error' | 'warning' | 'info' | 'none'
  ) => void;

  static getAppSettings(): AppSettingsService {
    if (this.appSettings == undefined) {
      console.log('App settings is null! Creating new app settings');
      this.appSettings = new AppSettingsService();
    } else {
      console.log('App settings already exists in the user context!');
    }
    return this.appSettings;
  }

  static getAuthService(appSettings: AppSettingsService): AuthService {
    if (this.authService == undefined) {
      console.log('Auth service is null! Creating new auth service.');
      this.authService = new AuthService(appSettings);
    } else {
      console.log('Auth Service already exists in the user context!');
    }

    return this.authService;
  }

  static getApiService(
    appSettings: AppSettingsService,
    authService: AuthService
  ): ApiService {
    return new ApiService(
      appSettings.GetWebApiBaseUri(),
      authService
    );
  }

  static getTenantGuid(): string {
    var windowLocationPath = window.location.pathname;

    return windowLocationPath.split('/')[1];
  }

  static showMessage(
    message: string,
    flag?: 'success' | 'error' | 'warning' | 'info' | 'none' | undefined
  ) {
    if (flag == undefined) {
      flag = 'info';
    }

    this.setNotificationMessage(flag, message);
    this.showNotificationMessage(flag);
  }
}
