import React, { useState } from 'react';
import bottomBackgroundImage from '../../Assets/images/bg-img.png';
import Work365AutocompleteTextbox from '../../Lib/Core/TextBox/Work365AutocompleteTextbox';
import { Work365DropdownItem } from '../../Lib/Models/Work365DropdownItem';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import { Global } from '../../Helpers/Global';
import { useNavigate } from 'react-router-dom';

const SwitchTenants: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Work365DropdownItem[]>();
  const [tenantGuid, setTenantGuid] = useState<string>('');
  const [environmentName, setEnvironmentName] = useState<string>('');

  const handleChange = (event: any) => {
    setTenantGuid(event.work365Value);
    setEnvironmentName(event.defaultValue);
  };

  const handleClick = (event: any) => {
    event.preventDefault();

    if (tenantGuid === undefined || tenantGuid === null || tenantGuid === '') {
      Global.showMessage('Please select a tenant and try again!', 'warning');
    } else {
      localStorage.setItem('environment', environmentName);
      navigate(`${tenantGuid}/home`);
    }
  };

  React.useEffect(() => {
    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);
    var apiClient = Global.getApiService(appSettings, authService);

    apiClient
      .ExecuteRequest('/Tenants/GetTenants', 'GET')
      .then((response: any) => {
        var tenants: any[] = response.data;

        setData(
          tenants.map((tenant) => {
            return {
              Name: tenant.endPointUrl,
              Value: tenant.guid,
            };
          })
        );
      });
  }, []);

  return (
    <div>
      <div
        className='text-center'
        style={{
          display: 'table',
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: -9,
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '32%',
            transform: 'translate(-50%, -32%)',
          }}
        >
          <h5>Please select a tenant below to continue.</h5>
          <Work365AutocompleteTextbox
            data={data}
            placeHolder='Start typing the name of the CRM environment'
            width='50vw'
            onChange={handleChange}
          />
          <Work365PrimaryButton
            style={{ float: 'right', marginTop: '5px' }}
            title='Continue'
            onClick={handleClick}
          />
        </div>
      </div>
      <div className='fixed-bottom' style={{ zIndex: -10 }}>
        <img
          src={bottomBackgroundImage}
          alt='background'
          style={{ height: '350px', width: '100%' }}
        />
      </div>
    </div>
  );
};

export default SwitchTenants;
