import React, { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Col, Row } from 'react-bootstrap';
import { FormInput, EmailValidator, FormMaskedTextBox, phoneValidator, RequiredValidator } from '../Lib/Core/Work365FormInputs';
import { User } from '../Lib/Models/User';
import Work365Card from '../Lib/Core/Work365Card';
import Work365PagingGrid from '../Lib/Core/Work365PagingGrid';
import { Global } from '../Helpers/Global';
import { resolveSoa } from 'dns';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';

export type Work365CreateInternalUserFormProps = {
    user: any | undefined;
    onSubmit: (values: { [name: string]: any; }, roles: any[], event?: SyntheticEvent<any, Event> | undefined) => void;
    onCheckboxClicked: (object: any, event: CheckboxChangeEvent) => void;
    isSelected: (object: any) => boolean;
    selectedRoles: any[];
}

const Work365CreateInternalUserForm: React.FC<Work365CreateInternalUserFormProps> = ({ user, onSubmit, onCheckboxClicked, isSelected, selectedRoles}) => {
    const locales = [
        {
            language: 'en-US',
            locale: 'en'
        }, {
            language: 'de',
            locale: 'de'
        },
        {
            language: 'ja',
            locale: 'ja'
        },
        {
            language: 'es-ES',
            locale: 'es'
        }
    ];

    var selectedlang = locales[0];

    const roles = [{
        id: 'Administrator',
        description: 'Gives the user all roles.'
    },
    {
        id: 'JobController',
        description: 'Allows the user to create and manage System Jobs.'
    },
    {
        id: 'Manager',
        description: 'Gives the user permissions to manage Users.'
    },
    {
        id: 'Support',
        description: 'Gives the user the Support role.'
    }];

    const [internalRoles, setInternalRoles] = React.useState<any[]>(roles);

    const gridColumns = [
        {
            field: 'id',
            title: 'Name'
        },
        {
            field: 'description',
            title: 'Description'
        }
    ]

    const onNextPage = () => {
        return;
    };
  
    const onPreviousPage = () => {
        return;
    }
    
    return  (
        <Work365Card>
                <Form initialValues={user} key={JSON.stringify(user)} onSubmit={(values, event) => {
                    onSubmit(values, selectedRoles, event);
                }} render={(formRenderProps) => (
                    <FormElement>
                        <Row>{formRenderProps.visited && formRenderProps.errors && formRenderProps.errors.VALIDATION_SUMMARY && <div className={'k-messagebox k-messagebox-error'}>{formRenderProps.errors.VALIDATION_SUMMARY}</div>}</Row>
                        <Row>
                            <Col>
                                <Field name={'loginName'} component={FormInput} label={'Email'} initialValues={""} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Work365PagingGrid
                                    filterable={false}
                                    showCheckbox={true}
                                    excelref={undefined}
                                    state={{
                                        data: internalRoles,
                                        dataState: undefined,
                                        currentLocale: selectedlang,
                                        lastUsedDataState: null,
                                        skip: 0,
                                        take: 10000,
                                        morePagesAvailable: false,
                                        isLoading: false
                                    }}
                                    onCheckClicked={onCheckboxClicked}
                                    getCheckboxValue={isSelected}
                                    columns={gridColumns}
                                    showEditButton={false}
                                    onEditButtonClick={() => {
                                        return;
                                    }}
                                    onNextPage={onNextPage}
                                    onPreviousPage={onPreviousPage}>

                                </Work365PagingGrid>
                            </Col>
                        </Row>

                        <div className="k-form-buttons">
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                disabled={!formRenderProps.allowSubmit}>
                                Submit
                            </button>
                        </div>

                    </FormElement>
                )}/>
        </Work365Card>)
}

export default Work365CreateInternalUserForm;