import { Component } from "react";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartCategoryAxisItemProps, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesItemProps, ChartSeriesItemTooltip, ChartTitle, ChartValueAxis, ChartValueAxisItem, ChartValueAxisItemProps, SeriesClickEvent, SeriesLabels, Zoomable } from '@progress/kendo-react-charts'
import 'hammerjs';
import '@progress/kendo-theme-default/dist/all.css';

interface Work365ChartProps {
    title?: string | undefined;
    legendPosition?: "top" | "bottom" | "left" | "right" | "custom" | undefined;
    legendOrientation?: "vertical" | "horizontal" | undefined;
    onSeriesClick?: (event: SeriesClickEvent) => void | undefined;
    seriesItems: IChartSeriesItemProps[];
    pannable?: boolean | undefined;
    zoomable?: boolean | Zoomable | undefined

    categoryItemAxis?: ChartCategoryAxisItemProps | undefined;
    categoryValueAxis?: ChartValueAxisItemProps | undefined;
}

export interface IChartSeriesItemProps {
    type: `area` | `bar` | `boxPlot` | `bubble` | `bullet` | `candlestick` | `column` | `donut` | `funnel` | `horizontalWaterfall` | `line` | `ohlc` | `pie` | `polarArea` | `polarLine` | `polarScatter` | `radarArea` | `radarColumn` | `radarLine` | `rangeArea` | `rangeBar` | `rangeColumn` | `scatter` | `scatterLine` | `verticalArea` | `verticalBoxPlot` | `verticalBullet` | `verticalLine` | `verticalRangeArea` | `waterfall`;
    data?: any[] | undefined;
    field?: string | undefined;
    categoryField?: string | undefined;
    labels?: SeriesLabels | undefined
    stack?: string | undefined;
    tooltip?: string | undefined;
    name?: string | undefined;
}

export default class Work365Chart extends Component<Work365ChartProps>{
    render() {
        return (
            <Chart
                style={{ height: '100%', width: '100%' }}
                onSeriesClick={this.props.onSeriesClick}
                pannable={this.props.pannable}
                zoomable={this.props.zoomable}
            >
                <ChartTitle text={this.props.title} />
                <ChartLegend position={this.props.legendPosition} orientation={this.props.legendOrientation} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem {...this.props.categoryItemAxis} />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem {...this.props.categoryValueAxis} />
                </ChartValueAxis>
                <ChartSeries>
                    {
                        this.props.seriesItems.map((x: IChartSeriesItemProps) =>
                            <ChartSeriesItem
                                type={x.type}
                                name={x.name}
                                data={x.data}
                                field={x.field}
                                categoryField={x.categoryField}
                                labels={x.labels}
                                stack={x.stack} >
                                <ChartSeriesItemTooltip format={x.tooltip} />
                            </ChartSeriesItem>
                        )
                    }

                </ChartSeries>
            </Chart >
        )
    }
}