import React, { CSSProperties } from 'react';

interface TenantBarStatusIndicatorProps {
    styles: CSSProperties;
}

const TenantBarStatusIndicator: React.FC<TenantBarStatusIndicatorProps> = ({styles}) => {
    return (
        <div className='tenant-bar-status-indicator' style={styles}>

        </div>
    )
}

export default TenantBarStatusIndicator;