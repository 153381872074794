import React from "react";
import { Work365Dialog } from "./Work365Dialog";
import Work365PrimaryButton from "./Buttons/Work365PrimaryButton";
import Work365CancelButton from "./Buttons/Work365CancelButton";
import { Col, Row } from "react-bootstrap";

export type Work365ConfirmationDialogProps = {
  headerText: string;
  onOKClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onCancelClick?: () => void | undefined;
};
const Work365ConfirmationDialog: React.FC<Work365ConfirmationDialogProps> = ({
  headerText,
  onOKClick,
  onCancelClick,
}) => {
  return (
    <Work365Dialog onClose={onCancelClick} title="Confirmation">
      <p style={{ marginBottom: "50px" }}>{headerText}</p>
      <br />
      <Row>
        <Col>
          <Work365PrimaryButton
            style={{ width: "70px", float: "right" }}
            title="Ok"
            onClick={onOKClick}
          ></Work365PrimaryButton>
          <Work365CancelButton
            style={{ width: "70px", float: "right", marginRight: "5px" }}
            title="Cancel"
            onClick={onCancelClick}
          ></Work365CancelButton>
        </Col>
      </Row>
    </Work365Dialog>
  );
};
export default Work365ConfirmationDialog;
