import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import Work365DropdownBox from '../../Lib/Core/Work365DropdownBox';
import Work365PagingGrid from '../../Lib/Core/Work365PagingGrid';
import Work365Card from '../../Lib/Core/Work365Card';
import { GridColumn } from '@progress/kendo-react-grid';
import Work365Grid from '../../Lib/Core/Work365Grid';
import { Col, Row } from 'react-bootstrap';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import { Work365LargeDialog } from '../../Lib/Core/Work365LargeDialog';
import Work365PriceListForm from '../../Forms/Work365PriceListForm';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';


type SyncProductsOperationProps = {
    tenantId: string | undefined;
};

const SyncProductsOperation: React.FC<SyncProductsOperationProps> = ({ tenantId }) => {

    const operationType = 5;

    let firstLoad = true;

    const [visible, setVisible] = React.useState(false);

    const [operationId, setOperationId] = useState();
    const [inProgress, setInProgress] = useState(false); 
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    let intervalNumber: any = undefined;

    const [providers, setProviders] = useState<any[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<any>();
    const [selectedProviderId, setSelectedProviderId] = useState<any>();
    const [providerPriceList, setProviderPriceLists] = useState<any[]>([]);
  
    function saveAdditionalParameters(selectedProviderTmp: any) {
      let body = {
        providerId: selectedProviderTmp.connectorId
      };

      Global.getApiService(appSettings, authService)
          .ExecuteRequest(`tenant/${tenantId}/operation/${operationId}/saveadditionalparameters/${JSON.stringify(body)}`, 'POST')
          .then((response: any) => {

          });
    }

    const checkProgress = () => {
      Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/get/${operationId}`, 'GET', undefined, false)
        .then((response: any) => {
          if (response.data != undefined){
            setProgress(response.data.progressPercent);
  
            if (response.data.operationStatus != 'P'){
              clearInterval(intervalNumber);
              setCompleted(true);
  
              intervalNumber = undefined;
            }
          }
        });
    };
  
    function handleNewOperation() {
      setTimeout(function () {
        getOperation();
      }, 5000);
    }
  
    useEffect(() => {
        getOperation();

        Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/get`, 'GET').then((response: any) => {
          setProviders(response.data);
        });

  
        return () => {
            if (intervalNumber != undefined) {
                clearInterval(intervalNumber);
                intervalNumber = undefined;
            }
        };
    }, []); 

    let defaultValue = {};
  
    function getOperation() {
      let operationContext = {
        operationType: operationType
      };
  
      Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
      .then((response: any) => {
        if (response.data == undefined){
          Global.getApiService(appSettings, authService)
          .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
          .then((response: any) => {
            setOperationId(response.data.operationId);
          });
        }
        else {

          if (response?.data?.additionalParameters){
            var additionalParametersJson = JSON.parse(response.data.additionalParameters);
            var responseSelectedProvider = providers.find(item => item.connectorId === additionalParametersJson.providerId);
            if (responseSelectedProvider) {
              setSelectedProvider(responseSelectedProvider);
              defaultValue = {
                name: responseSelectedProvider.name,
                value: responseSelectedProvider.connectorId
              };
            } else {
              setSelectedProviderId(additionalParametersJson.providerId);
            }
          }

          setOperationId(response.data.operationId);

          if (response.data.operationStatus == 'P'){
            setInProgress(true);
            setProgress(response.data.progressPercent);
  
            if (intervalNumber != undefined) {
              clearInterval(intervalNumber);
              intervalNumber = undefined;
            }
        
            intervalNumber = setInterval(checkProgress, 5000);
          }
        }
      });
    }

    function getPriceListsForProvider(providerId: any) {
      Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/${providerId}/pricelist/get`, 'GET').then((response: any) => {
        setProviderPriceLists(response.data);
      });
    }

    let providerDropdownData = providers?.map((provider: any) => {
        return {
            name: provider.name,
            value: provider.connectorId
        }
    });

    const handleUpdate = (dataItem: any) => {
      var pricelist = {
        name: dataItem?.name,
        segment: dataItem?.segmentOption?.value,
        currencyId: dataItem?.currencyOption?.value,
        marketIds: dataItem?.marketOption?.map((market: any) => market.value)
      };

      Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/${selectedProviderId}/pricelist/createorupdate`, 'POST', pricelist).then((response: any) => {
        getPriceListsForProvider(selectedProviderId);
      });

      setVisible(!visible);
    }

    const handleSubmit = () => {
      setVisible(!visible);
    }

    return (
        <div style={{marginTop: '8px'}}>
            {inProgress && (
                <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
            )}

            <p>Select the Provider</p>
            <Row>
              <Col>
                <Work365DropdownBox data={providerDropdownData} style={{width: '350px'}} onChange={(event) => {
                  let provider = providers.find(item => item.connectorId === event.value.value);

                  if (provider != undefined) {
                    setSelectedProvider(provider);
                    saveAdditionalParameters(provider);

                    getPriceListsForProvider(provider.connectorId);
                  }
                }}/>
              </Col>
              <Col className='text-right'>
                <Work365PrimaryButton title='Create New' onClick={() => {
                  setVisible(true);
                }} />
              </Col>
            </Row>

            <div style={{marginTop: '6px'}}>
              <Work365Card>
                <Work365Grid 
                  data={providerPriceList}
                  columns={[
                    {
                      field: 'name',
                      title: 'Name'
                    }, 
                    {
                      field: 'currency.name',
                      title: 'Currency'
                    }, 
                    {
                      field: 'segment',
                      title: 'Segment'
                    }]} />
              </Work365Card>
            </div>
            
            <Work365SubmitPanel
                operationId={operationId} 
                tenantId={tenantId}
                handleNewOperation={handleNewOperation} />

            {visible && (
                <Work365Dialog onClose={handleSubmit} title="Work 365 Price List">
                    <div style={{marginTop: '14px'}}>
                        <Work365PriceListForm onSubmit={handleUpdate} tenantId={tenantId} />
                    </div>
                </Work365Dialog>
            )}
        </div>
    );
}

export default SyncProductsOperation;