import { TileLayout, TileLayoutItem, TileLayoutRepositionEvent, TilePosition } from "@progress/kendo-react-layout";
import { Component } from 'react';
import { Work365Tile } from "../Models/Work365Tile";

interface Work365TileLayoutProps {
    columns?: number | undefined;
    rowHeight?: string | number | undefined;
    rows?: number | undefined;
    tiles: Work365Tile[];
    rowGap?: number | undefined;
    columnsGap?: number | undefined;
    onReposition?: ((event: TileLayoutRepositionEvent) => void) | undefined;
    backgroundColor?: string | undefined;
}

const customHeader = () => <div style={{ height: 0 }}></div>; // return a custom header element

// The TileLayout is not supported in server rendering.
// When trying to run this during server rendering, it will give an error 'clearTimeout is not supported in server-rendered Javascript'
export default class Work365TileLayout extends Component<Work365TileLayoutProps>{

    render() {
        return (

            <TileLayout

                columns={this.props.columns ?? 4}
                rowHeight={this.props.rowHeight ?? 255}
                gap={{ rows: this.props.rowGap, columns: this.props.columnsGap }}
                positions={this.props.tiles.map(w365Tile => {
                    var col = w365Tile.col;
                    var colSpan = w365Tile.colSpan;
                    var rowSpan = w365Tile.rowSpan;

                    var position = {
                        col,
                        colSpan,
                        rowSpan
                    }

                    return position;
                })}

                items={this.props.tiles.map(w365Tile => {
                    var body = w365Tile.body;
                    var header = w365Tile.header;

                    var tile: TileLayoutItem = {
                        body,
                        header: customHeader(),
                        reorderable: false,
                        resizable: false,
                        className: 'no-header'
                    }

                    if (tile.header === undefined){
                        console.log('Tile header is undefined');
                        tile.header = <div hidden></div>;
                    }

                    return tile;
                })}

                onReposition={this.props.onReposition}
                style={{
                    padding: '0px',
                    backgroundColor: 'inherit'
                }} />
        )
    }
}