import React, { useEffect, useState } from 'react';
import Work365PrimaryButton from '../Core/Buttons/Work365PrimaryButton';
import { Work365Dialog } from '../Core/Work365Dialog';
import Work365ProgressBar from '../Core/Work365ProgressBar';
import { useNavigate } from 'react-router-dom';

export type Work365OperationInProgressModelProps = {
    value: number;
    isCompleted: boolean;
};
const Work365OperationInProgressModel: React.FC<Work365OperationInProgressModelProps> = ({
    value,
    isCompleted
}) => {
  function handleSubmit() {
    window.location.reload();
    return;
  }

  if (isCompleted) {
    return (
        <Work365Dialog title='Operation completed!'>
            <div className='text-center mt-3'>
                <p>Operation has been completed! Please click Continue to go back to the home page.</p>
            </div>
            <div className='text-right'>
                <Work365PrimaryButton title='Continue' onClick={handleSubmit}/>
            </div>
        </Work365Dialog>
    )
  }

  return (
    <Work365Dialog title='Operation is currently in progress' onClose={handleSubmit}>
        <div className='text-center mt-3'>
            <p>Operation is currently in progress... Please wait.</p>
        </div>
        <div className='text-center mt-3'>
          <p><strong>{value}% Completed!</strong></p>
        </div>
        <Work365ProgressBar value={value}/>
    </Work365Dialog>
  );
};

export default Work365OperationInProgressModel;
