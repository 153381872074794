import * as React from "react";
import {
    Grid,
    GridColumn,
    GridToolbar,
    GridDetailRowProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridSortChangeEvent,
    GridGroupChangeEvent,
    GridCellProps,
    GridRowClickEvent,
    GridPageChangeEvent,
    GridColumnMenuProps,
    GridColumnMenuFilter,
    GridFilterOperators,
} from "@progress/kendo-react-grid";

import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, filterBy } from "@progress/kendo-data-query";
import { Work365Column } from "../../Models/Work365Column";
import { Size } from "../../Models/Size";
import { Work365ColumnMenu } from "../Work365ColumnMenu";
import { ReactNode } from "react";
import {ExcelExport} from '@progress/kendo-react-excel-export'


export interface IGridState {
    sortable?: boolean | undefined;
    filterable?: boolean | undefined;
    groupable?: boolean | undefined;
    reorderable?: boolean | undefined;
    resizable?: boolean | undefined;
    scrollable?: "none" | "scrollable" | "virtual" | undefined;
    pageSize?: number | undefined;
    rowHeight?: number | undefined;
    skip?: number | undefined;
    take?: number | undefined;
    total?: number | undefined;
    sort?: Array<SortDescriptor> | undefined;
    filter?: CompositeFilterDescriptor | undefined;
    group?: Array<GroupDescriptor> | undefined;
}

interface IGridProps {
    data: unknown[];
    style?: React.CSSProperties | undefined;
    gridState?: IGridState;
    detailComponent?: React.ComponentType<GridDetailRowProps> | null | undefined;
    columns?: Work365Column[] | undefined;
    toolbar?: ReactNode | undefined | null;
    cellRender?: (defaultRendering: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => React.ReactElement<HTMLTableCellElement> | React.ReactElement<HTMLTableCellElement>[] | null;

    dataStateChange?: ((event: GridDataStateChangeEvent) => void) | undefined;
    expandChange?: ((event: GridExpandChangeEvent) => void) | undefined;
    sortChange?: ((event: GridSortChangeEvent) => void) | undefined;
    onGroupChange?: ((event: GridGroupChangeEvent) => void) | undefined;
    onRowclick?: ((event: GridRowClickEvent) => void) | undefined;
    onPageChange?: ((event: GridPageChangeEvent) => void) | undefined;
    onScroll?: ((event: any) => void) | undefined;
    onFilterChange?: ((event: any) => void) | undefined;
}

const Work365FlexiGrid: React.FC<IGridProps> = (props: IGridProps) => {
    const [filterPosition, setFilterPosition] = React.useState({ x: 0, y: 0 })
    const _export = React.useRef(null)
    const getColumn = (column: Work365Column) => {
        if (column == undefined) {
            return;
        }

        if (column.filter == 'date' && column.format == undefined) {

            if (column.width == undefined) {
                column.width = 235;
            }
        }

        return (
            <GridColumn
                className={column.className}
                field={column.field}
                title={column.title}
                width={column.width}
                filter={column.filter}
                format={column.format}
                filterable={column.filterable}
                columnMenu={column.filterable ? Work365ColumnMenu : undefined}
                cell={column.cell}
                headerCell={column.headerCell}
                locked={column.locked}
            />
        )
    }

    return (
        <div>
            <Grid
                style={props.style}
                sortable={props.gridState?.sortable}
                filterable={props.gridState?.filterable}
                groupable={props.gridState?.groupable}
                reorderable={props.gridState?.reorderable}
                resizable={props.gridState?.resizable}
                scrollable={props.gridState?.scrollable}
                skip={props.gridState?.skip}
                take={props.gridState?.take}
                total={props.gridState?.total}
                sort={props.gridState?.sort}
                filter={props.gridState?.filter}
                group={props.gridState?.group}
                data={props.data}
                pageSize={props.gridState?.pageSize}
                rowHeight={props.gridState?.rowHeight}
                detail={props.detailComponent}
                cellRender={props.cellRender}
                expandField="expanded"
                onExpandChange={props.expandChange}
                onDataStateChange={props.dataStateChange}
                onSortChange={props.sortChange}
                onGroupChange={props.onGroupChange}
                onRowClick={props.onRowclick}
                onPageChange={props.onPageChange}
                onScroll={props.onScroll}
                onFilterChange={props.onFilterChange}
            >
                {props.toolbar ?
                    <GridToolbar>
                        {props.toolbar}
                    </GridToolbar> : ''}
                {props.columns != null ? props.columns.map((column: any) => (
                    (column.show ?? true) && getColumn(column)
                )) : undefined}

            </Grid>
        </div>
    );
};

export default Work365FlexiGrid