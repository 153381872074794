import { AuthenticationResult } from "@azure/msal-browser";
import AuthService from "./AuthService";
import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { trackPromise } from "react-promise-tracker";

export default class ApiService {
  _baseUri: string;
  _authService: AuthService;
  _apiClient: AxiosInstance;
  DEFAULT_TIMEOUT = 20;

  constructor(baseUri: string, authService: AuthService){
    if (!baseUri){
      throw new Error('The base URI was not provided.');
    }

    if (!authService){
      throw new Error('The auth service was not provided.');
    }

    this._baseUri = baseUri;
    this._authService = authService;
    this._apiClient = axios.create({
      baseURL: this._baseUri,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  private async GetRefreshToken() {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          this._authService.GetToken().then(
            (response: AuthenticationResult) => {
              return response;
            }
          )
        );
      }, this.DEFAULT_TIMEOUT);
    });

    return trackPromise(promise);
  };

  private getAxiosHeaders(accessToken?: any): AxiosRequestHeaders {
    let headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken.accessToken,
    };

    return headers;
  }

  private handleResponse = (response: AxiosResponse<any, any>) : AxiosResponse<any, any> => {
    return response;
  };

  private handleError = (error: any) => {
    return error;
  };

  public async ExecuteRequest(url: string, method: string, body?: any, showProgress?: boolean, params?: any) {
    var refreshToken: any = await this.GetRefreshToken();
    const promise = new Promise<AxiosResponse<any, any>>((resolve, reject) => {
      setTimeout(() => {
        resolve(axios({
          method: method,
          url: `${this._baseUri}/${url}`,
          headers: this.getAxiosHeaders(refreshToken),
          data: body,
          params: params
        }).then((response) => {
          return this.handleResponse(response);
        }).catch((error) => {
          return this.handleError(error);
        }))
      }, this.DEFAULT_TIMEOUT)
    });

    if (showProgress || showProgress == undefined){
      return trackPromise(promise);
    }
    else{
      return promise;
    }
  }
}