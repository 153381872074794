import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import { Global } from '../../Helpers/Global';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';

type ImportCRMSolutionProps = {
  isInstalled: boolean | undefined;
  tenantId?: string;
};
const ImportCRMSolution: React.FC<ImportCRMSolutionProps> = ({
  isInstalled,
  tenantId
}) => {
  const [operationId, setOperationId] = useState();

  const [inProgress, setInProgress] = useState(false); 
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState(0);

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  const operationType = 1;

  let intervalNumber: any = undefined;

  const checkProgress = () => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/get/${operationId}`, 'GET', undefined, false)
      .then((response: any) => {
        if (response.data != undefined){
          setProgress(response.data.progressPercent);

          if (response.data.operationStatus != 'P'){
            clearInterval(intervalNumber);
            setCompleted(true);

            intervalNumber = undefined;
          }
        }
      });
  };

  function handleNewOperation() {
    setTimeout(function () {
      getOperation();
    }, 5000);
  }

  useEffect(() => {
    getOperation();

      return () => {
        if (intervalNumber != undefined) {
          clearInterval(intervalNumber);
          intervalNumber = undefined;
        }
      };
  }, []); 

  function getOperation() {
    let operationContext = {
      operationType: operationType
    };

    Global.getApiService(appSettings, authService)
    .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
    .then((response: any) => {
      if (response.data == undefined){
        Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
        .then((response: any) => {
          setOperationId(response.data);
        });
      }
      else {
        setOperationId(response.data);

        if (response.data.operationStatus == 'P'){
          setInProgress(true);
          setProgress(response.data.progressPercent);

          if (intervalNumber != undefined) {
            clearInterval(intervalNumber);
            intervalNumber = undefined;
          }
      
          intervalNumber = setInterval(checkProgress, 5000);
        }
      }
    });
  }

  return (
    <div>
      {inProgress && (
        <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
      )}

      {isInstalled && (
        <p>
          It looks like you already have the Work 365 solution installed in your
          dynamics environment.
          <br /> Please proceed below to upgrade your solution file
        </p>
      )}
      {!isInstalled && (
        <>
          <p>
            Thank you for chosing Work 365! To install Work 365 in your dynamics
            environment, we will need to import the Work 365 solution file.
            <br></br>
            To install the Work 365 solution file, please select the solution
            file in the dropdown box and then click on the import solution
            button.
            <br></br>
            After the import is complete, continue on-to the post install Work
            365 operation. To monitor the current progress, please view the
            progress
            <br /> in the logs page.
          </p>
        </>
      )}
      <Row>
        <Col md className='mt-1'>
          <label
            style={{ color: 'gray', fontStyle: 'italic' }}
            className='ml-4'
          >
            Ensure that Default Time Zone is set on configuration.
          </label>
        </Col>
      </Row>
      <Work365SubmitPanel
          operationId={operationId} 
          tenantId={tenantId}
          handleNewOperation={handleNewOperation} />
    </div>
  );
};

export default ImportCRMSolution;
