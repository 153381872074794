import { Component } from "react";
import { Dialog, DialogCloseEvent } from '@progress/kendo-react-dialogs';

export type Work365LargeDialogProps = {
    title?: string;
    children?: | React.ReactNode;
    onClose?: ((event: DialogCloseEvent) => void) | undefined
}

export class Work365LargeDialog extends Component<Work365LargeDialogProps>{
    render() {
        return (
            <Dialog title={this.props.title} width={"95%"} height={"90%"} onClose={this.props.onClose}>
                {this.props.children}
            </Dialog>
        )
    }
}