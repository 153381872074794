import React, { useEffect, useState } from 'react';
import { IsTenantReady } from '../../Lib/Models/IsTenantReady';
import { Col, Row } from 'react-bootstrap';
import { Work365HoverTooltip } from '../../Lib/Core/Work365Tooltip';
import TenantStatusIndicator from './TenantStatusIndicator';
import Work365Label from '../../Lib/Core/Work365Label';
import { Global } from '../../Helpers/Global';
import TenantBarStatusIndicator from './TenantBarStatusIndicator';
import { ErrorColor, NotStartedColor, SuccessfulColor, WarningColor } from '../../Lib/Models/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface TenantCardProps {
    tenant: IsTenantReady
    handleSubmit: (tenant: IsTenantReady) => void;
    selectedTenant?: IsTenantReady | undefined;
}

const tenantReadyStyle = {
    backgroundColor: SuccessfulColor
}

const tenantErrorStyle = {
    backgroundColor: ErrorColor
}

const tenantNotReadyStyle = {
    backgroundColor: NotStartedColor
}

const tenantProgressStyle = {
    backgroundColor: WarningColor
}

const TenantCard: React.FC<TenantCardProps> = ({tenant, handleSubmit, selectedTenant}) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [inactive, setInactive] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('Loading...');

    let tenantEndPointUrl = Math.floor(Math.random() * 10000).toString();
    let isNewTenant = false;
    let environmentLabel = 'Production';
    let backgroundColor = '#FFFFFF';
    let tenantBarStatusStyle = tenantProgressStyle;

    if (tenant?.endPointUrl === '' || tenant?.endPointUrl === undefined){
        isNewTenant = true;
    }
    else{
        tenantEndPointUrl = new URL(tenant?.endPointUrl ?? "").hostname.split('.')[0];
    }

    useEffect(() => {
        const appSettings = Global.getAppSettings();
        const authService = Global.getAuthService(appSettings);

        if (tenant?.endPointUrl != '' || tenant?.endPointUrl !== undefined){
            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/verify?environmentUrl=${tenant.endPointUrl}`, 'GET', undefined, false).then((response: any) => {
                setError(!response.data.successful);
                setInactive(response.data.inactive);
                setMessage(response.data.message);
    
                setLoading(false);
            });
        }
        else{
            setError(false);
            setInactive(true);
            setMessage('Tenant is ready for Solution install!');

            setLoading(false);
        }
      }, []);


    if (!loading){
        if (inactive){
            tenantBarStatusStyle = tenantNotReadyStyle;
        }
        else if (error){
            tenantBarStatusStyle = tenantErrorStyle;
        }
        else{
            tenantBarStatusStyle = tenantReadyStyle;
        }
    }

    switch(tenant.isProduction){
        case true:
            environmentLabel = 'Production';
            break;
        case false:
            environmentLabel = 'Sandbox';
            break;
        default:
            environmentLabel = 'Unknown'
            break;
    }

    if (tenant.endPointUrl === selectedTenant?.endPointUrl){
        backgroundColor = '#D3D3D3';
    }

    return (
        <div style={{width: '100%', height: '100%', backgroundColor: backgroundColor, cursor: 'pointer'}} onClick={() => handleSubmit(tenant)}>
            {isNewTenant ? newTenant() : normalTenant() }
        </div>
    )

    function normalTenant(){
        return (
            <div>
                <TenantBarStatusIndicator styles={tenantBarStatusStyle}/>
                <div style={{marginRight: '3px', marginLeft: '3px'}}>
                    <Row className='mt-1'>
                        <Col>
                            <p style={{fontSize: '15px', marginBottom:'0px'}}><b>{tenantEndPointUrl}</b></p>
                        </Col>
                        <Col style={{marginRight: '5px'}} className='c-parent'>
                            <Work365HoverTooltip>
                                <TenantStatusIndicator tenant={tenant} isLoading={loading} isError={error} isInactive={inactive} statusMessage={message} />
                            </Work365HoverTooltip>
                        </Col>
                    </Row>
                    <div className='overflowText'>
                        <small>{tenant.endPointUrl}</small>
                    </div>
                    <div className='overflowText'>
                        <small>{tenant.version}</small>
                    </div>
                    <div style={{position: 'absolute', bottom: '0', width: '100%'}}>
                        <div className='text-center'>
                            <Work365Label>
                                {environmentLabel}
                            </Work365Label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function newTenant() {
        return (
          <div style={{height: '100%'}}>
            <TenantBarStatusIndicator styles={tenantNotReadyStyle}/>
            <div className='text-center' style={{height:'21px', marginTop: '3px'}} >
                <p style={{fontSize: '15px', marginBottom:'0px'}}><b>New Environment</b></p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 24px)'}}>
              <FontAwesomeIcon icon={faPlus} style={{width: '100%', height: 'calc(100% - 24px)'}} />
            </div>
          </div>
        )
      }

    function openTenant(){
        return (
            <div>
                <TenantBarStatusIndicator styles={tenantNotReadyStyle}/>
                <div style={{marginRight: '3px', marginLeft: '3px'}}>
                    <Row className='mt-1'>
                        <Col>
                            <p style={{fontSize: '15px', marginBottom:'0px'}}><b>New Tenant</b></p>
                        </Col>
                        <Col style={{marginRight: '5px'}} className='c-parent'>
                            <Work365HoverTooltip>
                                <TenantStatusIndicator tenant={tenant} isLoading={loading} isError={error} isInactive={inactive} statusMessage={message} />
                            </Work365HoverTooltip>
                        </Col>
                    </Row>
                    <div className='overflowText'>
                        <small>Work 365 solution is ready and available to install.</small>
                    </div>
                    <div className='overflowText'>
                        <small>{tenant.version}</small>
                    </div>
                    <div style={{position: 'absolute', bottom: '0', width: '100%'}}>
                        <div className='text-center'>
                            <Work365Label>
                                {environmentLabel}
                            </Work365Label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TenantCard;