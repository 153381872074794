import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import DrawerContainer from '../../Lib/Core/Drawer/DrawerContainer';
import OperationLog from '../../Components/ManageTenants/OperationLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

type OperationLogsProps = {
    tenantId?: string;
};

const OperationLogs: React.FC<OperationLogsProps> = ({
    tenantId
}) => {

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    const [operations, setOperations] = useState<any[]>([]);
    const [operationCards, setOperationCards] = useState<any[]>([]);
    const [selectedOperation, setSelectedOperation] = useState<React.ReactNode>(<></>);

    useEffect(() => {
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/get?getOldOperations=true`, 'GET')
            .then((response: any) => {
                setOperations(response.data);

                let tmpOperationCards = response.data.map((operation: any) => {
                    return {
                        text: `${operation.operationId}-${operation.operationType}`,
                        pathName: operation.operationId,
                        selected: false,
                        content: <OperationLog operation={operation} tenantId={tenantId} />,
                        icon: <FontAwesomeIcon icon={faCheckCircle} />
                    }
                });

                setOperationCards(tmpOperationCards);
            });
    }, []); 

    const handleSelect = (ev: { itemTarget: { props: { text: string; content: JSX.Element | undefined }; }; }) => {
        setOperationCards(operationCards.map(item => {
          if (item.text === ev.itemTarget.props.text) {
              item.selected = true;
          } else {
              item.selected = false;
          }
          return item;
        }));
    
        setSelectedOperation(ev.itemTarget.props.content);
      };

    return (
        <div>
            {operations.length > 0 ? 
            <DrawerContainer
                items={operationCards}
                onSelect={handleSelect}
                width={200}
                className='drawer-container-scrollable'
                style={{overflow: 'auto !important'}}>
                {selectedOperation}
            </DrawerContainer> : 
            <p>No operations were found!</p>}
        </div>
    );
}

export default OperationLogs;