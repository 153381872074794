import React from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365StackLayout from '../../Lib/Core/Layout/Work365StackLayout';
import Work365TabStrip from '../../Lib/Core/Work365TabStrip';
import Work365TabStripTab from '../../Lib/Core/Work365TabStripTab';
import Work365StackListLayout, { IWork365StockListEvent, IWork365StockListItem } from '../../Lib/Core/Layout/Work365StackListLayout';
import { Global } from '../../Helpers/Global';
import { Utils } from '../../Services/Utils';
import Work365Switch from '../../Lib/Core/Work365Switch';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';

const Plans: React.FC = () => {
    const [state, setState] = React.useState({
        licensePlans: [] as any[],
        selectedPlan: {} as any,
        entitlements: [] as any[],
        planEntitlements: [] as any[],
        scheduledJobDefinitions: [] as any[],
        planJobDefinitions: [] as any[],
    });

    const licensePlanOnClick = (e: IWork365StockListEvent) => {
        getEntitlementsAndScheduledJobDefinitions(e.selected.id);
    }
    const licensePlanOnEditDone = (e: IWork365StockListEvent) => {
        updateLicenePlan(e.selected.id, e.selected.name, e.selected.description);
    }

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/licenseplan/get`, 'GET').then(async (licensePlansResponse: any) => {
            await apiClient.ExecuteRequest(`tenant/${null}/entitlement/null/get`, 'GET').then(async (entitlementResponse: any) => {
                var licensePlans = licensePlansResponse.data;
                await apiClient.ExecuteRequest(`tenant/${null}/LicensePlan/${licensePlans[0].planId}/entitlement/get`, 'GET').then(async (planEntitlementResponse: any) => {
                    await apiClient.ExecuteRequest(`tenant/${null}/scheduledJob/scheduledjobdefinition/get`, 'GET').then(async (scheduledJobResponse: any) => {
                        await apiClient.ExecuteRequest(`tenant/${null}/LicensePlan/${licensePlans[0].planId}/scheduledjobdefinition/get`, 'GET').then((planScheduledJobDefinitionResponse: any) => {
                            var entitlements = entitlementResponse.data;
                            var planEntitlements = planEntitlementResponse.data;
                            var scheduledJobDefinitions = scheduledJobResponse.data;
                            var planJobDefinitions = planScheduledJobDefinitionResponse.data;
                            setState({
                                ...state,
                                licensePlans: licensePlans,
                                entitlements: entitlements,
                                planEntitlements: planEntitlements,
                                scheduledJobDefinitions: scheduledJobDefinitions,
                                planJobDefinitions: planJobDefinitions,
                                selectedPlan: {
                                    id: licensePlans[0].planId,
                                    name: licensePlans[0].planName,
                                    description: licensePlans[0].planDesc,
                                    selected: true
                                }
                            });
                        });
                    });
                });
            });
        });
        return () => {
            console.log("Unmounted")
        };
    }, [])

    const getEntitlementsAndScheduledJobDefinitions = (planId: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/LicensePlan/${planId}/entitlement/get`, 'GET', undefined, false).then((planEntitlementResponse: any) => {
            apiClient.ExecuteRequest(`tenant/${null}/LicensePlan/${planId}/scheduledjobdefinition/get`, 'GET', undefined, false).then((planScheduledJobDefinitionResponse: any) => {
                var planEntitlements = planEntitlementResponse.data;
                var planJobDefinitions = planScheduledJobDefinitionResponse.data;
                var p = state.licensePlans.filter((x: any) => x.planId == planId)[0];
                setState({
                    ...state,
                    planEntitlements: planEntitlements,
                    planJobDefinitions: planJobDefinitions,
                    selectedPlan: {
                        id: p.planId,
                        name: p.planName,
                        description: p.planDesc,
                    }
                });
            });
        });
    }

    const updateLicenePlan = (planId: number, name: string, description: string) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/licenseplan/${planId}/uddate?name=${encodeURI(name)}&description=${encodeURI(description)}`, 'PUT', undefined, false).then(async (licensePlanResponse: any) => {

        })
    }

    const updatePlanentitlement = (planId: number, entitlementId: number, limit: number) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/licenseplan/${planId}/entitlement/${entitlementId}/createorupdate?limit=${limit}`, 'POST', undefined, false).then(async (planEntitlmentesponse: any) => {

        })
    }

    const updatePlanScheduledJobDefinition = (planId: number, scheduledJobDefinitionId: number, deleted: boolean) => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        apiClient.ExecuteRequest(`tenant/${null}/licenseplan/${planId}/scheduledjobdefinition/${scheduledJobDefinitionId}/createordelete?delete=${deleted}`, 'POST', undefined, false).then(async (planEntitlmentesponse: any) => {

        })
    }

    return <>
        {state.licensePlans?.length > 0 ?
            <Work365StackLayout
                orientation='horizontal'
                gap={15}
            >
                <Work365Card>
                    <Work365StackListLayout
                        data={state.licensePlans.map((p: any) => {
                            return {
                                id: p.planId,
                                name: p.planName,
                                description: p.planDesc,
                                selected: false
                            } as IWork365StockListItem;
                        })}
                        editable={true}
                        onEditDone={licensePlanOnEditDone}
                        onClick={licensePlanOnClick}
                        prefix='PLAN '
                        height='calc(75vh)'
                    />
                </Work365Card>
                <Work365Card>
                    <Work365TabStrip>
                        <Work365TabStripTab title='Entitlement'>
                            <Work365StackLayout orientation='vertical'>
                                {state.entitlements?.length > 0 ?
                                    <>
                                        {
                                            state.entitlements.filter((x: any) => x.type == 'LimitBased').map((x: any) => {
                                                var value = state.planEntitlements.filter((e: any) => e.entitlementId == x.entitlementId)[0]?.limit ?? 0;
                                                return <>
                                                    <div style={{ padding: '10px', borderBottom: '1px solid #c9c5c5', verticalAlign: 'middle', margin: 'auto 0', lineHeight: '30px', display: 'inline-flex' }}>
                                                        <span style={{ width: '50%' }}>
                                                            {x.entitlementName}
                                                        </span>

                                                        <span style={{ width: '45%' }}>
                                                            <Input
                                                                type='number'
                                                                key={state.selectedPlan.id}
                                                                autoFocus={!(x.readOnly ?? true)}
                                                                defaultValue={value.toString()}
                                                                readOnly={x.readOnly ?? true}
                                                                onBlur={(e) => {
                                                                    updatePlanentitlement(state.selectedPlan.id, x.entitlementId, Number(e.target.value));
                                                                    x.readOnly = true;
                                                                    setState({
                                                                        ...state,
                                                                        entitlements: state.entitlements
                                                                    });
                                                                }} />
                                                        </span>
                                                        <span>
                                                            <Button icon='pencil' size={'small'} fillMode={'link'} themeColor={'primary'} title='Edit' onClick={() => {
                                                                x.readOnly = false;
                                                                setState({
                                                                    ...state,
                                                                    entitlements: state.entitlements
                                                                });
                                                            }} />
                                                        </span>
                                                    </div>
                                                </>

                                            })
                                        }
                                        <br />
                                        <div style={{ padding: '10px', borderBottom: '1px solid #c9c5c5', verticalAlign: 'middle', margin: 'auto 0', fontSize: '15px', fontWeight: 'bold' }}>
                                            <span>
                                                Entitlement Name
                                            </span>
                                            <span style={{ float: 'right' }}>
                                                State
                                            </span>
                                        </div>

                                        {
                                            state.entitlements.filter((x: any) => x.type == 'Switch').map((x: any) => {
                                                var value = state.planEntitlements.filter((e: any) => e.entitlementId == x.entitlementId)[0]?.limit ?? 0;
                                                return <>
                                                    <div style={{ padding: '10px', borderBottom: '1px solid #c9c5c5', verticalAlign: 'middle', margin: 'auto 0' }}>
                                                        <span style={{ lineHeight: '30px' }}>
                                                            {x.entitlementName}
                                                        </span>
                                                        <span style={{ float: 'right', textAlign: 'right' }}>
                                                            <Work365Switch field={undefined} value={value > 0} onChange={(e) => {
                                                                updatePlanentitlement(state.selectedPlan.id, x.entitlementId, Number(e.value));
                                                                var planEntitlements = state.planEntitlements;
                                                                if (e.value) {
                                                                    var pe = state.planEntitlements.filter((e: any) => e.entitlementId == x.entitlementId);
                                                                    if (pe.length > 0) {
                                                                        pe[0].limit = 1;
                                                                    } else {
                                                                        (planEntitlements as any[]).push({
                                                                            planId: state.selectedPlan.id,
                                                                            entitlementId: x.entitlementId,
                                                                            limit: 1
                                                                        });
                                                                    }
                                                                } else {
                                                                    planEntitlements = state.planEntitlements.filter((e: any) => e.entitlementId != x.entitlementId);
                                                                }

                                                                setState({ ...state, planEntitlements: planEntitlements });
                                                            }} />
                                                        </span>
                                                    </div>
                                                </>

                                            })
                                        }
                                    </>
                                    : <></>
                                }
                            </Work365StackLayout>
                        </Work365TabStripTab>
                        <Work365TabStripTab title='Scheduled Jobs'>
                            {
                                state.scheduledJobDefinitions?.length > 0 ? <>
                                    <div style={{ padding: '10px', borderBottom: '1px solid #c9c5c5', verticalAlign: 'middle', margin: 'auto 0', fontSize: '15px', fontWeight: 'bold' }}>
                                        <span>
                                            Scheduled Job Definition Name
                                        </span>
                                        <span style={{ float: 'right' }}>
                                            State
                                        </span>
                                    </div>
                                    <div style={{ height: 'calc(65vh)', overflowY: 'scroll' }}>
                                        {
                                            state.scheduledJobDefinitions.map((x: any) => {
                                                var value = state.planJobDefinitions.filter((e: any) => e.scheduleJobDefinitionId == x.scheduleJobDefinitionId).length ?? 0;
                                                return <>
                                                    <div style={{ padding: '10px', borderBottom: '1px solid #c9c5c5', verticalAlign: 'middle', margin: 'auto 0' }}>
                                                        <span style={{ lineHeight: '30px' }}>
                                                            {x.name}
                                                        </span>
                                                        <span style={{ float: 'right', textAlign: 'right' }}>
                                                            <Work365Switch field={undefined} value={value > 0} onChange={(e) => {
                                                                updatePlanScheduledJobDefinition(state.selectedPlan.id, x.scheduleJobDefinitionId, !e.value);
                                                                var planJobDefinitions = state.planJobDefinitions;
                                                                if (e.value) {
                                                                    var pe = state.planJobDefinitions.filter((e: any) => e.scheduleJobDefinitionId == x.scheduleJobDefinitionId);
                                                                    if (pe.length == 0) {
                                                                        (planJobDefinitions as any[]).push({
                                                                            planId: state.selectedPlan.id,
                                                                            scheduleJobDefinitionId: x.scheduleJobDefinitionId,
                                                                        });
                                                                    }
                                                                } else {
                                                                    planJobDefinitions = state.planJobDefinitions.filter((e: any) => e.scheduleJobDefinitionId != x.scheduleJobDefinitionId);
                                                                }

                                                                setState({ ...state, planJobDefinitions: planJobDefinitions });
                                                            }} />
                                                        </span>
                                                    </div>
                                                </>

                                            })
                                        }
                                    </div>
                                </> : <></>
                            }
                        </Work365TabStripTab>
                    </Work365TabStrip>
                </Work365Card>
            </Work365StackLayout >
            : <></>}
    </>
}

export default Plans;