import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Row } from 'react-bootstrap';
import { FormInput, EmailValidator, FormMaskedTextBox, phoneValidator, RequiredValidator } from '../Lib/Core/Work365FormInputs';
import { User } from '../Lib/Models/User';
import Work365Card from '../Lib/Core/Work365Card';

export type Work365CompleteProfileFormProps = {
    user: User | undefined;
    onSubmit: (values: { [name: string]: any; }, event?: SyntheticEvent<any, Event> | undefined) => void;
}

const Work365CompleteProfileForm: React.FC<Work365CompleteProfileFormProps> = ({ user, onSubmit}) => {

    let { i18n, t } = useTranslation(['CompleteProfile']);
    
    return  (
        <Work365Card>
                <Form initialValues={user} key={JSON.stringify(user)} onSubmit={onSubmit} render={(formRenderProps) => (
                    <FormElement>
                        <Row>{formRenderProps.visited && formRenderProps.errors && formRenderProps.errors.VALIDATION_SUMMARY && <div className={'k-messagebox k-messagebox-error'}>{formRenderProps.errors.VALIDATION_SUMMARY}</div>}</Row>
                        
                        <Field name={'FirstName'} component={FormInput} label={t('firstName')} initialValues={""} />
                        <Field name={'LastName'} component={FormInput} label={t('lastName')} validator={RequiredValidator} />
                        <Field name={'Email'} component={FormInput} label={t('email')} validator={EmailValidator} disabled />
                        <Field name={'JobTitle'} component={FormInput} label={t('jobTitle')} />
                        <Field name={'Phone'} component={FormMaskedTextBox} label={t('phone')} mask={"(999) 000-0000"} validator={phoneValidator} />

                        <div className="k-form-buttons text-right">
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                                {t('submit')}
                            </button>
                        </div>
                    </FormElement>
                )}/>
        </Work365Card>)
}

export default Work365CompleteProfileForm;