import React from "react";
import { TabStrip, TabStripSelectEventArguments } from "@progress/kendo-react-layout";

export interface Work365TabStripProps {
    children: React.ReactNode[] | React.ReactNode; 
}
const Work365TabStrip: React.FC<Work365TabStripProps> = ({
    children,
}) => {

    const [selected, setSelected] = React.useState<number>(0);

    const handleSelect = (e: TabStripSelectEventArguments) => {
      setSelected(e.selected);
    };

    return (
        <TabStrip selected={selected} onSelect={handleSelect} tabContentStyle={{innterWidth: '100%', innerHeight: '100%'}} style={{width: '100%', height: '100%'}}>
            {children}
        </TabStrip>
    );
};

export default Work365TabStrip;
