import React from "react";
import * as ReactDOM from "react-dom";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";

export interface Work365SwitchProps {
  data?: any | undefined;
  field?: string | undefined;
  value?: boolean | undefined;
  title?: string | undefined;
  disabled?: boolean | undefined;
  style?: React.CSSProperties | undefined;
  key?: string | undefined;
  onChange?: ((e: ICustomSwitchChangeEvent) => void) | undefined;
}

interface ICustomSwitchChangeEvent extends SwitchChangeEvent{
  dataItem: any;
  field: string | undefined;
}

const Work365Switch: React.FC<Work365SwitchProps> = (p:Work365SwitchProps) => {

  const onChange = (e: any) =>{
    e.dataItem = p.data;
    e.field = p.field;

    if(p.onChange)
      p.onChange((e as ICustomSwitchChangeEvent));
  }

  const baseStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const mergedStyles = { ...baseStyles, ...p.style };

  return (
    <div style={mergedStyles}>
      <Switch
        key={p.key}
        disabled={p.disabled} 
        onChange={onChange} 
        defaultChecked={p.value}  
        size={"small"} />
      <span style={{marginLeft: '5px'}}>{p.title}</span>
    </div>
  );
};

export default Work365Switch;
