import { Work365Tile } from '../../Lib/Models/Work365Tile';
import React from 'react';
import Work365TileLayout from '../../Lib/Core/Work365TileLayout';

interface GridTemplateProps {
    tile1Component: TileComponent;
    tile2Component: TileComponent;
    tile3Component: TileComponent;
    tile4Component: TileComponent;
}

export type TileComponent = {
    header: JSX.Element;
    component: JSX.Element;
}

class GridTemplate extends React.Component<GridTemplateProps> {
    tiles: Work365Tile[] = [
        {
            header: this.props.tile1Component.header,
            body: this.props.tile1Component.component,
            col: 1,
            colSpan: 4,
            rowSpan: 2
        },
        {
            header: this.props.tile2Component.header,
            body: this.props.tile2Component.component,
            col: 5,
            colSpan: 4,
            rowSpan: 2
        },
        {
            header: this.props.tile3Component.header,
            body: this.props.tile3Component.component,
            col: 9,
            colSpan: 4,
            rowSpan: 2
        },
        {
            header: this.props.tile4Component.header,
            body: this.props.tile4Component.component,
            col: 1,
            colSpan: 12,
            rowSpan: 3
        }
    ];

    render() {
        return (
            <Work365TileLayout
                columns={12}
                rowHeight={220}
                rows={12}
                rowGap={12}
                columnsGap={12}
                tiles={this.tiles} />
        )
    }
}

export default GridTemplate;