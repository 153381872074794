import { Component, ReactNode } from "react";
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartArea, ChartSeriesLabels, SharedTooltipContext, TooltipContext } from '@progress/kendo-react-charts'

interface Work365DonutChartProps{
    tooltipRender?: ((props: TooltipContext | SharedTooltipContext) => ReactNode) | undefined;
    title?: string | undefined;
    chartLegendVisible?: boolean | undefined;
    name?: string | undefined;
    data?: any[] | undefined;
    field?: string | undefined;
    categoryField?: string | undefined;
    showChartLabels?: boolean | undefined;
    labelContent?: ((e: any) => string) | undefined;
    height?: number | undefined;
    width?: number| undefined;
}

export default class Work365DonutChart extends Component<Work365DonutChartProps, any>{
    render(){
        return (
            <Chart style={{height: this.props.height, width: this.props.width}}>
                <ChartTooltip render={this.props.tooltipRender} />
                <ChartTitle text={this.props.title} />
                <ChartLegend visible={this.props.chartLegendVisible} />
                <ChartArea background='none' />
                <ChartSeries>
                    <ChartSeriesItem 
                        type='donut' 
                        startAngle={150} 
                        name={this.props.name} 
                        data={this.props.data} 
                        field={this.props.field} 
                        categoryField={this.props.categoryField}>
                        {this.props.showChartLabels ? <ChartSeriesLabels position='outsideEnd' background='none' content={this.props.labelContent} /> : null}
                    </ChartSeriesItem>
                </ChartSeries>
            </Chart>
        )
    }
}