import { Label } from "@progress/kendo-react-labels";
import { ReactNode } from "react";

interface Work365CardProps{
    children: ReactNode;
}

const Work365Label: React.FC<Work365CardProps> = ({children}) => {
    return (
        <Label style={{marginBottom: '0px'}}>
            {children}
        </Label>
    );
}
  
export default Work365Label;