import * as React from 'react';

import { GridDataStateChangeEvent, GridExpandChangeEvent, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { State, process, DataResult, SortDescriptor, CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { Global } from '../../Helpers/Global';
import { Utils } from '../../Services/Utils';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365TabStrip from '../../Lib/Core/Work365TabStrip';
import Work365TabStripTab from '../../Lib/Core/Work365TabStripTab';
import Work365FlexiGrid, { IGridState } from '../../Lib/Core/Grid/Work365FlexiGrid';
import { Work365Column } from '../../Lib/Models/Work365Column';
import { ExcelExport } from '@progress/kendo-react-excel-export';

const initialFilter = {
    logic: "and", filters: []
} as CompositeFilterDescriptor;

const AzureADConsent: React.FC = () => {
    const _export = React.useRef<ExcelExport | null>(null);
    const [adConsent, setAdConsent] = React.useState<any[]>([] as any[]);
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState([
        {
            field: "domain",
            dir: "asc",
        } as SortDescriptor,
    ]);
    const [gridState, setGridState] = React.useState<IGridState>({
        groupable: false,
        resizable: true,
        filterable: false,
        sortable: true,
        scrollable: "none",
        pageSize: 30,
        rowHeight: 40,
        //total:0,
    });
    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 30,
        group: []
    });
    const dataStateAllData = (currentDataState: State, adconsent: any[]) => ({ ...currentDataState, take: adconsent.length });
    const [dataResult, setDataResult] = React.useState<DataResult>(process(adConsent, dataStateAllData(dataState, adConsent)));
    React.useEffect(() => {

        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        apiClient.ExecuteRequest(`company/customer/azureadconsent/get`, 'GET').then(async (adConsentResponse: any) => {
            var adConsents = adConsentResponse.data;
            setAdConsent(adConsents);
            //setDataResult({ ...adConsents, data: [...adConsents.data] });
            setDataResult(process(adConsents, dataStateAllData(dataState, adConsents)))
        });
    }, []);

    const DateCustomCell = (props: any) => {
        var field = props.field || '';
        var value = props.dataItem[field];
        return (
            <td>
                {Utils.ToDisplayDateFormat(value, false, 'fr-CA')}
            </td>
        )
    }

    const onPageChange = (e: GridPageChangeEvent) => {
        setDataState({ skip: e.page.skip, take: dataState.take ?? 0 })
    }

    const azureADConsentGridColumns = [
        {
            field: "azureAdTenantId",
            title: "AAD ID",
            width: 400,
            filterable: true,
            filter: "text"
        },
        {
            field: "domain",
            title: "Email Domain",
            width: 200,
            filterable: true,
            filter: "text"
        },
        {
            field: "endPointUrl",
            title: "End Point Url",
            width: 200,
            filterable: true,
            filter: "text"
        },
        {
            field: "firstConsentOnUtc",
            title: "First Consent On",
            width: 200,
            cell: DateCustomCell
        },
        {
            field: "firstConsentBy",
            title: "First Consent By",
            width: 200,
            filterable: true,
            filter: "text"
        },
        {
            field: 'lastConsentOnUtc',
            title: 'Last Consent On',
            width: 200,
            cell: DateCustomCell
        },
        {
            field: 'lastConsentBy',
            title: 'Last Consent By',
            width: 200,
            filterable: true,
            filter: "text"
        }
    ] as Work365Column[];

    const onFilterChange = (e: any) => {
        setFilter(e.filter);
        setGridState({ ...gridState, sort: e.sort });
        setDataState({ ...dataState, skip: 0 });

        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const onSortChange = (e: any) => {
        setSort(e.sort);
        setGridState({ ...gridState, sort: e.sort });
        setDataState({ ...dataState, skip: 0 });
        let gridContainer = document.querySelector('.k-grid-content');
        if (gridContainer) {
            gridContainer.scrollTop = 0;
        }
    };

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save(filterBy(orderBy(dataResult.data, sort), filter)?.map((x:any)=>{
                x.lastConsentOnUtc = Utils.ToDisplayDateFormat(x.lastConsentOnUtc , false, 'fr-CA');
                x.firstConsentOnUtc = Utils.ToDisplayDateFormat(x.firstConsentOnUtc , false, 'fr-CA');
                return x;
            }), azureADConsentGridColumns)
        }
    }

    return (
        <Work365Card>
            <Work365TabStrip>
                <Work365TabStripTab title={'Azure AD Consent'}>
                    <div style={{ position: 'relative' }}>
                        <div style={{ maxHeight: 'calc(100vh - 7em)', overflowY: 'auto', paddingTop: '5px' }}>
                            <ExcelExport ref={_export} fileName={`Work365AzureADConsent_${new Date().toLocaleString().replace(/[/\\:*?"<>|]/g, "_")}`} />
                            <Work365FlexiGrid
                                key={'azureAdConsent'}
                                columns={azureADConsentGridColumns}
                                data={filterBy(orderBy(dataResult.data, sort), filter)}
                                gridState={gridState}
                                style={{ height: '780px', width: '1600px' }}
                                dataStateChange={undefined}
                                onPageChange={onPageChange}
                                onFilterChange={onFilterChange}
                                sortChange={onSortChange}
                                toolbar={
                                    <button
                                        title='Export to Excel'
                                        className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'
                                        onClick={excelExport}
                                    >
                                        Export to Excel
                                    </button>
                                }
                            />
                        </div>
                    </div>
                </Work365TabStripTab>
            </Work365TabStrip>
        </Work365Card>
    );
}

export default AzureADConsent;