import { Grid, GridColumn, GridDataStateChangeEvent, GridDetailRowProps, GridExpandChangeEvent, GridGroupChangeEvent, GridSortChangeEvent, GridToolbar  } from "@progress/kendo-react-grid";
import React, { ReactNode } from 'react';
import { CompositeFilterDescriptor, DataResult, SortDescriptor, filterBy, orderBy, toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { Work365Column } from "../Models/Work365Column";
import { Size } from "../Models/Size";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Work365ColumnMenu } from "./Work365ColumnMenu";
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import esbrNumbers from 'cldr-numbers-full/main/bg/numbers.json';
import esbrLocalCurrency from 'cldr-numbers-full/main/bg/currencies.json';
import esbrCaGregorian from 'cldr-dates-full/main/bg/ca-gregorian.json';
import esbrDateFields from 'cldr-dates-full/main/bg/dateFields.json';
import bgNumbers from 'cldr-numbers-full/main/bg/numbers.json';
import bgLocalCurrency from 'cldr-numbers-full/main/bg/currencies.json';
import bgCaGregorian from 'cldr-dates-full/main/bg/ca-gregorian.json';
import bgDateFields from 'cldr-dates-full/main/bg/dateFields.json';
import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';
import esNumbers from 'cldr-numbers-full/main/es-AR/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es-AR/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es-AR/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es-AR/dateFields.json';
import { GridState } from "../Models/GridState";
import { ButtonItem } from "@progress/kendo-react-buttons/dist/npm/ListButton/ButtonItem";
import Work365Checkbox from "./Checkbox/Work365Checkbox";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";

load(
    esbrNumbers,
    esbrLocalCurrency,
    esbrCaGregorian,
    esbrDateFields,
    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields,
    likelySubtags,
    currencyData,
    weekData,
    bgLocalCurrency,
    bgNumbers,
    bgCaGregorian,
    bgDateFields,
    deLocalCurrency,
    deNumbers,
    deCaGregorian,
    deDateFields
)

interface Work365PagingGridProps {
    excelFileName?: string | undefined;
    gridtoolbar?: ReactNode | undefined;
    columns?: Work365Column[] | undefined;
    detailComponent?: React.ComponentType<GridDetailRowProps> | null | undefined;
    state: GridState;

    filter?: CompositeFilterDescriptor | undefined;
    sort?: SortDescriptor[] | undefined;

    showEditButton: boolean | undefined;
    onEditButtonClick?: (id: string) => void;

    //this will need to be a reference that is created on the page if we want to 
    //export the grid from a button that is not assocated to the grid
    excelref?: React.MutableRefObject<any> | undefined;

    filterable?: boolean | undefined;
    groupable?: boolean | undefined;
    sortable?: boolean | undefined;
    reorderable?: boolean | undefined;

    onNextPage: () => void;
    onPreviousPage: () => void;

    onExpandChange?: ((event: GridExpandChangeEvent) => void) | undefined;
    onSortChange?: ((event: GridSortChangeEvent) => void) | undefined;
    onGroupChange?: ((event: GridGroupChangeEvent) => void) | undefined;
    onFilterChange?: ((event: any) => void) | undefined;

    onCheckClicked?: (object : any, event: CheckboxChangeEvent) => void;
    getCheckboxValue?: (object : any) => boolean;

    showCheckbox?: boolean;

    children: ReactNode | ReactNode[] | undefined;
}

export default function Work365PagingGrid(props: Work365PagingGridProps) {

    interface customPagingControlProps {
        morePagesAvailable: boolean;
        skip: number;
        onNextPage: () => void;
        onPreviousPage: () => void;
    }

    const CustomPagingControl: React.FC<customPagingControlProps> = ({
        morePagesAvailable,
        skip,
        onNextPage,
        onPreviousPage
    }) => {
        return (
            <div>
                <button
                    onClick={onPreviousPage}
                    disabled={skip == 0}
                    className={`k-pager-nav k-link k-state-default ${skip == 0 ? 'k-state-disabled' : ''}`}>
                    <span className='k-icon k-i-arrow-60-left'></span>
                </button>
                <button 
                    onClick={onNextPage}
                    disabled={!morePagesAvailable}
                    className={`k-pager-nav k-link k-state-default ${!morePagesAvailable ? 'l-state-disabled' : ''}`}>
                        <span className='k-icon k-i-arrow-60-right'></span>
                    </button>
            </div>
        )
    }

    const expandChange = (event: any) => {
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        if (props.onExpandChange != undefined) {
            props.onExpandChange(event);
        }
    };

    const getColumn = (column: Work365Column) => {
        if (column == undefined) {
            return;
        }

        if (column.filter == 'date' && column.format == undefined) {

            if (column.width == undefined) {
                column.width = 235;
            }
        }

        return (
            <GridColumn
                className={column.className}
                field={column.field}
                title={column.title}
                width={column.width}
                filter={column.filter}
                format={column.format}
                filterable={column.filterable}
                columnMenu={column.filterable ? Work365ColumnMenu : undefined}
                cell={column.cell}
                headerCell={column.headerCell}
                locked={column.locked}
            />
        )
    }

    const EditButton = ({ dataItem }: { dataItem: any }) => (
        <td>
            <button onClick={() => props.onEditButtonClick && props.onEditButtonClick(dataItem.id)}>
                Edit
            </button>
        </td>
    );

    const Checkbox = ({dataItem } : { dataItem: any }) => (
        <td>
            <Work365Checkbox defaultChecked={props.getCheckboxValue && props.getCheckboxValue(dataItem)} id={dataItem.id} onChange={(event) => props.onCheckClicked && props.onCheckClicked(dataItem, event)} />
        </td>
    );

    const renderCustompager = () => {
        return (
            <CustomPagingControl 
                morePagesAvailable={props.state.morePagesAvailable}
                skip={props.state.skip}
                onNextPage={props.onNextPage}
                onPreviousPage={props.onPreviousPage}
            />
        )
    }

    const prepareData = () => {
        let data = props.state.data;

        if (props.filter != undefined) {
            data = filterBy(data, props.filter);
        }

        if (props.sort != undefined) {
            data = orderBy(data, props.sort);
        }

        return data;
    }

    return (
        <div>
            {props.state.isLoading ?
                null
                :
                <LocalizationProvider language={props.state.currentLocale.language}>
                    <IntlProvider locale={props.state.currentLocale.locale}>
                        <div>
                            <ExcelExport
                                fileName={props.excelFileName}
                                //this is a reference to a parent object so that
                                //the grid can be exported from a component higher than the current component(Work365PagingGrid)

                                ref={props.excelref}
                            >
                                <Grid
                                    style={{ height: '70vh' }}
                                    sortable={props.sortable}
                                    filterable={props.filterable}
                                    groupable={props.groupable}
                                    reorderable={props.reorderable}
                                    pager={renderCustompager}
                                    data={prepareData()}
                                    detail={props.detailComponent}
                                    expandField="expanded"
                                    onExpandChange={expandChange}
                                    filter={props.filter}
                                    sort={props.sort}

                                    onSortChange={props.onSortChange}
                                    onFilterChange={props.onFilterChange}

                                    pageable={true}

                                    skip={props.state.skip}
                                    pageSize={props.state.take}
                                >
                                    {props.showCheckbox && 
                                    <GridColumn cell={Checkbox} title='' width='40px' />}
                                    {props.gridtoolbar}
                                    {props.columns != null ? props.columns.map((column) => (
                                        getColumn(column)
                                    )) : undefined}
                                    {props.showEditButton == true &&
                                    props.onEditButtonClick != undefined &&
                                    <GridColumn cell={EditButton} title="Edit" width="100px" />}
                                    {props.children}
                                </Grid>
                            </ExcelExport >
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            }
        </div>
    )
}

