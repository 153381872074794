import React from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365TabStrip from '../../Lib/Core/Work365TabStrip';
import Work365TabStripTab from '../../Lib/Core/Work365TabStripTab';
import { CardHeader } from '@progress/kendo-react-layout';
import EnvironmentReport from './EnvironmentReport';

const ReportHome: React.FC = () => {
    return <>
        <Work365Card>
            {/* <CardHeader>Environment Report</CardHeader> */}
            <Work365TabStrip>
                <Work365TabStripTab title={'Environment Report'}>
                <div style={{ padding: '10px' }}>
                        <EnvironmentReport/>
                    </div>
                </Work365TabStripTab>
                <Work365TabStripTab title={'Provider Billing'}>
                    <div style={{ padding: '10px' }}>
                        <p>Coming soon!</p>
                    </div>
                </Work365TabStripTab>
                <Work365TabStripTab title={'Billing Contract Utilization'}>
                <div style={{ padding: '10px' }}>
                        <p>Coming soon!</p>
                    </div>
                </Work365TabStripTab>
                <Work365TabStripTab title={'Inactive Tenants with Scheduled Jobs'}>
                    <div style={{ padding: '10px' }}>
                        <p>Coming soon!</p>
                    </div>
                </Work365TabStripTab>
            </Work365TabStrip>
        </Work365Card>
    </>
}
export default ReportHome;