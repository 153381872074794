import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

interface Work365DropdownBox{
    data: any[]
    defaultValue?: any | undefined;
    onChange: ((event: ComboBoxChangeEvent) => void) | undefined;
    style?: React.CSSProperties;
}

const Work365DropdownBox = (props: Work365DropdownBox) => {
    return (
        <ComboBox
            data={props.data}
            defaultValue={props.defaultValue}
            allowCustom={true}
            dataItemKey="value" 
            textField="name"
            onChange={props.onChange}
            style={props.style}
        />
    );
  }
  
  export default Work365DropdownBox;