import React, { useState } from 'react';
import { Global } from '../../Helpers/Global';
import Work365Textbox from '../Core/TextBox/Work365Textbox';
import Work365PrimaryButton from '../Core/Buttons/Work365PrimaryButton';
import Work365ConfirmationDialog from '../Core/Work365ConfirmationDialog';

export type Work365SubmitPanelProps = {
  operationId: string | undefined;
  tenantId: string | undefined;
  additionalParameters?: any | undefined;
  handleNewOperation: () => void;
};
const Work365SubmitPanel: React.FC<Work365SubmitPanelProps> = ({
  operationId,
  additionalParameters,
  tenantId,
  handleNewOperation,
}) => {
  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  const [emails, setEmails] = useState<any>(authService.account?.username);
  const [showConformationDialog, setConformationDialog] = useState<boolean>(false);

  function createOperation() {
    toggleDialog();
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/start?operationId=${operationId}&notificationEmails=${emails}&additionalParameters=${additionalParameters}`, 'POST').then((response: any) => {
        handleNewOperation();
      });
  }

  function updateEmailState(event: any){
    setEmails(event.target.value);
  }

  function toggleDialog(){ 
    setConformationDialog(!showConformationDialog);
  }

  return (
    <div style={{bottom: 0, right: 0, position: 'sticky', marginRight: '5px', marginBottom: '5px', marginTop: '5px'}}>
      {showConformationDialog ? 
        <Work365ConfirmationDialog
          headerText={"Are you sure you want to continue?"}
          onOKClick={createOperation}
          onCancelClick={toggleDialog} />
      : 
        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
          <Work365Textbox
            id='txtsetEmailIds'
            value={authService.account?.username}
            style={{ marginRight: '5px', width: '300px' }}
            tooltip='Enter Email address (separated by comma) to be notified when this process completed'
            placeHolder='Enter Email address (separated by comma) to be notified when this process completed'
            onChange={(e: any) => updateEmailState(e)}
            disabled={false} />
          <Work365PrimaryButton
            title='Proceed'
            onClick={toggleDialog} 
            style={{width: '75px'}}/>
        </div>}
    </div>
  );
};

export default Work365SubmitPanel;
