import React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Work365DropdownItem } from '../../Models/Work365DropdownItem';

export interface Work365TextboxProps {
    data?: Work365DropdownItem[] | undefined;
    placeHolder: string | undefined;
    width?: string | undefined;
    onChange?: ((event: any) => void) | undefined;
    value?: string | undefined;
}

const Work365AutocompleteTextbox: React.FC<Work365TextboxProps> = ({data, placeHolder, width, onChange, value} : Work365TextboxProps) => {
    var autocompleteData: string[] = [];

    const changeHandler = !onChange ? undefined : (event: any) => {
        var value = undefined;
        if (data != undefined){
            var autocompleteObject = data.find(x => x.Name === event.value);

            if (autocompleteObject){
                value = autocompleteObject.Value;
            }
        }
        
        onChange({work365Value: value, defaultValue: event.value});
    };

    if (data != undefined){
        autocompleteData = data.map(row => row.Name);
    }

    return <AutoComplete 
        data={autocompleteData}
        placeholder={placeHolder}
        style={{width: width}}
        onChange={changeHandler}
        value={value} />
}

export default Work365AutocompleteTextbox;