import React from 'react';
import GridTemplate from '../../Components/Common/GridTemplate';
import Work365PagingGrid from '../../Lib/Core/Work365PagingGrid';
import Work365StackedBarChart from '../../Lib/Core/Work365StackedBarChart';
import Work365DonutChart from '../../Lib/Core/Work365DonutChart';
import { useTranslation } from 'react-i18next';
import Work365Header from '../../Lib/Core/Work365Header';
import { Button } from '@progress/kendo-react-buttons';



const customers = [
  'Businesszilla',
  'Syndicate Business',
  'Intelligence Business',
  'Mind Business'
]

const labelContent = (e: any) => `${e.category}: \n $${e.value}`;

const renderTooltipForDonut = (context: any) => {
  const { category, series, value } = context.point || context;
  return (
    <div>
      {category} ({series.name}): ${value}
    </div>
  );
};


const servicesUsage = [{
  category: 'SQL Database',
  value: 380.22
}, {
  category: 'Virtual Machines',
  value: 218.82
}, {
  category: 'Azure App Service',
  value: 135.53
}, {
  category: 'Storage',
  value: 90.71
}];

const locationUsage = [{
  category: 'US West',
  value: 453.32
}, {
  category: 'US East',
  value: 423.42
}, {
  category: 'Europe',
  value: 567.64
}, {
  category: 'new zealand',
  value: 32.74
}];

const resouceUsage = [{
  category: 'rg-iotap-prod',
  value: 600.40
}, {
  category: 'rg-work365-eu01',
  value: 165.66
}, {
  category: 'rg-work365-uat',
  value: 69.71
}, {
  category: 'rg-iotap-prod-asr',
  value: 17.85
}];

const invoicedUsageSummaries = [{
  category: '2021-06',
  value: 600.40
}, {
  category: '2021-07',
  value: 580.45
}, {
  category: '2021-08',
  value: 890.54
}, {
  category: '2021-09',
  value: 365.54
}, {
  category: '2021-10',
  value: 754.41
}, {
  category: '2021-11',
  value: 546.51
}, {
  category: '2021-12',
  value: 587.52
}, {
  category: '2022-01',
  value: 602.24
}, {
  category: '2022-02',
  value: 650.54
}, {
  category: '2022-03',
  value: 680.54
}, {
  category: '2022-04',
  value: 700.54
}, {
  category: '2022-05',
  value: 745.54
}, {
  category: '2022-06',
  value: 424.54
}];

const _export = React.createRef<any>();
const excelExport = () => {
  if (_export.current !== null) {
    _export.current.save();
  }
};

const GetGridButtons = () => {
  return <Button icon="export" title="Export" onClick={excelExport} />;
};

const uninvoicedUsageSummaries = [
  {
    category: '2022-06',
    value: 450
  }
]

const renderTooltip = (context: any) => {
  const { category, series, value } = context.point || context;
  return (
    <div>
      {category} : ${value}
    </div>
  );
};

const TenantBillingContracts: React.FC = () => {
  React.useEffect(() => {

  }, []);

  let { i18n, t } = useTranslation(['billing-contracts']);

  return <GridTemplate

    tile1Component={{
      header: t('topbillingcontractsbydensity'),
      component: <Work365StackedBarChart
        invoicedUsageSummaries={invoicedUsageSummaries}
        tooltipRender={renderTooltip}
        height={300} />
    }}

    tile2Component={{
      header: t('topbillingcontractsbyacv'),
      component: <Work365StackedBarChart
        invoicedUsageSummaries={invoicedUsageSummaries}
        tooltipRender={renderTooltip}
        height={300} />
    }}


    tile3Component={{
      header: t('servicename'),
      component:
        <Work365DonutChart
          tooltipRender={renderTooltipForDonut}
          name='Usage Summaries'
          data={servicesUsage}
          field='value'
          categoryField='category'
          labelContent={labelContent}
          chartLegendVisible={true}
          showChartLabels={false}
          height={300}
        />


    }}

    tile4Component={{
      header: <Work365Header Buttons={GetGridButtons()} HeaderCaption={t('billingcontracts')} />,
      component: <></>
    }} />

}

export default TenantBillingContracts;