import React, { useState } from 'react';
import { Global } from '../Helpers/Global';

const Login: React.FC = () => {


    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        Global.getAuthService(appSettings).SignIn();
    }, []);


    const handleClick = (event: any) => {
        event.preventDefault();

        var appSettings = Global.getAppSettings();
        Global.getAuthService(appSettings).SignIn();
    }

    return <div></div>
}

export default Login;