import { Work365Tile } from "../../Lib/Models/Work365Tile";
import React, { ReactElement, useEffect, useState } from 'react';
import Work365TileLayout from "../../Lib/Core/Work365TileLayout";
import { IsTenantReady } from "../../Lib/Models/IsTenantReady";
import TenantBarStatusIndicator from "./TenantBarStatusIndicator";
import { ErrorColor, SuccessfulColor, WarningColor, NotStartedColor } from "../../Lib/Models/Colors";
import { TenantStatus } from "../../Lib/Models/TenantStatus";
import { Col, Row } from "react-bootstrap";
import TenantStatusIndicator from "./TenantStatusIndicator";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Work365HoverTooltip } from "../../Lib/Core/Work365Tooltip";
import Work365Label from "../../Lib/Core/Work365Label";
import TenantCard from "./TenantCard";
import Work365Card from "../../Lib/Core/Work365Card";

interface TenantGridProps {
    tenants: IsTenantReady[];
    selectedTenant: IsTenantReady | undefined;
    handleSubmit: (tenant: IsTenantReady) => void;
}

export function getSortedTenants(tenants: IsTenantReady[]){
    var successfulTenants = tenants.filter(tenant => tenant.status === TenantStatus.TenantIsReady);
    var errorTenants = tenants.filter(tenant => tenant.status === TenantStatus.TenantError);
    var notReadyTenants = tenants.filter(tenant => tenant.status === TenantStatus.TenantNotReady);

    successfulTenants = successfulTenants.sort(tenant => { return tenant.isProduction ? -1 : 1 });
    errorTenants.sort(tenant => { return tenant.isProduction ? -1 : 1 });
    notReadyTenants.sort(tenant => { return tenant.isProduction ? -1 : 1 });

    let columnNumber = 0;
    return successfulTenants.concat(errorTenants, notReadyTenants);
}

const TenantGrid: React.FC<TenantGridProps> = ({tenants, selectedTenant, handleSubmit}) => {

    const tenantReadyStyle = {
        backgroundColor: SuccessfulColor
    }

    const tenantErrorStyle = {
        backgroundColor: ErrorColor
    }

    const tenantNotReadyStyle = {
        backgroundColor: NotStartedColor
    }

    const tenantProgressStyle = {
        backgroundColor: WarningColor
    }

    function initializeTenant(tenant: IsTenantReady) {
        let statusBarComponent: ReactElement<any, any> =  <TenantBarStatusIndicator styles={tenantErrorStyle}/>;
        let tenantStatus: TenantStatus = TenantStatus.TenantError;
        let statusMessage = "Could not determine status of the tenant.";

        switch(true){
            case !tenant.isWork365Installed:
                if (!tenant.isTenantInitialized){
                    statusBarComponent = <TenantBarStatusIndicator styles={tenantNotReadyStyle}/>
                    statusMessage = "Tenant is not authorized to install a Work 365 trial. Please contact Work 365 support for more information.";
                    tenantStatus = TenantStatus.TenantNotReady;

                    break;
                }

                if (!tenant.isDynamicInstanceInitialized){
                    statusBarComponent = <TenantBarStatusIndicator styles={tenantNotReadyStyle}/>
                    statusMessage = "Tenant does not have Work 365 installed. To install Work 365, please contact a system administrator.";
                    tenantStatus = TenantStatus.TenantNotReady;

                    break;
                }

                statusBarComponent = <TenantBarStatusIndicator styles={tenantNotReadyStyle}/>
                tenantStatus = TenantStatus.TenantNotReady;
                statusMessage = "Work 365 is not installed! Use the Import Solution operation to install Work 365."

                break;
            case !tenant.isApplicationUserCreated:
                statusBarComponent = <TenantBarStatusIndicator styles={tenantErrorStyle}/>
                tenantStatus = TenantStatus.TenantError;
                statusMessage = "Application user is not created! Please create the application user for Work 365.";

                break;
            case tenant.isWork365Initialized && tenant.isTenantInitialized:
                statusBarComponent = <TenantBarStatusIndicator styles={tenantReadyStyle}/>
                tenantStatus = TenantStatus.TenantIsReady;
                statusMessage = "Tenant is ready for operations!"

                break;
        }

        tenant.statusBarComponent = statusBarComponent;
        tenant.status = tenantStatus;
        tenant.statusMessage = statusMessage;
    }

    function renderTenant(tenant: IsTenantReady) {
        return (
            <TenantCard tenant={tenant} handleSubmit={handleSubmit} selectedTenant={selectedTenant}/>
        )
    }

    tenants.forEach(initializeTenant);

    let columnNumber = 0;
    const sortedTenants = getSortedTenants(tenants);

    var work365Tiles: Work365Tile[] = sortedTenants.map((tenant) => {

        columnNumber++;

        if (columnNumber === 7){
            columnNumber = 1;
        }

        return {
            body: renderTenant(tenant),
            col: columnNumber,
            colSpan: 1,
            rowSpan: 1
        }
    });

    return (
        <Work365TileLayout
            columns={6}
            rowHeight={150}
            rows={12}
            rowGap={6}
            columnsGap={6}
            tiles={work365Tiles} />
    )
}

export default TenantGrid;