import React from 'react';
import { Chart as ChartJS, CategoryScale, Tooltip, Legend, LinearScale, BarElement, Title, ChartData, ChartTypeRegistry, Point, BubbleDataPoint } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface IBarChartData {
    labels: string[];
    datasets: {
        label?: string | undefined;
        backgroundColor?: string | undefined;
        borderColor?: string | undefined;
        borderWidth?: number | undefined;
        data: number[];
    }[]
}

export interface IWork365BarChartProps {
    options?: any | undefined;
    data?: any | IBarChartData | undefined;
    style?: React.CSSProperties | undefined;
}

const Work365BarChart: React.FC<IWork365BarChartProps> = (props: IWork365BarChartProps) => {
    return <Bar options={props.options} data={props.data} style={props.style} />;
}

export default Work365BarChart;