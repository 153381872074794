import * as React from "react";
import {
    Drawer,
    DrawerContent,
    DrawerItem,
    DrawerItemHandle,
    DrawerItemProps,
    DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { WithRouter } from "../../../Helpers/WithRouter";

export interface IDrawerItem {
    text: string;
    pathName: string;
    selected: boolean;
    content?: JSX.Element | undefined;
}

export interface IDrawerContainerProps {
    items: IDrawerItem[];
    children: JSX.Element | undefined;
    onSelect?: ((event: DrawerSelectEvent) => void) | undefined;
    width?: number | undefined;
    style?: React.CSSProperties;
    className?: string | undefined;
}

const CustomItem = (props: JSX.IntrinsicAttributes & Pick<DrawerItemProps, keyof DrawerItemProps> & React.RefAttributes<DrawerItemHandle | null>) => {
    return (
        <DrawerItem {...props} style={props.style}>
            <div>{props.icon} {props.text}</div>
        </DrawerItem>
    );
};
const DrawerContainer = (props: IDrawerContainerProps) => {
    let width = 175;

    const [expanded, setExpanded] = React.useState(true);
    const handleClick = () => {
        setExpanded((prevState) => !prevState);
    };

    if (props.width != undefined && props.width != 0) {
        width = props.width
    }

    return (
        <Drawer
        expanded={expanded}
        mode="push"
        mini={true}
        width={width}
        items={props.items}
        item={CustomItem}
        onSelect={props.onSelect}
        className={props.className}
        >
            <DrawerContent style={{height: '100%'}}>{props.children}</DrawerContent>
        </Drawer>
    );
};
export default WithRouter(DrawerContainer);