import { Grid, GridColumn, GridDetailRowProps } from "@progress/kendo-react-grid";
import { Component } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { process } from "@progress/kendo-data-query";
import { Size } from "../Models/Size";
import { Work365Column } from "../Models/Work365Column";
import React from "react";

const dataState = {
    skip: 0,
    take: 50
}

const DATE_FORMAT = '{0:EEEE, MMMM d, y}'

interface Work365GridProps {
    data: unknown[] | undefined;
    style?: Size;
    detailComponent?: React.ComponentType<GridDetailRowProps> | null | undefined;
    columns?: Work365Column[] | undefined;
}

export default class Work365Grid extends Component<Work365GridProps> {
    locales = [{
        language: 'en-US',
        locale: 'en'
    }, {
        language: 'es-ES',
        locale: 'es'
    }];

    constructor(props: Work365GridProps) {
        super(props);
    }

    expandChange = (event: any) => {
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        this.setState({ ...this.state });
    };

    getColumn(column: Work365Column) {
        if (column == undefined) {
            return;
        }

        if (column.filter == 'date' && column.format == undefined) {
            column.format = DATE_FORMAT;

            if (column.width == undefined) {
                column.width = 235;
            }
        }

        return <GridColumn field={column.field} title={column.title} width={column.width} filter={column.filter} format={column.format} />
    }

    render() {

        return (
            <Grid
                sortable={false}
                filterable={false}
                groupable={false}
                reorderable={false}
                pageable={false}
                data={this.props.data}
                detail={this.props.detailComponent}
                expandField="expanded"
                onExpandChange={this.expandChange
                }>

                {this.props.columns != null ? this.props.columns.map((column: any) => (
                    this.getColumn(column)
                )) : undefined}

            </Grid>
        )
    }
}