import React from 'react';
import { Component, useState } from 'react';
import Work365DonutChart from '../../../Lib/Core/Work365DonutChart';
import Work365HorizontalBarChart from '../../../Lib/Core/Work365HorizontalLineChart';
import Work365StackedBarChart from '../../../Lib/Core/Work365StackedBarChart';
import Work365TileLayout from '../../../Lib/Core/Work365TileLayout';
import { Work365Tile } from '../../../Lib/Models/Work365Tile';

const servicesUsage = [{
  category: 'SQL Database',
  value: 380.22
}, {
  category: 'Virtual Machines',
  value: 218.82
}, {
  category: 'Azure App Service',
  value: 135.53
}, {
  category: 'Storage',
  value: 90.71
}];

const locationUsage = [{
  category: 'US West',
  value: 453.32
}, {
  category: 'US East',
  value: 423.42
}, {
  category: 'Europe',
  value: 567.64
}, {
  category: 'new zealand',
  value: 32.74
}];

const resouceUsage = [{
  category: 'rg-iotap-prod',
  value: 600.40
}, {
  category: 'rg-work365-eu01',
  value: 165.66
}, {
  category: 'rg-work365-uat',
  value: 69.71
}, {
  category: 'rg-iotap-prod-asr',
  value: 17.85
}];

const invoicedUsageSummaries = [{
  category: '2021-06',
  value: 600.40
}, {
  category: '2021-07',
  value: 580.45
}, {
  category: '2021-08',
  value: 890.54
}, {
  category: '2021-09',
  value: 365.54
}, {
  category: '2021-10',
  value: 754.41
}, {
  category: '2021-11',
  value: 546.51
}, {
  category: '2021-12',
  value: 587.52
}, {
  category: '2022-01',
  value: 602.24
}, {
  category: '2022-02',
  value: 650.54
}, {
  category: '2022-03',
  value: 680.54
}, {
  category: '2022-04',
  value: 700.54
}, {
  category: '2022-05',
  value: 745.54
}, {
  category: '2022-06',
  value: 424.54
}];

const uninvoicedUsageSummaries = [
  {
    category: '2022-06',
    value: 450
  }
]

const customers = [
  'Businesszilla',
  'Syndicate Business',
  'Intelligence Business',
  'Mind Business'
]

const usageDataForCustomers = [
  791,
  520,
  400,
  326,
]

const labelContent = (e: any) => `${e.category}: \n $${e.value}`;

const renderTooltipForDonut = (context: any) => {
  const { category, series, value } = context.point || context;
  return (
    <div>
      {category} ({series.name}): ${value}
    </div>
  );
};

const renderTooltip = (context: any) => {
  const { category, series, value } = context.point || context;
  return (
    <div>
      {category} : ${value}
    </div>
  );
};

interface tileSettings {
  col: number;
  colSpan: number;
  rowSpan: number;
}

export default class UsageCharts extends Component {
  render() {
    var tiles: Work365Tile[] = [{
      header: <>'Usage Summaries by Customer'</>,
      col: 1,
      colSpan: 6,
      rowSpan: 2,
      body: <Work365HorizontalBarChart
        title='Usage Summaries by Customer'
        categories={customers}
        axisTitle='Customers'
        tooltipRender={renderTooltip}
        data={usageDataForCustomers} />
    }, {
      header: <>'Usage Summaries'</>,
      col: 7,
      colSpan: 6,
      rowSpan: 2,
      body: <Work365StackedBarChart
        title='Usage Summaries'
        invoicedUsageSummaries={invoicedUsageSummaries}
        uninvoicedUsageSummaries={uninvoicedUsageSummaries}
        tooltipRender={renderTooltip} />
    }, {
      header: <>'Service Name'</>,
      col: 1,
      colSpan: 4,
      rowSpan: 2,
      body: <Work365DonutChart
        title='Service Name'
        tooltipRender={renderTooltipForDonut}
        name='Usage Summaries'
        data={servicesUsage}
        field='value'
        categoryField='category'
        labelContent={labelContent}
        chartLegendVisible={true}
        showChartLabels={false} />
    }, {
      header: <>'Location'</>,
      col: 5,
      colSpan: 4,
      rowSpan: 2,
      body: <Work365DonutChart
        title='Location'
        tooltipRender={renderTooltipForDonut}
        name='Usage Summaries'
        data={locationUsage}
        field='value'
        categoryField='category'
        labelContent={labelContent}
        chartLegendVisible={true}
        showChartLabels={false} />
    }, {
      header: <>'Resource Group Name'</>,
      col: 9,
      colSpan: 4,
      rowSpan: 2,
      body: <Work365DonutChart
        title='Resource Group Name'
        tooltipRender={renderTooltipForDonut}
        name='Usage Summaries'
        data={resouceUsage}
        field='value'
        categoryField='category'
        labelContent={labelContent}
        chartLegendVisible={true}
        showChartLabels={false} />
    }];

    return (
      <Work365TileLayout
        columns={12}
        rowHeight={255}
        tiles={tiles}
        rows={2}
        rowGap={12}
        columnsGap={12} />
    )
  }
}