import React from "react";

interface IWork365InputDisabledProps {
    value?: string | any | undefined;
    link: string | undefined;
    onIconClick?: ((event: any) => void) | undefined;
}

const Work365InputLink: React.FC<IWork365InputDisabledProps> = (props: IWork365InputDisabledProps) => {
    return (
        <div className='k-input k-input-md k-rounded-md k-input-solid' style={{ padding: '6px', cursor: 'pointer',height:'2.45em' }}>
            <a href={props.link} target='_blank' style={{ cursor: 'pointer' }}>{props.value}</a>
            <a href='#' style={{ position: 'absolute', right: '0', paddingRight: '6px' }} onClick={props.onIconClick}>
                <i className='fa fa-edit icon'></i>
            </a>
        </div>)
}

export default Work365InputLink