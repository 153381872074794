import React, { Suspense } from 'react';
import IAppProps from './IAppProps';
import IAppState from './IAppState';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Work365Routes, Work365Route } from '../../Helpers/Routes';
import NavbarOnlyLayout from '../../Layouts/NavbarOnlyLayout';
import Login from '../../Views/Login';

import 'Project-Everest/dist/css/project-everest.css';

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
  }

  invokeSignInEvent = () => {
    this.props.authServiceInstance.SignIn();
  };

  invokeSignOutEvent = () => {
    this.props.authServiceInstance.SignOut();
  };

  render() {
    if (this.props.authServiceInstance.account) {
      return (
        <BrowserRouter>
          <Suspense fallback={<p>Loading....</p>}>
            <Routes>
              {Work365Routes.map((route, i) => {
                // Route is protected, we want to make sure we expose the route as protected.
                if (route.isProtected) {
                  return <>{getRouteWithChildren(route)}</>;
                }

                // Route is unprotected, we want to make sure we expose the route as unprotected
                return getRouteWithChildren(route);
              })}
            </Routes>
          </Suspense>
        </BrowserRouter>
      );
    } else {
      return (
        <NavbarOnlyLayout>
          <Login />
        </NavbarOnlyLayout>
      );
    }
  }
}

function getRouteWithChildren(route: Work365Route): React.ReactElement {
  if (route.children && route.children.length > 0) {
    return (
      <Route
        key={route.path}
        element={
          <route.layout>
            <route.component />
          </route.layout>
        }
        path={route.path}
      >
        {route.children.map((childRoute: Work365Route) => {
          return (
            <Route
              key={childRoute.path}
              element={
                <childRoute.layout>
                  <childRoute.component />
                </childRoute.layout>
              }
              path={childRoute.path}
            />
          );
        })}
      </Route>
    );
  }

  return (
    <Route
      key={route.path}
      element={
        <route.layout>
          <route.component />
        </route.layout>
      }
      path={route.path}
    />
  );
}

export default App;
