import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import { Work365DatePicker } from '../../Lib/Core/Work365DatePicker';


type ArchiveItemsProps = {
    tenantId: string | undefined;
};

const ArchiveItems: React.FC<ArchiveItemsProps> = ({ tenantId }) => {

    const operationType = 7;

    const [operationId, setOperationId] = useState();
    const [inProgress, setInProgress] = useState(false); 
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    let intervalNumber: any = undefined;
  
    const checkProgress = () => {
      Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/get/${operationId}`, 'GET', undefined, false)
        .then((response: any) => {
          if (response.data != undefined){
            setProgress(response.data.progressPercent);
  
            if (response.data.operationStatus != 'P'){
              clearInterval(intervalNumber);
              setCompleted(true);
  
              intervalNumber = undefined;
            }
          }
        });
    };
  
    const onDateChange = (event: any) => {
        saveAdditionalParameters(event);
    };

    function saveAdditionalParameters(event: any) {
        let body = {
            ArchiveDate: event.value
        };
  
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/${operationId}/saveadditionalparameters/${JSON.stringify(body)}`, 'POST')
            .then((response: any) => {
  
        });
    }


    function handleNewOperation() {
      setTimeout(function () {
        getOperation();
      }, 5000);
    }
  
    useEffect(() => {
      getOperation();
  
        return () => {
          if (intervalNumber != undefined) {
            clearInterval(intervalNumber);
            intervalNumber = undefined;
          }
        };
    }, []); 
  
    function getOperation() {
        let operationContext = {
          operationType: operationType
        };
    
        Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
        .then((response: any) => {
          if (response.data == undefined){
            Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
            .then((response: any) => {
              setOperationId(response.data.operationId);
            });
          }
          else {
            setOperationId(response.data.operationId);
  
            if (response.data.operationStatus == 'P'){
              setInProgress(true);
              setProgress(response.data.progressPercent);
    
              if (intervalNumber != undefined) {
                clearInterval(intervalNumber);
                intervalNumber = undefined;
              }
          
              intervalNumber = setInterval(checkProgress, 5000);
            }
          }
        });
    }  

    return (
        <div style={{marginTop: '8px'}}>
            {inProgress && (
                <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
            )}

            <p>All LCL, SLCL, NRI, TimeLogs, Usage Summary, Usage Detail items whose Archive date is less then Selected Archive Date, will be updated as In-Active, Please verify before submitting</p>
            <Work365DatePicker onChange={onDateChange}/>
            
            <Work365SubmitPanel
                operationId={operationId} 
                tenantId={tenantId}
                handleNewOperation={handleNewOperation} />
        </div>
    );
}

export default ArchiveItems;