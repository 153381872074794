import React from 'react';
import { Outlet } from 'react-router-dom';
import Work365LoadingIndicator from '../Lib/Common/Work365LoadingIndicator';
import Work365Notifications from '../Lib/Core/Notifications/Work365Notification';

const OutletLayout: React.FC<any> = ({children}) => {
    return (
        <div>
            <Work365LoadingIndicator />
            <Work365Notifications />
            <Outlet />
            {children}
        </div>
    )
}

export default OutletLayout;