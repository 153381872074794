import React, { useEffect } from 'react';
import Work365PrimaryButton from '../Lib/Core/Buttons/Work365PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ViewStyles/PageNotFound.Styles.css'


const PageNotFound: React.FC = () => {
    const navigate = useNavigate();
    let { i18n, t } = useTranslation(['404']);

    useEffect(() => { 
        var errorMessage = `${t('message')}`;
        navigate(`Error/${errorMessage}`);
    }, []);

    return  <div className="error-container"></div>
}
export default PageNotFound;