import React, { useEffect, useState } from 'react';
import Work365Title from '../../Lib/Core/Work365Title';
import Work365Label from '../../Lib/Core/Work365Label';
import { Global } from '../../Helpers/Global';
import Work365RadioButton from '../../Lib/Core/RadioButton/Work365RadioButton';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import CustomerSyncGrid from './CustomerSyncGrid';

type CustomerSyncProps = {
    tenantId?: string;
};

const CustomerSync: React.FC<CustomerSyncProps> = ({
    tenantId
}) => {
    const [providers, setProviders] = useState<any[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<any>();

    const [providerVerifySuccessful, setProviderVerifySuccessful] = useState(false);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    const shouldRender = providers && providers.length > 0;

    useEffect(() => {
        Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/get`, 'GET').then((response: any) => {
            setProviders(response.data);
        });
    }, [])

    return (
        <div>
            { providerVerifySuccessful 
            ?      
                <CustomerSyncGrid tenantId={tenantId} providerId={selectedProvider?.connectorId} />
            :                 
            <div>
                <Work365Title title='Customer Sync Operation'/>
                <Work365Label>The Import Customer Data operation will automatically sync provider accounts, create CRM accounts / match existing accounts, create contacts and agreements.</Work365Label>
                <br />
                <Work365Label>Please select a provider below.</Work365Label>
                <br />
                <div style={{backgroundColor: 'lightgray', width: '600px'}}>
                {shouldRender ? (
                    providers.map((provider, index) => (
                        <div>
                            <Work365RadioButton 
                                name={provider.name} 
                                value={provider.name} 
                                checked={selectedProvider != undefined && selectedProvider?.connectorId === provider.connectorId} 
                                label={provider.name}
                                onChange={(value) => {
                                    setSelectedProvider(provider);
                                }} />
                        </div>
                    ))) : (
                        <div>No providers are available</div>
                    )
                }
                </div>
                <Work365PrimaryButton title='Continue' style={{marginTop: '4px'}} onClick={(event) => {
                    Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/verify/${selectedProvider.connectorId}`, 'GET').then((response: any) => {
                        if (response.data){
                            setProviderVerifySuccessful(true);
                        }
                    });
                }} />
            </div>}
        </div>
    );
}

export default CustomerSync;