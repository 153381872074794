import { DatePicker } from "@progress/kendo-react-dateinputs";

export type Work365DatePickerProps ={
    onChange?: ((event: any) => void) | undefined
}

export const Work365DatePicker = (props: Work365DatePickerProps) => {
    return (
        <DatePicker placeholder="Choose a date..." onChange={props.onChange} />
    );
}