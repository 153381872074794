import React from 'react';
import { IsTenantReady } from '../../Lib/Models/IsTenantReady';
import 'font-awesome/css/font-awesome.min.css';
import { TenantStatus } from '../../Lib/Models/TenantStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';


interface TenantStatusIndicatorProps {
    tenant: IsTenantReady;
    isLoading: boolean;
    isInactive: boolean;
    isError: boolean;
    statusMessage?: string | undefined;
}

const TenantStatusIndicator: React.FC<TenantStatusIndicatorProps> = ({tenant, isLoading, isInactive, isError, statusMessage}) => {
    let colorStatus = 'warning';
    let icon = faSpinner;
    let pulse = false;

    if (isLoading){
        colorStatus = 'warning';
        pulse = true;
    }
    else if (isInactive){
        colorStatus = 'notinitialized';
        icon = faServer;
    }
    else if (isError){
        colorStatus = 'error';
        icon = faX;
    }
    else{
        colorStatus = 'success';
        icon = faCheck;
    }
    
    return (
        <span 
            title={statusMessage} 
            style={{float: 'right', cursor: 'default'}} 
            className={`c-text ${colorStatus}`}
        >
            <FontAwesomeIcon icon={icon} pulse={pulse} />
        </span>
    )
}

export default TenantStatusIndicator;