import React from 'react';
import { Global } from '../../Helpers/Global';
import { Col, Container, Row } from 'react-bootstrap';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { getter } from '@progress/kendo-react-common';
import { useNavigate } from 'react-router-dom';
import Work365LoadingIndicator from '../../Lib/Common/Work365LoadingIndicator';

interface UserProfileProps {
    Partner: JSON,
    Contact: | JSON
}

const firstNameGetter = getter('FirstName');
const lastNameGetter = getter('LastName');
const companyNameGetter = getter('CompanyName');
const websiteGetter = getter('Website');
const AddressLine1Getter = getter('AddressLine1');
const CityGetter = getter('City');
const CountryGetter = getter('Country');


const UserProfileValidator = (values: any) => {

    if (firstNameGetter(values) &&
        lastNameGetter(values) &&
        companyNameGetter(values) &&
        websiteGetter(values) &&
        AddressLine1Getter(values) &&
        CityGetter(values) &&
        CountryGetter(values)
    ) {
        return;
    }

    return {
        VALIDATION_SUMMARY: 'Please fill out the following fields.',
        ['FirstName']: 'Please enter a First name.',
        ['LastName']: 'Please enter a Last Name.',
        ['CompanyName']: 'Please enter a Company Name.',
        ['Website']: 'Please enter a valid email address.',
        ['AddressLine1']: 'Please enter a valid Address 1.',
        ['City']: 'Please enter a valid city.',
        ['Country']: 'Please enter a valid Country.',
    };
};


const UserProfile: React.FC = () => {
    return (<Form validator={UserProfileValidator} render={formRenderProps => <FormElement style={{

    }}>
        <Work365LoadingIndicator />
        <Container fluid>
            <legend className={'k-form-legend'}>Please fill in the following information:</legend>
            <Row>{formRenderProps.visited && formRenderProps.errors && formRenderProps.errors.VALIDATION_SUMMARY && <div className={'k-messagebox k-messagebox-error'}>{formRenderProps.errors.VALIDATION_SUMMARY}</div>}</Row>

            <Row style={{ fontWeight: 'bold', paddingTop: '20px', paddingLeft: '20px' }}>Company</Row>
            <Row>
                <Col md={2}><Field name={'CompanyName'} component={Input} label={'Name'} /></Col>
                <Col md={2}><Field name={'ContactEmail'} component={Input} label={'Contact Email'} /></Col>
                <Col md={2}><Field name={'Phone'} component={Input} label={'Phone'} /></Col>

            </Row>
            <Row>
                <Col md={2}><Field name={'Website'} component={Input} label={'Website'} /></Col>
                <Col md={2}><Field name={'TimeZone'} component={Input} label={'Time Zone'} /></Col>
            </Row>
            <Row className='row' style={{ fontWeight: 'bold', paddingTop: '20px', paddingLeft: '20px' }}>Company</Row>
            <Row>
                <Col md={2}><Field name={'AddressLine1'} component={Input} label={'Address Line 1'} /></Col>
                <Col md={2}><Field name={'AddressLine2'} component={Input} label={'Address Line 2'} /></Col>
                <Col md={2}><Field name={'City'} component={Input} label={'City'} /></Col>

            </Row>

            <Row>
                <Col md={2}><Field name={'State'} component={Input} label={'State'} /></Col>
                <Col md={2}><Field name={'Country'} component={Input} label={'Country'} /></Col>
                <Col md={2}><Field name={'Zip'} component={Input} label={'Zip'} /></Col>
            </Row>

            <Row className='row' style={{ fontWeight: 'bold', paddingTop: '20px', paddingLeft: '20px' }}>User</Row>
            <Row>
                <Col md={2}><Field name={'FirstName'}   component={Input} label={'First name'} /></Col>
                <Col md={2}><Field name={'MiddleName'} component={Input} label={'Middle name'} /></Col>
                <Col md={2}><Field name={'LastName'} component={Input} label={'Last name'} /></Col>
            </Row>

        </Container>


        <button type={'submit'} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>
            Submit
        </button>
    </FormElement >} />);

}

export default UserProfile;
