import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface IPieChartData {
    labels: string[];
    datasets: {
        label?: string | undefined;
        backgroundColor?: string | undefined;
        borderColor?: string | undefined;
        borderWidth?: number | undefined;
        data: number[];
    }[]
}

export interface IWork365PieChartProps {
    options?: any | undefined;
    data?: any | IPieChartData | undefined;
    style?: React.CSSProperties | undefined;
    onClick?: (event: any) => void | undefined;
    ref?: any | undefined;
}

const Work365PieChart: React.FC<IWork365PieChartProps> = (props: IWork365PieChartProps) => {
    return <Pie
        ref={props.ref}
        data={props.data}
        style={props.style}
        options={props.options}
        onClickCapture={props.onClick}
    />
}

export default Work365PieChart;