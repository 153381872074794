import * as React from 'react';
import {
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
} from '@progress/kendo-react-grid';

export const Work365ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props}/>
      <GridColumnMenuFilter  {...props} expanded={true}/>
    </div>
  );
};