import React from 'react';

enum ChangeType {
    FEATURE = 'New Features',
    MODIFICATION = 'Modifications',
    BUG_FIX = 'Bug Fixes',
    BACKEND = 'Backend Changes'
  }

interface Change {
    description: string;
    timestamp: string;
    type: ChangeType;
}

const ChangeLog: React.FC = () => {

    const changes: Change[] = [
        {
            description: 'Added ChangeLogs view to the Manage App.',
            timestamp: '2023-May-02',
            type: ChangeType.FEATURE
        },
        {
            description: 'Made the Manage Tenant page load faster, moved the check server side. Client Side still needs to be developed.',
            timestamp: '2023-May-02',
            type: ChangeType.BUG_FIX
        },
        {
            description: 'Modified the Manage Tenants page UI to load the tenant status after it renders on the UI.',
            timestamp: '2023-May-03',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Changed the API client to allow sending requests to the API without having the loading promise dialog visible. This was used to make a request to the API on the manage tenants page. This still needs to be structured correctly, and I think with the API rework we need to rework how we are getting our API service.',
            timestamp: '2023-May-03',
            type: ChangeType.BACKEND
        },
        {
            description: 'Add new page for setup and Install where user can install CRM, Portal solution and create providers. Only UI is ready and not internal operation',
            timestamp: '2023-May-04',
            type: ChangeType.BACKEND
        },
        {
            description: 'Added a Work 365 Arc Gauge for the manage tenants page.',
            timestamp: '2023-May-04',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added a Work 365 Radial Gauge for the manage tenants page.',
            timestamp: '2023-May-04',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added base components to the Manage Tenants page.',
            timestamp: '2023-May-04',
            type: ChangeType.FEATURE
        },
        {
            description: 'Fixed the Kendo gauge component to make it look better on the manage tenants page.',
            timestamp: '2023-May-05',
            type: ChangeType.BUG_FIX
        },
        {
            description: 'Modified the Toggle button styles to make it look better.',
            timestamp: '2023-May-05',
            type: ChangeType.BUG_FIX
        },
        {
            description: 'Modified the UI on the Manage Tenants page. Most of the UI is now completed.',
            timestamp: '2023-May-05',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Started implementation on getting connectors using Entity Framework.',
            timestamp: '2023-May-05',
            type: ChangeType.BACKEND
        },
        {
            description: 'Created a Switch component, currently in use on the manage tenants page.',
            timestamp: '2023-May-05',
            type: ChangeType.FEATURE
        },
        {
            description: 'Created a Tab Strip and the respective Tab component, currently in use on the manage tenants page.',
            timestamp: '2023-May-05',
            type: ChangeType.FEATURE
        },
        {
            description: 'Created a Work 365 title label currently in use on the manage tenants page.',
            timestamp: '2023-May-05',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added a new Tenant Card for tenants that does not exist or is not setup currently.',
            timestamp: '2023-May-08',
            type: ChangeType.FEATURE
        },
        {
            description: 'Add tenants view as table grouped customer ID.',
            timestamp: '2023-May-08',
            type: ChangeType.FEATURE
        },
        {
            description: 'Add tenants details',
            timestamp: '2023-May-12',
            type: ChangeType.FEATURE
        },
        {
            description: 'Changed the project structure to be more simplified',
            timestamp: '2023-May-19',
            type: ChangeType.BACKEND
        },
        {
            description: 'Change UI for customer view',
            timestamp: '2023-May-17',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Refactored the new API. Currently not in use, since we need to setup the deployment pipeline.',
            timestamp: '2023-May-16',
            type: ChangeType.BACKEND
        },
        {
            description: 'Added the Import CRM Solution Operation.',
            timestamp: '2023-Jun-28',
            type: ChangeType.FEATURE
        },
        {
            description: 'Updated the Import CRM Solution Operation to handle Tenant Upgrades.',
            timestamp: '2023-Jul-5',
            type: ChangeType.FEATURE
        },
        {
            description: 'Updated the Import CRM Solution Operation to handle unit configuration.',
            timestamp: '2023-Jul-5',
            type: ChangeType.FEATURE
        },
        {
            description: 'Updated our dynamics framework to improve the development experience.',
            timestamp: '2023-Jul-6',
            type: ChangeType.BACKEND
        },
        {
            description: 'Modified the Manage Tenants page to view updated Subscriptions, Billing Contracts, Accounting Systems, Providers, and License Change Logs count.',
            timestamp: '2023-Jul-6',
            type: ChangeType.FEATURE
        },
        {
            description: 'Fixed overflow issues with Kendo Drawer Component',
            timestamp: '2023-Jul-7',
            type: ChangeType.BUG_FIX
        },
        {
            description: 'Fixed and changed the Navbar UI',
            timestamp: '2023-Jul-7',
            type: ChangeType.BUG_FIX
        },
        {
            description: 'Started implementation of the Chart.JS Gauge chart',
            timestamp: '2023-Jul-7',
            type: ChangeType.FEATURE
        },
        {
            description: 'Updated Kendo Gauge Charts to update the width and height on page resize. Currently there is an issue with full screen updates and Im currently working with Kendo to fix that.',
            timestamp: '2023-Jul-11',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added Jobs to the Manage Tenants page',
            timestamp: '2023-Jul-12',
            type: ChangeType.FEATURE
        },
        {
            description: 'Updated the Invoices graph on the Manage Tenants page to be live',
            timestamp: '2023-Jul-12',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added the Create Provider operation. Got it working end to end.',
            timestamp: '2023-Jul-17',
            type: ChangeType.FEATURE
        },
        {
            description: 'Released the GUI of the Sync Customers Operation. Backend functionality for selecting accounts is working currently working on the matching process.',
            timestamp: '2023-Jul-18',
            type: ChangeType.FEATURE
        },
        {
            description: 'Changed the Billing Contract Template form.',
            timestamp: '2023-Jul-21',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Changed the GUI of the Customer Sync Operation.',
            timestamp: '2023-Jul-21',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Changeed the Create Provider Operation form.',
            timestamp: '2023-Jul-21',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Modified the GUI of all of the operations for the Operations UI change.',
            timestamp: '2023-Jul-25',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Finished the GUI modification to the Operations tab.',
            timestamp: '2023-Jul-25',
            type: ChangeType.MODIFICATION
        },
        {
            description: 'Added the Autocomplete textbox to the Customer Sync Operation.',
            timestamp: '2023-Jul-27',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added saving capabilities to the Customer Sync Operation.',
            timestamp: '2023-Jul-27',
            type: ChangeType.FEATURE
        },
        {
            description: 'Added the Matching capabilities to the Customer Sync Operation.',
            timestamp: '2023-Jul-27',
            type: ChangeType.FEATURE
        },
        {
            description: 'Modified the API to handle the matching capabilities. Also modified the client app to handle this but it isnt in a completed state.',
            timestamp: '2023-Jul-26',
            type: ChangeType.FEATURE
        }
    ]

    const groupedChanges: Record<string, Record<string, Change[]>> = changes.reduce((acc, change) => {
        const timestamp = change.timestamp;
        const type = change.type;
        if (!acc[timestamp]) {
          acc[timestamp] = {};
        }
        if (!acc[timestamp][type]) {
          acc[timestamp][type] = [];
        }
        acc[timestamp][type].push(change);
        return acc;
      }, {} as Record<string, Record<string, Change[]>>);
      
    return  <div style={{padding: '6px'}} className='mt-2'>
    <h3>Change Log</h3>
    {Object.entries(groupedChanges).map(([timestamp, typeGroups]) => (
      <div key={timestamp}>
        <h5>{timestamp}</h5>
        <div className="ml-4">
            {Object.entries(typeGroups).map(([type, changes]) => (
                <div>
                    <h5>{type}</h5>
                    <ul>
                        {changes.map((change) => (
                            <li>
                                {change.description}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
      </div>
    ))}
  </div>
}
export default ChangeLog;