import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Support, Administrator, Manager, JobController } from '../Lib/Models/Roles';

import { faBook, faCloud, faHome } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import PageNotFound from '../Views/PageNotFound';
import TenantHomePage from '../Views/Tenant/TenantHomePage';
import TenantNotFound from '../Views/TenantPageNotFound';
import TenantPageNotFound from '../Views/TenantPageNotFound';
import RedirectToTenantHome from '../Views/Tenant/RedirectToTenantHome';
import TenantBillingContracts from '../Views/Tenant/TenantBillingContracts';
import TenantCustomers from '../Views/Tenant/TenantCustomers';
import SwitchTenants from '../Views/Tenant/SwitchTenants';
import Index from '../Views/Index';

import DefaultLayout from '../Layouts/DefaultLayout';
import HiddenLayout from '../Layouts/HiddenLayout';
import OutletLayout from '../Layouts/OutletLayout';
import BlankLayout from '../Layouts/BlankLayout';
import NavbarOnlyLayout from '../Layouts/NavbarOnlyLayout';
import TestPage from '../Views/Tenant/TestPage';
import UserProfile from '../Views/Tenant/UserProfile';
import ProfileComplete from '../Views/ProfileComplete';
import ManageTenants from '../Views/NewTenant/ManageTenants';
import Account from '../Views/NewTenant/Account';
import Groups from '../Views/NewTenant/Groups';
import InvoiceTemplates from '../Views/NewTenant/InvoiceTemplates';
import EmailTemplates from '../Views/NewTenant/EmailTemplates';
import BillingContractTemplates from '../Views/NewTenant/BillingContractTemplates';
import Customers from '../Views/NewTenant/Customers';
import Tenants from '../Views/NewTenant/Tenants';
import AzureADConsent from '../Views/NewTenant/AzureADConsent';
import Tenant from '../Views/NewTenant/Tenant';
import Users from '../Views/NewTenant/Users';
import Error from '../Views/Error';
import ChangeLog from '../Views/Internal/ChangeLog';
import SetupInstall from '../Views/Tenant/SetupInstall';
import ImportCRMSolution from '../Views/Tenant/ImportCRMSolution';
import CreateProvider from '../Views/NewTenant/CreateProvider';
import Consent from '../Views/NewTenant/Consent'
import CustomerSync from '../Views/NewTenant/CustomerSync';
import CustomerSyncGrid from '../Views/NewTenant/CustomerSyncGrid';
import OperationLogs from '../Views/NewTenant/OperationLogs';
import RegisterNewTenant from '../Views/NewTenant/RegisterNewTenant';
import InternalUsers from '../Views/NewTenant/InternalUsers';

import LicensingHome from '../Views/Licensing/Home';
import ReportHome from '../Views/Reports/ReportHome'

const Pages = {
  PageNotFound: PageNotFound,
  TenantNotFound: TenantNotFound,
  TenantPageNotFound: TenantPageNotFound,
  TenantHomePage: TenantHomePage,
  RedirectToTenantHome: RedirectToTenantHome,
  BillingContracts: TenantBillingContracts,
  TenantCustomers: TenantCustomers,
  SwitchTenants: SwitchTenants,
  UserProfile: UserProfile,
  TestPage: TestPage,
  Index: Index,
  ProfileComplete: ProfileComplete,

  ManageTenants: ManageTenants,
  Account: Account,
  Groups: Groups,
  InvoiceTemplates: InvoiceTemplates,
  EmailTemplates: EmailTemplates,
  BillingContractTemplates: BillingContractTemplates,
  Customers: Customers,
  Tenants: Tenants,
  AzureADConsent: AzureADConsent,
  Tenant: Tenant,
  Users: Users,
  Error: Error,
  ChangeLog: ChangeLog,
  SetupInstall: SetupInstall,
  ImportCRMSolution: ImportCRMSolution,
  LicensingHome: LicensingHome,
  CreateProvider: CreateProvider,
  Consent: Consent,
  CustomerSync: CustomerSync,
  CustomerSyncGrid: CustomerSyncGrid,
  OperationLogs: OperationLogs,
  RegisterNewTenant: RegisterNewTenant,
  InternalUsers: InternalUsers,
  ReportHome: ReportHome,
};

export const Layouts = {
  DefaultLayout: DefaultLayout,
  HiddenLayout: HiddenLayout,
  OutletLayout: OutletLayout,
  BlankLayout: BlankLayout,
  NavbarOnlyLayout: NavbarOnlyLayout,
};

export interface Work365Route {
  path: string;
  name?: string;
  isProtected?: boolean;
  isAdminApp?: boolean;
  component: React.FC<any>;
  permission?: string;
  layout: React.FC<any>;
  children?: Work365Route[];
  showOnNavBar: boolean;
  navbarName?: string;
  navbarIcon?: IconProp;
  element?: string;
}

export const Work365Routes: Work365Route[] = [
  {
    path: '/ManageEnvironments',
    name: 'Manage Environments',
    isProtected: true,
    isAdminApp: false,
    component: Pages.ManageTenants,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Manage Environments',
    navbarIcon: faHome,
  },
  {
    path: '/Environment/:tenantUrl/register',
    name: 'Register',
    isProtected: true,
    isAdminApp: false,
    component: Pages.RegisterNewTenant,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Customers',
    name: 'Customers',
    isProtected: true,
    isAdminApp: true,
    permission: Support,
    component: Pages.Customers,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Customers',
    navbarIcon: faServer,
  },
  {
    path: '/Customer/Environments/:customerId',
    name: 'Environments',
    isProtected: true,
    isAdminApp: true,
    permission: Support,
    component: Pages.Tenants,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
    navbarName: 'Environments',
    navbarIcon: faServer,
  },
  {
    path: '/Environment/all',
    name: 'Environments',
    isProtected: true,
    isAdminApp: true,
    permission: Support,
    component: Pages.Tenants,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Environments',
    navbarIcon: faServer,
  },
  {
    path: '/Environment/:tenantId',
    name: 'Environment',
    isProtected: true,
    isAdminApp: true,
    permission: Support,
    component: Pages.Tenant,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
  },
  {
    path: '/AzureADConsent',
    name: 'AzureADConsent',
    isProtected: true,
    isAdminApp: true,
    permission: Manager,
    component: Pages.AzureADConsent,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Azure AD Consent',
    navbarIcon: faServer,
  },
  {
    path: '/Environment/:environmentId/operations/importcrmsolution',
    name: 'Import CRM Solution',
    isProtected: true,
    isAdminApp: false,
    component: Pages.ImportCRMSolution,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Environment/:environmentId/operations/createprovider',
    name: 'Create Provider',
    isProtected: true,
    isAdminApp: false,
    component: Pages.CreateProvider,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Environment/:environmentId/consent/:providerId',
    name: 'Consent',
    isProtected: true,
    isAdminApp: false,
    component: Pages.Consent,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Environment/:environmentId/operations/customersync',
    name: 'Customer Sync',
    isProtected: true,
    isAdminApp: false,
    component: Pages.CustomerSync,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Environment/:environmentId/operations/customersyncgrid/:providerId',
    name: 'Customer Sync Grid',
    isProtected: true,
    isAdminApp: false,
    component: Pages.CustomerSyncGrid,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/Environment/:environmentId',
    name: 'Tenant',
    isProtected: true,
    isAdminApp: true,
    permission: Support,
    component: Pages.Tenant,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false
  },
  {
    path: '/InvoiceTemplates',
    name: 'Templates',
    isProtected: true,
    isAdminApp: false,
    component: Pages.InvoiceTemplates,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
    navbarName: 'Invoice Templates',
    navbarIcon: faFileInvoice,
  },
  {
    path: '/EmailTemplates',
    name: 'Templates',
    isProtected: true,
    isAdminApp: false,
    component: Pages.EmailTemplates,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
    navbarName: 'Email Templates',
    navbarIcon: faEnvelope,
  },
  {
    path: '/BillingContractTemplates',
    name: 'Templates',
    isProtected: true,
    isAdminApp: false,
    component: Pages.BillingContractTemplates,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Billing Contract Templates',
    navbarIcon: faFileContract,
  },
  {
    path: '/InternalUsers',
    name: 'Internal Users',
    isProtected: true,
    isAdminApp: true,
    permission: Manager,
    component: Pages.InternalUsers,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Internal Users',
    navbarIcon: faUsers
  },
  {
    path: '/Users',
    name: 'Users',
    isProtected: true,
    isAdminApp: false,
    component: Pages.Users,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
    navbarName: 'Users',
    navbarIcon: faUsers,
  },
  {
    path: '/Groups',
    name: 'Groups',
    isProtected: true,
    isAdminApp: false,
    component: Pages.Groups,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
    navbarName: 'Groups',
    navbarIcon: faCloud,
  },
  {
    path: '/Account',
    name: 'Account',
    isProtected: true,
    isAdminApp: false,
    component: Pages.Account,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Account',
    navbarIcon: faUser,
  },
  {
    path: '/Licensing',
    name: 'Plan',
    isProtected: true,
    isAdminApp: true,
    permission: Manager,
    component: Pages.LicensingHome,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Licensing',
    navbarIcon: faUserGroup,
  },
  {
    path: '/Reports',
    name: 'Reports',
    isProtected: true,
    isAdminApp: true,
    component: Pages.ReportHome,
    layout: Layouts.DefaultLayout,
    showOnNavBar: true,
    navbarName: 'Reports',
    navbarIcon: faBook,
  },
  {
    path: '/SwitchEnvironments',
    name: 'SwitchEnvironments',
    isProtected: false,
    isAdminApp: false,
    component: Pages.SwitchTenants,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    isProtected: false,
    isAdminApp: false,
    component: Pages.UserProfile,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/CompleteProfile',
    name: 'CompleteProfile',
    isProtected: false,
    isAdminApp: false,
    component: Pages.ProfileComplete,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/',
    name: 'Index',
    isProtected: false,
    isAdminApp: false,
    component: Pages.Index,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/Error/:errorMessage',
    name: 'Error',
    isProtected: false,
    isAdminApp: false,
    component: Pages.Error,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/ChangeLog',
    name: 'ChangeLog',
    isProtected: false,
    isAdminApp: false,
    component: Pages.ChangeLog,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
  {
    path: '/:id/SetupInstall',
    name: 'SetupInstall',
    isProtected: false,
    isAdminApp: false,
    component: Pages.SetupInstall,
    layout: Layouts.DefaultLayout,
    showOnNavBar: false,
  },
  {
    path: '*',
    name: 'NotFound',
    isProtected: false,
    isAdminApp: false,
    component: Pages.PageNotFound,
    layout: Layouts.NavbarOnlyLayout,
    showOnNavBar: false,
  },
];
