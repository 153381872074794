import React from 'react';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Work365LoadingIndicator from '../Lib/Common/Work365LoadingIndicator';
import EverestSidebar from '../Components/Common/EverestSidebar';
import Work365Notifications from '../Lib/Core/Notifications/Work365Notification';

const DefaultLayout: React.FC<any> = ({children}) => {
    return (
        <>
            <Work365LoadingIndicator />
            <Work365Notifications />
            <EverestSidebar >
                <div id='content'>
                    <div className='container-fluid page-body-wrapper'>
                        <div className='main-panel'>
                            <div className='content-wrapper'>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </EverestSidebar>
        </>
    )
}

export default DefaultLayout;