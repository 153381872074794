import ReactDOM from 'react-dom';
import './index.css';
import './Assets/css/site.css';
import './Assets/css/site.min.css';
import './Assets/css/style.css';
import App from './Components/App/App';
import AuthFailure from './Components/AuthFailure/AuthFailure';
import { Global } from './Helpers/Global';

import '../node_modules/jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/js/src/collapse.js";
import "./i18n"

// Security Warning: Be aware that this code sample uses the browser's sessionStorage to store tokens. 
// This means the token could be extracted if the site is vulnerable to an XSS attack or if 
// untrusted/malicious scripts are executed in your web app. Keep this in mind when using this authentication flow.

var appSettings = Global.getAppSettings();
var authService = Global.getAuthService(appSettings);

authService.HandlePageLoadEvent().then(() => {
    // auth flow was successful.
    // start the application now.
    var apiService = Global.getApiService(appSettings, authService);
    ReactDOM.render(<App authServiceInstance={authService} apiService={apiService} />, document.getElementById('root'));
}).catch((error) => {
    // auth flow has failed.
    // display an error instead of starting the main application.
    ReactDOM.render(<AuthFailure errorMessage={error.stack} />, document.getElementById('root'));
});