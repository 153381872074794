import React, { useEffect } from 'react';
import { Global } from '../Helpers/Global';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';

const Index: React.FC = () => {
    const navigate = useNavigate();
    let { i18n, t } = useTranslation(['CompanyNotRegistered']);

    const IsIotapEmail = (email: any, iotapDomains: any[]) => {
        return iotapDomains.some((domain) => email.toLowerCase().includes(domain.toLowerCase()));
    };

    useEffect(() => {

        const onLoadAsync = async () => {
            const appSettings = Global.getAppSettings();
            const authService = Global.getAuthService(appSettings);
            const apiService = Global.getApiService(appSettings, authService);

            let iotapDomains = await apiService.ExecuteRequest('iotap/domain/get', 'GET');
            let userProfile = authService.account;

            if (IsIotapEmail(userProfile?.username, iotapDomains.data)){
                localStorage.setItem('isIotapUser', true.toString());

                let adminAppUser = await apiService.ExecuteRequest('adminappuser/get/' + userProfile?.username, 'GET');
                localStorage.setItem('user', JSON.stringify(adminAppUser.data));

                if (adminAppUser == undefined || adminAppUser == null) {
                    var errorMessage = 'Internal User was not found! Please create a support ticket and request permissions to the Admin App.';
                    navigate(`/Error/${errorMessage}`);
                    return;
                }
                
                if (!adminAppUser.data.isManager 
                    && !adminAppUser.data.isAdministrator
                    && !adminAppUser.data.isSupportPerson) {
                    var errorMessage = 'You are not authorized to access the Admin App. Please create a support ticket and request permissions to the Admin App.';
                    navigate(`/Error/${errorMessage}`);
                    return;
                }

                navigate('/Customers');
                return;
            }
            else {
                localStorage.setItem('isIotapUser', false.toString());
                let contacts = await apiService.ExecuteRequest('company/contact', 'GET');

                if (!contacts.data 
                    || contacts?.data?.length == 0 
                    || contacts?.data?.length == undefined) {
                    var errorMessage = 'You are currently not invited to a company. Please contact Work 365 support for more information.';
                    navigate(`/Error/${errorMessage}`);
                    return;
                }
    
                if (contacts.data.length > 1) {
                    var errorMessage = 'Multiple matches was found for your account. Please contact Work 365 support for next steps.';
                    navigate(`/Error/${errorMessage}`);
                    return;
                }
    
                let contact = contacts.data[0];
    
                if (contact.account == undefined ||
                    contact.account.id == undefined)
                {
                    var errorMessage = 'Your account is currently not assigned to a company. Please contact Work 365 support for next steps.';
                    navigate(`/Error/${errorMessage}`);
                    return;
                }
    
                localStorage.setItem('companyId', contact.account.id);
                
                navigate('/ManageEnvironments');
                return;
            }
        }

        onLoadAsync();
      }, []);

    return <div></div>
}

export default Index;