import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const requireContext = require.context('./Translations', true, /\.json$/);
const resources = {};

requireContext.keys().forEach(fileName => {
  const [language, namespace] = fileName.replace(/^\.\/|\.json$/g, '').split('/');

  if (!resources[language]) {
    resources[language] = {};
  }

  if (!resources[language][namespace]) {
    resources[language][namespace] = {};
  }

  resources[language][namespace] = requireContext(fileName);
});

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: resources,
		lng: 'en',
		fallbackLng: 'en',
		interpolation: {escapeValue: false }
	});

export default i18n;