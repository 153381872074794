import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import { IsTenantReady } from '../../Lib/Models/IsTenantReady';
import TenantsGrid, { getSortedTenants } from '../../Components/ManageTenants/TenantsGrid';
import Work365Card from '../../Lib/Core/Work365Card';
import TenantsInfo from '../../Components/ManageTenants/TenantsInfo';
import { Tenant } from '../../Lib/Models/Tenant';
import { ScheduledJob } from '../../Lib/Models/ScheduledJob';
import { Invoice } from '../../Lib/Models/Invoice';
import { Work365Dialog } from '../../Lib/Core/Work365Dialog';
import Work365Textbox from '../../Lib/Core/TextBox/Work365Textbox';
import { Col, Row } from 'react-bootstrap';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import { Navigate, useNavigate } from 'react-router-dom';

const ManageTenants: React.FC = () => {

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<Tenant>();

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    const navigate = useNavigate();

    const [billingContractCount, setBillingContractCount] = useState(0);
    const [subscriptionsCount, setSubscriptionsCount] = useState(0);
    const [licenseChangeLogsCount, setLicenseChnageLogsCount] = useState(0);
    const [accountingSystemsCount, setAccountSystemsCount] = useState(0);
    const [providersCount, setProvidersCount] = useState(0);
    const [scheduledJobs, setScheduledJobs] = useState<ScheduledJob[]>([]);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [newTenantUrl, setNewTenantUrl] = useState<string>('');
    const [showNewTenantModel, setShowNewTenantModel] = useState<boolean>(false);

    let companyId = localStorage.getItem('companyId');

    useEffect(() => {
        Global.getApiService(appSettings, authService).ExecuteRequest('tenant/mytenants', 'GET').then((response: any) => {
            debugger;
            var tenantsFromRequest: Tenant[] = response.data;
            var productionTenants = tenantsFromRequest.filter(tenant => tenant.isProduction);
            if (productionTenants.length > 0) {
                setNewTenant(productionTenants[0]);
            }

            tenantsFromRequest.push({});

            setTenants(tenantsFromRequest);
        });
      }, []);

    const handleSubmit = (tenant: Tenant) => {
        if (tenant.endPointUrl == '' || tenant.endPointUrl == undefined) {
            setShowNewTenantModel(true);
            return;
        }
        if (tenant.endPointUrl != selectedTenant?.endPointUrl){
            setNewTenant(tenant);
        }
    };

    const setNewTenant = (tenant: Tenant) => {
        setSelectedTenant(tenant);

        if (tenant?.endPointUrl !== '' && tenant != undefined) {
            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/billingcontract/count`, 'GET').then((response: any) => {
              setBillingContractCount(response.data);
            })
            .catch((err) => {
                setBillingContractCount(0);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/subscription/count`, 'GET').then((response: any) => {
                setSubscriptionsCount(response.data);
            })
            .catch((err) => {
                setSubscriptionsCount(0);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/licensechangelog/count`, 'GET').then((response: any) => {
                setLicenseChnageLogsCount(response.data);
            })
            .catch((err) => {
                setLicenseChnageLogsCount(0);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/provider/count`, 'GET').then((response: any) => {
                setProvidersCount(response.data);
            })
            .catch((err) => {
                setProvidersCount(0);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/accountingsystem/count`, 'GET').then((response: any) => {
                setAccountSystemsCount(response.data);
            })
            .catch((err) => {
                setAccountSystemsCount(0);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/scheduledjob/get`, 'GET').then((response: any) => {
                if (response.data != undefined)
                {
                    setScheduledJobs(response.data);
                }
                else
                {
                    setScheduledJobs([]);
                }
            })
            .catch((err) => {
                setScheduledJobs([]);
            });

            Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenant?.guid}/invoice/get`, 'GET').then((response: any) => {
                if (response.data != undefined)
                {
                    setInvoices(response.data);
                }
                else
                {
                    setInvoices([]);
                }
            })
            .catch((err) => {
                setInvoices([]);
            });
        }
        else{
            setBillingContractCount(0);
            setLicenseChnageLogsCount(0);
            setSubscriptionsCount(0);
        }
    }

    function changeSelectedTenant(tenant: any) {
        setSelectedTenant(tenant);
    }
      
    return <div style={{display: 'flex', flexDirection: 'column', height: 'calc(100vh - 68px)'}}>
        <TenantsGrid tenants={tenants} handleSubmit={handleSubmit} selectedTenant={selectedTenant}/>
        <Work365Card style={{flex: 1, marginTop: '0.5REM'}} bodyStyle={{padding: 0}}>
            <TenantsInfo selectedTenant={selectedTenant} 
                billingContractCount={billingContractCount} 
                subscriptionsCount={subscriptionsCount} 
                licenseChangeLogsCount={licenseChangeLogsCount}
                providersCount={providersCount}
                accountingSystemsCount={accountingSystemsCount}
                scheduledJobs={scheduledJobs}
                invoices={invoices}
                companyId={companyId}
                changeSelectedTenant={changeSelectedTenant} />
        </Work365Card>
        {showNewTenantModel &&  (
            <Work365Dialog>
                <p>Before we begin, we will need the new tenant's dynamics url. Please specify the Tenant Url below.</p>
                <Work365Textbox onChange={(event: any) => { 
                    setNewTenantUrl(event.value);
                }}/>
                <Row style={{marginTop: '5px'}}>
                    <Col>
                        <Work365PrimaryButton style={{width: '100%'}} title='Cancel' onClick={() => {
                            setShowNewTenantModel(false);
                        }} />
                    </Col>
                    <Col>
                        <Work365PrimaryButton style={{width: '100%'}} title="Submit" onClick={() => {
                            navigate(`/Environment/${encodeURIComponent(newTenantUrl)}/register`)
                        }} />
                    </Col>
                </Row>
            </Work365Dialog>)}
        </div>
}

export default ManageTenants;