import * as React from "react";

import {
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  State,
  process,
  DataResult,
  filterBy,
  orderBy,
  CompositeFilterDescriptor,
  SortDescriptor,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { Global } from "../../Helpers/Global";
import { Utils } from "../../Services/Utils";
import Work365Switch from "../../Lib/Core/Work365Switch";
import Work365FlexiGrid, {
  IGridState,
} from "../../Lib/Core/Grid/Work365FlexiGrid";
import { Work365Column } from "../../Lib/Models/Work365Column";
import Work365Card from "../../Lib/Core/Work365Card";
import Work365TabStrip from "../../Lib/Core/Work365TabStrip";
import Work365TabStripTab from "../../Lib/Core/Work365TabStripTab";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { DisabledInput } from "../../Lib/Core/Work365FormInputs";
import Work365Textbox from "../../Lib/Core/TextBox/Work365Textbox";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";
import Work365PagingGrid from "../../Lib/Core/Work365PagingGrid";

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "isActive",
      operator: "eq",
      value: true,
    },
  ] as FilterDescriptor[],
} as CompositeFilterDescriptor;

const Customers: React.FC = () => {
  const _export = React.useRef<ExcelExport | null>(null);
  const [customers, setCustomers] = React.useState<any[]>([] as any[]);
  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState([
    {
      field: "name",
      dir: "asc",
    } as SortDescriptor,
  ]);

  const [gridState, setGridState] = React.useState<IGridState>({
    groupable: false,
    resizable: true,
    filterable: false,
    sortable: true,
    scrollable: "none",
    pageSize: 30,
    rowHeight: 40,
  });

  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 30,
    group: [],
  });

  const [includeInactive, setIncludeInactive] = React.useState(false);

  const dataStateAllData = (currentDataState: State, customers: any[]) => ({
    ...currentDataState,
    take: customers.length,
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(customers, dataStateAllData(dataState, customers))
  );

  React.useEffect(() => {
    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);
    var apiClient = Global.getApiService(appSettings, authService);

    apiClient
      .ExecuteRequest(`company/customers/get`, "GET")
      .then(async (customerResponse: any) => {
        var resCustomers = customerResponse.data;
        setCustomers(resCustomers);
        //setGridState({...gridState, total: resCustomers.length});
        setDataResult(
          process(resCustomers, dataStateAllData(dataState, resCustomers))
        );
      });
    return () => {
      console.log("Unmounted");
    };
  }, []);

  const handleSwitchChange = (e: any, customerId: string) => {
    var value = e.value;
    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);
    var apiClient = Global.getApiService(appSettings, authService);

    apiClient
      .ExecuteRequest(
        `company/customers/${customerId}/status/${value}/update`,
        "PUT"
      )
      .then(async (tenantResponse: any) => {});
  };

  const DateCustomCell = (props: any) => {
    var field = props.field || "";
    var value = props.dataItem[field];
    return <td>{Utils.ToDisplayDateFormat(value, false, "fr-CA")}</td>;
  };

  const SwitchCustomCell = (props: any) => {
    const field = props.field || "";
    if (props.dataItem.items) {
      return <></>;
    }
    var value = props.dataItem[field];
    if (field === "disabled") {
      value = !value;
    }
    return (
      <td
        style={{
          color: "white",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        <Work365Switch
          disabled={disableControls}
          data={props.dataItem}
          field={field}
          value={value === null ? false : value}
          onChange={(e: any) => {
            handleSwitchChange(e, props.dataItem.customerId);
          }}
        />
      </td>
    );
  };

  const LinkCustomCell = (props: any) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    var custId =
      props.dataItem["customerId"] == null
        ? "Unattached"
        : props.dataItem["customerId"];
    return (
      <td style={{ color: "blue" }}>
        <a href={`/customer/environments/${custId}`}>{value}</a>
      </td>
    );
  };

  const onPageChange = (e: GridPageChangeEvent) => {
    setDataState(e.page);
  };

  const customersGridColumns = [
    {
      field: "name",
      title: "Customer Name",
      cell: LinkCustomCell,
      filterable: true,
      filter: "text",
    },
    {
      field: "tenantCount",
      title: "Environment Count",
      filterable: true,
      filter: "text",
    },
    {
      field: "website",
      title: "Website",
      filterable: true,
      filter: "text",
    },
    {
      field: "isActive",
      title: "Active",
      cell: SwitchCustomCell,
    },
    {
      field: "createdOnUtc",
      title: "Created On Utc",
      cell: DateCustomCell,
    },
  ] as Work365Column[];

  const onFilterChange = (e: any) => {
    setFilter(e.filter);
    setGridState({ ...gridState, sort: e.sort });
    setDataState({ ...dataState, skip: 0 });

    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const onSortChange = (e: any) => {
    setSort(e.sort);
    setGridState({ ...gridState, sort: e.sort });
    setDataState({ ...dataState, skip: 0 });
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  var userString = localStorage.getItem("user");
  var disableControls = true;

  if (userString != undefined) {
    var user: any = JSON.parse(userString);
    if (user.isAdministrator || user.isManager) {
      disableControls = false;
    }
  }

  const CustomTabHeader = () => {
    return (
      <table>
        <tr>
          <td style={{ width: "200px" }}>Customers</td>
          <td>
            <Work365Switch
              style={{ display: "inline" }}
              title="Include Inactive"
              key="environment-include-inactive"
              value={includeInactive}
              onChange={(e) => {
                if (e.value) {
                  filter.filters = filter.filters.filter(
                    (x: any) => x.field !== "isActive"
                  );
                } else {
                  const filterIndex = filter.filters.findIndex(
                    (x: any) => x.field === "isActive" && x.operator === "eq"
                  ) as any;
                  if (filterIndex !== -1) {
                    filter.filters[filterIndex] = {
                      field: "isActive",
                      operator: "eq",
                      value: true,
                    };
                  } else {
                    filter.filters.push({
                      field: "isActive",
                      operator: "eq",
                      value: true,
                    });
                  }
                }

                setFilter({ ...filter });
                setIncludeInactive(!includeInactive);
                setGridState({ ...gridState, filter: filter });
                setDataState({ ...dataState, skip: 0 });

                let gridContainer = document.querySelector(".k-grid-content");
                if (gridContainer) {
                  gridContainer.scrollTop = 0;
                }
              }}
            />
          </td>
        </tr>
      </table>
    );
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(
        filterBy(orderBy(dataResult.data, sort), filter)?.map((x: any) => {
          x.createdOnUtc = Utils.ToDisplayDateFormat(
            x.createdOnUtc,
            false,
            "fr-CA"
          );
          return x;
        }),
        customersGridColumns
      );
    }
  };

  const locales = [{
    language: 'en-US',
    locale: 'en'
  }, {
    language: 'de',
    locale: 'de'
  },
  {
    language: 'ja',
    locale: 'ja'
  },
  {
    language: 'es-ES',
    locale: 'es'
  }
];

var selectedlang = locales[0];

  return (
    <Work365Card>
      <div style={{ position: "relative" }}>
        <div
          style={{
            maxHeight: "calc(100vh - 7em)",
            overflowY: "auto",
            paddingTop: "5px",
          }}
        >
          <ExcelExport
            ref={_export}
            fileName={`Work365Customers_${new Date()
              .toLocaleString()
              .replace(/[/\\:*?"<>|]/g, "_")}`}
          />
          <Work365PagingGrid
            excelref={_export}
            state={{
              data: dataResult.data,
              dataState: dataState,
              currentLocale: selectedlang,
              lastUsedDataState: null,
              skip: 0,
              take: 10000,
              morePagesAvailable: false,
              isLoading: false,
            }}
            columns={customersGridColumns}
            filter={filter}
            sort={sort}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            showEditButton={false}
            groupable={false}
            sortable={false}
            filterable={false}
            reorderable={false}
            onEditButtonClick={() => {}}
            gridtoolbar={
              <GridToolbar>
                <Work365Switch
                  style={{ marginRight: "10px", marginTop: "2px" }}
                  title="Include Inactive"
                  key="environment-include-inactive"
                  value={includeInactive}
                  onChange={(e) => {
                    if (e.value) {
                      filter.filters = filter.filters.filter(
                        (x: any) => x.field !== "isActive"
                      );
                    } else {
                      const filterIndex = filter.filters.findIndex(
                        (x: any) =>
                          x.field === "isActive" && x.operator === "eq"
                      ) as any;
                      if (filterIndex !== -1) {
                        filter.filters[filterIndex] = {
                          field: "isActive",
                          operator: "eq",
                          value: true,
                        };
                      } else {
                        filter.filters.push({
                          field: "isActive",
                          operator: "eq",
                          value: true,
                        });
                      }
                    }

                    setFilter({ ...filter });
                    setIncludeInactive(!includeInactive);
                    setGridState({ ...gridState, filter: filter });
                    setDataState({ ...dataState, skip: 0 });

                    let gridContainer =
                      document.querySelector(".k-grid-content");
                    if (gridContainer) {
                      gridContainer.scrollTop = 0;
                    }
                  }}
                />
                <Work365Textbox
                  placeHolder="Type here to filter based on Customer Name."
                  style={{ width: "300px", marginRight: "10px" }}
                  onChange={(event) => {
                    if (
                      event.value === "" ||
                      event.value == undefined ||
                      event.value == null
                    ) {
                      const filterIndex = filter.filters.findIndex(
                        (x: any) => x.field === "name"
                      ) as any;

                      if (
                        filterIndex !== -1 &&
                        filterIndex > -1 &&
                        filterIndex < filter.filters.length
                      ) {
                        filter.filters.splice(filterIndex, 1);

                        setFilter({ ...filter });
                        setGridState({ ...gridState, filter: filter });
                        setDataState({ ...dataState, skip: 0 });
                      }

                      return;
                    }
                    const filterIndex = filter.filters.findIndex(
                      (x: any) => x.field === "name"
                    ) as any;
                    if (filterIndex !== -1) {
                      filter.filters[filterIndex] = {
                        field: "name",
                        operator: "contains",
                        value: event.value,
                      };
                    } else {
                      filter.filters.push({
                        field: "name",
                        operator: "contains",
                        value: event.value,
                      });
                    }

                    setFilter({ ...filter });
                    setGridState({ ...gridState, filter: filter });
                    setDataState({ ...dataState, skip: 0 });
                  }}
                />
                <Work365PrimaryButton style={{marginLeft: 'auto'}} title='Export to Excel' onClick={excelExport} />
              </GridToolbar>
            }
            onNextPage={() => {}}
            onPreviousPage={() => {}}>

          </Work365PagingGrid>
        </div>
      </div>
    </Work365Card>
  );
};

export default Customers;
