import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Col, Row } from 'react-bootstrap';
import {
  FormInput,
  ComboboxInput,
  FormNumericTextBox,
  PercentageTextBox,
} from '../Lib/Core/Work365FormInputs';
import Work365Card from '../Lib/Core/Work365Card';
import { BillingContractTemplate } from '../Lib/Models/BillingContractTemplate';
import { Currency } from '../Lib/Models/Currency';
import { Global } from '../Helpers/Global';

export type Work365BillingContractTemplateFormProps = {
  billingContractTemplate?: BillingContractTemplate | undefined;
  onSubmit: ( values: { [name: string]: any }, event?: SyntheticEvent<any, Event> | undefined) => void;
};

const Work365BillingContractTemplateForm: React.FC<Work365BillingContractTemplateFormProps> = ({ billingContractTemplate, onSubmit }) => {
  const [currencies, setCurrencies] = useState([]);

  let { i18n, t } = useTranslation(['BillingContractTemplateForm']);

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  useEffect(() => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest('currency/get', 'GET')
      .then((response: any) => {
        setCurrencies(
          response.data.map((currency: Currency) => {
            return {
              name: currency.name,
              value: currency.currencyIsoCode,
            };
          })
        );
      });
  }, []);

  const billingTypes = [
    { name: 'Recurring', value: '225050001', isDefault: true },
    { name: 'Item based', value: '225050000' },
  ];

  const billingFrequencies = [
    { name: 'Monthly', value: '225050000', isDefault: true },
    { name: 'Quarterly', value: '225050002' },
    { name: 'Annual', value: '225050001' },
  ];

  const paymentTerms = [
    { name: 'Net 15', value: '225050000' },
    { name: 'Net 30', value: '1' },
    { name: '2% 10, Net 30', value: '2' },
    { name: 'Net 45', value: '3' },
    { name: 'Net 60', value: '4' },
    { name: 'Net 90', value: '225050001' },
    { name: 'Due on Receipt', value: '22505999', isDefault: true },
  ];

  const productTypes = [
    { name: 'CSP', value: '123030000', isDefault: true },
    { name: 'Support Contract', value: '100000000' },
    { name: 'Managed Services', value: '123030002' },
  ];

  const billingPolicies = [
    { name: 'Advance', value: '225050000', isDefault: true },
    { name: 'Arrears', value: '225050001' },
  ];

  const indexationTypes = [
    { name: 'Use Global Setting', value: '225050003', isDefault: true },
    { name: 'No Indexation', value: '225050000' },
    { name: 'Term Based Indexation', value: '225050002' },
    { name: 'Annual Indexation', value: '225050001' },
  ];

  const booleanComboBoxes = [
    { name: 'Yes', value: true },
    { name: 'No', value: false, isDefault: false },
  ];

  var formBillingContractTemplate = {
    id: billingContractTemplate?.id,
    company: billingContractTemplate?.company,
    companyId: billingContractTemplate?.companyId,
    name: billingContractTemplate?.name,
    type: billingContractTemplate?.type,
    contractTerm: billingContractTemplate?.contractTerm,
    autoRenew: billingContractTemplate?.autoRenew,
    billingFrequency: billingContractTemplate?.billingFrequency,
    paymentTerms: billingContractTemplate?.paymentTerms,
    productType: billingContractTemplate?.productType,
    currencyIso: billingContractTemplate?.currencyIso,
    startDateMonth: billingContractTemplate?.startDateMonth,
    startDateDay: billingContractTemplate?.startDateDay,
    billingPolicy: billingContractTemplate?.billingPolicy,
    autosyncInvoice: billingContractTemplate?.autosyncInvoice,
    autochargeInvoice: billingContractTemplate?.autochargeInvoice,
    processRefunds: billingContractTemplate?.processRefunds,
    emailTemplate: billingContractTemplate?.emailTemplate,
    discountPercent: billingContractTemplate?.discountPercent,
    adminCharges: billingContractTemplate?.adminCharges,
    prorateUnit: billingContractTemplate?.prorateUnit,
    invoiceTemplate: billingContractTemplate?.invoiceTemplate,
    indexationType: billingContractTemplate?.indexationType,
    indexationRate: billingContractTemplate?.indexationRate,
    autosendInvoice: billingContractTemplate?.autosendInvoice,

    currencyOption: undefined,
    processRefundsOption: undefined,
    autoRenewOption: undefined,
    autosyncInvoiceOption: undefined,
    autochargeInvoiceOption: undefined,
    prorateUnitOption: undefined,
    billingTypeOption: undefined,
    paymentTermOption: undefined,
    productTypeOption: undefined,
    billingPolicyOption: undefined,
    indexationTypeOption: undefined,
    billingFrequencyOption: undefined
  };

  if (billingContractTemplate != undefined) {
    if (formBillingContractTemplate.currencyIso != undefined) {
      const currency: any = currencies.find(
        (c: any) => c.value === formBillingContractTemplate?.currencyIso
      );

      if (currency) {
        formBillingContractTemplate.currencyOption = currency;
      }
    }

    if (formBillingContractTemplate.processRefunds != undefined) {
      const processRefund: any = booleanComboBoxes.find(
        (b: any) => b.value === formBillingContractTemplate?.processRefunds
      );

      if (processRefund) {
        formBillingContractTemplate.processRefundsOption = processRefund;
      }
    }

    if (formBillingContractTemplate.autoRenew != undefined) {
      const autoRenew: any = booleanComboBoxes.find(
        (b: any) => b.value === formBillingContractTemplate?.autoRenew
      );

      if (autoRenew) {
        formBillingContractTemplate.autoRenewOption = autoRenew;
      }
    }

    if (formBillingContractTemplate.autosyncInvoice != undefined) {
      const autosyncInvoice: any = booleanComboBoxes.find(
        (b: any) => b.value === formBillingContractTemplate?.autosyncInvoice
      );

      if (autosyncInvoice) {
        formBillingContractTemplate.autosyncInvoiceOption = autosyncInvoice;
      }
    }

    if (formBillingContractTemplate.autochargeInvoice != undefined) {
      const autochargeInvoice: any = booleanComboBoxes.find(
        (b: any) => b.value === formBillingContractTemplate?.autochargeInvoice
      );

      if (autochargeInvoice) {
        formBillingContractTemplate.autochargeInvoiceOption = autochargeInvoice;
      }
    }

    if (formBillingContractTemplate.prorateUnit != undefined) {
      const prorateUnit: any = booleanComboBoxes.find(
        (b: any) => b.value === formBillingContractTemplate?.prorateUnit
      );

      if (prorateUnit) {
        formBillingContractTemplate.prorateUnitOption = prorateUnit;
      }
    }

    if (formBillingContractTemplate.type != undefined) {
      const billingType: any = billingTypes.find(
        (b: any) => b.value === formBillingContractTemplate?.type
      );

      if (billingType) {
        formBillingContractTemplate.billingTypeOption = billingType;
      }
    }

    if (formBillingContractTemplate.billingFrequency != undefined) {
      const billingFrequency: any = billingFrequencies.find(
        (b: any) => b.value === formBillingContractTemplate?.billingFrequency
      );

      if (billingFrequency) {
        formBillingContractTemplate.billingTypeOption = billingFrequency;
      }
    }

    if (formBillingContractTemplate.paymentTerms != undefined) {
      const paymentTerm: any = paymentTerms.find(
        (b: any) => b.value === formBillingContractTemplate?.paymentTerms
      );

      if (paymentTerm) {
        formBillingContractTemplate.paymentTermOption = paymentTerm;
      }
    }

    if (formBillingContractTemplate.productType != undefined) {
      const productType: any = productTypes.find(
        (b: any) => b.value === formBillingContractTemplate?.productType
      );

      if (productType) {
        formBillingContractTemplate.productTypeOption = productType;
      }
    }

    if (formBillingContractTemplate.billingPolicy != undefined) {
      const billingPolicy: any = billingPolicies.find(
        (b: any) => b.value === formBillingContractTemplate?.billingPolicy
      );

      if (billingPolicy) {
        formBillingContractTemplate.billingPolicyOption = billingPolicy;
      }
    }

    if (formBillingContractTemplate.indexationType != undefined) {
      const indexationType: any = indexationTypes.find(
        (b: any) => b.value === formBillingContractTemplate?.indexationType
      );

      if (indexationType) {
        formBillingContractTemplate.indexationTypeOption = indexationType;
      }
    }

    if (formBillingContractTemplate.billingFrequencyOption != undefined){
      const billingFrequency: any = billingFrequencies.find(
        (b: any) => b.value === formBillingContractTemplate.billingFrequency
      );

      if (billingFrequency){
        formBillingContractTemplate.billingFrequencyOption = billingFrequency;
      }
    }
  } else {
    billingContractTemplate = {};
  }

  if (formBillingContractTemplate?.billingTypeOption == undefined) {
    var billingTypeOption: any = billingTypes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.billingTypeOption = billingTypeOption;
  }

  if (formBillingContractTemplate?.processRefundsOption == undefined) {
    var processRefundsOption: any = booleanComboBoxes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.processRefundsOption = processRefundsOption;
  }

  if (formBillingContractTemplate?.autoRenewOption == undefined) {
    var autorenewOption: any = booleanComboBoxes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.autoRenewOption = autorenewOption;
  }

  if (formBillingContractTemplate?.autosyncInvoiceOption == undefined) {
    var autosyncInvoiceOption: any = booleanComboBoxes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.autosyncInvoiceOption = autosyncInvoiceOption;
  }

  if (formBillingContractTemplate?.autochargeInvoiceOption == undefined) {
    var autochargeInvoiceOption: any = booleanComboBoxes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.autochargeInvoiceOption = autochargeInvoiceOption;
  }

  if (formBillingContractTemplate?.prorateUnitOption == undefined) {
    var prorateUnitOption: any = booleanComboBoxes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.prorateUnitOption = prorateUnitOption;
  }

  if (formBillingContractTemplate?.billingFrequencyOption == undefined) {
    var billingFrequencyOption: any = billingFrequencies.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.billingFrequencyOption = billingFrequencyOption;
  }

  if (formBillingContractTemplate?.paymentTermOption == undefined) {
    var paymentTermOption: any = paymentTerms.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.paymentTermOption = paymentTermOption;
  }

  if (formBillingContractTemplate?.productTypeOption == undefined) {
    var productTypeOption: any = productTypes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.productTypeOption = productTypeOption;
  }

  if (formBillingContractTemplate?.billingPolicyOption == undefined) {
    var billingPolicyOption: any = billingPolicies.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.billingPolicyOption = billingPolicyOption;
  }

  if (formBillingContractTemplate?.indexationTypeOption == undefined) {
    var indexationTypeOption: any = indexationTypes.find(
      (b) => b.isDefault
    );

    formBillingContractTemplate.indexationTypeOption = indexationTypeOption;
  }

  if (formBillingContractTemplate?.adminCharges == undefined) {
    formBillingContractTemplate.adminCharges = 0;
  }

  if (formBillingContractTemplate?.indexationRate == undefined) {
    formBillingContractTemplate.indexationRate = 0;
  }

  if (formBillingContractTemplate?.discountPercent == undefined) {
    formBillingContractTemplate.discountPercent = 0;
  }

  if (formBillingContractTemplate?.startDateDay == undefined) {
    formBillingContractTemplate.startDateDay = 1;
  }

  if (formBillingContractTemplate?.startDateMonth == undefined) {
    formBillingContractTemplate.startDateMonth = 1;
  }

  return (
    <Work365Card>
      <Form
        initialValues={formBillingContractTemplate}
        key={JSON.stringify(formBillingContractTemplate)}
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <Row>
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={'k-messagebox k-messagebox-error'}>
                    {formRenderProps.errors.VALIDATION_SUMMARY}
                  </div>
                )}
            </Row>
            <b>General Details</b>
            <br />

            <Row>
              <Field name={'name'} component={FormInput} label={t('name')} />
            </Row>
            <Row>
              <Col>
                <Field
                  name={'billingTypeOption'}
                  component={ComboboxInput}
                  data={billingTypes}
                  label={t('type')}
                />
              </Col>
              <Col>
                <Field
                  name={'billingFrequencyOption'}
                  component={ComboboxInput}
                  data={billingFrequencies}
                  label={t('billingFrequency')}
                />
              </Col>
              <Col>
                <Field
                  name={'paymentTermOption'}
                  component={ComboboxInput}
                  data={paymentTerms}
                  label={t('paymentTerms')}
                />
              </Col>
              <Col>
                <Field
                  name={'productTypeOption'}
                  component={ComboboxInput}
                  data={productTypes}
                  label={t('productType')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name={'billingPolicyOption'}
                  component={ComboboxInput}
                  data={billingPolicies}
                  label={t('billingPolicy')}
                />
              </Col>
              <Col>
                <Field
                  name={'processRefundsOption'}
                  component={ComboboxInput}
                  data={booleanComboBoxes}
                  label={t('processRefunds')}
                />
              </Col>
              <Col>
                <Field
                  name={'startDateDay'}
                  component={FormNumericTextBox}
                  label={t('startDateDay')}
                />
              </Col>
              <Col>
                <Field
                  name={'startDateMonth'}
                  component={FormNumericTextBox}
                  label={t('startDateMonth')}
                />
              </Col>
            </Row>

            <br />
            <b>Automatic Settings</b>
            <br />

            <Row>
              <Col>
                <Field
                  name={'autosyncInvoiceOption'}
                  component={ComboboxInput}
                  data={booleanComboBoxes}
                  label={t('autosyncInvoice')}
                />
              </Col>
              <Col>
                <Field
                  name={'autochargeInvoiceOption'}
                  component={ComboboxInput}
                  data={booleanComboBoxes}
                  label={t('autochargeInvoice')}
                />
              </Col>
            </Row>

            <br />
            <b>Auto Renew Settings</b>
            <br />

            <Row>
              <Col>
                <Field
                  name={'autoRenewOption'}
                  component={ComboboxInput}
                  data={booleanComboBoxes}
                  label={t('autoRenew')}
                />
              </Col>
            </Row>

            <br />
            <b>Billing Settings</b>
            <br />

            <Row>
              <Col>
                <Field
                  name={'currencyOption'}
                  component={ComboboxInput}
                  data={currencies}
                  label={t('currencyIso')}
                />
              </Col>
              <Col>
                <Field
                  name={'discountPercent'}
                  component={PercentageTextBox}
                  label={t('discountPercent')}
                />
              </Col>
            </Row>

            <br />
            <b>Advance Settings</b>
            <br />

            <Row>
              <Col>
                <Field
                  name={'indexationRate'}
                  component={PercentageTextBox}
                  label={t('indexationRate')}
                />
              </Col>
              <Col>
                <Field
                  name={'indexationTypeOption'}
                  component={ComboboxInput}
                  data={indexationTypes}
                  label={t('indexationType')}
                />
              </Col>
              <Col>
                <Field
                  name={'adminCharges'}
                  component={PercentageTextBox}
                  label={t('adminCharges')}
                />
              </Col>
              <Col>
                <Field
                  name={'prorateUnitOption'}
                  component={ComboboxInput}
                  data={booleanComboBoxes}
                  label={t('prorateUnit')}
                />
              </Col>
            </Row>

            <div className='k-form-buttons text-right'>
              <button
                type={'submit'}
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
              >
                {t('save')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Work365Card>
  );
};

export default Work365BillingContractTemplateForm;
