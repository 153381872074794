import { ButtonGroup, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { Component } from 'react';

interface Work365HeaderProps {
    HeaderCaption: string,
    Buttons: | React.ReactNode
}

export default class Work365Header extends Component<Work365HeaderProps>{
    render() {
        return (
            <div className="k-card-title">
                {this.props.HeaderCaption}
                <Toolbar style={{ display: 'inline-block', padding: '0px', float: 'right' }}>
                    <ToolbarItem >
                        <ButtonGroup>
                            {this.props.Buttons}
                        </ButtonGroup>
                    </ToolbarItem>
                </Toolbar>
            </div>
        )
    }
}