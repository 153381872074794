import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import Work365DropdownBox from '../../Lib/Core/Work365DropdownBox';
import { Col, Row } from 'react-bootstrap';


type SyncSubscriptionsOperationProps = {
    tenantId: string | undefined;
};

const SyncSubscriptionsOperation: React.FC<SyncSubscriptionsOperationProps> = ({ tenantId }) => {

    const operationType = 6;

    const [operationId, setOperationId] = useState();
    const [inProgress, setInProgress] = useState(false); 
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    let intervalNumber: any = undefined;

    const [providers, setProviders] = useState<any[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<any>();
  
    function saveAdditionalParameters(selectedProviderTmp: any) {
        let body = {
          providerId: selectedProviderTmp.connectorId
        };
  
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/${operationId}/saveadditionalparameters/${JSON.stringify(body)}`, 'POST')
            .then((response: any) => {
  
        });
    }

    const checkProgress = () => {
      Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/get/${operationId}`, 'GET', undefined, false)
        .then((response: any) => {
          if (response.data != undefined){
            setProgress(response.data.progressPercent);
  
            if (response.data.operationStatus != 'P'){
              clearInterval(intervalNumber);
              setCompleted(true);
  
              intervalNumber = undefined;
            }
          }
        });
    };
  
    function handleNewOperation() {
      setTimeout(function () {
        getOperation();
      }, 5000);
    }
  
    useEffect(() => {
      getOperation();
  
        Global.getApiService(appSettings, authService).ExecuteRequest(`tenant/${tenantId}/provider/get`, 'GET').then((response: any) => {
            setProviders(response.data);
        });

        return () => {
            if (intervalNumber != undefined) {
                clearInterval(intervalNumber);
                intervalNumber = undefined;
            }
        };
    }, []); 
  
    function getOperation() {
        let operationContext = {
          operationType: operationType
        };
    
        Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
        .then((response: any) => {
          if (response.data == undefined){
            Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
            .then((response: any) => {
              setOperationId(response.data.operationId);
            });
          }
          else {
            setOperationId(response.data.operationId);
  
            if (response.data.operationStatus == 'P'){
              setInProgress(true);
              setProgress(response.data.progressPercent);
    
              if (intervalNumber != undefined) {
                clearInterval(intervalNumber);
                intervalNumber = undefined;
              }
          
              intervalNumber = setInterval(checkProgress, 5000);
            }
          }
        });
    }  

    let providerDropdownData = providers?.map((provider: any) => {
        return {
            name: provider.name,
            value: provider.connectorId
        }
    });

    return (
        <div style={{marginTop: '8px'}}>
            {inProgress && (
                <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
            )}

            <Row>
                <Col>
                    <p>This operation will sync all subscriptions to billing contracts under the provider selected. Please select a provider you want to use and click on the continue button.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Work365DropdownBox data={providerDropdownData} style={{width: '350px'}} onChange={(event) => {
                        let provider = providers.find(item => item.connectorId === event.value.value);

                        if (provider != undefined) {
                            setSelectedProvider(provider);
                            saveAdditionalParameters(provider);
                        }
                    }}/>
                </Col>
            </Row>
            
            <Work365SubmitPanel
                operationId={operationId} 
                tenantId={tenantId}
                handleNewOperation={handleNewOperation} />
        </div>
    );
}

export default SyncSubscriptionsOperation;