import { SyntheticEvent, useEffect, useState } from "react";
import Work365Card from "../Lib/Core/Work365Card";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Col, Row } from "react-bootstrap";
import { ComboboxInput, FormInput, FormNumericTextBox } from "../Lib/Core/Work365FormInputs";
import { Global } from "../Helpers/Global";

export type Work365CreateProviderFormProps = {
    onSubmit: (values: { [name: string]: any; }, event?: SyntheticEvent<any, Event> | undefined) => void;
    priceLists: any[];
}

const environments = [
    {
        name: 'Commercial',
        value: 'commercial',
        isDefault: true
    }
];

const azureSpecializations = [
    {
        name: 'We dont have Azure Specialization',
        value: 'not-applicable',
        isDefault: true
    },
    {
        name: 'Pass specialization discounts to Customer',
        value: 'true',
        isDefault: false
    },
    {
        name: 'Retain specialization discounts as margin',
        value: 'false',
        isDefault: false
    },
];

const days: any[] = [];

for (let i = 1; i <= 31; i++) {
    days.push({
        name: `${i}`,
        value: `${i}`
    });
}

const Work365CreateProviderForm: React.FC<Work365CreateProviderFormProps> = ({onSubmit, priceLists}) => {
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    useEffect(() => {
        Global.getApiService(appSettings, authService).ExecuteRequest('country/get', 'GET').then((response: any) => {
            setCountries(response.data.map((country: any) => {
                return {
                    name: country.name,
                    value: country.countryAlpha2Code
                };
            }));
        });

        Global.getApiService(appSettings, authService).ExecuteRequest('currency/get', 'GET').then((response: any) => {
            setCurrencies(response.data.map((currency: any) => {
                return {
                    name: currency.name,
                    value: currency.currencyIsoCode
                };
            }));
        });
    }, [])

    var defaultValues = {
        name: 'Microsoft Partner Center',
        environment: environments[0]
    }

    return (
        <Work365Card>
            <Form  initialValues={defaultValues} key={JSON.stringify(defaultValues)} onSubmit={onSubmit}  render={(formRenderProps) => (
                <FormElement>
                    <Row>{formRenderProps.visited && formRenderProps.errors && formRenderProps.errors.VALIDATION_SUMMARY && <div className={'k-messagebox k-messagebox-error'}>{formRenderProps.errors.VALIDATION_SUMMARY}</div>}</Row>

                    <b>General Settings</b>
                    <br />
                    <Row>
                        <Col>
                            <Field name={'name'} component={FormInput} label={'Name'} />
                        </Col>
                        <Col>
                            <Field name={'environment'} label={'Environment'} component={ComboboxInput} data={environments} />
                        </Col>
                    </Row>

                    <br />
                    <b>Connector Settings</b>
                    <br />
                    <Row>
                        <Col>
                            <Field name={'countryCode'} component={ComboboxInput} label={'Country Code'} data={countries}/>
                        </Col>
                        <Col>
                            <Field name={'currencyCode'} component={ComboboxInput} label={'Currency Code'} data={currencies}/>
                        </Col>
                        <Col>
                            <Field name={'taxRate'} component={FormNumericTextBox} label={'Tax Rate'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field name={'resellerRelationshipAuthorize'} component={FormInput} label={'Reseller Relationship Authorize'} />
                        </Col>
                        <Col>
                            <Field name={'delegatedAdminAuthorizeURL'} component={FormInput} label={'Delegated Admin Authorize Url'} />
                        </Col>
                    </Row>

                    <br />
                    <b>Azure Configuration</b>
                    <br />

                    <Row>
                        <Col>
                            <Field name={'usageMismatchTolerance'} component={FormNumericTextBox} label={'Usage Mismatch Tolerance'} />
                        </Col>
                        <Col>
                            <Field name={'azureSpecializations'} component={ComboboxInput} label={'Azure Specializations'} data={azureSpecializations}/>
                        </Col>
                    </Row>

                    <div className="k-form-buttons text-right">
                        <button
                            type={"submit"}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                            {'Submit'}
                        </button>
                    </div>
                </FormElement>
            )} />
        </Work365Card>
    )
}

export default Work365CreateProviderForm;