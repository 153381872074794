import { Component } from "react";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { CSSProperties } from "styled-components";

export type Work365DialogProps = {
  title?: string;
  children?: React.ReactNode;
  width?: number | undefined;
  height?: number | undefined;
  onClose?: ((event: DialogCloseEvent) => void) | undefined;
};

export class Work365Dialog extends Component<Work365DialogProps> {
  render() {
    return (
      <Dialog
        style={{zIndex: '1'}}
        width={this.props.width}
        height={this.props.height}
        title={this.props.title}
        onClose={this.props.onClose}>
        {this.props.children}
      </Dialog>
    );
  }
}
