import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Global } from '../../Helpers/Global';
import CopyItem from './Images/copy-icon.png';
import { useNavigate } from 'react-router-dom';


type ConsentProps = {

};

const Consent: React.FC<ConsentProps> = () => {

    const navigate = useNavigate();

    let { environmentId } = useParams();
    let { providerId } = useParams();
    
    const [consentUrl, setConsentUrl] = useState('');
    const [getCopy, setCopy] = useState(false);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    useEffect(() => {
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${environmentId}/provider/generateconsent/${providerId}`, 'GET')
            .then((response: any) => {
                setConsentUrl(response.data);
            });
    }, []);

    const gotoManageTenantsPage = (dataItem: any) => {
        navigate('/ManageEnvironments');
    };

    return (
        <div style={{marginTop: '8px'}}>
            <h3>Finish connector setup</h3>
            <p>Please follow the steps below to finish the connector setup.</p>
            <ul className="ulstyle">
                <li>
                    Click here to <b>provide consent to Work 365</b> to access Partner Center APIs using the new secure application model: <a href={consentUrl} target="_blank" id="consentlink" style={{pointerEvents: 'none'}}>Link</a>
                    <a id="copyconsentlink-button" href={consentUrl} target="_blank"><img src={CopyItem} alt="Click here to open in new Tab" title="Click here to open in new Tab" /></a> 
                </li>
                <li>Continue to login with the <b>service account</b> that has "Admin Agent" permissions in Partner Center.</li>
                <li><b>Accept the prompt for consent</b> to the Work 365 CPV Application. Once done, you should see a success page confirming the consent is successful.</li>
            </ul>
            <br />
            <p>Click <a href='#' onClick={gotoManageTenantsPage}>here</a> to return to the Manage Tenants Page.</p>
        </div>
    );
}

export default Consent;