import { StackLayout } from '@progress/kendo-react-layout';
import React from 'react';

export interface IStackLayoutProps{
    orientation?: 'horizontal' | 'vertical' | undefined,
    gap?: number;
    children: React.ReactNode;
    style?: React.CSSProperties | undefined;
    onclick?: (event: any) => void;
}

const Work365StackLayout: React.FC<IStackLayoutProps>=(p:IStackLayoutProps)=>{
    return <>
        <StackLayout
            style={p.style}
            orientation={p.orientation}
            gap={p.gap}
        >
            {p.children}
        </StackLayout>
    </>
}

export default Work365StackLayout;