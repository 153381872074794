import { ReactNode } from "react";
import { Card, Container } from "react-bootstrap";

interface Work365CardProps {
  children: ReactNode;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  className?: string | undefined;
}

const Work365Card: React.FC<Work365CardProps> = ({ children, style, className, bodyStyle }) => {
  return (
    <Card className={className} style={{ ...style }}>
      <Card.Body style={bodyStyle}>{children}</Card.Body>
    </Card>
  );
};

export default Work365Card;
