import React, { CSSProperties } from 'react';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365Title from '../../Lib/Core/Work365Title';

interface NumberStatComponentProps {
    title: string;
    amount: string | undefined;
}

const NumberStatComponent: React.FC<NumberStatComponentProps> = ({title, amount}) => {
    return (
        <Work365Card className="overflow-auto mt-2 p-2">
            <Work365Title title={title} />
            <p style={{fontSize: 50}} className="mt-4">{amount}</p>
        </Work365Card>
    )
}

export default NumberStatComponent;