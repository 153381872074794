import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  Input,
  MaskedTextBox,
  NumericTextBox,
  Checkbox,
  ColorPicker,
  Switch,
  RadioGroup,
  Slider,
  SliderLabel,
  RangeSlider,
  TextArea,
  Rating,
} from "@progress/kendo-react-inputs";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  DateRangePicker,
  DateInput,
} from "@progress/kendo-react-dateinputs";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import { CompositeFilterDescriptor, FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import Work365DropdownList from "./Work365DropDownList";

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);

export const EmailValidator = (value: string) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";
  
export const phoneValidator = (value: string) =>
  !value
    ? ""
    : phoneRegex.test(value)
    ? ""
    : "Not a valid phone number.";

export const RequiredValidator = (value: string) => {
        return value ? "" : "This field is required.";
    }

    export const PercentageTextBox = (fieldRenderProps: FieldRenderProps) => {
      const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        ...others
      } = fieldRenderProps;
    
      const showValidationMessage: string | false | null =
        touched && validationMessage;
      const showHint: boolean = !showValidationMessage && hint;
      const hintId: string = showHint ? `${id}_hint` : "";
      const errorId: string = showValidationMessage ? `${id}_error` : "";
    
      return (
        <div style={{marginTop: "5px"}}>
          <Label>
            {label}
          </Label>
          <NumericTextBox
            ariaDescribedBy={`${hintId} ${errorId}`}
            step={0.01} 
            valid={valid}
            id={id}
            disabled={disabled}
            format="p0" // This will format the value as a percentage
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </div>
      );
    };

    export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
      const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        ...others
      } = fieldRenderProps;
    
      const showValidationMessage: string | false | null =
        touched && validationMessage;
      const showHint: boolean = !showValidationMessage && hint;
      const hintId: string = showHint ? `${id}_hint` : "";
      const errorId: string = showValidationMessage ? `${id}_error` : "";
    
      return (
        <div style={{marginTop: "5px"}}>
          <Label>
            {label}
          </Label>
          <NumericTextBox
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </div>
      );
    };

export const FormInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, label, id, valid, ...others } =
    fieldRenderProps;
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <div style={{marginTop: "5px"}}>
      <Label>
        {label}
      </Label>
      <Input {...others}/>
      {showValidationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, ...others } =
  fieldRenderProps;
const showValidationMessage: string | false | null =
  visited && validationMessage;

  return (
    <div style={{marginTop: "5px"}}>
      <Label>
        {label}
      </Label>
      <TextArea {...others}/>
      {showValidationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ComboboxInput = (fieldRenderProps: FieldRenderProps) => {
  const { data, label, name, onChange, value, disabled } = fieldRenderProps;

  return (
    <div>
      <label>{label}</label>
      <Work365DropdownList
        data={data}
        onChange={(selectedValue: string) => onChange({target: undefined, value: selectedValue})}
        name={name}
        defaultValue={value}
        textField="name"
        dataItemKey="value"
        disabled={disabled}
      />
    </div>
  );
};
export const MultiSelectInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, ...others } =
    fieldRenderProps;
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <div style={{marginTop: "5px"}}>
      <Label>
        {label}
      </Label>
      <MultiSelect {...others} dataItemKey="value" textField="name"/>
      {showValidationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
}

export const DisabledInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, ...others } =
  fieldRenderProps;
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <div style={{marginTop: "5px"}}>
      <Label>
        {label}
      </Label>
      <Input {...others} disabled={true}/>
      {showValidationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const FormMaskedTextBox = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      hint,
      optional,
      name,
      ...others
    } = fieldRenderProps;
  
    const showValidationMessage: string | false | null =
      touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
  
    return (
      <div style={{marginTop: "5px"}}>
        <Label>
          {name}
        </Label>
        <div className={"k-form-field-wrap"}>
          <MaskedTextBox
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </div>
    );
  };