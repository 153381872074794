import React, { ReactNode, useEffect, useRef, useState } from "react";
import { ArcGauge, ArcGaugeProps } from "@progress/kendo-react-gauges";
import Work365Title from "./Work365Title";
import screenfull from 'screenfull';

export interface Work365ArcGaugeProps {
  value: number;
  total: number;
  title?: string | undefined;
}

const gaugeStyles = {
  display: "block"
};

const Work365ArcGauge: React.FC<Work365ArcGaugeProps> = ({
    value,
    total,
    title
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
  useEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;
        
        setDimensions({ width, height });
      }
    }
    
    updateSize(); 

    window.addEventListener('resize', updateSize); 
    screenfull.on('change', updateSize);
    
    return () => {
      window.removeEventListener('resize', updateSize);
      screenfull.off('change', updateSize);
    };
  }, []);

  const arcOptions: ArcGaugeProps = {
    value: value,
    color: '#00A0DD',
    
    scale: {
      rangeSize: 10,
      rangeLineCap: "round",
      startAngle: 0,
      endAngle: 180,
      reverse: false,
      min: 0,
      max: total,
      majorTicks: {visible: false},
      minorTicks: {visible: false},
      labels: {
        visible: true
      }
    }
  };

  const arcCenterRenderer = (value: any, color: any) => {
    return <h3 style={{ color: color }}>{value}</h3>;
  };

  return (
    <div ref={containerRef} style={{
      width: '100%', 
      height: '200px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      marginTop: '10px'
    }}>
      <Work365Title title={title} />

      <div style={{ width: dimensions.width, height: dimensions.height }}>
        <ArcGauge style={gaugeStyles} {...arcOptions} arcCenterRender={arcCenterRenderer} />
      </div>
    </div>
  );
};

export default Work365ArcGauge;
