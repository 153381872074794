import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { Work365Dialog } from "../Core/Work365Dialog";

const Work365LoadingIndicator: React.FC = () => {
    const { promiseInProgress } = usePromiseTracker();
    const loadingLogo = require('../Assets/W365Loader.gif');

    return (
        (promiseInProgress === true && typeof window != 'undefined') ?
            <Work365Dialog>
                <div className='row'>
                    <div className='col-md-12' id='processingContainer'>
                        <img src={loadingLogo} alt="" height="25px" className='mr-1' />
                        <b>Loading...</b>
                        <br />
                    </div>
                </div>
            </Work365Dialog>
            :
            <div hidden></div>
    )
}

export default Work365LoadingIndicator;