import { Component } from "react";

import Work365Logo from '../../Assets/images/logo.png';

export default class Work365EmptyNavbar extends Component{
    render(){
        return (
            <nav className="navbar col-lg-12 col-12 p-0 d-flex flex-row" style={{backgroundColor: '#F2F4F4', border: '1px solid #DFE3E7'}}>
                <div className="d-flex mb-1" style={{backgroundColor: '#F2F4F4'}}>
                    <div className="">
                        <a className="w365sidebarLogo" href="/"><img src={Work365Logo} alt="logo" /></a>
                    </div>
                </div>
            </nav>
        )
    }
}