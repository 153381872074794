import React from 'react';
import Work365Card from '../Work365Card';
import {
    TreeView,
    processTreeViewItems,
    handleTreeViewCheckChange,
    TreeViewExpandChangeEvent,
    TreeViewCheckChangeEvent,
    TreeViewItemClickEvent

} from "@progress/kendo-react-treeview";

export interface ITreeViewDataItem {
    text: string;
    expanded?: boolean;
    checked?: boolean | null;
    selected?: boolean;
    items?: any[];
}

export interface IWork365TreeViewProps {
    data: ITreeViewDataItem[],
    expandIcon?: boolean,
    'aria-multiselectable'?: boolean,
    checkboxes?: boolean,
    item?: React.ComponentType<any>,
    onExpandChange?: (e: TreeViewExpandChangeEvent) => void,
    onCheckChange?: (e: TreeViewCheckChangeEvent) => void
}

const Work365TreeView: React.FC<IWork365TreeViewProps> = (p: IWork365TreeViewProps) => {
    const onCheckChange = (e: TreeViewCheckChangeEvent) => {
        if (p.onCheckChange)
            p.onCheckChange(e);
    }
    return (
        <div style={{width:'100%'}}>
            <TreeView
                data={p.data}
                expandIcons={p.expandIcon}
                aria-multiselectable={p['aria-multiselectable']}
                checkboxes={p.checkboxes}
                onExpandChange={p.onExpandChange}
                onCheckChange={onCheckChange}
                item={p.item}
            />
        </div>
    )
}

export default Work365TreeView;