import {
    DropDownList,
} from "@progress/kendo-react-dropdowns";
import React, { useEffect } from "react";

interface Work365DropdownList {
    data: any[]
    onChange: ((event: any) => void) | undefined;
    style?: React.CSSProperties;
    dataItemKey?: string | undefined;
    textField?: string | undefined;
    name?: string | undefined;
    defaultValue?: any | undefined;
    title?: string | undefined;
    disabled?: boolean | undefined;
}

const Work365DropdownList: React.FC<Work365DropdownList> = ({ data, onChange, style, dataItemKey, textField, name, defaultValue, title, disabled }) => {
    const [privateData, setPrivateData] = React.useState(data);
    const [value, setValue] = React.useState(defaultValue);

    function handleValueChange(event: any) {
        if (onChange != undefined) {
            onChange(event?.target.value);
        }

        setValue(event?.target.value);
    }

    function handleFilterChange(event: any) {
        const filterValue = event.filter.value;
        const filteredData = data.filter((item) => {
            return item.name.toLowerCase().includes(filterValue.toLowerCase())
        });

        debugger;
        setPrivateData(filteredData);
    }

    useEffect(() => {
        setPrivateData(data);
    }, [data]);

    useEffect(() => {

    }, [privateData]);

    return (
        <DropDownList
            style={style}
            data={privateData}
            value={value}
            onChange={handleValueChange}
            onFilterChange={handleFilterChange}
            dataItemKey={dataItemKey}
            textField={textField}
            filterable={true}
            name={name}
            title={title}
            disabled={disabled}
        />
    );
}

export default Work365DropdownList;