import React from "react";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";

export interface Work365TabStripTabProps {
    children: React.ReactNode;
    title?: React.ReactNode;
}
const Work365TabStripTab: React.FC<Work365TabStripTabProps> = ({
    children, 
    title
}) => {
    return (
        <TabStripTab title={title}>
            {children}
        </TabStripTab>
    );
};

export default Work365TabStripTab;
