import * as React from "react";
import './Work365LoggedInUser.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Work365DropDownButton } from "../DropDownButton/Work365DropDownButton";
import { Global } from '../../../Helpers/Global';
import { useNavigate } from 'react-router-dom';

export type Work365LoggedInUserProps = {
  name?: string | undefined;
}

const Work365LoggedInUser: React.FC<Work365LoggedInUserProps> = ({ name }) => {

  const navigate = useNavigate();
  const usermenu = [
    {
      Name: "Switch Environments",
      Action: () => {
        navigate('/');
      }
    },
    {
      Name: "Logout",
      Action: () => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);

        authService.SignOut();
      }
    }];
  return (
    <>
        <div className="box">
          <p className="nav-link d-flex justify-content-end">
            <FontAwesomeIcon icon={faUser} size="2x" style={{ paddingTop: '2px' }} />
            <div className="profile-name">{name}</div>
          </p>
        </div>
    </>
  )
}

export default Work365LoggedInUser;