import React, { useEffect } from "react";
import { Global } from "../../Helpers/Global";
import { Company } from "../../Lib/Models/Company";
import Work365CompanyProfileForm from "../../Forms/Work365CompanyProfileForm";

const Account: React.FC = () => {
  const [company, setCompany] = React.useState<Company>();

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  useEffect(() => {
    Global.getApiService(appSettings, authService)
      .ExecuteRequest("company/mycompany", "GET")
      .then((response: any) => {
        var resCompany = response.data;
        setCompany(resCompany);
      });
  }, []);

  const handleSubmit = (dataItem: any) => {
    var updateCompany: Company = {
      id: dataItem.id,
      name: dataItem.name,
      externalAccountId: dataItem.externalAccountId,
      activeDirectoryId: dataItem.activeDirectoryId,
      website: dataItem.website,
      createdBy: dataItem.createdBy,
      createdOnUtc: dataItem.createdOnUtc,
      modifiedBy: dataItem.modifiedBy,
      modifiedOnUtc: dataItem.modifiedOnUtc,
      primaryContactFirstName: dataItem.primaryContactFirstName,
      primaryContactLastName: dataItem.primaryContactLastName,
      primaryContactPhoneNumber: dataItem.primaryContactPhoneNumber,
      primaryContactEmail: dataItem.primaryContactEmail,
      preferredLanguage: dataItem?.preferredLanguageOption?.value,
      billToCompanyName: dataItem.billToCompanyName,
      billToContactFirstName: dataItem.billToContactFirstName,
      billToContactLastName: dataItem.billToContactLastName,
      billToContactPhoneNumber: dataItem.billToContactPhoneNumber,
      billToContactEmail: dataItem.billToContactEmail,
      billToPONumber: dataItem.billToPONumber,
      companyVatId: dataItem.companyVatId,
      billingCurrency: dataItem?.billingCurrencyOption?.currencyIsoCode,
      address: {
        id: dataItem?.address?.id,
        street1: dataItem?.address?.street1,
        street2: dataItem?.address?.street2,
        city: dataItem?.address?.city,
        state: dataItem?.address?.state,
        postalCode: dataItem?.address?.postalCode,
        country: dataItem?.addressCountryOption?.countryAlpha2Code
      },
      billToAddress: {
        id: dataItem?.billToAddress?.id,
        street1: dataItem?.billToAddress?.street1,
        street2: dataItem?.billToAddress?.street2,
        city: dataItem?.billToAddress?.city,
        state: dataItem?.billToAddress?.state,
        postalCode: dataItem?.billToAddress?.postalCode,
        country: dataItem?.billToAddressCountryOption?.countryAlpha2Code
      }
    }

    setCompany(updateCompany);

    Global.getApiService(appSettings, authService)
      .ExecuteRequest("company/update", "PUT", updateCompany)
      .then((response: any) => {
        Global.showMessage(
          "Successfully updated Company Profile!",
          "success"
        );
      });
  };

  return (
    <div style={{ marginTop: "7px" }}>
      <Work365CompanyProfileForm company={company} onSubmit={handleSubmit} />
    </div>
  );
};

export default Account;
