import React from "react";
import { RadialGauge, RadialGaugeProps } from "@progress/kendo-react-gauges";
import { ProgressBar } from "@progress/kendo-react-progressbars";

export interface Work365ProgressBarProps {
  value: number;
}

const Work365ProgressBar: React.FC<Work365ProgressBarProps> = ({
    value,
}) => {

  const radialOptions: RadialGaugeProps = {
    pointer: {
        value: value,
    },
  };

  return (
    <div className="text-center">
        <ProgressBar 
          value={value} />
    </div>
  );
};

export default Work365ProgressBar;
