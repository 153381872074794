import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Global } from '../../Helpers/Global';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import Work365CreateProviderForm from '../../Forms/Work365CreateProviderForm';
import { ElementCompact, js2xml } from 'xml-js';

type CreateProviderProps = {
    tenantId?: string;
};

const CreateProvider: React.FC<CreateProviderProps> = ({
    tenantId
}) => {
    const [operationId, setOperationId] = useState();
    const [inProgress, setInProgress] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [partnerCenterTenantId, setPartnerCenterTenantId] = useState();

    const navigate = useNavigate();

    const [priceLists, setPriceLists] = useState([]);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    const operationType = 2;

    let intervalNumber: any = undefined;

      function jsonToXml(json: any) {
        let xml = '';
      
        for (const [key, value] of Object.entries(json)) {
          xml += `<${key}>`;
          if (typeof value === 'object') {
            xml += jsonToXml(value);
          } else {
            xml += value;
          }
          xml += `</${key}>`;
        }
      
        return xml;
      }

    const checkProgress = () => {
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/get/${operationId}`, 'GET', undefined, false)
            .then((response: any) => {
                if (response.data != undefined) {
                    setProgress(response.data.progressPercent);

                    if (response.data.operationStatus != 'P'){
                        clearInterval(intervalNumber);
                        setCompleted(true);

                        intervalNumber = undefined;
                    }
                }
            });
    }

    function handleNewOperation() {
        setTimeout(function() {
            getOperation();
        }, 5000)
    }

    useEffect(() => {
        getOperation();

        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/provider/getpartnercenterid`, 'GET', undefined, false)
            .then((response: any) => {
                console.log('partner center : ');
                console.log(response.data);
                setPartnerCenterTenantId(response.data);
            });

        return () => {
            if (intervalNumber != undefined) {
                clearInterval(intervalNumber);
                intervalNumber = undefined;
            }
        };
    }, [])

    function getOperation() {
        let operationContext = {
            operationType: operationType
        };

        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
            .then((response: any) => {
                if (response.data == undefined) {
                    Global.getApiService(appSettings, authService)
                        .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
                        .then((response: any) => {
                            setOperationId(response.data);
                        });
                }
                else{
                    setOperationId(response.data);
                    
                    if (response.data.operationStatus == 'P'){
                        setInProgress(true);
                        setProgress(response.data.progressPercent);

                        if (intervalNumber != undefined) {
                            clearInterval(intervalNumber);
                            intervalNumber = undefined;
                        }

                        intervalNumber = setInterval(checkProgress, 5000);
                    }
                }
            });
        
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/pricelist/get`, 'GET')
            .then((response: any) => {
                setPriceLists(response.data.map((pricelist: any) => {
                    return {
                        name: pricelist.name,
                        value: pricelist.id
                    };
                }));
            });
    }

    const handleSubmit = (dataItem: any) => {
        var additionalInformation: any = {
            ProviderProperties: {
            }
        };

        if (dataItem?.countryCode?.value){
            additionalInformation.ProviderProperties.countryCode = dataItem?.countryCode?.value;
        }

        if (dataItem?.currencyCode?.value){
            additionalInformation.ProviderProperties.currencyCode = dataItem?.currencyCode?.value
        }

        if (dataItem?.azureSpecializations?.value){
            additionalInformation.ProviderProperties.passSpecializationDiscountToCustomer = dataItem?.azureSpecializations?.value;
        }

        if (dataItem?.taxRate){
            additionalInformation.ProviderProperties.defaultTaxRate = dataItem?.taxRate;
        }

        if (dataItem?.resellerRelationshipAuthorize){
            additionalInformation.ProviderProperties.resellerRelationshipAuthorizeUrl = dataItem?.resellerRelationshipAuthorize;
        }

        if (dataItem?.delegatedAdminAuthorizeURL){
            additionalInformation.ProviderProperties.delegatedAdminAuthorizeUrl = dataItem?.delegatedAdminAuthorizeURL;
        }

        if (dataItem?.usageMismatchTolerance){
            additionalInformation.ProviderProperties.usageMismatchTolerance = dataItem?.usageMismatchTolerance;
        }

        if (partnerCenterTenantId){
            additionalInformation.ProviderProperties.partnerCenterTenantId = partnerCenterTenantId;
        }

        // The following values are populated server side
        // TenantId
        // ConnectorType / ConnectorTypeId
        // EndPointUrl
        var connector = { 
            name: dataItem?.name,
            parameters: jsonToXml(additionalInformation)
        };

        var requestBody = { 
            currencyCode: dataItem?.currencyCode?.value,
            pricelistId: dataItem?.defaultPriceList?.value,
            connector: connector
        }

        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/provider/create`, 'POST', requestBody)
            .then((response => { 
                navigate(`/Environment/${tenantId}/consent/${response.data}`);
            }));
    };

    return (
        <div>
            {inProgress && (
                <Work365OperationInProgressModel isCompleted={completed} value={progress} />
            )}
            <Work365CreateProviderForm onSubmit={handleSubmit} priceLists={priceLists}/>
        </div>
    );
}

export default CreateProvider;