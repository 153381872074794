import React from "react";
import Work365Card from "../../Lib/Core/Work365Card";
import {
  GridExpandChangeEvent,
  GridGroupChangeEvent,
  GridPageChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Row } from "react-bootstrap";
import { Global } from "../../Helpers/Global";
import Work365FlexiGrid, {
  IGridState,
} from "../../Lib/Core/Grid/Work365FlexiGrid";
import {
  SortDescriptor,
  orderBy,
  groupBy,
  GroupDescriptor,
  GroupResult,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from "@progress/kendo-data-query";
import {
  setGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { Size } from "../../Lib/Models/Size";
import Work365Switch from "../../Lib/Core/Work365Switch";
import { Button } from "@progress/kendo-react-buttons";
import { Utils } from "../../Services/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Work365Column } from "../../Lib/Models/Work365Column";
import { Work365Dialog } from "../../Lib/Core/Work365Dialog";
import Work365TabStrip from "../../Lib/Core/Work365TabStrip";
import Work365TabStripTab from "../../Lib/Core/Work365TabStripTab";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import Work365PagingGrid from "../../Lib/Core/Work365PagingGrid";
import Work365Textbox from "../../Lib/Core/TextBox/Work365Textbox";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";

interface ICustomer {
  customerId: string | undefined;
  name: string | undefined;
  website: string | undefined;
  isActive: boolean | undefined;
  createdOnUtc: Date | undefined;
  tenantCount: number | undefined;
  expanded: boolean | undefined;
  tenants: [];
}

interface ICustomerTenantState {
  customers: ICustomer[];
  searchCustomerResults: ICustomer[];
  selectedCustomer: string;
  gridState: IGridState;
  style: Size;
  includeDisabled: boolean;
}

const initialDataState: GroupDescriptor[] = [];

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "isActive",
      operator: "eq",
      value: true,
    },
    {
      field: "customerName",
      operator: "contains",
      value: "unattached",
    },
  ] as FilterDescriptor[],
} as CompositeFilterDescriptor;

const processWithGroups = (
  t: any[],
  group: GroupDescriptor[],
  searchKey: string | undefined
) => {
  const newDataState = groupBy(
    orderBy(
      filterBy(t, {
        logic: "or",
        filters: [
          { field: "customerName", operator: "contains", value: searchKey },
          { field: "endPointUrl", operator: "contains", value: searchKey },
          { field: "appVersion", operator: "contains", value: searchKey },
          { field: "_prod", operator: "eq", value: searchKey },
          { field: "_eligible", operator: "eq", value: searchKey },
          { field: "_enforceEntitlement", operator: "eq", value: searchKey },
          { field: "_tenantStatus", operator: "eq", value: searchKey },
          { field: "_customerStatus", operator: "eq", value: searchKey },
        ],
      }),
      [
        { field: "customerName", dir: "asc" },
        { field: "endPointUrl", dir: "asc" },
      ]
    ),
    group
  );
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};
const processWithfilter = (t: any[], filter: CompositeFilterDescriptor) => {
  const newDataState = orderBy(filterBy(t, filter), [
    { field: "customerName", dir: "asc" },
    { field: "endPointUrl", dir: "asc" },
  ]);
  return newDataState;
};

const CustomerTenant: React.FC = () => {
  const [filter, setFilter] = React.useState(initialFilter);

  const [sort, setSort] = React.useState([
    {
      field: "customerName",
      dir: "asc",
    } as SortDescriptor,
  ]);

  const _export = React.useRef<ExcelExport | null>(null);
  const pageSize = 20;
  const [pageInfo, setPageInfo] = React.useState({ skip: 0, take: pageSize });
  const [state, setState] = React.useState<ICustomerTenantState>({
    customers: [] as ICustomer[],
    searchCustomerResults: [] as ICustomer[],
    gridState: {
      groupable: false,
      resizable: true,
      filterable: false,
      sortable: true,
      scrollable: "virtual",
      total: 0,
      skip: pageInfo.skip,
      take: pageSize,
      filter: filter,
      sort: sort,
    } as IGridState,

    selectedCustomer: "",
    style: {
      height: 82,
      width: 83,
    } as Size,
    includeDisabled: false,
  });

  const [group, setGroup] = React.useState(initialDataState);
  const [resultState, setResultState] = React.useState<GroupResult[]>([]);
  const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
  const [columns, setColumns] = React.useState<Work365Column[]>([]);

  const [confirmS2S, setConfrimS2S] = React.useState(false);
  const [showError, setShowError] = React.useState({
    value: false,
    message: "",
  });
  const [tenants, setTenants] = React.useState<any[]>([]);
  const [selectedTenantId, setSelectedTenantId] = React.useState("");
  const [includeInactive, setIncludeInactive] = React.useState(false);
  let navigate = useNavigate();

  var appSettings = Global.getAppSettings();
  var authService = Global.getAuthService(appSettings);
  var apiClient = Global.getApiService(appSettings, authService);

  const loc = useLocation();

  var customerName = loc.hash.replace("#", "");
  var custNo = loc.pathname.split("/").pop();

  const getTenants = (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      apiClient
        .ExecuteRequest(`tenant/withcustomerdetails/${custNo}/get`, "GET")
        .then((tenantResponse: any) => {
          resolve(tenantResponse.data);
        });
    });
  };

  React.useEffect(() => {
    const initialLoad = async () => {
      var [tenants] = await Promise.all([getTenants()]);
      tenants = tenants.map((tenant: any) => {
        tenant.isActive = !tenant.disabled;
        return tenant;
      });

      console.log(tenants);

      setState({
        ...state,
        selectedCustomer: customerName,
        gridState: {
          ...state.gridState,
          group: initialDataState,
          total: tenants?.length,
        },
      });

      setTenants(tenants);
      setColumns(gridColumns);
      setResultState(tenants);
    };

    initialLoad();
  }, []);

  const handleSwitchChange = (e: any) => {
    var value = e.value;
    updateResultList(
      e.dataItem.guid,
      e.field ?? "",
      value
    );
    updateTenant(e.dataItem.guid, e.field ?? "", value);
  };

  const SwitchCustomCell = (props: any) => {
    const field = props.field || "";
    if (props.dataItem.items) {
      return <></>;
    }
    var value = props.dataItem[field];
    return (
      <td
        style={{
          color: "white",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        <Work365Switch
          key={`${field}_${value}`}
          data={props.dataItem}
          field={field}
          value={value === null ? false : value}
          disabled={disableControls}
          onChange={(e: any) => {
            handleSwitchChange(e);
          }}
        />
      </td>
    );
  };

  const ActionCustomCell = (props: any) => {
    const field = props.field || "";
    if (props.dataItem.items) {
      return <></>;
    }

    const version = props.dataItem["appVersion"] || "0.0";
    const tenantUrl = props.dataItem["fullEndPointUrl"];
    return (
      <td
        style={{
          color: "white",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        <a target="_blank" href={Utils.ToAdminHubUrl(version, tenantUrl)}>
          <Button
            icon="gear"
            size={"small"}
            fillMode={"link"}
            themeColor={"primary"}
            title="Support Impersonation"
            disabled={disableControls}
          />
        </a>
        <Button
          icon="lock"
          size={"small"}
          fillMode={"link"}
          themeColor={"primary"}
          title={
            props.dataItem.username
              ? "Environment already using S2s Authentication"
              : "Convert to S2S Authentication"
          }
          onClick={() => {
            setSelectedTenantId(props.dataItem.guid);
            setConfrimS2S(true);
          }}
          disabled={disableControls ?? props.dataItem.username}
        />
      </td>
    );
  };

  const [gridState, setGridState] = React.useState<IGridState>({
    groupable: false,
    resizable: true,
    filterable: false,
    sortable: true,
    scrollable: "none",
    pageSize: 30,
    rowHeight: 40,
  });

  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 30,
    group: [],
  });

  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "de",
      locale: "de",
    },
    {
      language: "ja",
      locale: "ja",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];

  var selectedlang = locales[0];

  const ConvertToUserAuth = (tenantId: string) => {
    let newEnvironments = tenants.map((t: any) => {
      if (t.guid == tenantId) {
        t.username = "done";
      }
      return t;
    });

    setResultState(processWithfilter(newEnvironments, filter));

    apiClient
      .ExecuteRequest(`tenant/${tenantId}/convertToUserAuth`, "PUT")
      .then(async (tenantResponse: any) => {
        setConfrimS2S(false);
      });
  };

  const LinkCustomCell = (props: any) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    return (
      <td style={{ color: "blue" }}>
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            CanConnectToDataVerse(props.dataItem["guid"], () => {
              navigate(`/Environment/${props.dataItem["guid"]}`);
            });
          }}
        >
          {value}
        </p>
      </td>
    );
  };

  const CanConnectToDataVerse = (tenantId: string, callback: Function) => {
    apiClient
      .ExecuteRequest(`tenant/${tenantId}/canConnectToDataverse`, "GET")
      .then((canConnect: any) => {
        if (!canConnect.data) {
          setShowError({
            value: true,
            message: "Unable to connect to dataverse",
          });
        } else {
          callback();
        }
      });
  };

  const CustomerLinkCustomCell = (props: any) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const link = props.dataItem["crmRecord"];
    return link == "" ? (
      <td>{value}</td>
    ) : (
      <td style={{ color: "blue" }}>
        <a href={`${link}`} target="_blank">
          {value}
        </a>
      </td>
    );
  };

  const DateCustomCell = (props: any) => {
    var field = props.field || "";
    var value = props.dataItem[field];
    return <td>{Utils.ToDisplayDateFormat(value, false, "fr-CA")}</td>;
  };

  function updateTenant(id: string, prop: string, value: any) {
    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);
    var apiClient = Global.getApiService(appSettings, authService);

    const data = {
      [prop]: value,
    };

    apiClient
      .ExecuteRequest(
        `tenant/update?tenantId=${id}&data=${JSON.stringify(data)}`,
        "PUT"
      )
      .then(async (response: any) => {});
  }

  const updateResultList = (id: string, prop: string, value: any) => {
    const newResultList = tenants.map((t: any) => {
      if (t.guid == id) {
        return { ...t, [prop]: value };
      }
      return t;
    }) as never[];
    if (newResultList.length > 0) setTenants(newResultList);
  };

  const onGroupChange = (event: GridGroupChangeEvent) => {
    const newDataState = processWithGroups(tenants, event.group, "");
    setGroup(event.group);
    setResultState(newDataState);
    setState({
      ...state,
      gridState: {
        ...state.gridState,
        group: event.group,
      },
    });
  };

  const onExpandChange = (event: GridExpandChangeEvent) => {
    const item = event.dataItem;
    if (item.groupId) {
      const newCollapsedids = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);

      setCollapsedState(newCollapsedids);
    }
  };

  const newData = () => {
    return setExpandedState({
      data: resultState,
      collapsedIds: collapsedState,
    });
  };

  const gridColumns = [
    {
      field: "customerName",
      title: "Customer Name",
      width: 150,
      cell: CustomerLinkCustomCell,
      filterable: true,
      filter: "text",
    },
    {
      field: "endPointUrl",
      title: "Endpoint URL",
      width: 150,
      cell: LinkCustomCell,
      filterable: true,
      filter: "text",
    },
    {
      field: "tenantVersion",
      title: "Environment Ver.",
      width: 150,
      filterable: true,
      filter: "text",
    },
    {
      field: "isActive",
      title: "Active",
      width: 80,
      cell: SwitchCustomCell,
    },
    {
      field: "isProduction",
      title: "Production",
      width: 150,
      filterable: false,
      cell: SwitchCustomCell,
    },
    {
      field: "enforceEntitlement",
      title: "Enforce Entitlement",
      width: 170,
      filterable: false,
      cell: SwitchCustomCell,
    },
    {
      field: 'maxEntitlements',
      title: 'Entitlements',
      width: 150,
      filterable: true,
      filter: 'numeric',
  },
    {
      title: "Actions",
      width: 100,
      filterable: false,
      cell: ActionCustomCell,
    },
  ] as Work365Column[];

  const onFilterChange = (e: any) => {
    setFilter(e.filter);
    setPageInfo({ skip: 0, take: state.gridState.take ?? 0 });
    setState({
      ...state,
      gridState: {
        ...state.gridState,
        skip: 0,
        filter: e.filter,
      },
    });

    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const onSortChange = (e: any) => {
    setSort(e.sort);
    setPageInfo({ skip: 0, take: state.gridState.take ?? 0 });
    setState({
      ...state,
      gridState: {
        ...state.gridState,
        skip: 0,
        sort: e.sort,
      },
    });
    setResultState(processWithfilter(tenants, filter));
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(
        filterBy(orderBy(newData(), sort), filter)?.map((x: any) => {
          x.createdOnUtc = Utils.ToDisplayDateFormat(
            x.createdOnUtc,
            false,
            "fr-CA"
          );
          x.deactivatedOnUtc = Utils.ToDisplayDateFormat(
            x.deactivatedOnUtc,
            false,
            "fr-CA"
          );
          return x;
        }),
        columns
      );
    }
  };

  var userString = localStorage.getItem("user");
  var disableControls = true;

  if (userString != undefined) {
    var user: any = JSON.parse(userString);
    if (user.isAdministrator || user.isManager) {
      disableControls = false;
    }
  }

  return (
    <>
      {confirmS2S ? (
        <Work365Dialog
          width={650}
          onClose={() => setConfrimS2S(false)}
          title={"Confirmation"}
          children={
            <>
              <p>
                Are you sure, you want convert this Environment Authentication
                mode to User Authentication?
              </p>
              <Row>
                <div style={{ textAlign: "right" }}>
                  <Button
                    themeColor="primary"
                    onClick={() => ConvertToUserAuth(selectedTenantId)}
                  >
                    Yes
                  </Button>{" "}
                  &nbsp;
                  <Button
                    themeColor="error"
                    onClick={() => setConfrimS2S(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </>
          }
        />
      ) : (
        <></>
      )}
      {showError.value ? (
        <Work365Dialog
          width={300}
          onClose={() => setShowError({ value: false, message: "" })}
          title={"Error"}
          children={
            <>
              <p>{showError.message}</p>
              <Row>
                <div style={{ textAlign: "right" }}>
                  <Button
                    themeColor="error"
                    onClick={() => setShowError({ value: false, message: "" })}
                  >
                    Ok
                  </Button>
                </div>
              </Row>
            </>
          }
        />
      ) : (
        <></>
      )}
        <Work365Card>
              <Work365PagingGrid
                excelref={_export}
                state={{
                  data: newData(),
                  dataState: dataState,
                  currentLocale: selectedlang,
                  lastUsedDataState: null,
                  skip: 0,
                  take: 10000,
                  morePagesAvailable: false,
                  isLoading: false,
                }}
                columns={columns}
                filter={filter}
                sort={sort}
                onFilterChange={onFilterChange}
                onSortChange={onSortChange}
                showEditButton={false}
                groupable={false}
                sortable={false}
                filterable={false}
                reorderable={false}
                onEditButtonClick={() => {}}
                gridtoolbar={
                  <GridToolbar>
                    <Work365Switch
                      style={{ marginRight: "10px", marginTop: "2px" }}
                      title="Include Inactive"
                      key="environment-include-inactive"
                      value={includeInactive}
                      onChange={(e) => {
                        if (e.value) {
                          filter.filters = filter.filters.filter(
                            (x: any) => x.field !== "isActive"
                          );
                        } else {
                          const filterIndex = filter.filters.findIndex(
                            (x: any) =>
                              x.field === "isActive" && x.operator === "eq"
                          ) as any;
                          if (filterIndex !== -1) {
                            filter.filters[filterIndex] = {
                              field: "isActive",
                              operator: "eq",
                              value: true,
                            };
                          } else {
                            filter.filters.push({
                              field: "isActive",
                              operator: "eq",
                              value: true,
                            });
                          }
                        }

                        setFilter({ ...filter });
                        setIncludeInactive(!includeInactive);
                        setGridState({ ...gridState, filter: filter });
                        setDataState({ ...dataState, skip: 0 });

                        let gridContainer =
                          document.querySelector(".k-grid-content");
                        if (gridContainer) {
                          gridContainer.scrollTop = 0;
                        }
                      }}
                    />
                    <Work365Textbox
                      placeHolder="Type here to filter based on Customer Name."
                      style={{ width: "300px", marginRight: "10px" }}
                      onChange={(event) => {
                        if (
                          event.value === "" ||
                          event.value == undefined ||
                          event.value == null
                        ) {
                          const filterIndex = filter.filters.findIndex(
                            (x: any) => x.field === "customerName"
                          ) as any;

                          if (
                            filterIndex !== -1 &&
                            filterIndex > -1 &&
                            filterIndex < filter.filters.length
                          ) {
                            filter.filters.splice(filterIndex, 1);

                            setFilter({ ...filter });
                            setGridState({ ...gridState, filter: filter });
                            setDataState({ ...dataState, skip: 0 });
                          }

                          return;
                        }
                        const filterIndex = filter.filters.findIndex(
                          (x: any) => x.field === "customerName"
                        ) as any;
                        if (filterIndex !== -1) {
                          filter.filters[filterIndex] = {
                            field: "customerName",
                            operator: "contains",
                            value: event.value,
                          };
                        } else {
                          filter.filters.push({
                            field: "customerName",
                            operator: "contains",
                            value: event.value,
                          });
                        }

                        setFilter({ ...filter });
                        setGridState({ ...gridState, filter: filter });
                        setDataState({ ...dataState, skip: 0 });
                      }}
                    />
                    <Work365PrimaryButton
                      style={{ marginLeft: "auto" }}
                      title="Export to Excel"
                      onClick={excelExport}
                    />
                  </GridToolbar>
                }
                onNextPage={() => {}}
                onPreviousPage={() => {}}
              >

              </Work365PagingGrid>
        </Work365Card>
    </>
  );
};

export default CustomerTenant;
