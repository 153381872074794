import * as React from "react";

import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Global } from '../../../Helpers/Global';

interface State {
  success: boolean,
  error: boolean,
  warning: boolean,
  info: boolean,
  none: boolean
}

interface MessageStates {
  successMessage?: string | undefined,
  errorMessage?: string | undefined,
  warningMessage?: string | undefined,
  infoMessage?: string | undefined,
  noneMessage?: string | undefined
}

function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

const Work365Notifications: React.FC = () => {
  const [state, setState] = React.useState<State>({ success: false, error: false, warning: false, info: false, none: false });
  const [messages, setMessages] = React.useState<MessageStates>({successMessage: undefined, errorMessage: undefined, warningMessage: undefined, infoMessage: undefined, noneMessage: undefined});
  const forceUpdate = useForceUpdate();


  const setMessage = (flag: 'success' | 'error' | 'warning' | 'info' | 'none', message: string) => {
    if (flag === 'success'){
      messages.successMessage = message;
    }

    if (flag === 'error'){
      messages.errorMessage = message;
    }

    if (flag === 'warning'){
      messages.warningMessage = message;
    }

    if (flag === 'info'){
      messages.infoMessage = message;
    }

    if (flag === 'none'){
      messages.noneMessage = message;
    }

    console.log(messages);
    setMessages(messages);
    forceUpdate();
  }
  Global.setNotificationMessage = setMessage;

  const showNotification = (flag: 'success' | 'error' | 'warning' | 'info' | 'none') => {
    if (flag === 'success'){
      state.success = !state.success;
    }

    if (flag === 'error'){
      state.error = !state.error;
    }

    if (flag === 'warning'){
      state.warning = !state.warning;
    }

    if (flag === 'info'){
      state.info = !state.info;
    }

    if (flag === 'none'){
      state.none = !state.none;
    }

    console.log(state);
    setState(state);
    forceUpdate();
  }
  Global.showNotificationMessage = showNotification;

  const { success, error, warning, info, none } = state;

  return <NotificationGroup
  style={{
            right: 0,
            bottom: 0,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse'
        }}
    >
  <Fade>
    {success && <Notification
      type={{ style: 'success', icon: true }}
      closable={true}
      onClose={() => setState({ ...state, success: false })}
            >
      <span>{messages.successMessage}</span>
    </Notification>}
  </Fade>
  <Fade>
    {error && <Notification
      type={{ style: 'error', icon: true }}
      closable={true}
      onClose={() => setState({ ...state, error: false })}
            >
      <span>{messages.errorMessage}</span>
    </Notification>}
  </Fade>
  <Fade>
    {warning && <Notification
      type={{ style: 'warning', icon: true }}
      closable={true}
      onClose={() => setState({ ...state, warning: false })}
            >
      <span>{messages.warningMessage}</span>
    </Notification>}
  </Fade>
  <Fade>
    {info && <Notification
      type={{ style: 'info', icon: true }}
      closable={true}
      onClose={() => setState({ ...state, info: false })}
            >
      <span>{messages.infoMessage}</span>
    </Notification>}
  </Fade>
  <Fade>
    {none && <Notification
      type={{ style: 'none', icon: false }}
      closable={true}
      onClose={() => setState({ ...state, none: false })}
      style={{ overflow: 'visible' }}
            >
      <span>{messages.noneMessage}</span>
    </Notification>}
  </Fade>
</NotificationGroup>
}

export default Work365Notifications;