import React, { useEffect, useState } from 'react';
import { Global } from '../../Helpers/Global';
import { useNavigate, useParams } from 'react-router-dom';
import Work365CreateTenantForm from '../../Forms/Work365CreateTenantForm';
import Work365Label from '../../Lib/Core/Work365Label';

const RegisterNewTenant: React.FC = () => {

    const navigate = useNavigate();

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);
    
    const { tenantUrl } = useParams<{ tenantUrl: string }>();
    const [timeZones, setTimeZones] = useState<any[]>([]);

    function onSubmit(data: any) {
       console.log(data); 
       var newTenant = {
              endPointUrl: tenantUrl,
              organizationName: data.organizationName,
              uniqueName: data.uniqueName,
              timeZone: data.timeZone.value,
              environmentType: data.environmentType.value
       };

       debugger;
       Global.getApiService(appSettings, authService)
        .ExecuteRequest('tenant/create', 'POST', undefined, undefined, newTenant)
        .then((response: any) => {
            navigate('/ManageEnvironments');
        });
    }

    useEffect(() => {
        Global.getApiService(appSettings, authService)
            .ExecuteRequest('timezone/get', 'GET')
            .then((response: any) => {
                console.log(response);
                setTimeZones(response.data.map((timezone: any) => {
                    return {
                        name: timezone.displayName,
                        value: timezone.standardName
                    };
                }));
            })
    }, [])

    return (
        <div>
            <Work365Label>To finish the registeration process for [{tenantUrl}] please confirm the following details.</Work365Label>
            <br />
            <Work365CreateTenantForm onSubmit={onSubmit} timeZones={timeZones} />
        </div>
    );
}

export default RegisterNewTenant;