import Work365Drawer from '../../Lib/Core/Work365Drawer';
import { Work365Routes } from '../../Helpers/Routes';
import { Global } from '../../Helpers/Global';
import { DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Support, Administrator, JobController, Manager } from '../../Lib/Models/Roles';

function translatePath(path: string): string {
    return path.replace(':tenantId', Global.getTenantGuid());
}

function getItems(): any {
    var navbarItems: any[] = [];
    var isIotapUser = localStorage.getItem('isIotapUser') == 'true';
    var userString = localStorage.getItem('user');

    if (userString == undefined) {
        return navbarItems;
    }

    var user: any = JSON.parse(userString);
    
    Work365Routes.filter(x => {
        if (isIotapUser){

            if (x.isAdminApp == undefined || !x.isAdminApp) {
                return false;
            }

            if (user.isAdministrator) {
                return true;
            }

            if (x.permission == Support && user.isSupportPerson) {
                return true;
            }

            if (x.permission == Manager && user.isManager) {
                return true;
            }

            if (x.permission == JobController && user.isJobController) {
                return true;
            }

            return false;
        }

        return !x.isAdminApp;
    }).forEach(function (route) {
        if (route.children != null && route.children.length > 0) {
            route.children.forEach(function (childRoute) {
                if (childRoute.showOnNavBar) {
                    navbarItems.push({
                        text: childRoute.navbarName,
                        icon: childRoute.navbarIcon,
                        route: translatePath(childRoute.path)
                    });

                    navbarItems.push({
                        separator: true
                    });
                }
            });
        }

        if (route.showOnNavBar) {
            navbarItems.push({
                text: route.navbarName,
                icon: route.navbarIcon,
                route: translatePath(route.path),
                seperator: true
            });
        }
    });

    return navbarItems;
}

const customItem = (props: DrawerItemProps) => {
    var work365Route = Work365Routes.find(route => route.navbarName === props.text);

    if (work365Route != undefined && work365Route.navbarIcon != null) {
        return (
            <DrawerItem {...props}>
                <div style= {{ width: 34 }} className='text-center'>
                    <FontAwesomeIcon icon={work365Route.navbarIcon} />
                </div>
                <div className='item-descr-wrap ml-2'>
                    <div>{props.text}</div>
                </div>
            </DrawerItem>
        )
    }

    return (
        <DrawerItem {...props}>
            <div className='item-descr-wrap'>
                <div>{props.text}</div>
            </div>
        </DrawerItem>
    )
}

const EverestSidebar = (props: any) => {

    return (
        <>
            < Work365Drawer
                items={getItems()}
                customItem={customItem} >
                {props.children}
            </Work365Drawer >

        </>

    );
}

export default EverestSidebar;