import React, { ReactNode } from 'react';
import Work365Card from '../Core/Work365Card';
import Work365PagingGrid from '../Core/Work365PagingGrid';
import { Global } from '../../Helpers/Global';
import { GridState } from '../Models/GridState';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { GridColumn } from '@progress/kendo-react-grid';
import Work365Textbox from '../Core/TextBox/Work365Textbox';
import Work365AutocompleteTextbox from '../Core/TextBox/Work365AutocompleteTextbox';
import { Work365DropdownItem } from '../Models/Work365DropdownItem';
import { Col, Row } from 'react-bootstrap';
import Work365PrimaryButton from '../Core/Buttons/Work365PrimaryButton';

export type CustomersMatchGridProps = {
    providerId: string | undefined;
    tenantId: string | undefined;
    selectedAccounts: string[];
    matchedAccounts: any[];
    handleCheckboxClicked: (object: any, event: CheckboxChangeEvent) => void;
    onTextboxChange: ((providerId: any, event: any) => void);
    unmatchAllCustomers: (objects: any[]) => void
    matchAllCustomers: (objects: any[]) => void;
    handleMatchCustomers: () => void;
    crmCustomers: any[];
}

const CustomersMatchGrid: React.FC<CustomersMatchGridProps> = ({ 
    tenantId,
    providerId, 
    handleCheckboxClicked, 
    selectedAccounts,
    matchedAccounts,
    crmCustomers,
    onTextboxChange,
    unmatchAllCustomers,
    matchAllCustomers,
    handleMatchCustomers }) => {

    const [visible, setVisible] = React.useState(false);
    const [update, setUpdate] = React.useState(0);

    const PAGE_SIZE = 50;

    const locales = [{
        language: 'en-US',
        locale: 'en'
    }, {
        language: 'de',
        locale: 'de'
    },
    {
        language: 'ja',
        locale: 'ja'
    },
    {
        language: 'es-ES',
        locale: 'es'
    }
    ];

    //default to english
    var selectedlang = locales[0];

    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);

    locales.forEach(function (arrayItem) {
        if (arrayItem.locale == localStorage.getItem('i18nextLng')) {
            selectedlang = arrayItem;
        }
    });

    const [state, setState] = React.useState<GridState>(
        { 
            data: null, 
            dataState: null, 
            isLoading: false, 
            currentLocale: selectedlang, 
            lastUsedDataState: null,
            skip: 0,
            take: PAGE_SIZE,
            morePagesAvailable: false
        }
    );

    function handleAccounts(accounts: any[]) {
        accounts.forEach(function (account) {
            var matchedAccount = matchedAccounts?.filter(x => x?.providerCustomerId === account?.providerSpecificAccountId);

            if (!matchedAccount){
                return;
            }

            if (matchedAccount.length == 0){
                return;
            }

            account.crmName = matchedAccount[0].crmCustomerName;
        });

        return accounts;
    }

    const fetchData = (event: string) => {
        let params = {
            $skip: state.skip,
            $top: state.take
        }

        if (event.toLowerCase() === 'increase') {
            params.$skip += state.take;
        }
        else if (event.toLowerCase() === 'decrease') {
            params.$skip -= state.take;
        }

        // TODO : there's a performance gain here!
        // We can add server side filtering so this page is a lot faster.
        // Currently, yes the data is "server" side filtered, but on the API side
        // we are getting ALL provider accounts in both CRM and Partner Center.
        // Technically, we just need to get ALL provider accounts in CRM and then apply filtering on Partner Center, or even better we can cache that value.
        Global.getApiService(appSettings, authService)
            .ExecuteRequest(`tenant/${tenantId}/provider/${providerId}/unlinked/get`, 'GET', undefined, undefined, params).then((response: any) => {
            if (event.toLowerCase() === 'increase'){
                setState(
                {
                    data: handleAccounts(response.data.items),
                    dataState: undefined,
                    currentLocale: state.currentLocale,
                    isLoading: false,
                    lastUsedDataState: undefined,
                    skip: state.skip + PAGE_SIZE,
                    take: state.take,
                    morePagesAvailable: response.data.morePagesAvailable}
                );
            }
            else if (event.toLowerCase() === 'decrease'){
                setState(
                    {
                        data: handleAccounts(response.data.items),
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip - PAGE_SIZE,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    }
                );
            }
            else{
                setState(
                    {
                        data: handleAccounts(response.data.items),
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    }
                );
            }
        });
    }

    React.useEffect(() => {
        fetchData('');
    }, [matchedAccounts]);

    const handleEditButtonClick = (id: any) => {
        
    };

    const onNextpage  = () => {
        fetchData('INCREASE');
    };

    const onPreviousPage = () => {
        fetchData('DECREASE');
    };

    const onCheckboxClicked = (object: any, event: CheckboxChangeEvent) => {
        handleCheckboxClicked(object, event);
        setUpdate(update + 1);
    }

    function isSelected(account: any)
    {
        var accountFilter = selectedAccounts.filter(item => item === account.providerSpecificAccountId);
        return accountFilter.length === 1;
    }

    const filterTextbox = ({ dataItem }: { dataItem: any }) => {
        let selectedItem = selectedAccounts.find(x => x === dataItem.providerSpecificAccountId);

        let autocompleteData : Work365DropdownItem[] = crmCustomers.map((account: any) => {
            return {
                Name: account.name,
                Value: account.id
            }
        });

        if (selectedItem !== undefined){
            return (
                <td>
                    <Work365AutocompleteTextbox 
                        value={dataItem.crmName} 
                        data={autocompleteData} 
                        placeHolder={'Please select an account and try again.'}
                        onChange={(event: any) => onTextboxChange(dataItem.providerSpecificAccountId, event)} />
                </td>
            )
        }

        return (
            <td>
                <Work365Textbox value={dataItem.crmName} disabled/>
            </td>
        )
    };

    return (
        <Row>
            <Col className={'text-right'}>
                <Work365PrimaryButton title='Clear Matched Customers' style={{width: '185px', marginRight: '5px'}} onClick={() => unmatchAllCustomers(state.data)} />
                <Work365PrimaryButton title='Select All Customers' style={{width: '185px', marginRight: '5px'}} onClick={() => matchAllCustomers(state.data)} />
                <Work365PrimaryButton title='Match Selected Customers' style={{width: '185px'}} onClick={handleMatchCustomers} />
            </Col>
            <Col md={12} style={{marginTop: '5px'}}>
                <div>
                    <Work365Card>
                        <Work365PagingGrid 
                            filterable={false} 
                            showCheckbox={true}
                            excelref={undefined} 
                            onNextPage={onNextpage}
                            onPreviousPage={onPreviousPage}
                            state={state}
                            showEditButton={false}
                            onEditButtonClick={handleEditButtonClick}
                            onCheckClicked={onCheckboxClicked}
                            getCheckboxValue={isSelected}
                            columns={[
                                {
                                    field: 'providerName',
                                    title: 'Name'
                                }
                            ]}>
                                <GridColumn cell={filterTextbox} title='Work 365 Matched Account'/>
                            </Work365PagingGrid>
                    </Work365Card>
                </div>
            </Col>
        </Row>
    )
}

export default CustomersMatchGrid