import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { GridToolbar } from '@progress/kendo-react-grid';
import { loadMessages } from '@progress/kendo-react-intl';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Global } from '../../Helpers/Global';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import Work365Card from '../../Lib/Core/Work365Card';
import Work365PagingGrid from '../../Lib/Core/Work365PagingGrid';
import { Work365Column } from '../../Lib/Models/Work365Column';
import esMessages from '../../Assets/Internationalization/es.json';
import { GridState } from '../../Lib/Models/GridState';
import { Work365LargeDialog } from '../../Lib/Core/Work365LargeDialog';
import Work365BillingContractTemplateForm from '../../Forms/Work365BillingContractTemplateForm';

export type TemplateGridProps = {
    excelFileName?: string | undefined;
    method: string;
    baseUrl?: string | undefined;
    columns?: Work365Column[] | undefined;
    saveItem: (values: { [name: string]: any; }, event?: React.SyntheticEvent<any, Event> | undefined) => Promise<unknown>

    loadSaveModel: (values: { [name: string]: any; }, event?: React.SyntheticEvent<any, Event> | undefined) => Promise<unknown>
}

const TemplateGrid: React.FC<TemplateGridProps> = ({ saveItem, excelFileName, method, baseUrl, columns, loadSaveModel }) => {

    const [visible, setVisible] = React.useState(false);

    const PAGE_SIZE = 10;

    const locales = [{
        language: 'en-US',
        locale: 'en'
    }, {
        language: 'de',
        locale: 'de'
    },
    {
        language: 'ja',
        locale: 'ja'
    },
    {
        language: 'es-ES',
        locale: 'es'
    }
    ];

    //default to english
    var selectedlang = locales[0];
    let { i18n, t } = useTranslation(['billingContractTemplates']);

    var appSettings = Global.getAppSettings();
    var authService = Global.getAuthService(appSettings);

    locales.forEach(function (arrayItem) {
        if (arrayItem.locale == localStorage.getItem('i18nextLng')) {
            selectedlang = arrayItem;
        }
    });

    const [billingContractTemplate, setBillingContractTemplate] = React.useState();
    const [state, setState] = React.useState<GridState>(
        { 
            data: null, 
            dataState: null, 
            isLoading: false, 
            currentLocale: selectedlang, 
            lastUsedDataState: null,
            skip: 0,
            take: PAGE_SIZE,
            morePagesAvailable: false
        }
    );

    const fetchData = (event: string) => {
        let params = {
            $skip: state.skip,
            $top: state.take
        }

        if (event.toLowerCase() === 'increase') {
            params.$skip += state.take;
        }
        else if (event.toLowerCase() === 'decrease') {
            params.$skip -= state.take;
        }

        let url = `${baseUrl}`;
        Global.getApiService(appSettings, authService).ExecuteRequest(url, method, undefined, undefined, params).then((response: any) => {
            if (event.toLowerCase() === 'increase'){
                setState(
                    {
                        data: response.data.items,
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip + PAGE_SIZE,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    }
                );
            }
            else if (event.toLowerCase() === 'decrease'){
                setState(
                    {
                        data: response.data.items,
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip - PAGE_SIZE,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    }
                );
            }
            else{
                setState(
                    {
                        data: response.data.items,
                        dataState: undefined,
                        currentLocale: state.currentLocale,
                        isLoading: false,
                        lastUsedDataState: undefined,
                        skip: state.skip,
                        take: state.take,
                        morePagesAvailable: response.data.morePagesAvailable
                    }
                );
            }
        });
    }

    React.useEffect(() => {
        loadMessages(esMessages, 'es-ES');
        fetchData('');
    }, []);

    const handleSubmit = () => {
        setBillingContractTemplate(undefined);
        setVisible(!visible);
    };

    const handleUpdate = (dataItem: any) => {
        saveItem(dataItem).then(() => {
            setVisible(!visible);
            fetchData('UPDATE');
        });
    }

    const handleEditButtonClick = (id: any) => {
        loadSaveModel(id).then((response: any) => {
            setBillingContractTemplate(response.data);

            setVisible(!visible);
        });
    };

    const onNextpage  = () => {
        fetchData('INCREASE');
    };

    const onPreviousPage = () => {
        fetchData('DECREASE');
    };

    return (
        <div>
            <Work365Card>
                <Work365PagingGrid 
                    filterable={false} 
                    excelref={undefined} 
                    excelFileName={excelFileName} 
                    onNextPage={onNextpage}
                    onPreviousPage={onPreviousPage}
                    state={state}
                    showEditButton={true}
                    onEditButtonClick={handleEditButtonClick}
                    gridtoolbar={
                        <GridToolbar> 
                            <Work365PrimaryButton onClick={handleSubmit} style={{marginLeft: "auto"}} title="Add New" />
                        </GridToolbar>
                    }
                    columns={columns}>
                        
                    </Work365PagingGrid>
            </Work365Card>
            {visible && (
                <Work365LargeDialog onClose={handleSubmit} title="Create Billing Contract Template">
                    <div style={{marginTop: '14px'}}>
                        <Work365BillingContractTemplateForm onSubmit={handleUpdate} billingContractTemplate={billingContractTemplate} />
                    </div>
                </Work365LargeDialog>
            )}
        </div>
    )
}

export default TemplateGrid;