import React from 'react';
import { Global } from '../../Helpers/Global';
import { useLocation, useNavigate } from 'react-router-dom';
import DrawerContainer, { IDrawerItem } from '../../Lib/Core/Drawer/DrawerContainer';
import { ContactsTab, AddOnsTab, EntitlementTab, GeneralTab, LicenseTab, RegistrationTab, ProviderBillingTab, ScheduleJobsTab, pasreRevenueConfig, EnvironmentSettings } from './TenantDetails';
import { AsyncFocusBlur } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { Row } from 'react-bootstrap';
import OperationLogs from './OperationLogs';

interface IState {
    tenant: any,
    licencePlanOptions: unknown[] | undefined,
    customerOptions: unknown[] | undefined,
    billingContractSubscriptionIds: unknown[] | undefined,
    licenseplanSubscriptionIds: unknown[] | undefined,
    items: IDrawerItem[];
    children: JSX.Element | undefined;
}
const Tenant: React.FC = (p) => {
    const [state, setState] = React.useState({
        tenant: {},
        items: [] as IDrawerItem[],
    } as IState);

    const loc = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);
        var tenantId = loc.pathname.split('/').pop();
        apiClient.ExecuteRequest(`tenant/${tenantId}/get`, 'GET').then(async (tenantResponse: any) => {
            await apiClient.ExecuteRequest(`tenant/${tenantId}/licenseplan/get`, 'GET').then(async (licenseResponse: any) => {
                await apiClient.ExecuteRequest(`company/customers/get`, 'GET').then(async (customerResponse: any) => {
                    await apiClient.ExecuteRequest(`tenant/${tenantId}/solution/version/get`, 'GET').then(async (versionResponse: any) => {
                        apiClient.ExecuteRequest(`tenant/${tenantId}/tenantconfiguration/azurerevenueconfig/get`, 'GET').then((revenueConfigresponse: any) => {
                            // await apiClient.ExecuteRequest(`tenant/${tenantId}/billingcontract/get`, 'GET').then(async (billingContractSubscriptionIdsResponse: any) => {
                            //     await apiClient.ExecuteRequest(`tenant/${tenantId}/subscription/get`, 'GET').then((licensePlanSubscriptionIdsResponse: any) => {
                            if (tenantResponse.status != 200) {
                                navigate(`/Error/${tenantResponse.response.data}`);
                                return;
                            }

                            var resTenant = tenantResponse.data;
                            var licensePlans = licenseResponse.data;
                            var customers = customerResponse.data;

                            var revenueConfig = revenueConfigresponse.data;
                            revenueConfig = pasreRevenueConfig(revenueConfig);
                            //var billingContractSubscriptionIds = billingContractSubscriptionIdsResponse.data;
                            //var licensePlansSubscriptionIds = licensePlanSubscriptionIdsResponse.data;
                            var coreSolutionVersion = versionResponse.data.filter((x: any) => x.uniqueName == "work365")[0]?.version ?? 'Not installed';
                            var portalSolutionVersion = versionResponse.data.filter((x: any) => x.uniqueName == "work365selfserviceportal")[0]?.version ?? 'Not installed';
                            if (resTenant == null) {
                                navigate(`/Error/Environment Not Found`);
                                return;
                            }

                            const items = [
                                {
                                    text: 'General',
                                    selected: true,
                                    content: <GeneralTab
                                        key={'general'} 
                                        tenant={resTenant}
                                        licensePlanOptions={licensePlans}
                                        customerOptions={customers}
                                        coreSolutionVersion={coreSolutionVersion}
                                        portalSolutionVersion={portalSolutionVersion}
                                        revenueConfig={revenueConfig}
                                    // billingContractSubscriptionIdsOptions={billingContractSubscriptionIds}
                                    // licensePlanSubscriptionIdsOptions={licensePlansSubscriptionIds}
                                    />,
                                },
                                {
                                    text: 'Scheduled Jobs',
                                    selected: false,
                                    content: <ScheduleJobsTab key={'scheduledjobs'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'Provider Billing',
                                    pathName: 'Provider Billing',
                                    selected: false,
                                    content: <ProviderBillingTab key={'providerbilling'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'Entitlements',
                                    pathName: 'Entitlements',
                                    selected: false,
                                    content: <EntitlementTab key={'entitlements'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'License',
                                    selected: false,
                                    content: <LicenseTab key={'license'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'Add Ons',
                                    selected: false,
                                    content: <AddOnsTab key={'addons'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'Registration',
                                    selected: false,
                                    content: <RegistrationTab key={'registration'}  tenant={resTenant} />,
                                },
                                {
                                    text: 'Contacts',
                                    selected: false,
                                    content: <ContactsTab key={'contacts'}  tenant={resTenant} />
                                },
                                {
                                    text: 'Onboarding Logs',
                                    selected: false,
                                    content: <OperationLogs key={'logs'} tenantId={resTenant?.guid} />
                                }
                            ] as unknown[];
                            setState({
                                ...state,
                                tenant: resTenant,
                                licencePlanOptions: licensePlans,
                                customerOptions: customers,
                                items: items as IDrawerItem[],
                                children: <GeneralTab
                                    tenant={resTenant}
                                    licensePlanOptions={licensePlans}
                                    customerOptions={customers}
                                    coreSolutionVersion={coreSolutionVersion}
                                    portalSolutionVersion={portalSolutionVersion}
                                    revenueConfig={revenueConfig}
                                // billingContractSubscriptionIdsOptions={billingContractSubscriptionIds}
                                // licensePlanSubscriptionIdsOptions={licensePlansSubscriptionIds}
                                />
                                //     });
                                // });
                            });
                        });
                    });
                });
            })
        });
        return () => {
            console.log("Unmounted")
        };
    }, []);

    const handleSelect = (ev: { itemTarget: { props: { text: string; content: JSX.Element | undefined }; }; }) => {
        setState({
            ...state,
            items: state.items.map(item => {
                if (item.text === ev.itemTarget.props.text) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
                return item;
            }),
            children: ev.itemTarget.props.content,
        });
    };

    return (state.tenant?.endPointUrl ? <>
        <Row>
            <h3>{state.tenant?.endPointUrl ?? ''}</h3>
            <span style={{ textAlign: 'right' }}>
                <Button style={{ height: '32px', position: 'absolute', top: '4em', right: '2em' }} themeColor='primary' onClick={() => {
                    window.history.back();
                }}>Back</Button>
            </span>
        </Row>
        <div style={{ lineHeight: '10px' }}>
            <br />
        </div>
        <div style={{ maxHeight: 'calc(100vh - 9em)', maxWidth: 'calc(100vw - 15em)', overflowY: 'auto', padding: '5px' }}>
            <DrawerContainer
                items={state.items}
                onSelect={handleSelect}
                children={state.children}
            />
        </div>
    </> : <></>);
}

export default Tenant;