import React from 'react';
import Work365StackLayout from './Work365StackLayout';
import { Button } from '@progress/kendo-react-buttons';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Utils } from '../../../Services/Utils';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';

export interface IWork365StockListLayout {
    data: IWork365StockListItem[];
    onClick?: (event: IWork365StockListEvent) => void;
    onEditDone?: (event: IWork365StockListEvent) => void;
    prefix?: string | undefined;
    height?:string | undefined;
    selectable?: boolean,
    editable?: boolean
}

export interface IWork365StockListItem {
    id: any,
    name: string,
    description: string,
    selected: boolean,
    edit: boolean
}

export interface IWork365StockListEvent extends React.MouseEventHandler<HTMLDivElement> {
    event: React.MouseEventHandler<HTMLDivElement> | undefined,
    dataItem: any,
    selected: any,
}

const Work365StackListLayout: React.FC<IWork365StockListLayout> = (p: IWork365StockListLayout) => {
    if (p.data.length > 0 && !p.data.map((d: IWork365StockListItem) => d.selected).includes(true))
        p.data[0].selected = true;

    const [items, setData] = React.useState([...p.data]);

    const onRowClick = (e: any, i: IWork365StockListItem) => {
        i.selected = true;

        var data = items.map(d => {
            if (d.id === i.id) {
                d.selected = true;
            } else {
                d.selected = false;
            }

            if (!(i.edit ?? false)) {
                if ((d.edit ?? false)) {
                    onEditIsDone(e, d);
                }
                d.edit = false;

            }

            return d;
        });

        setData(data);
        var evt = {
            dataItem: data,
            selected: i,
            event: e
        } as IWork365StockListEvent;

        if (p.onClick)
            p.onClick(evt);
    }

    const onEditIsDone = (e: any, i: IWork365StockListItem) => {
        var evt = {
            dataItem: items,
            selected: i,
            event: e
        } as IWork365StockListEvent;

        if (p.onEditDone)
            p.onEditDone(evt);
    }

    const updatePlanName = (e: any, i: IWork365StockListItem) => {
        var data = items.map((d: IWork365StockListItem) => {
            if (d.id === i.id) {
                d.name = e.target.value;
            }
            return d;
        });
        setData(data);
    }
    const updatePlanDescription = (e: any, i: IWork365StockListItem) => {
        var data = items.map((d: IWork365StockListItem) => {
            if (d.id === i.id) {
                d.description = e.target.value;
            }
            return d;
        });
        setData(data);
    }

    const toStackListItem = (i: IWork365StockListItem) => {
        var backColor = '';
        var fontColor = '';
        if (p.selectable ?? true) {
            backColor = (i.selected ?? false) ? '#3780BC' : 'white';
            fontColor = (i.selected ?? false) ? 'white' : '';
        }

        return <>
            <div style={{
                borderBottom: '1px solid #c9c5c5',
                padding: '10px',
                backgroundColor: backColor,
                color: fontColor,
                margin: 'auto 0',
                cursor: 'pointer',
                verticalAlign: 'middle',
            }}
                onClick={(e: any) => { onRowClick(e, i) }}
            >
                {
                    (i.edit ?? false) == false ? <>
                        <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                            {`${p.prefix ?? ''}${Utils.PadLeft(i.id, 5)}: ${i.name}`}
                            {
                                (p.editable ?? false) == true ? <>
                                    <Button
                                        icon='pencil'
                                        size={'small'}
                                        fillMode={'link'}
                                        themeColor={(i.selected ?? false) ? 'inverse' : 'primary'}
                                        title='Edit'
                                        onClick={() => {
                                            var newItems = items.map((item: IWork365StockListItem) => {
                                                if (item.id == i.id) {
                                                    item.edit = true;
                                                } else {
                                                    item.edit = false;
                                                }
                                                return item;
                                            })
                                            setData(newItems)
                                        }}
                                    />
                                </> : <></>
                            }

                        </div>

                        {
                            i.description ?? <div>{i.description}</div>
                        }
                    </> : <>
                        <FieldWrapper >
                            <Label>
                                PLAN: {Utils.PadLeft(i.id, 5)}:
                            </Label>
                            <Input defaultValue={i.name} onBlur={(e) => { updatePlanName(e, i) }} />
                        </FieldWrapper>
                        <TextArea rows={4} defaultValue={i.description} onBlur={(e) => { updatePlanDescription(e, i) }} />
                    </>
                }
            </div>
        </>
    }

    return <>
        <Work365StackLayout
            style={{ height: p.height ?? 'calc(50h)', overflowY: 'scroll' }}
            orientation='vertical'
        >
            {
                items.map((i: any) => {
                    return toStackListItem(i)
                })
            }
        </Work365StackLayout>
    </>
}

export default Work365StackListLayout;