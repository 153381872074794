import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Col, Container, Row } from 'react-bootstrap';
import { FormInput, EmailValidator, FormMaskedTextBox, phoneValidator, RequiredValidator, DisabledInput, ComboboxInput } from '../Lib/Core/Work365FormInputs';
import { User } from '../Lib/Models/User';
import Work365Card from '../Lib/Core/Work365Card';
import { Company } from '../Lib/Models/Company';
import { Global } from '../Helpers/Global';

export type Work365CompanyProfileFormProps = {
    company: Company | undefined;
    onSubmit: (values: { [name: string]: any; }, event?: SyntheticEvent<any, Event> | undefined) => void;
}

const Work365CompanyProfileForm: React.FC<Work365CompanyProfileFormProps> = ({ company, onSubmit}) => {

    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    let { i18n, t: tCompanyProfile } = useTranslation(['CompanyProfile']);
    let { t: tlanguages } = useTranslation(['languages']);

    const appSettings = Global.getAppSettings();
    const authService = Global.getAuthService(appSettings);

    useEffect(() => {
        Global.getApiService(appSettings, authService).ExecuteRequest('country/get', 'GET').then((response: any) => {
            setCountries(response.data);
        });

        Global.getApiService(appSettings, authService).ExecuteRequest('currency/get', 'GET').then((response: any) => {
            setCurrencies(response.data);
        });
      }, []);

    const languages = i18n
        .languages
        .map((language) => (tlanguages(language)))
        .map((language) => {
            return {
                name: language,
                value: language
            }
        });

    var formCompany = {
        id: company?.id,
        name: company?.name,
        externalAccountId: company?.externalAccountId,
        activeDirectoryId: company?.activeDirectoryId,
        website: company?.website,
        createdBy: company?.createdBy,
        createdOnUtc: company?.createdOnUtc,
        modifiedBy: company?.modifiedBy,
        modifiedOnUtc: company?.modifiedOnUtc,
        primaryContactFirstName: company?.primaryContactFirstName,
        primaryContactLastName: company?.primaryContactLastName,
        primaryContactPhoneNumber: company?.primaryContactPhoneNumber,
        primaryContactEmail: company?.primaryContactEmail,
        preferredLanguage: company?.preferredLanguage,
        billToCompanyName: company?.billToCompanyName,
        billToContactFirstName: company?.billToContactFirstName,
        billToContactLastName: company?.billToContactLastName,
        billToContactPhoneNumber: company?.billToContactPhoneNumber,
        billToContactEmail: company?.billToContactEmail,
        billToPONumber: company?.billToPONumber,
        companyVatId: company?.companyVatId,
        billingCurrency: company?.billingCurrency,
        address: company?.address,
        billToAddress: company?.billToAddress,

        preferredLanguageOption: undefined,
        billingCurrencyOption: undefined,
        addressCountryOption: undefined,
        billToAddressCountryOption: undefined
    };

    if (formCompany?.preferredLanguage != undefined){
        const language: any = languages.find(
            (b: any) => b.value === formCompany.preferredLanguage
        );

        if (language) {
            formCompany.preferredLanguageOption = language;
        }
    }

    if (formCompany?.billingCurrency != undefined){
        const currency: any = currencies.find(
            (b: any) => b.currencyIsoCode === formCompany.billingCurrency
        );

        if (currency) {
            formCompany.billingCurrencyOption = currency;
        }
    }

    if (formCompany?.address?.country != undefined){
        const country: any = countries.find(
            (b: any) => b.countryAlpha2Code === formCompany?.address?.country
        );

        if (country){
            formCompany.addressCountryOption = country;
        }
    }

    if (formCompany?.billToAddress?.country != undefined) {
        const country: any = countries.find(
            (b: any) => b.countryAlpha2Code === formCompany?.billToAddress?.country
        );

        if (country){
            formCompany.billToAddressCountryOption = country;
        }
    }

    return  (
        <Work365Card>
                <Form initialValues={formCompany} key={JSON.stringify(formCompany)} onSubmit={onSubmit} render={(formRenderProps) => (
                    <FormElement>
                        <Row>{formRenderProps.visited && formRenderProps.errors && formRenderProps.errors.VALIDATION_SUMMARY && <div className={'k-messagebox k-messagebox-error'}>{formRenderProps.errors.VALIDATION_SUMMARY}</div>}</Row>
                        
                        <b>Company Details</b>
                        <br />

                        <Field name={'name'} component={DisabledInput} label={tCompanyProfile('companyName')} />
                        <Field name={'website'} component={FormInput} label={tCompanyProfile('defaultDomain')} />
                        <Field name={'preferredLanguageOption'} component={ComboboxInput} data={languages} label={tCompanyProfile('preferredLanguage')} />
                        
                        <br />
                        <b>Primary Contact</b>
                        <br />
                        
                        <Row>
                            <Col>
                                <Field name={'primaryContactFirstName'} component={FormInput} label={tCompanyProfile('primaryContactFirstName')} />
                            </Col>
                            <Col>
                                <Field name={'primaryContactLastName'} component={FormInput} label={tCompanyProfile('primaryContactLastName')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'primaryContactPhoneNumber'} component={FormInput} label={tCompanyProfile('primaryContactPhoneNumber')} />
                            </Col>
                            <Col>
                                <Field name={'primaryContactEmail'} component={FormInput} label={tCompanyProfile('primaryContactEmail')} />
                            </Col>
                        </Row>

                        <br />
                        <b>Company Address</b>
                        <br />

                        <Row>
                            <Col>
                                <Field name={'address.street1'} component={FormInput} label={tCompanyProfile('addressLine1')} />
                            </Col>
                            <Col>
                                <Field name={'address.street2'} component={FormInput} label={tCompanyProfile('addressLine2')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'addressCountryOption'} component={ComboboxInput} data={countries} label={tCompanyProfile('addressCountry')} />
                            </Col>
                            <Col>
                                <Field name={'address.state'} component={FormInput} label={tCompanyProfile('addressState')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'address.city'} component={FormInput} label={tCompanyProfile('addressCity')} />
                            </Col>
                            <Col>
                                <Field name={'address.postalCode'} component={FormInput} label={tCompanyProfile('addressPostalCode')} />
                            </Col>
                        </Row>
                        
                        <br />
                        <b>Billing Contact</b>
                        <br />
                        
                        <Row>
                            <Col>
                                <Field name={'billToContactFirstName'} component={FormInput} label={tCompanyProfile('primaryContactFirstName')} />
                            </Col>
                            <Col>
                                <Field name={'billToContactLastName'} component={FormInput} label={tCompanyProfile('primaryContactLastName')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'billToContactPhoneNumber'} component={FormInput} label={tCompanyProfile('primaryContactPhoneNumber')} />
                            </Col>
                            <Col>
                                <Field name={'billToContactEmail'} component={FormInput} label={tCompanyProfile('primaryContactEmail')} />
                            </Col>
                        </Row>

                        <br />
                        <b>Billing Address</b>
                        <br />

                        <Row>
                            <Col>
                                <Field name={'billToAddress.street1'} component={FormInput} label={tCompanyProfile('addressLine1')} />
                            </Col>
                            <Col>
                                <Field name={'billToAddress.street2'} component={FormInput} label={tCompanyProfile('addressLine2')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'billToAddressCountryOption'} component={ComboboxInput} data={countries} label={tCompanyProfile('addressCountry')} />
                            </Col>
                            <Col>
                                <Field name={'billToAddress.state'} component={FormInput} label={tCompanyProfile('addressState')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name={'billToAddress.city'} component={FormInput} label={tCompanyProfile('addressCity')} />
                            </Col>
                            <Col>
                                <Field name={'billToAddress.postalCode'} component={FormInput} label={tCompanyProfile('addressPostalCode')} />
                            </Col>
                        </Row>

                        <div className="k-form-buttons text-right">
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                                {tCompanyProfile('Save')}
                            </button>
                        </div>
                    </FormElement>
                )}/>
        </Work365Card>)
}

export default Work365CompanyProfileForm;