import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Work365SubmitPanel from '../../Lib/Common/Work365SubmitPanel';
import { useParams } from 'react-router-dom';
import { Global } from '../../Helpers/Global';
import Work365OperationInProgressModel from '../../Lib/Common/Work365OperationInProgressModel';
import Work365Title from '../../Lib/Core/Work365Title';
import Work365Label from '../../Lib/Core/Work365Label';
import Work365Switch from '../../Lib/Core/Work365Switch';
import Work365PrimaryButton from '../../Lib/Core/Buttons/Work365PrimaryButton';
import CustomersMatchGrid from '../../Lib/Common/CustomersMatchGrid';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';

type CustomerSyncGridProps = {
  tenantId? : string;
  providerId? : string;
};

const CustomerSyncGrid: React.FC<CustomerSyncGridProps> = ({
  tenantId,
  providerId
}) => {
  const [operationId, setOperationId] = useState();

  let opId: any = undefined;
  const [inProgress, setInProgress] = useState(false); 
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState(0);

  const [crmCustomers, setCrmCustomers] = useState<any[]>([]);
  const [crmContacts, setCrmContacts] = useState<any[]>([]);

  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
  const [matchedAccounts, setMatchedAccounts] = React.useState<any[]>([]);

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  const operationType = 3;

  let intervalNumber: any = undefined;

  const checkProgress = () => {

    if (operationId != undefined){
      opId = operationId;
    }
    
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/get/${opId}`, 'GET', undefined, false)
      .then((response: any) => {
        if (response.data != undefined){
          setProgress(response.data.progressPercent);

          if (response.data.operationStatus != 'P'){
            clearInterval(intervalNumber);
            setCompleted(true);

            intervalNumber = undefined;
          }
        }
      });
  };

  function saveAdditionalParameters(matchedAccountsTemp: any[]) {
    let body = {
      providerId: providerId,
      matchedAccounts: matchedAccountsTemp
    }

    // TODO : need to convert this to use the body to pass the additional parameters instead of a url parameter. For some reason this didn't work.
    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/operation/${operationId}/saveadditionalparameters/${JSON.stringify(body)}`, 'POST')
      .then((response: any) => {
        
      });
  }

  function handleNewOperation() {
    setInProgress(true);
          if (intervalNumber != undefined) {
            clearInterval(intervalNumber);
            intervalNumber = undefined;
          }

          intervalNumber = setInterval(checkProgress, 5000);
  };

  useEffect(() => {
    getOperation();

    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/account/get`, 'GET')
      .then((response: any) => {
        setCrmCustomers(response.data);
      });

    Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/contact/get`, 'GET')
      .then((response: any) => {
        setCrmContacts(response.data);
      });

    return () => {
      if (intervalNumber != undefined) {
        clearInterval(intervalNumber);
        intervalNumber = undefined;
      }
    };
  }, []); 

  function getOperation() {
    let operationContext = {
      operationType: operationType
    };

    Global.getApiService(appSettings, authService)
    .ExecuteRequest(`tenant/${tenantId}/operation/bytype?operationType=${operationType}`, 'GET')
    .then((response: any) => {
      if (response?.data?.additionalParameters){
        var additionalParametersJson = JSON.parse(response.data.additionalParameters);
        var selectedAccountIds = additionalParametersJson?.matchedAccounts?.map((item: any) => item.providerCustomerId);
        setMatchedAccounts(additionalParametersJson.matchedAccounts);
        setSelectedAccounts(selectedAccountIds);
      }

      if (response.data == undefined){
        Global.getApiService(appSettings, authService)
        .ExecuteRequest(`tenant/${tenantId}/operation/create`, 'POST', operationContext)
        .then((response: any) => {
          opId = response.data
          setOperationId(response.data);
        });
      }
      else {
        opId = response.data.operationId
        setOperationId(response.data.operationId);

        if (response.data.operationStatus == 'P'){
          setInProgress(true);
          setProgress(response.data.progressPercent);

          if (intervalNumber != undefined) {
            clearInterval(intervalNumber);
            intervalNumber = undefined;
          }

          intervalNumber = setInterval(checkProgress, 5000);
        }
      }
    });
  }

  function handleCheckboxClicked(object: any, event: CheckboxChangeEvent) {
    if (event.value){
      selectedAccounts.push(object.providerSpecificAccountId);
      setSelectedAccounts(selectedAccounts);
    }
    else{
        setSelectedAccounts(removeStringFromArray(selectedAccounts, object.providerSpecificAccountId));

        const objectToRemove = matchedAccounts.find((item) => item.providerCustomerId === object.providerSpecificAccountId);

        if (objectToRemove) {
          const updatedData = matchedAccounts.filter((item) => item.providerCustomerId !== object.providerSpecificAccountId);
          setMatchedAccounts(updatedData);
          saveAdditionalParameters(updatedData);
        }
    }
  }

  function removeStringFromArray(array: string[], valueToRemove: string) {
    return array.filter(item => item !== valueToRemove);
  }

  function newMatchCustomer(customer: any, providerAccount: any){
    return {
      crmCustomerId: customer.id,
      crmCustomerName: customer.name,
      providerCustomerId: providerAccount.providerSpecificAccountId,
    }
  }

  async function matchAllCustomers(customers: any)
  {
    var providerSpecificAccountIds = customers.map((account: any) => account.providerSpecificAccountId);
    let newSelectedAccounts = [...selectedAccounts, ...providerSpecificAccountIds];

    setSelectedAccounts(newSelectedAccounts);
  }

  function unmatchAllCustomers(customers: any)
  {
    setMatchedAccounts([]);
    setSelectedAccounts([]);
    saveAdditionalParameters([]);
  }

  async function matchAccount(accountId: any)
  {
    let created = false;

    let response = await Global.getApiService(appSettings, authService)
      .ExecuteRequest(`tenant/${tenantId}/provider/${providerId}/provideraccount/${accountId}/billingprofile/get`, 'GET');

    // Matching based on name
    let customerByName : any[] = crmCustomers.filter(x => x?.name?.toLowerCase().includes(response.data.providerName.toLowerCase()))

    if (customerByName != undefined && customerByName.length != 0){
      return newMatchCustomer(customerByName[0], response.data);
    }

    // Matching based on email
    let customerByEmail = crmCustomers.filter(x => x?.email?.toLowerCase().includes(response.data.email.toLowerCase()));

    if (customerByEmail != undefined && customerByEmail.length != 0) {
      return newMatchCustomer(customerByEmail[0], response.data);
    }

    // Match by Contact (email and domain)
    for (let i = 0; i < crmCustomers.length; i++) {
      const customer = crmCustomers[i];

      let crmCustomerContacts = crmContacts.filter(x => x?.account?.id === customer.id);
      let crmContactByEmail = crmCustomerContacts.filter(x => x?.email?.toLowerCase().includes(response.data.email.toLowerCase()));

      if (crmContactByEmail != undefined && crmContactByEmail.length != 0) {
        return newMatchCustomer(customer, response.data);
      }

      // Match by Website
      let matchByDomain = response.data.customDomains.filter((x: any) => x?.includes(customer.website));

      if (matchByDomain != undefined && matchByDomain.length != 0) {
        return newMatchCustomer(customer, response.data);
      }
    };

    if (!created){
      let newCustomer = {
        crmCustomerId: 'Create New',
        crmCustomerName: 'Create New',
        providerCustomerId: response.data.providerSpecificAccountId,
      };

      return newCustomer;
    }
  }

  function handleMatchCustomers() {
    var newMatchedAccounts: any[] = [];

    let accountUpdates = selectedAccounts.map(async accountId => {
      var newAccount = await matchAccount(accountId);
      newMatchedAccounts.push(newAccount);
    });
    
    Promise.all(accountUpdates)
      .then(() => {
        // All updates complete
        var newMatchedAccountList = matchedAccounts.concat(newMatchedAccounts);
        setMatchedAccounts(newMatchedAccountList);
        saveAdditionalParameters(newMatchedAccountList);
      })
      .catch(err => {
        console.error(err);
      });
  }

  const handleTextboxValueChange = (providerId: any, event: any) => {
    let value = event.defaultValue;

    if (value == 'Create New') {
      let newMatchedAccount = {
        crmCustomerId: 'Create New',
        crmCustomerName: 'Create New',
        providerCustomerId: providerId,
      };

      let matchedAccountsTempList = matchedAccounts;
      matchedAccountsTempList.push(newMatchedAccount);

      setMatchedAccounts((prevArray) => [...prevArray, newMatchedAccount]);
      saveAdditionalParameters(matchedAccountsTempList);
      return;
    }

    let crmAccount = crmCustomers.filter(x => x.name === value);
    let filteredMatchedAccounts = matchedAccounts.filter(x => x.providerCustomerId === providerId);
    if (crmAccount != undefined && crmAccount.length != 0){
      let newMatchedAccount = {
        crmCustomerId: crmAccount[0].id,
        crmCustomerName: crmAccount[0].name,
        providerCustomerId: providerId
      }

      let matchedAccountsTempList = matchedAccounts;
      matchedAccountsTempList.push(newMatchedAccount);

      setMatchedAccounts((prevArray) => [...prevArray, newMatchedAccount]);
      saveAdditionalParameters(matchedAccountsTempList);
      return;
    }
    else if (filteredMatchedAccounts != undefined && filteredMatchedAccounts.length != 0){
      let newMatchedAccountList = matchedAccounts.filter(x => x.providerCustomerId !== providerId);
      setMatchedAccounts(newMatchedAccountList);
      saveAdditionalParameters(newMatchedAccountList);

      return;
    }
  };

  return (
    <div style={{marginTop: '8px'}}>
        {inProgress && (
          <Work365OperationInProgressModel isCompleted={completed} value={progress}/>
        )}

        <Work365Title title='Customer Matching'/>
        <br />
        <Work365Label>Select accounts you want to match. After you select all of the accounts you want to match, click on the match button. This will automatically match accounts in your system or if we can't find the account it will create a new account. After that is done, click on the 'complete' button. This will match all selected accounts, then for each new account it will create a new contact, and then sync all customer agreements.</Work365Label>
        <CustomersMatchGrid 
          tenantId={tenantId}
          providerId={providerId}
          handleCheckboxClicked={handleCheckboxClicked}
          onTextboxChange={handleTextboxValueChange}
          selectedAccounts={selectedAccounts}
          matchedAccounts={matchedAccounts} 
          crmCustomers={crmCustomers}
          unmatchAllCustomers={unmatchAllCustomers}
          matchAllCustomers={matchAllCustomers} 
          handleMatchCustomers={handleMatchCustomers} />
        <Work365SubmitPanel
          operationId={operationId} 
          tenantId={tenantId}
          handleNewOperation={handleNewOperation} />
    </div>
  );
};

export default CustomerSyncGrid;
