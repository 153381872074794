import React from 'react';
import { useParams } from 'react-router-dom'

import './ViewStyles/Error.Styles.css'

const CompanyNotRegistered: React.FC = () => {
    const { errorMessage } = useParams();

    return  <div className="error-container">
                <p>{errorMessage}</p>
            </div>
}
export default CompanyNotRegistered;