import React from "react";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import "./Work365TextboxStyles.css";

export interface Work365TextboxProps {
  textboxTitle?: string | undefined;
  valid?: boolean | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  id?: string | undefined;
  disabled?: boolean | undefined;
  maxLength?: number | undefined;
  hintId?: string | undefined;
  errorId?: string | undefined;
  value?: string | undefined;
  validationMessage?: string | undefined;
  hint?: string | undefined;
  required?: boolean | undefined;
  placeHolder?: string | undefined;
  tooltip?: string | undefined;
  style?: React.CSSProperties | undefined;
  onChange?: ((event: InputChangeEvent) => void) | undefined;
  className?: string | undefined
}

const PrivateWork365Textbox: React.FC<Work365TextboxProps> = ({
  textboxTitle,
  valid,
  type,
  id,
  disabled,
  maxLength,
  hintId,
  errorId,
  value,
  validationMessage,
  hint,
  placeHolder,
  tooltip,
  style,
  className,
  onChange,
}: Work365TextboxProps) => {
  const showHint = hint != undefined;
  const showValidationMessage = validationMessage != undefined;

  console.log('value : ' + value);
  return (
    <div>
      <Input
        type={type}
        id={id}
        disabled={disabled}
        maxLength={maxLength}
        ariaDescribedBy={`${hintId} ${errorId}`}
        placeholder={placeHolder}
        title={tooltip}
        style={style}
        defaultValue={value}
        onChange={onChange}
        className={className}
        value={value}
      ></Input>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

const Work365Textbox: React.FC<Work365TextboxProps> = ({
  textboxTitle,
  valid,
  type,
  id,
  disabled,
  maxLength,
  hintId,
  errorId,
  value,
  validationMessage,
  hint,
  placeHolder,
  tooltip,
  style,
  onChange,
}: Work365TextboxProps) => {
  if (textboxTitle == undefined) {
    return (
      <PrivateWork365Textbox
        valid={valid}
        type={type}
        id={id}
        disabled={disabled}
        maxLength={maxLength}
        hintId={hintId}
        errorId={errorId}
        value={value}
        validationMessage={validationMessage}
        hint={hint}
        placeHolder={placeHolder}
        tooltip={tooltip}
        style={style}
        onChange={onChange}
      />
    );
  }

  return (
    <FieldWrapper>
      // TODO : Add a label
      <div className="k-form-field-wrap">
        <PrivateWork365Textbox
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          maxLength={maxLength}
          hintId={hintId}
          errorId={errorId}
          value={value}
          validationMessage={validationMessage}
          hint={hint}
          placeHolder={placeHolder}
          tooltip={tooltip}
          style={style}
          onChange={onChange}
        />
      </div>
    </FieldWrapper>
  );
};

export default Work365Textbox;
