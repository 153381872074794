import { Component, useState } from 'react';

export default class OverviewPanel extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12 stretch-card">
                    <div className="card">
                        <div className="card-body dashboard-tabs p-0">
                            <ul className="nav nav-tabs px-4" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="sales-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="false">Subscription</a>
                                </li>

                            </ul>
                            <div className="tab-content py-0 px-0">
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                    <div className="d-flex flex-wrap justify-content-xl-between">
                                        <div className="d-none d-xl-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-calendar-heart icon-lg mr-3 text-primary"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Start date</small>
                                                <div className="dropdown">
                                                    <a className="btn btn-secondary dropdown-toggle p-0 bg-transparent border-0 text-dark shadow-none font-weight-medium" href="#" role="button" id="dropdownMenuLinkA" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <h5 className="mb-0 d-inline-block">Last 12 hours</h5>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLinkA">
                                                        <a className="dropdown-item" href="#">Last 24 hours</a>
                                                        <a className="dropdown-item" href="#">Last week</a>
                                                        <a className="dropdown-item" href="#">Last month</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-currency-usd mr-3 icon-lg text-danger"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Revenue</small>
                                                <h5 className="mr-2 mb-0">$5525</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-file-document-box mr-3 icon-lg text-success"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Invoices Generated </small>
                                                <h5 className="mr-2 mb-0">50</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-email mr-3 icon-lg text-warning"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Invoices Sent</small>
                                                <h5 className="mr-2 mb-0">45</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-account-multiple mr-3 icon-lg text-danger"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Active Users</small>
                                                <h5 className="mr-2 mb-0">549</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                                    <div className="d-flex flex-wrap justify-content-xl-between">
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-file-document-box mr-3 icon-lg text-warning"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Assigned Subscriptions</small>
                                                <h5 className="mr-2 mb-0">599</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-file-document-box mr-3 icon-lg text-success"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Unused Subscriptions</small>
                                                <h5 className="mr-2 mb-0">401</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-file-document-box mr-3 icon-lg text-danger"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Purchased Subscriptions</small>
                                                <h5 className="mr-2 mb-0">1000</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                                            <i className="mdi mdi-currency-usd mr-3 icon-lg text-danger"></i>
                                            <div className="d-flex flex-column justify-content-around">
                                                <small className="mb-1 text-muted">Current Subscription Spend</small>
                                                <h5 className="mr-2 mb-0">$59200</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}