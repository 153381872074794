import React from 'react';
import UsageCharts from '../../Components/Tenant/UsageSummaries/UsageCharts';
import { Global } from '../../Helpers/Global';
import OverviewPanel from '../../Components/Tenant/Home/OverviewPanel';

const TenantHomePage: React.FC = () => {
    React.useEffect(() => {

        var appSettings = Global.getAppSettings();
        var authService = Global.getAuthService(appSettings);
        var apiClient = Global.getApiService(appSettings, authService);

        apiClient.ExecuteRequest('/UsageSummary', 'GET').then((response: any) => {
          console.log(response);
        });

        return () => {
          console.log("Unmounted")
        };
    }, []);

    return <div>
      <OverviewPanel />
      <UsageCharts />
    </div>

}

export default TenantHomePage;