import React, { useEffect } from "react";
import { Global } from "../../Helpers/Global";
import Work365PagingGrid from "../../Lib/Core/Work365PagingGrid";
import { GridToolbar } from "@progress/kendo-react-grid";
import Work365PrimaryButton from "../../Lib/Core/Buttons/Work365PrimaryButton";
import { Work365LargeDialog } from "../../Lib/Core/Work365LargeDialog";
import Work365CreateInternalUserForm from "../../Forms/Work365CreateInternalUserForm";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";

const InternalUsers: React.FC = () => {
  const [internalUsers, setInternalUsers] = React.useState<any[]>([]);
  const [internalUser, setInternalUser] = React.useState<any>();

  const [selectedRoles, setSelectedRoles] = React.useState<any[]>([]);

  const [visible, setVisible] = React.useState(false);

  const appSettings = Global.getAppSettings();
  const authService = Global.getAuthService(appSettings);

  const gridColumns = [
    {
        field: 'loginName',
        title: 'Email'
    },
    {
        field: 'isAdministrator',
        title: 'Administrator'
    },
    {
        field: 'isJobController',
        title: 'Job Controller'
    },
    {
      field: 'isManager',
      title: 'Manager'
    },
    {
      field: 'isSupportPerson',
      title: 'Support Person'
    }
  ]

  const locales = [{
            language: 'en-US',
            locale: 'en'
        }, {
            language: 'de',
            locale: 'de'
        },
        {
            language: 'ja',
            locale: 'ja'
        },
        {
            language: 'es-ES',
            locale: 'es'
        }
    ];

  var selectedlang = locales[0];

  const onNextPage = () => {
    return;
  };

  const onPreviousPage = () => {
    return;
  }

  const handleEditButtonClick = (id: any) => {
    Global.getApiService(appSettings, authService).ExecuteRequest(`adminappuser/get/${id}`, 'GET').then((response: any) => {
        setInternalUser(response.data);

        let userSelectedRoles: any[] = [];

        if (response.data.isAdministrator) {
            userSelectedRoles.push('Administrator');
        }

        if (response.data.isJobController) {
          userSelectedRoles.push('JobController');
        }

        if (response.data.isManager) {
          userSelectedRoles.push('Manager');
        }

        if (response.data.isSupportPerson) {
          userSelectedRoles.push('Support');
        }

        setSelectedRoles(userSelectedRoles);
        setVisible(!visible);
    });
  };

  const handleSubmit = () => {
    setInternalUser(undefined);
    setSelectedRoles([]);
    setVisible(!visible);
  }

  const refreshInternalUsers = () => {
    Global.getApiService(appSettings, authService).ExecuteRequest('adminappuser/get', 'GET')
        .then((response: any) => {
            response.data.forEach((obj: any, index: any) => {
              obj.id = obj.loginName;
            });
          
            setInternalUsers(response.data);
        });
  }

  const onCheckboxClicked = (object: any, event: CheckboxChangeEvent) => {
    if (event.value) {
        selectedRoles.push(object.id);
        setSelectedRoles(selectedRoles);
    }
    else{
        setSelectedRoles(removeStringFromArray(selectedRoles, object.id));
    }
  }

  function removeStringFromArray(array: string[], valueToRemove: string) {
    return array.filter(item => item !== valueToRemove);
  }

  const isSelected = (obj: any) => {
    var roleFilter = selectedRoles.filter(item => item === obj.id);
    return roleFilter.length === 1;
  }

  const handleUpdate = (dataItem: any) => {
    debugger;
    let newOrUpdatedInternalUser: any = {
        loginName: dataItem?.loginName,
    };

    if (selectedRoles.includes('Administrator')) {
        newOrUpdatedInternalUser.isAdministrator = true;
    }
    else{
        newOrUpdatedInternalUser.isAdministrator = false;
    }

    if (selectedRoles.includes('JobController')) {
        newOrUpdatedInternalUser.isJobController = true;
    }
    else{
        newOrUpdatedInternalUser.isJobController = false;
    }

    if (selectedRoles.includes('Manager')) {
      newOrUpdatedInternalUser.isManager = true;
    }
    else{
      newOrUpdatedInternalUser.isManager = false;
    }

    if (selectedRoles.includes('Support')) {
      newOrUpdatedInternalUser.isSupportPerson = true;
    }
    else{
      newOrUpdatedInternalUser.isSupportPerson = false;
    }

    Global.getApiService(appSettings, authService).ExecuteRequest(`adminappuser/createorupdate`, 'POST', newOrUpdatedInternalUser)
        .then((x: any) => {
            setInternalUser(undefined);
            setSelectedRoles([]);
            setVisible(!visible);
            refreshInternalUsers();
        });
  }

  useEffect(() => {
    refreshInternalUsers();
  }, []);

  return (
    <div style={{ marginTop: "7px" }}>
        <Work365PagingGrid
            filterable={false}
            excelref={undefined}
            state={{
                data: internalUsers,
                dataState: undefined,
                currentLocale: selectedlang,
                lastUsedDataState: null,
                skip: 0,
                take: 10000,
                morePagesAvailable: false,
                isLoading: false
            }} 
            columns={gridColumns}
            showEditButton={true}
            onEditButtonClick={handleEditButtonClick}
            gridtoolbar={
                <GridToolbar> 
                    <Work365PrimaryButton onClick={handleSubmit} style={{marginLeft: "auto"}} title="Add New" />
                </GridToolbar>
            }
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}>

            </Work365PagingGrid>
            {visible && (
                <Work365LargeDialog onClose={handleSubmit} title="Create new Internal User">
                    <div style={{marginTop: '14px'}}>
                        <Work365CreateInternalUserForm 
                            onSubmit={handleUpdate} 
                            user={internalUser}
                            onCheckboxClicked={onCheckboxClicked}
                            isSelected={isSelected}
                            selectedRoles={selectedRoles}/>
                    </div>
                </Work365LargeDialog>
            )}
    </div>
  );
};

export default InternalUsers;
