import { Component } from 'react';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { CSSProperties } from 'styled-components';

export type Work365CheckboxProps = {
  id: string;
  isDisabled?: boolean | undefined;
  label?: string | undefined;
  defaultChecked?: boolean | false;
  style?: CSSProperties | undefined
  onChange?: ((event: CheckboxChangeEvent) => void) | undefined;
};

export default class Work365Checkbox extends Component<Work365CheckboxProps> {
  render() {
    var isDisabled = false;

    if (this.props.isDisabled) {
      isDisabled = true;
    }
    return (
      <div style={this.props.style}>
        <Checkbox
          size={'small'}
          id={this.props.id}
          label={this.props.label}
          defaultChecked={this.props.defaultChecked}
          disabled={isDisabled}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
